import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  GetPDFSVG,
  SelectToolOpts,
  SetTool, UnDo,
} from 'src/app/canvas/canvas.actions';
import { ShowAsset } from 'src/app/pages/pages.actions';

import { PagesState } from 'src/app/pages/pages.model';
import { isPhone, showAsset } from 'src/app/pages/pages.selectors';

import { WSSService } from 'src/app/wss/wss.service';
import { selectedTool, selectToolOptions, selShape, selSignerKey, shapeOptions, signers, signerSteps, toolsChanged, undoStackSize } from '../canvas.selectors';
import { CanvasService } from '../canvas.service';


@Component({
  selector: 'white-board-header',
  templateUrl: './white-board-header.component.html',
  styleUrls: ['./white-board-header.component.scss']
})
export class WhiteBoardHeaderComponent {
  signers: any = {}
  // signer_key: string = "";
  signer_title: string = "Caller"
  signer_color: string = "#000"
  isPhone: boolean = false;
  asset_name: string = ""
  bEditName: boolean = false;
  asset: any

  take_pic: boolean = false;
  driver_lic: boolean = false;
  color: string = "rgba(255,255,0,0.53)"//"#ffff00"
  width: number = 5
  bIsSigner: boolean = false;


  back_colors: any = {
    none: "",
    undo: "",
    move: "",
    erase: "",
    shapes: "",
    pencil: "",
    drop_text: "",
    drop_signature: "",
    comment: "",
    cloud: "",
    settings: ""
  }
  color_keys: string[] = []
  bIsInitials: boolean = false;
  opened_tool: string = ""

  shape_icon: string = "shapes.svg"
  sel_shape: number = -1
  shape_options: any[] = []
  undo_stack_size: number = 0
  selected_tool: string = "none"
  subscriptions: any[] = []
  bShowTools: boolean = false;
  constructor(
    public pagesState: Store<PagesState>,
    public canvas_service: CanvasService,
    public wss_service: WSSService,
    public canvasStore: Store<CanvasState>,

  ) {
    console.log("wb header")
    this.color_keys = Object.keys(this.back_colors)
    let back_colors = Object.assign({}, this.back_colors)
    this.color_keys.forEach(key => {
      let me: any = this
      back_colors[key] = "transparent"
    });
    setTimeout(() => {
      this.back_colors = back_colors
    });
  }
  ngOnInit() {
    this.subscriptions.push(this.canvasStore.select(undoStackSize).subscribe((undo_stack_size: number) => {
      this.undo_stack_size = undo_stack_size
      if (this.undo_stack_size == 0 && this.selected_tool == "undo") {
        this.canvasStore.dispatch(new SetTool("undo"))
      }
    }))

    this.subscriptions.push(this.canvasStore.select(selectToolOptions).subscribe((tool_options: any) => {
      if (tool_options) {
        this.opened_tool = tool_options.name
      }
    }))

    this.subscriptions.push(this.canvasStore.select(shapeOptions).subscribe((shape_options: any) => {
      this.shape_options = shape_options
    }))
    this.subscriptions.push(this.canvasStore.select(selShape).subscribe((sel_shape: number) => {
      this.sel_shape = sel_shape
      let opt = this.shape_options[this.sel_shape]
      if (opt) {
        this.shape_icon = opt.svg
        this.canvasStore.dispatch(new SetTool(opt.tool))
        this.canvasStore.dispatch(new SelectToolOpts(""))
        this.opened_tool = ""
      }
    }))

    this.subscriptions.push(this.pagesState.select(isPhone).subscribe((isPhone: boolean) => {
      setTimeout(() => this.isPhone = isPhone);
    }))
    // this.canvasStore.select(signers).subscribe((signers: any) => {
    //   this.signers = signers
    //   let signer = this.signers[this.signer_key]
    //   if (signer) {
    //     this.signer_title = signer.title
    //     this.signer_color = signer.color
    //   }
    // })
    this.subscriptions.push(this.canvasStore.select(selectedTool).subscribe((tool_name: string) => {
      this.selected_tool = tool_name
      this.canvasStore.dispatch(new SelectToolOpts(""))
      this.opened_tool = ""
      if (tool_name == 'drop_initials') {
        this.bIsInitials = true;
        this.opened_tool = ""
      } else if (tool_name == 'drop_signature') {
        this.bIsInitials = false;
        this.opened_tool = ""
      }
      console.log("asset header")
      //The selected tool will have color
      if (tool_name) {
        let back_colors = Object.assign({}, this.back_colors)
        this.color_keys.forEach(key => {
          let me: any = this
          let color = "transparent"
          if (tool_name == key
            || key == 'shapes' && (
              tool_name == 'rectangle'
              || tool_name == 'eclipse'
              || tool_name == 'polyfill'
              || tool_name == 'polygon'
              || tool_name == 'line'
              || tool_name == 'arrow'
            )
            || key == 'drop_signature' && tool_name == 'drop_initials') {
            color = "#FFCE38"
          }
          back_colors[key] = color
        });
        setTimeout(() => {
          this.back_colors = back_colors
        });
      }
    }))

    // this.canvasStore.select(selSignerKey).subscribe((signer_key: string) => {
    //   this.signer_key = signer_key
    //   let signer = this.signers[this.signer_key]
    //   if (signer) {
    //     this.signer_title = signer.title
    //     this.signer_color = signer.color
    //   }
    // })
    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      setTimeout(() => {
        if (asset) {
          this.asset = asset
          if (asset.name) {
            this.asset_name = asset.name
          } else {
            this.asset_name = asset.type
          }
        }
        this.bShowTools = (asset.type != 'uploaded'
          && asset.type != 'youtube'
          && asset.type != 'matterport'
          && asset.type != 'video_canvas'
          && asset.type != 'on_board_light')
      })
    }))
    this.subscriptions.push(this.canvasStore.select(toolsChanged).subscribe((tools: any) => {
      if (tools) {
        if (tools.pencil) {
          this.color = tools.pencil.color
          this.width = tools.pencil.width
        }
      }
    }))
    this.subscriptions.push(this.canvasStore.select(signerSteps).subscribe((steps: any) => {
      this.bIsSigner = Object.keys(steps).length > 0
    }))
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  none() {
    this.canvasStore.dispatch(new SelectToolOpts(""))
    this.canvasStore.dispatch(new SetTool("none"))
  }
  undo() {
    this.canvasStore.dispatch(new SelectToolOpts(""))
    this.canvasStore.dispatch(new SetTool("undo"))
    this.canvasStore.dispatch(new UnDo())
  }
  erase() {
    console.log("earse")
    this.canvasStore.dispatch(new SelectToolOpts(""))
    this.canvasStore.dispatch(new SetTool("erase"))
  }

  pen() {
    if (this.bIsInitials) {
      this.canvasStore.dispatch(new SetTool("drop_initials"))
    } else {
      this.canvasStore.dispatch(new SetTool("drop_signature"))
    }
  }
  penTool() {
    this.canvasStore.dispatch(new SelectToolOpts("sign"))
  }

  pencil() {
    this.canvasStore.dispatch(new SelectToolOpts(""))
    this.canvasStore.dispatch(new SetTool("pencil"))
  }
  text() {
    this.canvasStore.dispatch(new SelectToolOpts(""))
    this.canvasStore.dispatch(new SetTool("drop_text"))
  }
  shapes() {
    this.canvasStore.dispatch(new SelectToolOpts(""))
    let opt = this.shape_options[this.sel_shape]
    if (opt) {
      this.canvasStore.dispatch(new SetTool(opt.tool))
    } else {
      this.sel_shape = 5
      let opt = this.shape_options[this.sel_shape]
      if (opt) {
        this.shape_icon = opt.svg
        this.canvasStore.dispatch(new SetTool(opt.tool))
        this.canvasStore.dispatch(new SelectToolOpts(""))
        this.opened_tool = ""
      }
    }
  }
  // pallet() {
  //   this.canvasStore.dispatch(new SelectToolOpts("colors"))
  // }
  comment() {
    this.canvasStore.dispatch(new SelectToolOpts(""))
    this.canvasStore.dispatch(new SetTool("comment"))
  }
  cloud() {
    this.canvasStore.dispatch(new SelectToolOpts("sel-download"))
    // this.canvasStore.dispatch(new GetPDFSVG("download"))
  }
  selSigner() {
    this.canvasStore.dispatch(new SelectToolOpts("sel-signer"))
  }

  settings() {
    this.canvasStore.dispatch(new SelectToolOpts("pdf-opts"))
  }
  selectTool($event: any, name: string) {
    if (this.opened_tool) {
      if (this.opened_tool == name) {
        this.opened_tool = ""
        this.canvasStore.dispatch(new SelectToolOpts(""))
        return
      }
    }
    this.opened_tool = name
    var viewportOffset = $event.srcElement.getBoundingClientRect();
    this.canvasStore.dispatch(new SelectToolOpts(name, { x: viewportOffset.left, y: 125 }))

  }
}

<div class="out">


  <div class="line" (click)="down()">
    Download
    <img class="check" src="assets/images/download.svg">
  </div>

  <div class="line">
    Add to chat
    <img class="check" (click)="add()" src="assets/images/add_to_chat.svg">
  </div>

</div>

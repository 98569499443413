<div class="out">
  <div class="user heading">
    <span class="uname">Name</span>

    <span class="uphone"> Phone</span>


    <span class="uname"> Email</span>
    <span class="roll roll_t"> User Roll</span>
    <span class="actions">
      Actions
    </span>
  </div>
  <div class="users">
    <div class="user" *ngFor="let user of all_users, let i = index ">
      <!-- <k-check class="check" [checked]="selected_rows.has(i)" (checkedChange)="selRow(i)"></k-check> -->
      <span *ngIf="user.name" class="uname" [ngClass]="{pending:user.new_user}"> {{user.name}}</span>
      <span *ngIf="!user.name" class="uname pending"> No Name</span>
      <span *ngIf="user.phone" class="uphone" [ngClass]="{pending:user.new_user}"> {{user.phone}}</span>
      <span *ngIf="!user.phone" class="uphone pending"> No phone</span>
      <span class="uname" [ngClass]="{pending:user.new_user}"> {{user.email}}</span>
      <div class="roll">
        <div class="filter rep" [ngClass]="{admin:user.roll == 'admin', manager:user.roll == 'manager' }">
          <div class="abs_center">
            {{getSelTitle(user)}}
          </div>
        </div>
        <div *ngIf="user.new_user" class="pending_lbl">
          Pending...
        </div>
      </div>

      <div class="actions">


        <div *ngIf="roll=='admin'" class="img" (click)="edit($event, user)">
          <img class="abs_center" src="assets/images/edit_user.svg" alt="">
        </div>
        <div *ngIf="roll=='admin'" class="img" (click)="delete($event,user)">
          <img class="abs_center" src="assets/images/delete.svg" alt="">
        </div>
        <div class="img last" (click)="links($event,user)">
          <img class="abs_center" src="assets/images/dots.svg" alt="">
        </div>
      </div>
      <!-- <k-filter *ngIf="!user.new_user" [right]="true" [height]="35" [radius]="6" class="select" title="Call Links" [options]="call_link_opts" (selected)="getLink($event, i)"></k-filter>
      <span *ngIf="user.new_user" class="select pending">Status Pending</span> -->

    </div>
    <div class="bottom">

    </div>

    <!-- <add-user *ngIf="edit_user_top" [user]="edit_user" class="abs_center add_user_box" (close)="edit_user_top=0"></add-user> -->
    <!-- <add-user *ngIf="bAddingUser" class="abs_center add_user_box" (close)="bAddingUser=false"></add-user> -->

    <div *ngIf="link_user_top" [style.top.px]="link_user_top" class="link-opts z3">
      <div class="op_content">
        <div class="opt" *ngFor="let opt of link_options;let i = index" (click)="copyLink(i)">
          <div class="r_g">
            <img [src]="opt.img" class="abs_center opt_i">
          </div>
          {{opt.text}}
        </div>
      </div>
    </div>


    <!-- <div *ngIf="del_user_top" [style.top.px]="del_user_top" [style.left.px]="del_user_left" class="sure_box"> -->
    <!-- <div *ngIf="del_user_top" class="abs_center sure_box">
      <div class="margin">
        Are you sure you want to delete this user?
      </div>
      <div class="abs_center btnsyn">
        <k-button class="btn1" text="Yes" background="#fff" color="#000" (click)="deleteUser()"></k-button>
        <k-button text="No" background="#000" color="#fff" (click)="del_user_top=0"></k-button>
      </div>
    </div> -->
  </div>

</div>

<div class="out" [ngClass]="{out3:page==3}">
  <div *ngIf="page==1" class="page">
    <div class="line">
      <input #dt class="font_sub_title dt_b" [min]="minDate" type="date" name="" value="date" [ngModel]="date | date:'yyyy-MM-dd'" (ngModelChange)="changedDate($event)" (mouseover)="bShowSelTime=false">
      <div class="d_lbl" (click)="toggleDate()">
        {{fdate}}
      </div>
      <div class=" dt_b time" (click)="toogleTime()">
        <div class="time_l">
          {{time_lbl}}
        </div>
        <img class="open" [ngClass]="{close:bShowSelTime}" src="assets/images/expand.svg">
      </div>
    </div>
    <input #title_field class="in" type="text" placeholder="Event Title" name="" value="" [(ngModel)]=" title" (mouseover)="bShowSelTime=false">
    <input class="in" type="text" placeholder="First Name" name="" value="" [(ngModel)]=" first_name" (mouseover)="bShowSelTime=false">
    <input class="in" type="text" placeholder="Last Name" name="" value="" [(ngModel)]=" last_name" (mouseover)="bShowSelTime=false">
    <input class="in" type="email" placeholder="Email" name="" value="" [(ngModel)]=" email" (ngModelChange)="changedEmail($event)" (mouseover)="bShowSelTime=false">
    <input class="in" type="text" placeholder="Message" name="" value="" [(ngModel)]=" message" (mouseover)="bShowSelTime=false">

    <div class="btm_label">
      <div class="btm_tx">
        {{date|date:'d MMM yyyy'}} {{time_lbl}}, {{app_time}} minutes
      </div>
    </div>

    <div *ngIf="!bSentIt" class="send" [ngClass]="{disabled:bDisabled}" (click)="send()">
      <div class="abs_center">Send</div>
    </div>
    <div *ngIf="bSentIt" class="sent">
      <img class="abs_center" src="assets/images/party.svg">
    </div>

    <!-- <app-day class="slots_day" *ngIf="bShowSelTime" (timeChange)="setTime($event)">
  </app-day> -->
    <hours class="slots_day" *ngIf="bShowSelTime" [(times)]="from_to" (close)="bShowSelTime=false" (timesChange)="timesChange($event)">
    </hours>
  </div>
  <div *ngIf="page==2 && !bError" class="page">
    <k-spinner class="spin1"></k-spinner>
    <div class="spin2">
      Scheduling
    </div>
  </div>
  <div *ngIf="page==2 && bError" class="page">
    <div class="abs_center red">
      There was an error sending the email
    </div>
    <div class="send b_err" (click)="reTry()">
      <div class="abs_center">Try Again</div>
    </div>
  </div>
  <div *ngIf="page==3" class="page page3">
    <div class="p3_l p3_t">
      Call with
    </div>
    <div class="p3_l">
      {{first_name}} {{last_name}}
    </div>
    <div class="p3_l">
      {{title}}
    </div>
    <div class="p3_l mb5">
      {{date|date:'d MMM yyyy'}}
    </div>
    <div class="p3_l mb5">
      {{time_lbl}}
    </div>
    <div class=" p3_l m30">
      {{app_time}} minutes
    </div>
    <div class="p3_l suc">
      <img src="assets/images/arrow_done.svg" alt="" class="ok">
      Successfully Booked!
    </div>
    <div class="send" (click)="done()">
      <div class="abs_center">Done</div>
    </div>

  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { ContactsComponent } from './contacts/contacts.component';
import { FormsModule } from '@angular/forms';
import { ControlsModule } from 'src/app/controls/controls.module';
// import { AddContactComponent } from './add-contact/add-contact.component';
import * as contactsReducer from './contacts.reducer';
import { StoreModule } from '@ngrx/store';
import { LeadsComponent } from './leads/leads.component';
import { DelLeadComponent } from './del-lead/del-lead.component';
// import { AgencyComponent } from './agency/agency.component';



@NgModule({
  declarations: [
    // ContactsComponent,
    // AddContactComponent,
    LeadsComponent,
    DelLeadComponent,
    // AgencyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ControlsModule,
    StoreModule.forFeature('contacts', contactsReducer.contactsReducer),

  ], exports: [
    // ContactsComponent,
    LeadsComponent,
    DelLeadComponent
  ]
})
export class ContactsModule { }

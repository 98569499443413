<div class="out" #outComponent [style.border]="border">
  <div (click)="showOptions()">{{title}}
    <!-- <mat-icon *ngIf="bShowOptions" class="expand">expand_less</mat-icon>
    <mat-icon *ngIf="!bShowOptions" class="expand">expand_more</mat-icon> -->
  </div>
  <div *ngIf="bShowOptions" #optComponent class="options" [ngClass]="{opts_r:bShowRight}" [style.border]="border">
    <div class="opt" [style.border-bottom]="border" *ngFor="let opt of options;let i = index" (click)="selOption(i)">
      {{opt}}
    </div>
  </div>
</div>

<div class="out" [ngClass]="{out_ph:isPhone}">
  <div class="line">
    <div class="d_or_t">
      {{draw_or_type}} Initials <img src="assets/images/expand.svg" class="expand" [ngClass]="{close:bShowSignatureOpt}" (click)="bShowSignatureOpt=!bShowSignatureOpt" />
    </div>
    <!-- <div class="font" *ngIf="draw_or_type=='Type'">
      {{font_desc}} <img src="assets/images/expand.svg" class="expand" [ngClass]="{close:bShowFonts}" (click)="toogleShowFonts(); bShowSignatureOpt=false" />
    </div> -->

  </div>

  <sign-pad *ngIf="draw_or_type == 'Type'" #signatureCanvas [enabled]="draw_or_type=='Type'" [svg]="svg" (svgChange)="changedSVG($event)" class="type_signature" [clear]="clear_me">
  </sign-pad>
  <sign-pad1 *ngIf="draw_or_type == 'Draw'" #signatureCanvas1 [enabled]="draw_or_type=='Draw'" [svg]="svg" (svgChange)="changedSVG($event)" class="signature" [clear]="clear_me">
  </sign-pad1>
  <div class="btm_line" [ngClass]="{btm_line_ph:isPhone}">

  </div>

  <div *ngIf="!isPhone" class="line">
    <div class="ctr">
      <img *ngIf="draw_or_type == 'Draw'" class="img" src="assets/images/signature.svg" />
      <div *ngIf="draw_or_type == 'Draw'" class="inst sig_inst"> Write your initials using your mouse above.</div>
    </div>
  </div>
  <div *ngIf="isPhone" class="line line_ph">
    <div class="ctr">
      <img class="img" src="assets/images/signature.svg" />
      <div class="inst sig_inst"> Draw your initials above.</div>
    </div>
  </div>

  <input *ngIf="bTypeSignature" class="sign" [ngClass]="{sing_ph:isPhone}" [style.font-family]="font" [style.font-weigth]="weigth" [style.font-size]="size" onclick="this.select()" #inName matInput [placeholder]="intput_placeholder" [(ngModel)]="name"
    (ngModelChange)="changedName()">
  <div class="line" [ngClass]="{sign_ph:isPhone,  sign_dsk:draw_or_type=='Type'}">
    <k-button text="Initial" class="next" [background]="done_back" [color]="done_color" (click)="doneInitials()"></k-button>
  </div>

  <div class="inst_b"> By signing with Keyzii Sign, I acknowledge that this mark is an electronic representation of my signature or initials wherever used. I also acknowledge my name, email, and other identifying information will be visible to other
    participants in the document.</div>
</div>

<img class="cancel" src="assets/images/close.svg" alt="close" (click)="cancel()">
<img *ngIf="!bSigReady" class="clear" src="assets/images/undo_gray.svg" alt="close" (click)="clear()">
<img *ngIf="bSigReady" class="clear" src="assets/images/undo.svg" alt="close" (click)="clear()">

<div *ngIf="bShowSignatureOpt" class="box d_or_t_opt">
  <div class="opl" (click)="selectedDT('Type')">
    Type <div class="check" [ngClass]="{selected:draw_or_type=='Type'}"> </div>
  </div>
  <div class="opl" (click)="selectedDT('Draw')">
    Draw <div class="check" [ngClass]="{selected:draw_or_type=='Draw'}"> </div>
  </div>
</div>

<!-- <text-tool *ngIf="bShowFonts" class="font_opts"></text-tool> -->

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { ShowCC } from 'src/app/pages/pages.actions';
import { lastMsg } from 'src/app/wss/wss.selectors';
import { KpayBarComponent } from '../kpay-bar/kpay-bar.component';
import { kPayState } from '../kpay.model';
import { gotInvoice, payBarStep } from '../kpay.selectors';

@Component({
  selector: 'kpay-invoice-bar',
  templateUrl: './kpay-invoice-bar.component.html',
  styleUrls: ['../kpay-bar/kpay-bar.component.scss', './kpay-invoice-bar.component.scss']
})
export class KpayInvoiceBarComponent {
  state: string = "" //"acc" "invoice" "plan" //"bill" //
  amount: string = ""
  payment_lbl: string = "0.00"
  subscriptions: any[] = []
  constructor(
    public kpayState: Store<kPayState>,
  ) {
    console.log("kpay-bar")

  }
  ngAfterViewInit() {

    this.subscriptions.push(this.kpayState.select(payBarStep).subscribe((state: any) => {
      this.state = state
      if (this.state == "done") {
        this.payment_lbl = "Payment for " + this.amount + " received"
      }

    }))

    this.subscriptions.push(this.kpayState.select(gotInvoice).subscribe((invoice: any) => {
      if (invoice) {
        this.amount = invoice.amount
      }
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
}

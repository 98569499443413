<div class="out">
  <div class="check" [ngClass]="{selected:_selected}" (click)="select($event)"></div>

  <div class="font_title title" (click)="copyRef($event)">
    {{_contract.name }} <span class="ref">ref: {{_contract._id}}</span>
  </div>


  <div class="signers">
    <div class="signer" *ngFor="let key of signer_keys">
      {{_contract.signers[key].title}} {{_contract.signers[key].name}} {{_contract.signers[key].email}}

      <span *ngIf="_contract.signers[key].signed_at">Signed at {{_contract.signers[key].signed_at|date:'short'}}</span>
      <span *ngIf="_contract.signers[key].send_email_at">Send email at {{_contract.signers[key].send_email_at|date:'short'}}</span>
      <div class="chain" *ngIf="_contract.signers[key].chain">
        <div class="sub_font_title">
          Block chain verification
        </div>
        <p> To verify the integrity of the contract</p>
        <p><a target="_blank" [href]="_contract.signers[key].chain.url">Cardano Blockchain Explorer</a></p>
        <p>You will see the PDF hash in the metadata</p>
        <p><strong> {{_contract.signers[key].chain.hash}}</strong></p>
        <p>To independently verify the PDF Click
          <k-button text="Download" class="btn" background="#40BE5C" (click)="download(key)"></k-button>
          to download your contract </p>
        <p> And use <a href="https://www.openssl.org/"> openssl </a> to get the hash</p>
        <p> openssl dgst -sha256 blockchain.pdf</p>
        <p>the hash and the same hash is now inmutable in the cardando block chain ledger</p>
        <p> </p>
      </div>
    </div>
  </div>
</div>

<div class="out">
  <div class="sel_div" (click)="toogleWeight($event)">
    <span class="opt" [ngClass]="{ul:selecting=='weight'}">{{weight}}
      <img class="expand" [ngClass]="{colapse:selecting=='weight'}" src="assets/images/expand.svg" (click)="toogleWeight($event)">
    </span>
  </div>
  <div class="size">
    <input [(ngModel)]="size" (ngModelChange)="changedSize($event)"> px
  </div>
  <div class="sel_div title " (click)="selecting='font'" [style.font-family]="font" [ngClass]="{ bold:weight=='Bold'}">Font {{font}}
  </div>

  <div class="opt_contianier " *ngIf="selecting=='weight'">
    <div #optComponent class="options">
      <div class="opt" (click)="weight='Regular'">
        <span class="tx">Regular</span>
        <div class="check" [ngClass]="{selected:weight=='Regular'}"></div>
      </div>
      <div class="opt" (click)="weight='Bold'">
        <span class="tx">Bold</span>
        <div class="check" [ngClass]="{selected:weight=='Bold'}"></div>
      </div>
    </div>
  </div>

  <div class="opt_contianier " *ngIf="selecting=='font'">
    <div #optComponent class="options">
      <div class="opt" *ngFor="let opt of options;let i = index" (click)="selectedFont(i)">
        <span class="tx" [style.font-family]="opt">{{opt}}</span>
        <div class="check" [ngClass]="{selected:i==sel_index}"></div>
      </div>
    </div>
  </div>
</div>

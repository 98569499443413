import { Component, EventEmitter, Input, Output } from '@angular/core';
import { showAPI } from '../../pages.selectors';
import { SelAssetComponent } from '../sel-asset/sel-asset.component';

@Component({
  selector: 'asset',
  templateUrl: './asset.component.html',
  styleUrls: ['../sel-asset/sel-asset.component.scss', './asset.component.scss']
})
export class AssetComponent extends SelAssetComponent {

  bShowAPI: boolean = false
  @Input() preview: boolean = false
  initSubClass() {
    console.log("asset component")
    this.subscriptions.push(this.pagesState.select(showAPI).subscribe((show: boolean) => {
      this.bShowAPI = show

    }))
  }
}

<div class="scrn">
  <div class="abs_center out" (click)="outside()">
    <div class="line title t1">
      keyzii calendar settings
    </div>
    <div class="line title">
      Work Hours
    </div>
    <div class="line title">
      <div class="lbl">From </div>
      <div class="lbl">To </div>
      <!-- <div class="lbl lbld">Duration</div> -->
    </div>

    <div class="line top">
      <input class="in" (mousedown)="hours_for='from'" placeholder="hh:mm AM" [(ngModel)]="work_from">
      <img class="open_hours" [ngClass]="{close:hours_for=='from'}" src="assets/images/expand.svg" (click)="toogleHours($event, 'from')">

      <input class="in" (mousedown)="hours_for='to'" placeholder="hh:mm AM" [(ngModel)]="work_to">
      <img class="close_hours" [ngClass]="{close:hours_for=='to'}" src="assets/images/expand.svg" (click)="toogleHours($event, 'to')">
      <!-- <input class="in" placeholder="15" [(ngModel)]="slot_minutes" (ngModelChange)="durationChanged()"> -->
    </div>
    <div class="line title">
      <div class="">Meeting Time Slots </div>
    </div>
    <div class="line">
      <div class="ts" [ngClass]="{ts_sl:slot_minutes==15}" (click)="setSlotMinutes(15)">
        <div class="ts_tx"> 15 min </div>
      </div>
      <div class="ts" [ngClass]="{ts_sl:slot_minutes==30}" (click)="setSlotMinutes(30)">
        <div class="ts_tx">30 min</div>
      </div>
      <div class="ts" [ngClass]="{ts_sl:slot_minutes==45}" (click)="setSlotMinutes(45)">
        <div class="ts_tx"> 45 min</div>
      </div>
      <div class="ts" [ngClass]="{ts_sl:slot_minutes==60}" (click)="setSlotMinutes(60)">
        <div class="ts_tx"> 1 hour</div>
      </div>
    </div>

    <div class="line">
      <div class="tzl">
        {{time_zone.text}}
        <!-- <img class="expand" src="assets/images/exp10.svg" [ngClass]="{flip:bShowTimeZones}" (click)="toogleTZ()"> -->
      </div>
    </div>






    <input class="search" type="search" placeholder="Search Time zone" [(ngModel)]="search" (ngModelChange)="changedSearch($event)">

    <div class="time_zones">
      <div class="tz" *ngFor="let tz of time_zones; let i = index" (click)="selTimeZone(i,tz)">
        <div class="check" [ngClass]="{selected:i==tz_index}">
        </div>
        {{tz.text}}
      </div>

    </div>
    <div class="bline">
      <div class="abs_center send" [ngClass]="{disabled:!bChanged}" (click)="updateTime()">
        <div class="abs_center">Update</div>
      </div>
    </div>
    <hours-all *ngIf="hours_for=='from'" [start_index]="strart_from_index" [duration]="slot_minutes" class="slots_from" [(times)]="time_from" (close)="bShowSelTime=false" (timesChange)="timesChange($event)">
    </hours-all>
    <hours-all *ngIf="hours_for=='to'" [start_index]="strart_to_index" [duration]="slot_minutes" class="slots_from slots_to" [(times)]="time_to" (close)="bShowSelTime=false" (timesChange)="timesChange($event)">
    </hours-all>
    <img src="assets/images/close.svg" class="close_it" (click)="closeIt()">
  </div>
</div>

<!-- <assets-header *ngIf="_asset.type=='pdf'||_asset.type=='contract'"> </assets-header> -->
<div class="out">
  <assets-header class="hdr"> </assets-header>

  <div class="body">
    <images class="out" *ngIf="_asset.type=='image'" [asset]="_asset"></images>
    <iframe class="out iframe" *ngIf="_asset.type=='iframe'" [src]="sanitized_url"></iframe>



    <matterport class="out" *ngIf="_asset.type=='matterport'" [asset]="_asset" [id]="_asset.url"></matterport>

    <uploaded-video class="center" *ngIf="_asset.type=='uploaded' || _asset.type=='video'" [asset]="_asset"></uploaded-video>

    <!-- <view-pdf *ngIf="_asset.type=='pdf'" [asset]="_asset" class="out">
    </view-pdf> -->
    <!-- <edit-pdf [bOpenForEdit]="true" *ngIf="_asset.type=='pdf'" [asset]="_asset" class="out">
    </edit-pdf> -->

    <rep-pdf-canvas *ngIf="_asset.type=='pdf'||_asset.type=='contract'" [asset]="_asset" class="out">
    </rep-pdf-canvas>

    <app-youtube class="out" *ngIf='_asset.type == "youtube"' width=100 height=100 [asset]="_asset">
    </app-youtube>
    <app-kuula class="out" *ngIf="_asset.type=='kuula'"> </app-kuula>

  </div>
</div>

<div class="scrn">
  <div class="abs_center out">
    <div class="header_frame1">
      <div class="header">
        <div class="header_t1 font_sub_title">
          <img src="assets/images/chat.svg" class="chat" alt="">
          Call Transcription
        </div>
        <div class="header_t2">
          {{date|date}} - {{caller_name}} & {{rep_name}}
        </div>
        <img class="close" src="assets/images/close_w.svg" alt="" (click)="closeIt()">
        <!-- <k-button class="close" icon="close.svg" background="white" (click)="closeIt()"></k-button> -->
      </div>
    </div>
    <div class="body" #scroll_container>
      <div class="top_filler"></div>

      <div class="line" *ngFor="let msg of messages">
        <chat-line *ngIf="msg.chat_text" [mine]="!isCaller(msg)" (asset)="showAsset($event)" [text]="msg.chat_text"> </chat-line>
        <img *ngIf="msg.img" class="in_img" [ngClass]="{caller:isCaller(msg)}" [src]="msg.img">
        <sel-asset *ngIf="msg.asset_id" class="asset" [asset]="assets[msg.asset_id]"></sel-asset>

      </div>
    </div>
    <asset-preview [small]="true" *ngIf="preview_asset" [asset]="preview_asset" (close)="closePreview()" class="preview"></asset-preview>
  </div>

</div>

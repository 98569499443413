<div class="out">
  <div class="all">
  </div>
  <!-- <div class="progress" [style.width.%]="_progress">
  </div> -->
  <div class="frame">
    <div class="ball" [style.left.%]="_progress" [style.top.px]="top" [style.width.px]="width" [style.height.px]="width"></div>
    <div class="scr" #outComponent (click)="setW($event)">
    </div>
  </div>

  <div class="text">
    {{width}} px
  </div>
</div>

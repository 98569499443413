import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowCC } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { WSSService } from 'src/app/wss/wss.service';
import { StartKPay, StartKPlan } from '../kpay.actions';
import { kPayState } from '../kpay.model';


@Component({
  selector: 'kpay-menu',
  templateUrl: './kpay-menu.component.html',
  styleUrls: ['./kpay-menu.component.scss']
})
export class KpayMenuComponent {
  sel_option: number = 0
  options: any[] = [
    {
      text: "keyzii Plan"
    }, {
      text: "New Invoice"
    }]
  constructor(
    public kpayState: Store<kPayState>,

  ) { }
  selOption(i: number) {
    if (i == 0) {
      this.kpayState.dispatch(new StartKPlan())
    } else {
      this.kpayState.dispatch(new StartKPay())
    }
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { GetInitials, GetSignature, NewInitials, NewSignature, RemoveInitials, RemoveSignature } from 'src/app/canvas/canvas.actions';
import { CanvasState } from 'src/app/canvas/canvas.model';
import { CanvasService } from 'src/app/canvas/canvas.service';
import { KeyziiService } from 'src/app/wss/keyzii.service';
import { GotLoginData, GotMyPicture, NotifyError, NotifyUser, RemoveUserProfPic } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { hasGoogleCalendar, hostUrl, myCompany, myCompanyTitle, myEmail, myLastName, myName, myPicture, myRoll, mySms, repLink } from '../../pages.selectors';


import { SVG, extend as SVGextend, Element as SVGElement, G } from '@svgdotjs/svg.js'
import { changeInitials, changeSignature } from 'src/app/canvas/canvas.selectors';
import { Clipboard } from '@angular/cdk/clipboard'
import { CalendarService } from '../../scheduling/calendar.service';
import { checkPhone, loResImg, validateEmail } from 'src/app/utils';
import { CanvasUploaderService } from 'src/app/canvas/canvas-uploader.service';
// import { PagesService } from '../../pages.service';

@Component({
  selector: 'rep-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  picture: any | undefined
  email: string = ""
  rep_name: string = ""
  last_name: string = ""
  rep_title: string = ""
  phone: string = ""
  company: string = ""

  bInvalidEmail: boolean = false;
  bBadlink: boolean = true;
  pendingSaveForLink: boolean = false;
  bAgenOffLine: boolean = true;
  theme: string = "light"
  bShowSpinner: boolean = false;
  uploader: FileUploader | undefined
  img_src: string | undefined
  rep_link: string = ""
  rep_unique_link: string = "https:/my.keyzii.com/your_name"

  signature_tool: any
  initials_tool: any
  @ViewChild('signContainer') signContainer: ElementRef | undefined;
  @ViewChild('initialsContainer') initialsContainer: ElementRef | undefined;
  @ViewChild('profile_image') profile_image: ElementRef | undefined;

  profile_height: number = 120;
  profile_width: number = 120;
  profile_left: number = 0;
  profile_top: number = 0;


  save_in_cookie: boolean = true

  signature_data: any
  bNewSignature: boolean = false  //Only save when the user actually canged it
  initials_data: any
  bNewInitials: boolean = false
  host_url: string | undefined


  bShowCalendlySettings: boolean = false;
  // bShowSettings: boolean = false
  bNeedsConnection: boolean = true;
  auth_url: string | undefined
  subscriptions: any[] = []

  lic_src: string = ""
  roll: string = "rep"
  @Input() set save_it(what: string) {
    if (what.startsWith("contact")) {
      this.saveIt()
      this.pendingSaveForLink = false;
    }
  }

  @Output() enableSave: EventEmitter<boolean> = new EventEmitter();
  bShowDelUserWarning: boolean = false; //!=0 shows

  constructor(
    public upload_service: CanvasUploaderService,
    private keyzii_service: KeyziiService,
    private pagesState: Store<PagesState>,
    public canvasStore: Store<CanvasState>,
    public canvas_service: CanvasService,
    private clipboard: Clipboard,
    public calendar_service: CalendarService,
    // public page_service: PagesService
  ) {
    console.log("contact")
    let lic_src = localStorage.getItem("lic_src")
    if (lic_src) {
      this.lic_src = lic_src
    }
    this.subscriptions.push(this.pagesState.select(myRoll).subscribe((my_roll: string) => {
      setTimeout(() => this.roll = my_roll);
    }))
    this.subscriptions.push(this.pagesState.select(hasGoogleCalendar).subscribe((has_google_calendar: boolean) => {
      if (has_google_calendar) {
        this.bNeedsConnection = false;
      }
    }))

    this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
      if (url) {
        if (url.startsWith("local")) {
          this.host_url = "http://" + url
        } else {
          this.host_url = "https://" + url
        }

        if (this.host_url && this.rep_link) {
          this.rep_unique_link = this.host_url + "/" + this.rep_link
        }
      }
    }))
    this.subscriptions.push(this.pagesState.select(repLink).subscribe((rep_link: string | undefined) => {
      if (rep_link) {
        this.rep_link = rep_link
        this.bBadlink = (this.rep_link.length < 4 || this.rep_link.indexOf(' ') > 0)
        if (this.host_url) {
          this.rep_unique_link = this.host_url + "/" + this.rep_link
        }
      }
    }))
    this.subscriptions.push(this.pagesState.select(myPicture).subscribe((picture: any) => {
      this.picture = picture
      if (picture) {
        this.img_src = picture
      }
    }))
    this.subscriptions.push(this.pagesState.select(myEmail).subscribe((email: string) => {
      if (email) {
        this.email = email
      }
    }))
    this.subscriptions.push(this.pagesState.select(myName).subscribe((name: string) => {
      if (name) {
        this.rep_name = name
      }
    }))
    this.subscriptions.push(this.pagesState.select(myLastName).subscribe((last_name: string) => {
      if (last_name) {
        this.last_name = last_name
      }
    }))
    this.subscriptions.push(this.pagesState.select(myCompany).subscribe((company: string) => {
      if (company) {
        this.company = company
      }
    }))
    this.subscriptions.push(this.pagesState.select(myCompanyTitle).subscribe((rep_title: string) => {
      if (rep_title) {
        this.rep_title = rep_title
      }
    }))
    this.subscriptions.push(this.pagesState.select(mySms).subscribe((phone: string) => {
      if (phone) {
        this.phone = phone
      }
    }))
    this.uploader = this.upload_service.setUpUploader("image")
    this.uploader.onCompleteItem = async (item: FileItem, response: string, status: number, _header: any) => {
      if (status == 200) {
        let type = item._file.type
        let img_src = 'data:' + type + ';base64,' + response //picture.url
        this.bShowSpinner = false
        let low_res = await loResImg(img_src, 150, 150, true)
        this.picture = low_res //To save it
        this.img_src = low_res//picture.url
        this.enableSave.emit(true)
      }
    }


    this.subscriptions.push(this.canvasStore.select(changeSignature).subscribe((data: any | undefined) => {
      if (data) {
        this.signature_data = data

        if (this.signContainer && this.signature_data) {
          this.SetSignatures()
          // this.drawIt(this.signature_data, this.signature_tool, this.signContainer.nativeElement)
        }
        if (this.save_in_cookie && this.bNewSignature) {
          this.bNewSignature = false
          this.keyzii_service.setCookie("signature", JSON.stringify(data))
          this.pagesState.dispatch(new NotifyUser("Signature Saved"))
        }

      }

    }))
    this.subscriptions.push(this.canvasStore.select(changeInitials).subscribe((data: any | undefined) => {
      if (data) {

        this.initials_data = data

        if (this.initialsContainer && this.initials_data) {
          this.SetSignatures()
          //    this.drawIt(this.initials_data, this.initials_tool, this.initialsContainer.nativeElement)
        }
        if (this.save_in_cookie && this.bNewInitials) {
          this.bNewInitials = false;
          this.keyzii_service.setCookie("initials", JSON.stringify(data))
          this.pagesState.dispatch(new NotifyUser("Initials Saved"))
        }
      }
    }))

    if (localStorage.getItem("signature")) {
      this.save_in_cookie = true
    } else if (localStorage.getItem("initials")) {
      this.save_in_cookie = true
    }
  }
  ngOnDestroy() {
    // this.save()
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {
    this.SetSignatures()
    // if (this.link_f) {
    //   this.link_f.nativeElement.focus()
    // }
  }

  SetSignatures() {
    function removeSvg(container: ElementRef) {
      for (let i = 0; i < container.nativeElement.children.length; i++) {
        let child = container.nativeElement.children[i]
        if (child.instance && child.instance.type == "svg") {
          container.nativeElement.removeChild(child)
          break;
        }
      }
    }

    if (this.signContainer) {
      removeSvg(this.signContainer)
      this.signature_tool = SVG().addTo(this.signContainer.nativeElement)
      this.signature_tool.viewbox(0, 0, 300, 200)
      if (this.signature_data) {
        this.drawIt(this.signature_data, this.signature_tool, this.signContainer.nativeElement)
      }
    }
    if (this.initialsContainer) {
      removeSvg(this.initialsContainer)
      this.initials_tool = SVG().addTo(this.initialsContainer.nativeElement)
      if (this.initials_data) {
        this.drawIt(this.initials_data, this.initials_tool, this.initialsContainer.nativeElement)
      }
    }

  }

  changedEmail($event: any) {
    this.bInvalidEmail = !validateEmail(this.email)
    if (!this.bInvalidEmail) {
      this.changed()
    }
    console.log("email " + $event)
  }
  changedPhone($event: string) {
    this.phone = checkPhone($event)
    this.changed()
  }
  selectedUploadImage(evt: any) {
    console.log("Selected to upload")
    this.bShowSpinner = true;
    if (this.uploader) {
      this.uploader.uploadAll();
    }
    evt.srcElement.value = ""
  }
  saveIt() {

    let data: any = {
    }
    if (this.picture) {
      data.picture = this.picture
      this.pagesState.dispatch(new GotMyPicture(this.picture)) //let the other parts know
    }
    if (this.email.length > 0) data.email = this.email
    if (this.rep_name.length > 0) data.name = this.rep_name
    if (this.last_name.length > 0) data.last_name = this.last_name
    if (this.rep_title.length > 0) data.company_title = this.rep_title
    if (this.phone.length > 0) data.phone = this.phone
    if (this.company.length > 0) data.company = this.company

    data.rep_link = this.rep_link
    this.pagesState.dispatch(new GotLoginData(data))
    this.keyzii_service.setUserData(data)
    this.pagesState.dispatch(new NotifyUser("Saved"))
    this.enableSave.emit(false)
  }
  logOut() {
    this.keyzii_service.logOut()
  }
  changeLink($event: any) {
    if (this.rep_link.length < 4 || this.rep_link.indexOf(' ') > 0) {
      this.bBadlink = true
      return
    }
    this.bBadlink = false
    this.changed()
    this.pendingSaveForLink = true
    this.rep_unique_link = this.host_url + "/" + this.rep_link
  }
  changed() {
    this.enableSave.emit(true)
  }
  getSignature() {
    this.bNewSignature = true
    this.canvasStore.dispatch(new GetSignature("signature"))
  }
  getinitials() {
    this.bNewInitials = true
    this.canvasStore.dispatch(new GetInitials("initials"))
  }
  delSignature() {
    delete this.signature_data
    this.signature_tool.clear()
    localStorage.removeItem("signature")
    this.canvasStore.dispatch(new RemoveSignature())
  }
  delInitials() {
    delete this.initials_data
    this.initials_tool.clear()
    localStorage.removeItem("initials")
    this.canvasStore.dispatch(new RemoveInitials())
  }


  drawIt(data: any, draw_tool: any, target: any) {
    let svg: any = SVG(data.svg)
    draw_tool.clear()


    let new_children = getChildren(svg, [])
    let array: string[] = []
    let new_text_field: any
    let x = 10000
    let y = 10000
    let x2 = 0
    let y2 = 0

    let img_el
    for (let i = 0; i < new_children.length; i++) {
      let el = new_children[i]
      // let bbox
      if (el.type == 'image') {
        img_el = el
      }
      if (el.type == "polyline") {
        let spoints = el.attr('points')
        array.push(spoints)
      } else if (el.type == "text") {
        new_text_field = el
      }

    }

    let cw = 300
    let ch = 150
    if (this.signContainer) {
      cw = this.signContainer.nativeElement.clientWidth
      ch = this.signContainer.nativeElement.clientHeight
    }
    if (ch > cw / 2) {
      ch = cw / 2
    }
    let target_box = { //300 by 200 from .signature

      w: cw,
      h: ch,

    }
    if (img_el) {
      var bbox = img_el.bbox()
      let attr = img_el.attr()
      let src = attr['xlink:href']
      var new_el = draw_tool.image(src)


      new_el.size(cw, ch)
      let nb = new_el.bbox()

      new_el.move(0, 0)


      new_el.node.style.userSelect = "none"



    } else if (array.length > 0) {
      let poly_arrays = this.canvas_service.moveAndSize(array, data.rect, target_box)
      for (let i = 0; i < poly_arrays.length; i++) {
        let poly_array = poly_arrays[i]
        // poly_array = [20, 20, 150, 150]
        console.log("draw_tool.polyline " + JSON.stringify(poly_array))
        draw_tool.polyline(poly_array).fill('transparent').stroke({ width: 3, color: 'black' })
      }
    } else if (new_text_field) { //its a text field
      let attr = new_text_field.attr()
      let text = new_text_field.text()
      let drawing_text = draw_tool.text(attr)
      drawing_text.text(text)

      let bbox = new_text_field.bbox()
      let x = bbox.x + 10
      let y = 100
      drawing_text.move(x, y)
      console.log("draw " + text + " x " + x + " y " + y)
    }


    function getChildren(svg: any, rv: any[]): any[] {
      let children = svg.children()

      for (let i = 0; i < children.length; i++) {
        let el = children[i]
        if (el.type == "polyline") {
          rv.push(el)
        } else if (el.type == "text") {
          rv.push(el)
        } else if (el.type == "image") {
          rv.push(el)
        } else if (el.type == "svg" || el.type == "g") {
          rv = getChildren(el, rv)
        }
      }
      return rv
    }
  }


  copyRepLink() {
    if (this.pendingSaveForLink) {
      this.pagesState.dispatch(new NotifyError("Save before copying the link"))
      return;
    }
    this.clipboard.copy(this.rep_unique_link);
    this.pagesState.dispatch(new NotifyUser("Link copied"))

  }
  async linkCal() {
    try {
      this.auth_url = await this.calendar_service.getGoogleCalendarAuthURL()
      window.location.href = this.auth_url
      console.log("auth_url " + this.auth_url)
    } catch (e) {
      console.error("error getting auth_url " + e)
    }
  }
  removeCal() {
    this.calendar_service.delGoogleCalendarAuthCode()
    this.bNeedsConnection = true;
  }
  removeProfile() {
    delete this.img_src
    delete this.picture
    this.pagesState.dispatch(new RemoveUserProfPic())
    this.keyzii_service.delUserPicture()
    this.bShowDelUserWarning = false
  }
  gotLic(img_src: any) {
    this.lic_src = img_src
    localStorage.setItem("lic_src", this.lic_src)
  }
  delId() {
    this.lic_src = ""
    localStorage.removeItem("lic_src")

  }
  rotate() {
    if (this.lic_src) {
      const img = new Image();
      img.src = this.lic_src
      let me = this
      img.onload = function(el: any) {
        console.log("width " + el.target.width)
        console.log("height " + el.target.height)
        const canvas = document.createElement('canvas');
        canvas.width = el.target.height
        canvas.height = el.target.width
        const ctx = canvas.getContext('2d');
        if (ctx) {
          // ctx.save();
          // translate and rotate
          ctx.translate(canvas.width, canvas.height / canvas.width);
          ctx.rotate(Math.PI / 2);
          // draw the previows image, now rotated

          ctx.drawImage(el.target, 0, 0);
          me.lic_src = ctx.canvas.toDataURL('image/jpeg', 1);
          localStorage.setItem("lic_src", me.lic_src)
        }
      }
    }
  }
  reduce_image: string | undefined

  onImageLoad($event: any) {
    console.log("loaded profile pic")
    let ih = $event.currentTarget.naturalHeight//1285
    let iw = $event.currentTarget.naturalWidth//981
    if (ih > iw) {
      this.profile_width = 120
      this.profile_height = this.profile_width * ih / iw
      this.profile_left = 0
      this.profile_top = (120 - this.profile_height) / 2

    } else {
      this.profile_height = 120
      this.profile_width = this.profile_height * iw / ih
      this.profile_top = 0
      this.profile_left = (120 - this.profile_width) / 2
    }

  }
  calendly() {
    this.bShowCalendlySettings = !this.bShowCalendlySettings
  }
}

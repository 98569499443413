<div class="cont">

  <!-- <div class="p_line"> </div>
  <img class="circle" [style.left.%]="progress" src="assets/images/yellow_circle.svg" alt="3"> -->
  <div class="outx" *ngIf="results  && !bError">
    <div *ngIf="results.similar>1">


      <div class="title">
        Biometric Results
      </div>
      <div class="sub-title">
        Using Amazon's recognizer AI (c).
      </div>
      <div class="sub-title">
        {{results.compared_at|date}}
      </div>

      <div class="title">
        Face comparison
      </div>
      <div class="sub-title">
        The faces are {{results.similar | number:'1.0-2'}}% similar
      </div>
      <div *ngIf="results.lic_scores.name">
        <div class="title">
          Name comparison
        </div>
        <div class="sub-title">

          The {{signer_name}} appears in the licence text {{results.lic_scores.name | number:'1.0-2'}}%
        </div>
      </div>
      <div *ngIf="results.lic_scores.email">
        <div class="title">
          Email comparison
        </div>
        <div class="sub-title">

          The {{signer_email}} in the licence text {{results.lic_scores.email | number:'1.0-2'}}% similar
        </div>
      </div>
      <div class="title">
        Text in the License
      </div>
      <div class="">
        {{results.lic_text}}
      </div>
    </div>
  </div>
  <div class="abs_center fs14" *ngIf="!results && !bError">
    Biometric results
  </div>
  <div class="error" *ngIf="bError">
    <div class="error_tx top_err_line">Biometric results did not match.</div>
    <div class="error_tx">Please try again</div>
    <k-button text="Try Again" class="error_tx" color="white" background="#F5423B" (click)="retry()"></k-button>
  </div>
  <k-spinner class="abs_center" *ngIf="bShowSpinner"></k-spinner>
  <k-button *ngIf="results" text="Done" class="next" color="white" background="black" (click)="done()"></k-button>

  <!-- <k-button text="Cancel" class="cancel" background="#fff" (click)="cancel()"></k-button> -->

</div>

<div class="out">
  <div class="line top">
    <div class="">
      Signers
    </div>
    <!-- <div class="check" [ngClass]="{selected:bShowSelAll}" (click)="toogleAllSel()"></div>
    <div *ngIf="bShowSelAll" class="ml10" (click)="toogleAllSel()">Select all </div>
    <div *ngIf="!bShowSelAll" class="ml10" (click)="toogleAllSel()">Clear all </div> -->
    <k-button class="delete" icon="delete.svg" background="transparent" (click)="delete()"></k-button>
  </div>
  <div class="line lbls">
    <div class="display">Title</div>
    <div class="display">Name</div>
    <div class="display">Email</div>
  </div>

  <div class="info">
    <div class="line" *ngFor="let key of signers_keys; let i = index">

      <!-- <k-check class="check" [checked]="signers[key].selected" (checkedChange)="selRow(i)"></k-check> -->
      <div class="check" [ngClass]="{selected:signers[key].selected}" (click)="selRow(i)">

      </div>
      <!-- <div *ngIf="editing_index != i && signers[key].title.length > 0 " class="display" [style.color]="signers[key].color" (click)="setEditing1(i,'title')">{{signers[key].title}}</div> -->
      <input class="input ih30 in_border" [style.color]="signers[key].color" type="text" placeholder="Title" name="" value="" [ngModel]="signers[key].title" (ngModelChange)="changeTitle($event ,i )">

      <!-- <div *ngIf="signers[key].name.length>0 && editing_index != i " class="display" (click)="setEditing1(i,'name')">{{ signers[key].name}}</div> -->
      <input class="input ih30 in_border" type="text" placeholder="Name" name="" value="" [ngModel]="signers[key].name" (ngModelChange)="changeName($event ,i )">

      <!-- <div *ngIf="editing_index != i && signers[key].email.length > 0" class="display" (click)="setEditing1(i,'email')">{{signers[key].email}}</div> -->
      <input class="input ih30 in_border email" type="email" placeholder="Email" name="" value="" [ngModel]="signers[key].email" (ngModelChange)="changedEmail($event ,i )" [ngClass]="{invalid:invialid_email_index==i, no_email:no_email}">

    </div>
  </div>

  <div class="btm ul" (click)="newSigner()">Add signer</div>

  <!-- <k-button class="cancel" icon="close.svg" background="transparent" (click)="cancel()"></k-button> -->
  <img class="cancel" src="assets/images/close.svg" (click)="cancel()">

</div>

<div #outContainer class="out">
  <!-- <assets-header class="hdr" [bShowSigners]="bShowSigners"> </assets-header> -->
  <!-- <div class="shadow">
  </div> -->
  <!-- <div class="error">{{error}}</div> -->
  <div class="parent">


    <contract-progress *ngIf="bShowProgress" class="progress" [@OpenClose]="state"></contract-progress>

    <div #contWnd class="cont_wnd" (scroll)="onScroll($event)">

      <div class="container" #divContainer [style.height.%]="zoom" [style.width.%]="zoom">
        <img class="arrow" width="30" src="assets/images/logo-animated-small.gif" *ngIf="step_left>0" [style.left.px]="step_left" [style.top.px]="step_top">
        <edit-comment cdkDrag *ngIf="bEditComment" class="comment" [style.left.px]="edit_comment_x" [style.top.px]="edit_comment_y"></edit-comment>
        <show-comment *ngIf="bShowComment" class="comment" [style.left.px]="show_comment_x" [style.top.px]="show_comment_y"></show-comment>

      </div>
    </div>
  </div>
  <div class="expand_b" *ngIf="bShowProgress" [@aOpenClose]="state">
    <div class="exp_bdy" (click)="toggleOpen()">
      <img *ngIf="this.state == 'closed'" class="expand_i" src="assets/images/expand_l.svg">
      <img *ngIf="this.state != 'closed'" class="expand_i expand_b" src="assets/images/expand_l.svg">
    </div>
  </div>
  <k-slider *ngIf="!isPhone" class="slider" [(value)]="zoom" (valueChange)="zoomChanged($event)"></k-slider>

  <k-button *ngIf="this._asset.type=='contract' && !bShowEmailWnd && !call_id" class="email_cont" min_width="100" min_height="40" [background]="email_back_color" color="white" text="Email" (click)="emailContract()"></k-button>
  <email-contract class="email_cont email_wnd" *ngIf="bShowEmailWnd" (close)="bShowEmailWnd=false"></email-contract>

  <k-spinner class="abs_center" *ngIf="!pdfDoc"></k-spinner>

</div>

<div class="out" #outComponent>
  <div (click)="showOptions()" class="title">
    {{title}}
    <img class="exp" [ngClass]="{colapse:bShowOptions}" src="assets/images/expand.svg">

  </div>
  <div *ngIf="bShowMoreOptions" #optComponent class="options" [ngClass]="{opts_r:bShowRight}" [style.border]="border">
    <div>
      <div>{{flors_lbl}}</div>
      <mls-sel1to5 (changed)="setVal('floors',$event)" class="top_box"></mls-sel1to5>
    </div>
    <div>
      <div class="inlineb">
        <div>Parking</div>
        <mls-options class="options" name="parking" [names]='["Any", "Off Street", "On Street"]' [values]='["any", "off_street", "on_street"]' (changed)="setVal('parking',$event)" class="top_box"></mls-options>
      </div>
      <div class="inlineb ml">
        <div>Yard</div>
        <mls-options class="options" name="yard" [names]='["Yes", "No"]' [values]='["yes","no"]' (changed)="setVal('yard', $event)" class="top_box"></mls-options>
      </div>
      <div class="inlineb ml">
        <div>Pool</div>
        <mls-options class="options" name="pool" [names]='["Yes", "No"]' [values]='["yes","no"]' (changed)="setVal('pool', $event)" class="top_box">
        </mls-options>
      </div>
    </div>
    <div>
      <div class="inlineb ">
        <div>Casita</div>
        <mls-options class="options" name="casita" [names]='["No", "Yes", "Multiple Casitas or Hanciedas"]' [values]='["no","yes","multiple+casitas_or_haciendas"]' (changed)="setVal('casitas', $event)" class="top_box"></mls-options>
      </div>
      <div class="inlineb ml">
        <div>Gated</div>
        <mls-options class="options" name="gated" [names]='["Yes", "No","Any"]' [values]='["yes","no","any"]' (changed)="setVal('gated', $event)" class="top_box"></mls-options>
      </div>
      <div class="inlineb ml">
        <div>View</div>
        <mls-options class="options" name="view" [names]='["Lake", "Mountain", "Ocean", "Partial", "No"]' [values]='["yes","no","multiple+casitas_or_haciendas"]' (changed)="setVal('view', $event)" class="top_box"></mls-options>
      </div>
    </div>
    <div>
      <div class="inlineb ">
        <div>Furnished</div>
        <mls-options class="options" name="furnished" [names]='["Any","Yes", "No", "Partially","Optional Pkg"]' [values]='["any","yes","no","partially","Optional+Pkg"]' (changed)="setVal('furnished', $event)" class="top_box"></mls-options>
      </div>
    </div>
  </div>
</div>

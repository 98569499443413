import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowCC } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { callerFirstName, callerLastName } from 'src/app/pages/pages.selectors';
import { CancelLocalKPay, SetPlanFromRep } from '../kpay.actions';
import { kPayState } from '../kpay.model';
import { cancelLocalKPay } from '../kpay.selectors';
import { PayService } from '../pay.service';

@Component({
  selector: 'kplan-wnd',
  templateUrl: './kplan-wnd.component.html',
  styleUrls: ['./kplan-wnd.component.scss']
})
export class KplanWndComponent {
  num_users: number = 5
  user_price: number = 200
  bIFrame: boolean = true
  bOnBoard: boolean = true


  constructor(
    public pay_service: PayService, //Make sure the service is ready
    public kpayState: Store<kPayState>,
  ) {
    console.log("kpay-wnd")
    this.kpayState.select(cancelLocalKPay).subscribe((bCancel: boolean) => {
      this.close.emit()
    })

  }

  @Output() close: EventEmitter<any> = new EventEmitter();
  closeIt() {
    this.kpayState.dispatch(new CancelLocalKPay())
  }
  sendPlan() {
    let amount = this.num_users * this.user_price
    let plan: any = {
      num_users: this.num_users,
      min_users: this.num_users,
      user_price: this.user_price,
      amount: amount
    }
    if (this.bOnBoard) {
      plan.type = "on_board" //assets on_board_pro, on_board_wnd
    } else if (this.bIFrame) {
      plan.type = "iframe_pro" //on_board_light
    }

    this.kpayState.dispatch(new SetPlanFromRep(plan))
    this.close.emit()
  }

  setIframe($event: boolean) {
    this.bIFrame = $event
  }
  setOnBoard($event: boolean) {
    this.bOnBoard = $event
    if (this.bOnBoard) {
      this.bIFrame = true
    }
  }
}

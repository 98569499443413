<div class="scrn">
  <div class="abs_center out">
    <div class="title">
      Are you sure you want to delete this sent document?
    </div>

    <div class="txt">
      *Deleted documents will not be recoverable. Make sure you have completed and downloaded this document before deleting.
    </div>
    <div class="btnsyn">
      <div class="no yes" (click)="deletContract()">
        <div class="abs_center"> Yes </div>
      </div>
      <div class="no" (click)="closeIt()">
        <div class="abs_center"> No </div>
      </div>
    </div>
  </div>
</div>

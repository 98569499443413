import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getQueryParameter, validateEmail } from 'src/app/utils';
import { KeyziiService } from 'src/app/wss/keyzii.service';
import { NotifyError, OpenMenu } from '../pages.actions';
import { PagesState } from '../pages.model';
import { codeFromURL, isPhone } from '../pages.selectors';
import { CalendarService } from '../scheduling/calendar.service';
import { MSAuthService } from './ms_auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  email: string = "";
  password: string = "";
  password_conf: string = "";
  isPhone: boolean = false
  bInvalidEmail: boolean = true
  bForgat: boolean = false
  bPasswordOk: boolean = true
  bShowError: boolean = true
  bShowSentEmail: boolean = true
  error_text: string = ""
  info_text: string = ""
  title: string = "Log into Keyzii"
  reset_token: string | undefined
  //for sing up
  company: string = "";
  name: string = "";
  bShowGoogleBtn: boolean = true
  subscriptions: any[] = []
  last_name: string = ""

  btn_back: string = "#ECECEC"
  btn_color: string = "#000"
  @ViewChild('username') username: ElementRef | undefined;


  private msalConfig = {
    auth: {
      clientId: 'e9139727-9928-460e-9065-0bd7665f08a5',
      authority: 'https://login.microsoftonline.com/7616b607-80cc-4f97-82dd-9e85725be5cf',
      redirectUri: 'http://localhost:4205', // Redirect URI configured in Azure portal
    }
  };
  private msalInstance: any;

  constructor(
    public pagesState: Store<PagesState>,
    public keyzii_service: KeyziiService,
    public router: Router,
    public calendar_service: CalendarService,
    public ms_auth_service: MSAuthService,
    // public recaptcha_service: RecaptchaService
  ) {
    console.log("login")
    let error = getQueryParameter("error")
    if (error) {
      this.pagesState.dispatch(new NotifyError(error))
    }

    let session_id = getQueryParameter("session_id")
    if (session_id) { //
      console.log("already loged in")
      this.keyzii_service.logIntWithSession(session_id)
    }
    this.reset_token = getQueryParameter('reset')
    if (this.reset_token) {

      setTimeout(() => {
        this.title = "Reset Password"
        this.email = ""
      });
    }

    this.pagesState.select(codeFromURL).subscribe((code_from_url: string | undefined) => {
      if (code_from_url) {
        this.gotoSignUp()
      }
    })
    this.subscriptions.push(this.pagesState.select(isPhone).subscribe((isPhone: boolean) => {
      setTimeout(() => this.isPhone = isPhone);
    }))
    setTimeout(() => {
      this.bShowGoogleBtn = true
    }, 2000)
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }

  gotoSignUp() {
    this.pagesState.dispatch(new OpenMenu(true))
    this.router.navigate(["signup"]);
  }
  ngAfterViewInit(): void {

    setTimeout(() => {
      if (this.username) {
        this.changedEmail()

      }
      //******************************************************************************
      //******************************************************************************
      //******************************************************************************
      // this.email = "sergio@venditio.us" //"sergio@asci.us" // "ag204@ag.com" //
      // this.password = "Invento@6" //"Invento@21" //
      // this.login()
      //
      // setTimeout(() => {
      //   // this.pagesState.dispatch(new ShowCC("kpay-bar"))
      //   this.router.navigate(['profile']);
      //   // this.pagesState.dispatch(new ShowCC("billing"))
      // }, 100)

    }, )
    this.bInvalidEmail = !validateEmail(this.email)
  }


  showError(error: string) {

    setTimeout(() => this.error_text = error)
    setTimeout(() => this.error_text = "", 3000)
  }
  async  forgot() {
    if (this.bInvalidEmail) {
      this.showError("Please enter your email")
    } else {
      setTimeout(() => this.bShowSentEmail = true)
      try {
        this.bForgat = true;
        // this.pagesState.dispatch(new NotifyUser("You will receive an email to set your password"))

        await this.keyzii_service.signUp(this.email)
        this.bShowSentEmail = true
        let exp = new Date().getTime() + 5 * 60 * 1000
        let exp_date = new Date(exp)
        this.info_text = "Your link will expire on " + exp_date.toString()
      } catch (e: any) {
        let err = "There was a problem sending you the email " + JSON.stringify(e)
        this.showError(err)
        this.pagesState.dispatch(new NotifyError(err))
      }
    }
  }
  logedInWithGoogle() {
    console.log("Logged in with google")
  }
  async login() {
    if (this.reset_token) {
      try {

        // await this.recaptcha_service.checkCaptcha("login")

        let rv = await this.keyzii_service.resetPWd(this.reset_token, this.email, this.password)
      } catch (e: any) {
        let err_desc = ""
        if (e.error) {
          if (e.error.error) {
            err_desc = e.error.error
          }
        }
        this.error_text = "Error resetting the password " + err_desc
      }
    } else {

      try {
        await this.keyzii_service.loginWithEmail(this.email, this.password)
        //Navigation will happen when the side bar gets the myId
      } catch (e: any) {
        if (e.error instanceof ProgressEvent) {
          this.pagesState.dispatch(new NotifyError("Server might be down"))
          return

        }

        console.error(e)
        if (e.error) {
          if (e.error.error) {
            this.pagesState.dispatch(new NotifyError(e.error.error))
          } else {
            this.pagesState.dispatch(new NotifyError(e.error))
            // this.showError(e.error)

          }
        }
      }
    }
  }



  async changedEmail($event?: any) {
    this.bInvalidEmail = !validateEmail(this.email)
    if (!this.bInvalidEmail) {
      this.btn_back = "#000"
      this.btn_color = "#fff"
    } else {
      this.btn_back = "#ECECEC"
      this.btn_color = "#000"
    }
  }
  async changedConfirm($event: any) {
    if (this.password != this.password_conf) {
      this.error_text = "Passwords are diferent"
    } else {
      this.error_text = ""
    }
  }

  support() {
    window.open("https://www.keyzii.com/contact")
  }
  async logInWithGoogle() {
    try {
      let auth_url = await this.calendar_service.getGoogleLoginURL()
      window.location.href = auth_url
      console.log("auth_url " + auth_url)
    } catch (e) {
      console.error("error getting auth_url " + e)
    }
  }
  async msLogin() {
    try {
      let url = await this.ms_auth_service.getMSURL()
      console.log("Redirect to MS " + url)
      window.location.href = url
    } catch (e) {
      console.error(e)
    }
  }
}

<div class="scrn">
  <div class="abs_center out">


    <div class="title">
      Billing Details
    </div>
    <div class="stitle">Email Address </div>
    <input class="in" [ngClass]="{invalid:bInvalidEmail}" type="email" placeholder="Email address" name="" value="" [(ngModel)]="email" (ngModelChange)="changedEmail($event)">
    <div class="stitle">Company name </div>
    <div class="cc_li">
      <input class="in" type="text" placeholder="Company name" name="" value="" [(ngModel)]="cia_name" (ngModelChange)="changed()">
    </div>


    <div class="stitle">Company billing address (for invoice)</div>
    <input class="in" type="text" placeholder="Address" name="" value="" [(ngModel)]="cc_address" (ngModelChange)="changed()">
    <div class="cc_li">
      <input class="in zip" type="text" placeholder="Zip Code" name="" value="" [(ngModel)]="cc_zip" (ngModelChange)="changed()">
      <input class="in state" type="text" placeholder="State/Region" name="" value="" [(ngModel)]="cc_state" (ngModelChange)="changed()">
      <img src="assets/images/expand.svg" class="expand" [ngClass]="{close_ex:bShowStates}" (click)="bShowStates=!bShowStates" />
    </div>

    <div class="send add_cc" [ngClass]="{btn_ready:bReady}" (click)="updateBillingInfo()">
      <div class="abs_center btn" [ngClass]="{btn_tx_ready:bReady}">
        Update
      </div>
    </div>
    <div class="states st_t" *ngIf="bShowStates">
      <div class="state_l" *ngFor="let state of us_states; let i = index" (click)="selState(i)">
        {{state.name}}
      </div>
    </div>
    <img src="assets/images/close.svg" class="close" (click)="closeIt()">
  </div>

</div>

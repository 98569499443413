<div class="scrn">
  <div class="abs_center out">


    <div class="title">
      PaymentStars Form Request {{bReady}}
    </div>
    <div class="stitle">First name </div>
    <div class="cc_li">
      <input class="in" type="text" placeholder="First name" name="" value="" [(ngModel)]="first_name" (ngModelChange)="changed()">
    </div>
    <div class="stitle">Last name </div>
    <div class="cc_li">
      <input class="in" type="text" placeholder="Last name" name="" value="" [(ngModel)]="last_name" (ngModelChange)="changed()">
    </div>
    <div class="stitle">Company name </div>
    <div class="cc_li">
      <input class="in" type="text" placeholder="Company name" name="" value="" [(ngModel)]="cia_name" (ngModelChange)="changed()">
    </div>
    <div class="stitle">Email Address </div>
    <input class="in" [ngClass]="{invalid:bInvalidEmail}" type="email" placeholder="Email address" name="" value="" [(ngModel)]="email" (ngModelChange)="changedEmail($event)">
    <div class="stitle">Phone number</div>
    <div class="cc_li">
      <input class="in" type="text" placeholder="Phone number" name="" value="" [(ngModel)]="phone" (ngModelChange)="changed()">
    </div>



    <div class="send add_cc" [ngClass]="{btn_ready:bReady}" (click)="sendReq()">
      <div class="abs_center btn" [ngClass]="{btn_tx_ready:bReady}">
        Send Request
      </div>
    </div>

    <img src="assets/images/close.svg" class="close" (click)="closeIt()">
  </div>

</div>

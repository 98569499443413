import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { AgentProfileComponent } from './agent-profile/agent-profile.component';
import { ControlsModule } from '../controls/controls.module';
import { ProfileOptsComponent } from './agent-profile/profile-opts/profile-opts.component';
import { FormsModule } from '@angular/forms';
import { CanvasModule } from '../canvas/canvas.module';



@NgModule({
  declarations: [
    HeaderComponent,
    AgentProfileComponent,
    ProfileOptsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ControlsModule,
    CanvasModule
  ], exports: [
    HeaderComponent,
    ProfileOptsComponent
  ]
})
export class HeaderModule { }

import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetShare } from 'src/app/k-video/k-video.actions';
import { KVideoState } from 'src/app/k-video/k-video.model';
import { KVideoService } from 'src/app/k-video/k-video.service';
import { ShowAPI, ShowAsset } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { myCompany, showAPI, showAsset } from 'src/app/pages/pages.selectors';
import { ToolService } from 'src/app/tools/tool.service';
import { WSSService } from 'src/app/wss/wss.service';

@Component({
  selector: 'close-and-rename-asset',
  templateUrl: './close-and-rename.component.html',
  styleUrls: ['./close-and-rename.component.scss']
})
export class CloseAndRenameComponent {

  asset_name: string = ""
  bEditName: boolean = false;
  asset: any
  subscriptions: any[] = []
  bShowAPI: boolean = false
  company: string = ""
  @Input() bSharingScr: boolean = false
  constructor(
    public pagesState: Store<PagesState>,
    public wss_service: WSSService,
    private tools_service: ToolService,
    private kVideoState: Store<KVideoState>,
    private k_video_service: KVideoService, //Make sure the service is ready

  ) {
    console.log("close and rename")
    this.subscriptions.push(this.pagesState.select(showAPI).subscribe((show: boolean) => {
      this.bShowAPI = show
      if (this.bShowAPI) {
        this.asset_name = this.company + "\'s API"
      }
    }))
    this.subscriptions.push(this.pagesState.select(myCompany).subscribe((company: string) => {
      if (company) {
        this.company = company + "\'s API"
        if (this.bShowAPI) {
          this.asset_name = this.company
        }
      }
    }))
    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      setTimeout(() => {
        if (asset) {
          this.asset = asset
          if (asset.name) {
            this.asset_name = asset.name
          } else {
            this.asset_name = asset.type
          }
        }
      })
    }))

  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  close() {
    if (this.bSharingScr) {
      this.kVideoState.dispatch(new SetShare(false))
      this.k_video_service.stopShareScreen()
      let msg: any = {
        share_screen: "none",
        show_asset: "none"
      }
      this.wss_service.sendMessageToMembersAndMe(msg)
      return
    }
    if (this.bShowAPI) {
      this.pagesState.dispatch(new ShowAPI(false))
    } else {
      this.pagesState.dispatch(new ShowAsset({}));
      //The message is listend to by pages/in-call
      let msg: any = {
        show_asset: "none"
      }
      this.wss_service.sendMessageToMembersAndMe(msg)
    }



  }
  async changeName() {
    let asset = Object.assign({}, this.asset)
    asset.name = this.asset_name
    this.asset = asset
    this.bEditName = false

    try {
      await this.tools_service.changeAsset(this.asset)
    }
    catch (e: any) {
    }
  }
}

<div class="out">

  <img *ngIf="!bEditName" src="assets/images/close.svg" class="close" (click)="close()" />

  <div *ngIf="!bEditName" class="font_text asset_name">
    {{asset_name}}
  </div>
  <!-- <img *ngIf="!bEditName && !bShowAPI" src="assets/images/pen.svg" class="pen" (click)="bEditName=true" /> -->

  <!-- <input *ngIf="bEditName" class="in" type="text" placeholder="Name" name="" value="" [(ngModel)]="asset_name">
  <img *ngIf="bEditName" src="assets/images/save.svg" class="save" (click)="changeName()" /> -->

  <!-- <k-button *ngIf="bEditName" class="save_name" text="Save Name" background="#40BE5C" (click)="changeName()"></k-button> -->
</div>

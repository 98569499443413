<div class="out">
  <div class="line top_line">
    <div class="opt_tx">
      <div>
        {{new_caller}}
      </div>
      <div *ngIf="bShowPersonal" class="personal">
        Personal
      </div>
    </div>
  </div>
  <div class="line" *ngIf="queue_entry.prev_call">
    <div class="opt_tx">
      <div>
        Previous call
      </div>
      <div>
        {{prev_rep_name}}
      </div>
    </div>
    <div class="date"> {{prev_date|date:'shortDate'}}</div>
  </div>
  <phone-hang-up *ngIf="bShowHangUp" class="ph_r1 ph_r2" [round]="true" (click)="hangUp()"></phone-hang-up>
  <phone-answer class="ph_r1" [round]="true" (click)="answer()"></phone-answer>

</div>

<div class="cards">
  <div class="cc_box" *ngFor="let card of cards;let i = index">
    <img class="cc" [src]="getCCLogo(card.ssl_card_number)">

    <div class="cctxd">
      {{card.ssl_card_short_description}} {{card.ssl_card_number}}
    </div>
    <div class="ex_ccd">
      Exp. date {{formatExpDate(card.ssl_exp_date)}}
    </div>
    <div *ngIf="i!=converge.select" class="set_default" (click)="selCard(i)">
      Set as Default
    </div>
    <div *ngIf="i==converge.select" class="default">
      Default
    </div>
    <img src="assets/images/delete.svg" class="del" alt="" (click)="delCard(i)">
  </div>
  <div class="cc_box" *ngIf="cards.length==0">
    <div class="first">
      Add your first credit card for payment.
    </div>
    <div class="firsrt_imgs">
      <img class="fist_img" src="assets/images/cc_amex.svg" alt="">
      <img class="fist_img" src="assets/images/cc_visa.svg" alt="">
      <img class="fist_img" src="assets/images/cc_mc.svg" alt="">
    </div>
  </div>
</div>

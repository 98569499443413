<div #optComponent class="out">
  <div class="op_content">
    <div class="opt" *ngFor="let opt of options;let i = index" (click)="selOption(i)">
      <!-- <div class="check" [ngClass]="{sel:i == sel_option}">
      </div> -->
      <img class="copy_op" src="assets/images/copy.svg" (click)="copy($event)" />
      {{opt.text}}
    </div>
  </div>

</div>

<div class="out">
  <div class="line" (click)="sign()">
    <!-- <div class="img_b" [style.background]="box_color"> <img class="img" src="assets/images/signature_w.svg" alt=""></div> -->
    Signature
    <div class="check" [ngClass]="{selected:selected_input=='Sign'}"></div>
    <!-- <img class="r_check" src="assets/images/r_check.svg"> -->
  </div>
  <div class="line" (click)="initial()">
    <!-- <div class="img_b" [style.background]="box_color"><img class="img" src="assets/images/initials_w.svg" alt=""></div> -->
    Initials
    <div class="check" [ngClass]="{selected:selected_input=='Initial'}"></div>
    <!-- <img class="r_check" src="assets/images/r_check.svg"> -->
  </div>
  <div class="line" (click)="date()">
    <!-- <div class="img_b" [style.background]="box_color"><img class="img" src="assets/images/calendar_white.svg" alt=""></div> -->
    Date
    <div class="check" [ngClass]="{selected:selected_input=='Date'}"></div>
    <!-- <img class="r_check" src="assets/images/r_check.svg"> -->
  </div>
  <div class="line" (click)="name()">
    <!-- <div class="img_b" [style.background]="box_color"><img class="img" src="assets/images/name_w.svg" alt=""></div> -->
    Name
    <div class="check" [ngClass]="{selected:selected_input=='Name'}"></div>
    <!-- <img class="r_check" src="assets/images/r_check.svg"> -->
  </div>
  <div class="line" (click)="email()">
    <!-- <div class="img_b" [style.background]="box_color"><img class="img" src="assets/images/email_w.svg" alt=""></div> -->
    Email
    <div class="check" [ngClass]="{selected:selected_input=='Email'}"></div>
    <!-- <img class="r_check" src="assets/images/r_check.svg"> -->
  </div>
  <div class="line" (click)="text()">
    <!-- <div class="img_b" [style.background]="box_color"><img class="img" src="assets/images/text_w.svg" alt=""></div> -->
    Text
    <div class="check" [ngClass]="{selected:selected_input=='Text'}"></div>
    <!-- <img class="r_check" src="assets/images/r_check.svg"> -->
  </div>
</div>

<div class="out" #outComponent>
  <div (click)="showPriceOptions()" class="title">
    {{title}}
    <img class="exp" [ngClass]="{colapse:bShowOptions}" src="assets/images/expand.svg">
  </div>
  <div *ngIf="bShowPriceOptions" #optComponent class="options opts" [style.border]="border">

    <mls-min-price class="min-price"></mls-min-price>
    <br />
    <mls-max-price></mls-max-price>
  </div>
</div>

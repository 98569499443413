
import { CanvasState, initialCanvasState, } from './canvas.model'

import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectCanvasState = createFeatureSelector<CanvasState>("canvas");

export const toolsChanged = createSelector(
  selectCanvasState,
  state => state.tools
)
export const selectToolOptions = createSelector(
  selectCanvasState,
  state => state.select_tool_opt
)
export const selectedTool = createSelector(
  selectCanvasState,
  state => state.selected_tool
)
export const selectedInput = createSelector(
  selectCanvasState,
  state => state.selected_input
)

export const saveSVG = createSelector(
  selectCanvasState,
  state => state.save_svg
)

export const copyTo = createSelector(
  selectCanvasState,
  state => state.copy_to
)
export const pdfSecOpts = createSelector(
  selectCanvasState,
  state => state.sec_opts
)
export const pdfSvg = createSelector(
  selectCanvasState,
  state => state.pdf_svg
)

export const signers = createSelector(
  selectCanvasState,
  state => state.signers
)
export const signersWithSteps = createSelector(
  selectCanvasState,
  state => state.signers_with_steps
)

export const signerRoll = createSelector(
  selectCanvasState,
  state => state.signer_roll
)
export const changedSVG = createSelector(
  selectCanvasState,
  state => state.changed_svg
)
export const signerSteps = createSelector(
  selectCanvasState,
  state => state.signer_steps
)
export const didStepId = createSelector(
  selectCanvasState,
  state => {
    // console.log("Sel did_step_id" + state.did_step_id)
    return state.did_step_id
  }
)
export const goToStep = createSelector(
  selectCanvasState,
  state => state.go_to_step
)
export const pdfSvgBlob = createSelector(
  selectCanvasState,
  state => state.pdf_svg_blob
)
export const signerPic = createSelector(
  selectCanvasState,
  state => state.signer_pic
)
export const signerLic = createSelector(
  selectCanvasState,
  state => state.signer_lic
)
export const callerPic = createSelector(
  selectCanvasState,
  state => state.caller_pic
)
export const callerLic = createSelector(
  selectCanvasState,
  state => state.caller_lic
)
export const callerResults = createSelector(
  selectCanvasState,
  state => state.caller_results
)

export const progress = createSelector(
  selectCanvasState,
  state => state.progress
)
export const bioResults = createSelector(
  selectCanvasState,
  state => state.bio_results
)
export const bioResultsError = createSelector(
  selectCanvasState,
  state => state.bio_results_error
)
export const signerName = createSelector(
  selectCanvasState,
  state => state.signer_name
)
export const signerEmail = createSelector(
  selectCanvasState,
  state => state.signer_email
)
export const lastSigner = createSelector(
  selectCanvasState,
  state => state.last_signer
)
export const lastSignedContract = createSelector(
  selectCanvasState,
  state => state.last_signed_contract
)

export const selSignerKey = createSelector(
  selectCanvasState,
  state => state.sel_signer_key
)

export const contractExecuted = createSelector(
  selectCanvasState,
  state => state.contract_executed
)
export const contractSigned = createSelector(
  selectCanvasState,
  state => state.contract_signed
)
export const readyToBlockChain = createSelector(
  selectCanvasState,
  state => state.ready_to_block_chain
)
export const getSignature = createSelector(
  selectCanvasState,
  state => state.get_signature
)
export const getInitials = createSelector(
  selectCanvasState,
  state => state.get_initials
)
export const changeSignature = createSelector(
  selectCanvasState,
  state => state.signature_data
)
export const changeInitials = createSelector(
  selectCanvasState,
  state => state.initials_data
)
export const addToUndo = createSelector(
  selectCanvasState,
  state => state.add_to_undo
)
export const undo = createSelector(
  selectCanvasState,
  state => state.undo_seq
)
export const undoStackSize = createSelector(
  selectCanvasState,
  state => state.undo_stack_size
)
export const fillColor = createSelector(
  selectCanvasState,
  state => state.fill_color
)
export const strokeColor = createSelector(
  selectCanvasState,
  state => state.stroke_color
)
export const textColor = createSelector(
  selectCanvasState,
  state => state.text_color
)
export const pencilWidth = createSelector(
  selectCanvasState,
  state => state.pencil_width
)
export const tools = createSelector(
  selectCanvasState,
  state => state.tools
)
export const textFont = createSelector(
  selectCanvasState,
  state => state.text_font
)
export const textWeight = createSelector(
  selectCanvasState,
  state => state.text_weight
)
export const textSize = createSelector(
  selectCanvasState,
  state => state.text_size
)

export const fieldTextFont = createSelector(
  selectCanvasState,
  state => state.field_text_font
)
export const fieldTextWeight = createSelector(
  selectCanvasState,
  state => state.field_text_weight
)
export const fieldTextSize = createSelector(
  selectCanvasState,
  state => state.field_text_size
)

export const shapeOptions = createSelector(
  selectCanvasState,
  state => state.shape_options
)
export const selShape = createSelector(
  selectCanvasState,
  state => state.sel_shape
)
export const editComment = createSelector(
  selectCanvasState,
  state => state.edit_comment
)
export const showComment = createSelector(
  selectCanvasState,
  state => state.show_comment
)
export const selectedId = createSelector(
  selectCanvasState,
  state => state.selected_id
)
export const assetSVG = createSelector(
  selectCanvasState,
  (state: any, asset_id: string) => {
    return state.saved_svgs[asset_id]
  }
);
export const download = createSelector(
  selectCanvasState,
  state => state.download
)
export const addToChat = createSelector(
  selectCanvasState,
  state => state.add_to_chat
)
export const canvasOpts = createSelector(
  selectCanvasState,
  state => state.canvas_opts
)
export const isInitial = createSelector(
  selectCanvasState,
  state => state.bIsInitial
)
// export const numSigandInitials = createSelector(
//   selectCanvasState,
//   state => state.num_sig_and_initials
// )
export const signersSteps = createSelector(
  selectCanvasState,
  state => state.signers_steps
)
export const resizedCanvas = createSelector(
  selectCanvasState,
  state => state.resized_canvas
)
export const showDrawTools = createSelector(
  selectCanvasState,
  state => state.show_draw_tools
)

<div class="out">
  <div class="title">
    Signers
  </div>


  <div class="stitle" *ngIf="!bClose">
    Please complete the steps below.
  </div>


  <div class="signers" [ngClass]="{signers_bc:bBlockChain}" *ngIf="!bClose">


    <div *ngFor="let skey of signer_keys; let i=index" class="signer">
      <div class="line" [style.color]="signers[skey].color">
        {{signers[skey].name}}
        <div class="space"> </div> <img class="expand expand_user" [ngClass]="{close:!signers[skey].hidden}" src="assets/images/expand.svg" alt="" (click)="toogleSigner(skey)">
      </div>
      <progres-h class="progress" [progress]="signers[skey].progress"> </progres-h>

      <div *ngIf="!signers[skey].hidden">
        <div class="step" *ngFor="let key of signers[skey].steps_keys; let i=index" (click)="select(skey, key)">
          <div class="step_tx">
            <div *ngIf="signers[skey].curr_step==key" class="blue_dot">

            </div>
            {{(i+1)}}. {{signers[skey].steps[key].k_input}}{{signers[skey].steps[key].desc}}
          </div>

          <div class="step_status">
            <img *ngIf="!signers[skey].steps[key].done!" src="assets/images/arrow_not_done.svg">
            <img *ngIf="signers[skey].steps[key].done" src="assets/images/arrow_done.svg">
          </div>
        </div>
      </div>
      <div class="line1">

      </div>
    </div>

  </div>
  <!-- Caller steps  -->

  <!-- <div class="lower">
    {{lower_msg}}
  </div> -->
  <div class="bc_notice" *ngIf="bBlockChain">
    <strong>Please note:</strong> this document will be blockchain secured upon completion.
  </div>
  <k-button *ngIf="bShowDone" text="Done" class="done" [background]="done_color" (click)="done()"></k-button>
  <div *ngIf="!bShowDone" class="party">
    <img class="abs_center" src="assets/images/party.svg">
  </div>
</div>

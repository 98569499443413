import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SVG } from '@svgdotjs/svg.js';
// import { FeedBack } from '../controls/controls.actions';
import { ControlsState } from '../controls/controls.model';
import { CallerData, ContactInfo, NotifyUser, NotifyWait, ShowAsset } from '../pages/pages.actions';
import { PagesState } from '../pages/pages.model';
import { callerEmail, callerFirstName, callerLastName, iAmManager, myEmail, myName, showAsset } from '../pages/pages.selectors';
import { ToolService } from '../tools/tool.service';
import { validateEmail, colors } from '../utils';
import { WSSState } from '../wss/wss.model';
import { callId, lastMsg } from '../wss/wss.selectors';
import { WSSService } from '../wss/wss.service';
import { BioResults, BioResultsError, CallerLic, CallerPic, CallerResults, ClearCanvasState, ClearSVGs, ContractExecuted, ContractSigned, DidStep, GetPDFSVG, GoToStep, LastSignedContract, PdfSecOpt, ReadyToBlockChain, SelectToolOpts, SelSigner, SetSigners, ShowDrawTools, SignerEmail, SignerName, SignersWithSteps } from './canvas.actions';
import { changedSVG, changeInitials, signerPic, changeSignature, contractExecuted, contractSigned, copyTo, didStepId, pdfSecOpts, pdfSvg, pdfSvgBlob, readyToBlockChain, signerEmail, signerRoll, signers } from './canvas.selectors';



@Injectable({
  providedIn: 'root'
})
export class CanvasService {
  // dic: any = {
  //   "sign": "Sign",
  //   "initial": "Initial",
  //   "text": "Enter Text"
  // }

  signer_roll: string = "" //set up by pdf-canvas when it opens
  signer_email: string | undefined
  signer_name: string | undefined
  signer_key: string | undefined

  asset: any
  // contract_id: string | undefined
  signers: any = {}
  copy_to: any[] = []
  sec_opts: any = {}
  svg: string = ""

  face_name_url: string | undefined //s3 urls
  lic_name_url: string | undefined

  contract_signed: boolean = false //signed by the user
  contract_executed: boolean = false //signed by everybody
  ready_to_block_chain: boolean = false //signed by everybody
  next_signer_key: string | undefined
  caller_first_name: string | undefined
  caller_last_name: string | undefined
  caller_cia_name: string | undefined
  caller_email: string | undefined
  signature: any
  initials: any
  role: string = "caller"

  viewBoxWidth: number = 0
  viewBoxHeight: number = 0

  my_email: string | undefined
  my_name: string | undefined
  call_id: string | undefined

  bPDFLoaded: boolean = false
  constructor(
    public canvasStore: Store<CanvasState>,
    public tool_service: ToolService,
    public pagesState: Store<PagesState>,
    private httpClient: HttpClient,
    public controlsStore: Store<ControlsState>,
    public wss_service: WSSService,
    private wssState: Store<WSSState>,

  ) {
    console.log("canvas service")
    this.pagesState.select(myEmail).subscribe((email: string | undefined) => {
      if (email) {
        this.my_email = email
        this.setCallSigners()
      }
    })

    this.pagesState.select(myName).subscribe((name: string | undefined) => {
      if (name) {
        this.my_name = name
        this.setCallSigners()
      }
    })
    this.pagesState.select(iAmManager).subscribe((manager: boolean) => {
      if (manager) {
        this.role = "manager"
      } else {
        this.role = "rep" //we used agent in sing-tools.component
      }
    })
    this.canvasStore.select(changeSignature).subscribe((data: any | undefined) => {
      if (data) {
        // this.dump("before sign")
        this.signature = data
      }
    })
    this.canvasStore.select(changeInitials).subscribe((data: any | undefined) => {
      // this.dump("before initial")
      if (data) {
        this.initials = data
      }
    })
    this.canvasStore.select(signerPic).subscribe((data: any | undefined) => {
      this.lic_name_url = undefined
      this.face_name_url = undefined
    })

    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
      setTimeout(() => { //give it a chance to close and then erase it
        if (call_id) {
          this.canvasStore.dispatch(new ClearSVGs())
          this.canvasStore.dispatch(new ClearCanvasState())
          //Set up the emails for the rep and the caller
        } else {
          this.caller_email = undefined //If this is on, it will show it in the next call
          this.canvasStore.dispatch(new SetSigners({}))

        }
        this.setCallSigners()
      }, 500)
    })

    this.pagesState.select(callerEmail).subscribe((email: string | undefined) => {
      if (email) {
        // this.controlsStore.dispatch(new SignerEmail(email))
        this.caller_email = email
        this.setCallSigners()
      }
    })
    this.pagesState.select(callerFirstName).subscribe((name: string | undefined) => {
      if (name) {
        // this.controlsStore.dispatch(new SignerName(name))
        this.caller_first_name = name
        this.setCallSigners()
      }
    })
    this.pagesState.select(callerLastName).subscribe((last: string | undefined) => {
      if (last) {
        // this.controlsStore.dispatch(new SignerName(name))
        this.caller_last_name = last
        this.setCallSigners()
      }
    })
    this.pagesState.select(myEmail).subscribe((email: string) => {
      this.signer_email = email
      this.controlsStore.dispatch(new SignerEmail(email))
    })
    this.pagesState.select(myName).subscribe((name: string) => {
      this.signer_name = name
      this.controlsStore.dispatch(new SignerName(name))
    })

    //This is called when the contract.component reads the parameters
    this.canvasStore.select(signerEmail).subscribe((email: string | undefined) => {
      if (email) {
        this.signer_email = email
        this.setsSignerKey()
      }
    })
    this.pagesState.select(lastMsg).subscribe((msg: any) => {
      if (msg) {
        if (msg.last_contract_id) {
          this.asset._id = msg.last_contract_id
          return
        }
        //Here we will collect the information from the other signers when they are on line
        //signer_lic:base64 png, signer_pic:base64 png
        //signed id of the signed field, initial: id of the initialed fied
        if (msg.signer_done) {
          console.log("#PR got signer_done")
          let signers = Object.assign({}, this.signers)
          let signer = Object.assign({}, signers[msg.signer_done])
          signer.done_siging = true;
          signers[msg.signer_done] = signer
          this.signers = signers
          return
        }
        if (msg.caller_pic) {
          console.log("#PR got caller_pic")
          this.canvasStore.dispatch(new CallerPic(msg.caller_pic))
          this.processDidStep("take_pic", msg.signer_key)
        }
        if (msg.caller_lic) {
          console.log("#PR got caller_lic")
          this.canvasStore.dispatch(new CallerLic(msg.caller_lic, msg.img_type))
          this.processDidStep("driver_lic", msg.signer_key)
        }
        if (msg.caller_results) {
          console.log("#PR got caller_results " + JSON.stringify(msg))
          this.canvasStore.dispatch(new CallerResults(msg.caller_results))
          this.processDidStep("image_rec", msg.signer_key)
        }

      }
    })
    this.pagesState.select(showAsset).subscribe((asset: any) => {
      if (asset && asset.type) {
        if (asset.type == "contract"
          || asset.type == "pdf"
          || asset.type == "white_board"
          || asset.type == "on_board"
          || asset.type == "image"
          || asset.type == "video_canvas") {
          this.bPDFLoaded = false
          this.canvasStore.dispatch(new ShowDrawTools(false))
          //reset everything when the asset is opened
          this.contract_signed = false //signed by the user
          this.contract_executed = false //signed by everybody
          this.ready_to_block_chain = false //signed by everybody
          this.next_signer_key = undefined
          this.asset = Object.assign({}, asset)

          if (asset.signers) {
            let signers: any = Object.assign({}, asset.signers) //this.setSignersData(asset.signers)


            this.asset.signers = signers
            this.signers = signers
            this.setCallSigners()

            this.setsSignerKey()
            this.canvasStore.dispatch(new SetSigners(signers))//show assets
          }
          if (asset.sec_opts) {
            this.sec_opts = asset.sec_opts
            this.canvasStore.dispatch(new PdfSecOpt(this.sec_opts))
          }

        } else {
          delete this.asset
        }
        return
      }
      if (this.asset) {
        delete this.asset
      }
    })
    this.canvasStore.select(readyToBlockChain).subscribe((ready: boolean) => {
      this.ready_to_block_chain = ready
    })
    this.canvasStore.select(contractExecuted).subscribe((executed: boolean) => {
      this.contract_executed = executed
    })
    this.canvasStore.select(contractSigned).subscribe((signed: boolean) => {
      this.contract_signed = signed
    })

    // This also gets fired when we receive a message from the other members of the call
    this.canvasStore.select(didStepId).subscribe((id: string) => {
      // console.log("didStepId  " + id)
      if (this.signer_key) {
        this.processDidStep(id, this.signer_key)
      }
    })

    this.canvasStore.select(changedSVG).subscribe((num: number) => {
      if (num) {
        this.checkSGV()
      }
    })
    this.canvasStore.select(signerRoll).subscribe((roll: string) => {
      this.signer_roll = roll
    })

    this.canvasStore.select(pdfSecOpts).subscribe((sec_opts: any) => {
      if (sec_opts) {
        this.sec_opts = sec_opts
        this.debounce_is_user_signer = 0//By pass the timer
        this.checkSGV()
      }
    })

    this.canvasStore.select(signers).subscribe((signers: any) => {
      this.signers = signers
      //IF there is more than one signer, then ask the other party for email
      let keys = Object.keys(this.signers)
      if (keys.length == 2) {
        // if (this.caller_email) {
        //   this.pagesState.dispatch(new CallerEmail(this.caller_email))
        //   if (this.caller_name) {
        //     this.pagesState.dispatch(new CallerName(this.caller_name))
        //   }
        // } else {
        //   this.pagesState.dispatch(new ContactInfo("ask"))
        //   this.wss_service.sendMessageToOtherMembers({
        //     ask_for: "email"
        //   })
        // }


        if (!this.caller_email) {
          this.pagesState.dispatch(new ContactInfo("ask"))
          this.wss_service.sendMessageToOtherMembers({
            ask_for: "email"
          })
        }
      }

      this.setsSignerKey()
    })

    this.canvasStore.select(copyTo).subscribe((copy_to: any[]) => {
      this.copy_to = copy_to
    })

    //Called for requested_by not download, signed or blockchain
    this.canvasStore.select(pdfSvg).subscribe((rv: any) => {
      if (rv) {
        this.afterSVG(rv)
      }
      //This gets in a endless loop  this.canvasStore.dispatch(new PdfSvg({})) //Make sure we get it next time
      //insted we set a timer
    })
    //Called for requested_by  signed or blockchain
    this.canvasStore.select(pdfSvgBlob).subscribe((rv: any) => {
      if (rv) {
        if (rv.requested_by == "signed") {
          this.sendToServer(rv)
        }
        // else if (rv.requested_by == "signed") {
        //   this.saveSignedPDFAndBlob(rv)
        // }
      }
    })
  }
  setAsset(asset: any) {
    this.asset = asset
  }
  setCallSigners() {
    if (this.bPDFLoaded) {
      if (this.my_email) {
        this.canvasStore.dispatch(new SignerEmail(this.my_email))
      }
      if (this.my_name) {
        this.canvasStore.dispatch(new SignerName(this.my_name))
      }

      //If the contract was shared we DO NOT set the call signers even if we are in a call
      //We will know because there is no send_email_at in any of the signers
      if (this.call_id) {
        let signers = Object.assign({}, this.signers)

        let new_key: number = new Date().getTime()

        let keys = Object.keys(signers)
        if (this.my_email) {
          if (keys.length > 0) {
            let key = keys[0]
            let signer = Object.assign({}, signers[key])
            if (signer.send_email_at) {
              return
            }
            signer.email = this.my_email
            signer.name = this.my_name
            if (!signer.color) {
              signer.color = colors[0]
            }
            signers[key] = signer
          } else { //add the rep
            signers[new_key.toString()] = {
              color: colors[0],
              name: this.my_name,
              title: "Agent",
              email: this.my_email
            }
          }
        }
        if (this.caller_email) {
          if (keys.length > 1) {

            let key = keys[1]
            let signer = Object.assign({}, signers[key])
            if (signer.send_email_at) {
              return
            }
            signer.email = this.caller_email
            signer.name = this.caller_first_name + " " + this.caller_last_name
            signers[key] = signer

          } else { //add the caller
            new_key++
            signers[new_key.toString()] = {
              color: colors[1],
              name: this.caller_first_name + " " + this.caller_last_name,
              title: "Client",
              email: this.caller_email
            }
          }
        }
        this.canvasStore.dispatch(new SetSigners(signers)) //in set call signers after updating the emails and names
      }
    }
  }
  setsSignerKey() {
    if (this.signer_email) {
      let keys = Object.keys(this.signers)
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        let signer = this.signers[key]
        if (signer.email) {
          if (signer.email == this.signer_email) {
            this.signer_key = key  //This is my key
            this.signer_name = signer.name
            this.canvasStore.dispatch(new SelSigner(this.signer_key))
          }
        }
      }
    }
  }

  setSVGDim(viewBoxWidth: number, viewBoxHeight: number) {
    this.viewBoxWidth = viewBoxWidth
    this.viewBoxHeight = viewBoxHeight
  }
  // num_pages: number = 0
  // setNumPages(np: number) {
  //   this.num_pages = np
  // }
  /*setSignersData(in_signers: any) {
  let signers: any = Object.assign({}, in_signers)

      if (this.signer_roll == "manager") {
        if (signers.manager) {
          let manager = Object.assign({}, signers.manager)
          if (!validateEmail(manager.email)) {
            manager.email = this.signer_email
          }
          if (manager.name.length < 1) {
            manager.name = this.signer_name
          }
          signers.manager = manager
        }
      } else if (this.signer_roll == "agent") {
        if (signers.agent) {
          let agent = Object.assign({}, signers.agent)
          if (!validateEmail(agent.email)) {
            agent.email = this.signer_email
          }
          if (agent.name.length < 1) {
            agent.name = this.signer_name
          }
          signers.agent = agent
        }
      }
      if (this.caller_email) {
        let caller = Object.assign({}, signers.caller)
        if (!validateEmail(caller.email)) {
          caller.email = this.caller_email
        }
        if (!caller.name) {
          caller.name = this.caller_name
        } else if (caller.name.length < 1) {
          caller.name = this.caller_name
        }

        signers.caller = caller
      }

  return signers
  }*/
  //****************************************************************************
  // RESTFULL API
  //****************************************************************************

  async saveSignedPDF() {//  /contract_signed_pdf
    this.debounce_is_user_signer = new Date().getTime()
    this.canvasStore.dispatch(new GetPDFSVG("signed")) //"pdfSvgBlob"
  }
  async blockChain() { //  /rep_signed_pdf
    if (this.ready_to_block_chain) {
      this.canvasStore.dispatch(new GetPDFSVG("signed"))
    }
  }
  async savePDFAsset() { // /asset, in tools_service
    if (this.asset) {
      this.canvasStore.dispatch(new GetPDFSVG("asset"))
    }
  }
  bSendingPDFEmails: boolean = false
  async sendPDFEmails() { //called by the header
    if (this.asset) {
      // this.contract_id = undefined//make sure we get a new contract
      this.canvasStore.dispatch(new GetPDFSVG("emailing"))
    }
  }
  async saveFileForRecognition(asset_id: string, id_type: string, blob: Blob) {
    return new Promise<any>(async (resolve, reject) => {
      const from = "rep"
      let fname = from + "_" + id_type + ".png"

      let jdata: any = {
        asset_id: asset_id,
        id_type: id_type,
        fname: fname,
        name: this.signer_name,
        email: this.signer_email
      }
      if (id_type == "face") {
        jdata.face_name = fname
        jdata.lic_name = this.lic_name_url
      } else {
        jdata.lic_name = fname
        jdata.face_name = this.face_name_url
      }


      const form = new FormData();
      let sdata = JSON.stringify(jdata)
      form.append("file", blob, sdata);
      try {
        let rv: any = await this.httpClient.post('/pdf_face', form).toPromise();

        if (id_type == "face") {
          this.face_name_url = fname
        } else {
          this.lic_name_url = fname
        }
        console.log("Bimetric results " + JSON.stringify(rv))
        /*
        compared_at: "2023-06-28T20:33:11.870Z"
        similar:99.95919799804688
            {
            url:
            exp: 1687987931871
            url: "https://keyzii-test.s3.us-west-1.amazonaws.com/649c270ed0bfa30d91f276c9/rep_face.png?AWSAccessKeyId=AKIA4UNR24CVC42GBRFX&Expires=1687987991&Signature=7ngg6a3qRRCtzgcM3F64wNdb32o%3D"
            }
        */
        if (rv.hasOwnProperty("similar")) {
          if (rv.similar > 1) {
            this.canvasStore.dispatch(new BioResults(rv)) //it also has the lic_scores
            let jmsg: any = {
              caller_results: rv,
              signer_key: this.signer_key
            }
            this.wss_service.sendMessageToOtherMembers(jmsg)
            resolve(rv)
            return
          }
        } else {
          if (id_type == "face") { //not an error for the face
            resolve(rv)
            return;
          }
        }
      } catch (e: any) {
        console.error(e)
        if (id_type == "face") {
          delete this.face_name_url
          reject()
          return
        }
      }
      if (id_type == "lic") { //No error and similarity < 0
        delete this.face_name_url
        delete this.lic_name_url
        this.bioFailure()
        this.canvasStore.dispatch(new BioResultsError(new Date().getTime()))
        reject()
      }

    })
  }

  getContractQRCode() {
    return new Promise<any>(async (resolve, reject) => {
      //Live contracts do not have an asset._id


      try {
        let contract_id = 0
        if (this.asset) {
          contract_id = this.asset._id
        }
        let rv: any = await this.httpClient.get('/get_rep_qr/' + contract_id).toPromise();
        if (!contract_id && !rv.new_contract_id) {
          console.error("no contract_id")
        }
        if (rv.new_contract_id) {
          let asset = Object.assign({}, this.asset)
          asset.new_contract_id = rv.new_contract_id
          this.asset = asset
        }
        resolve(rv.qr);
      } catch (e: any) {
        console.error(e)
        reject(e)
      }
    })
  }
  //****************************************************************************
  // End of RESTFULL API
  //****************************************************************************
  getGroupBorders(group: any): any {
    let gr = group
    let bbr = {}
    if (gr.bbox) {
      let bb = gr.bbox()
      let dataset = gr.node.dataset
      bbr = {
        bottom: bb.y2,
        left: bb.x,
        right: bb.x2,
        top: bb.y,
        height: bb.height,
        width: bb.width,
        k_type: dataset['k_type'],
        id: dataset['id'],
      }
    } else {
      console.log("no bbox")
    }

    return bbr


  }
  moveAndSize(array: string[], signature_box: any, target_box: any) {
    // console.log("in  "+spoints)
    // console.log("signature_box" + JSON.stringify(signature_box))
    // console.log("target_box" + JSON.stringify(target_box))
    let scale = target_box.w / signature_box.width
    let scale_y = target_box.h / signature_box.height

    if (scale_y < scale) {
      scale = scale_y
    }

    let scaled_array: any[] = []

    let left = 10000
    let top = 10000
    let bottom = 0
    let right = 0

    let num_arrays = 1
    if (Array.isArray(array)) {
      num_arrays = array.length
    }
    for (let a = 0; a < num_arrays; a++) {
      let spoints = array[a]
      let points = spoints.split(' ') //some times there are pairs
      let scaled_points: number[] = []
      let c = 0;
      for (let i = 0; i < points.length; i++) {
        let spair = points[i]
        let spair_parts = spair.split(',')
        for (let j = 0; j < spair_parts.length; j++) {
          let sp = spair_parts[j]
          let p = parseFloat(sp)
          p *= scale
          if (c % 2 == 0) { //x
            if (p < left) {
              left = p
            }
            if (p > right) {
              right = p
            }
          } else {
            if (p > bottom) {
              bottom = p
            }
            if (p < top) {
              top = p
            }
          }
          c++
          scaled_points.push(p)
        }
      }
      scaled_array.push(scaled_points)
    }
    // console.log("scaled " + JSON.stringify(scaled_array))


    let d_w = right - left
    let d_h = bottom - top
    let dx = (target_box.w - d_w) / 2 - left
    let dy = (target_box.h - d_h) / 2 - top
    // console.log(" left " + left + " right " + right + " dx " + dx + " top " + top + " bottom " + bottom + " dy " + dy)

    let moved_array: any[] = []
    for (let a = 0; a < scaled_array.length; a++) {
      let scaled_points = scaled_array[a]
      let new_points: any[] = []
      for (let i = 0; i < scaled_points.length; i += 2) {
        let x = scaled_points[i] + dx
        let y = scaled_points[i + 1] + dy

        new_points.push(x)
        new_points.push(y)

      }
      moved_array.push(new_points)
    }
    // console.log("out " + JSON.stringify(moved_array))
    return moved_array
  }

  afterSVG(rv: any) {

    if (rv.svg) {
      console.log("#RSVG afterSVG " + rv.requested_by)
      this.svg = rv.svg //NOV 8, WE ARE NEVER HERE
      if (rv.requested_by == "asset") {
        this.savingAssetAfterGettingSVG()
      } else if (rv.requested_by == "emailing") {
        this.emailingAfterGettingSVG()
      } else if (rv.requested_by == "is_signer") {
        let svg = SVG(this.svg)
        this.setSteps(svg) //checktoSeeIfThereISASigner()

      }
    }
  }
  bioFailure() {
    let signers = Object.assign({}, this.signers)
    let signers_keys = Object.keys(signers)
    for (let i = 0; i < signers_keys.length; i++) {
      let key = signers_keys[i]
      let signer = Object.assign({}, signers[key])
      if (signer.email == this.signer_email) {
        let steps = Object.assign({}, signer.steps)
        if (steps['take_pic']) {
          steps['take_pic'].done = false
        }
        if (steps['driver_lic']) {
          steps['driver_lic'].done = false
        }
        signer.steps = steps
        signers[key] = signer
        this.signers = signers
        this.canvasStore.dispatch(new SetSigners(this.signers))
      }
    }
  }
  setSteps(svg: any) {
    let missing_emails_for = []
    let children = svg.children()

    let signers = Object.assign({}, this.signers)
    let signers_keys = Object.keys(signers)

    for (let i = 0; i < signers_keys.length; i++) {
      let key = signers_keys[i]
      let signer = Object.assign({}, signers[key])

      let steps: any = this.setSetepForSigner(key, signer, children)
      steps = this.setSecSteps(signer, steps, i == signers_keys.length - 1)
      let steps_keys = Object.keys(steps)
      signer.steps = steps
      signer.steps_keys = Object.keys(steps)
      if (steps_keys.length > 0) {


        let num_steps_done = 0
        for (let i = 0; i < steps_keys.length; i++) {
          let key = steps_keys[i]
          let step = steps[key]
          if (step.done) {
            num_steps_done++
          }
        }
        signer.progress = Math.floor(num_steps_done / steps_keys.length * 100)
        if (signer.progress == 100 && !signer.signed_at) {
          signer.signed_at = new Date()
        }
      }
      signers[key] = signer
    }
    this.signers = signers
    this.canvasStore.dispatch(new SignersWithSteps(this.signers)) //set steps
    console.log("steps " + JSON.stringify(this.signers))

    this.checkContractSteps()
  }
  setSetepForSigner(key: string, signer: any, children: any) {
    // console.log("check steps for" + signer.title + " children " + children.length)
    let steps: any = {}
    let me = this
    function setSteep(group: any) {
      let dataset = group.node.dataset

      let k_target = dataset['k_target']
      let k_type = dataset['k_type']
      if (k_type == 'input') {
        let k_done = false
        if (dataset['done']) {
          if (dataset['done'] == "true") {
            k_done = true
          }
        }
        if (k_target == key || k_target == signer.email) { //the target can be a roll or an email
          // let desc = me.dic[dataset.k_type]
          let border = me.getGroupBorders(group)
          steps[dataset.id] = {
            id: dataset.id,
            k_target: dataset.k_target,
            k_type: dataset.k_type,
            k_input: dataset.k_input,
            top: border.top,
            left: border.left,

            done: k_done
          }
        }
      }
    }

    //find out if there is a SVG signature, text or initial box in the doc
    for (let i = 0; i < children.length; i++) {
      let group = children[i]
      if (group.type == 'g') {
        if (group.node.children) {
          if (group.node.children.length > 0) {
            setSteep(group)
          }
        }
      } else if (group.type == 'image') {
        setSteep(group)
      }
    }
    console.log(JSON.stringify(steps))
    return steps
  }
  setSecSteps(signer: any, steps: any, last_one: boolean) {
    let prev_steps = signer.steps
    if (!prev_steps) {
      prev_steps = {}
    }
    let signer_steps = Object.assign({}, steps)
    if (this.sec_opts) {
      if (this.sec_opts.take_pic) {
        if (prev_steps['take_pic']) {
          signer_steps['take_pic'] = prev_steps['take_pic']
        } else {
          signer_steps['take_pic'] = {
            desc: "Your Picture",
            done: false,
            tool: 'take_pic',
            top: 1, //for the sort
            left: 1
          }
        }
      }
      if (this.sec_opts.driver_lic) {
        if (prev_steps['driver_lic']) {
          signer_steps['driver_lic'] = prev_steps['driver_lic']
        } else {
          signer_steps['driver_lic'] = {
            desc: "Driver's Licence",
            done: false,
            tool: 'driver_lic',
            top: 1, //for the sort
            left: 2
          }
        }
      }
      if (this.sec_opts.image_rec || this.sec_opts.driver_lic) {
        if (prev_steps['image_rec']) {
          signer_steps['image_rec'] = prev_steps['image_rec']
        } else {
          signer_steps['image_rec'] = {
            desc: "Biometric Results",
            done: false,
            tool: 'image_rec',
            top: 1, //for the sort
            left: 3
          }
        }
      }
    }
    return signer_steps
  }

  loadedPDF() {
    this.bPDFLoaded = true;
    this.setCallSigners()
    this.checkSGV()
  }

  //When the SVG Changes
  debounce_is_user_signer: number = 0
  checkSGV() {
    if (this.bPDFLoaded) {
      let now = new Date().getTime()
      if (now - this.debounce_is_user_signer > 2000) {
        this.debounce_is_user_signer = now
        this.canvasStore.dispatch(new GetPDFSVG("is_signer")) // afterSVG
        console.log("#RSVG GetPDFSVG is_signer")
      }
    }
  }
  //Mark the step as done, and trigger the next step
  processDidStep(id: string, signer_key: string) {
    if (id.length < 1) {
      return
    }
    let signers = Object.assign({}, this.signers)
    let signer = Object.assign({}, signers[signer_key])
    let steps = Object.assign({}, signer.steps)

    let step = Object.assign({}, steps[id])
    step.done = true;
    steps[id] = step
    signer.steps = steps

    let steps_keys = Object.keys(steps)
    let num_steps_done = 0
    let curr_step_key
    let bTookPic: boolean = false
    let bHasLic: boolean = false
    let bBioResults: boolean = false
    for (let i = 0; i < steps_keys.length; i++) {
      let key = steps_keys[i]
      let step = steps[key]
      if (step.done) {
        num_steps_done++
        if (signer_key == this.signer_key) {
          if (key == "take_pic") {
            bTookPic = true
          } else if (key == "driver_lic") {
            bHasLic = true
          } else if (key == "image_rec") {
            bBioResults = true
          }
        }
      } else {
        if (signer_key == this.signer_key) { //If we are the signer set the next step
          if (!curr_step_key) {
            curr_step_key = key
          }
        }
      }
    }
    if (bTookPic && bHasLic && (!bBioResults || id == "take_pic" || id == "driver_lic") && steps["image_rec"]) {
      this.canvasStore.dispatch(new GoToStep(steps["image_rec"]))
    } else if (curr_step_key) {
      if (steps[curr_step_key]) {
        this.canvasStore.dispatch(new GoToStep(steps[curr_step_key]))
      } else {
        console.error("no step")
      }
    }

    // signer.curr_step = curr_step_key
    signer.progress = Math.floor(num_steps_done / steps_keys.length * 100)
    if (signer.progress == 100) {
      signer.done_siging = true
      if (!signer.signed_at) {
        signer.signed_at = new Date()
      }
    }
    signers[signer_key] = signer
    this.signers = signers
    if (signer.progress == 100) { //Check the other signers to see if we are ready to execute the contract
      this.checkContractSteps()
    }



    this.canvasStore.dispatch(new SignersWithSteps(this.signers)) //set steps


  }
  //Called when the user does a step, or when the svg is modified
  checkContractSteps() {
    let signers_ready = 0
    let signers_keys = Object.keys(this.signers)
    for (let i = 0; i < signers_keys.length; i++) {
      let key = signers_keys[i]
      let tsigner = this.signers[key]
      if (tsigner.progress == 100) {
        signers_ready++
      } else {
        if (!tsigner.steps) {
          signers_ready++
        }
      }
    }
    if (signers_ready == signers_keys.length) { //The contract is ready
      if (signers_keys.length > 0) {
        this.canvasStore.dispatch(new ContractSigned(true))
      }

      let blockchain = "none"
      if (this.sec_opts) {
        if (this.sec_opts.blockchain) {
          blockchain = this.sec_opts.blockchain
        }
      }
      if (blockchain == "none") {
        this.canvasStore.dispatch(new ContractExecuted(true))
      }

      if (blockchain == "all") {
        this.canvasStore.dispatch(new ReadyToBlockChain(true))
        if (this.ready_to_block_chain == false) {
          this.canvasStore.dispatch(new SelectToolOpts("blockchain"))

        }
      } else if (blockchain == "once") {
        this.canvasStore.dispatch(new ReadyToBlockChain(true))
        if (this.ready_to_block_chain == false) {
          this.canvasStore.dispatch(new SelectToolOpts("blockchain"))

        }
      }
    } else {
      this.canvasStore.dispatch(new ContractSigned(false))
    }
  }

  /*
  Every box in the contrat needs to have an email
  */
  async emailingAfterGettingSVG() {
    let asset = Object.assign({}, this.asset)
    // delete asset.asset_id //keep it
    asset.type = 'contract'
    asset.sec_opts = this.sec_opts
    asset.svg = this.svg
    let svg = SVG(this.svg)
    let children = svg.children()
    //got throug the svg gourps
    let signers: any = {}
    for (let i = 0; i < children.length; i++) {
      let group = children[i]
      if (group.type == 'g') {
        let k_target = group.node.dataset['k_target']
        if (k_target) {
          let signer = this.signers[k_target]
          if (signer) {
            {
              if (signer.email) {
                if (validateEmail(signer.email)) {
                  signers[k_target] = signer
                } else {
                  let name = signer.title
                  if (signer.name) {
                    if (signer.name.length > 1) {
                      name = signer.name
                    }
                  }
                  // this.controlsStore.dispatch(new FeedBack("Invalid email for " + name, "error"))
                  return;
                }
              } else {
                // this.controlsStore.dispatch(new FeedBack("Mising email for  " + signer.title, "error"))
                return;
              }
            }
          } else {
            console.error("A signer was not found ")
          }
        }
      }
    }
    let keys = Object.keys(signers)
    if (children.length > 0 && keys.length < 1) {
      // this.controlsStore.dispatch(new FeedBack("Add Signatures to the document", "error"))
      return
    }
    // if (this.contract_id) {
    //   asset._id = this.contract_id //used by contract to overwrite
    // }
    asset.signers = signers
    asset.num_signers = keys.length
    asset.num_signed = 0
    asset.copy_to = this.copy_to

    try {
      this.pagesState.dispatch(new NotifyWait("Sending Email"))

      let rv: any = await this.httpClient.post('/send_invites', asset).toPromise();
      // this.controlsStore.dispatch(new FeedBack("Invititations Sent", "ok"))
      this.pagesState.dispatch(new NotifyUser("Invititations Sent"))
      // this.contract_id = rv.contract_id
    } catch (e: any) {
      console.error("Error add_asset show " + e)
      // this.controlsStore.dispatch(new FeedBack("Error sending emails", "error"))
    }
  }



  async savingAssetAfterGettingSVG() {
    let asset = Object.assign({}, this.asset)
    asset.sec_opts = this.sec_opts
    asset.svg = this.svg
    let keys = Object.keys(this.signers)
    let signers: any = {}
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      let signer = this.signers[key]
      signers[key] = {
        title: signer.title,
        color: signer.color,
      }
    }
    asset.signers = signers
    this.asset = asset
    this.tool_service.changeAsset(asset)
    this.controlsStore.dispatch(new NotifyUser("Assets saved"))
  }


  //****************************************************************************
  // First save the signaturues into the PDF
  // Save the PDF
  // If we are blockchaining
  //          The back end will make a copy of the saved pdf and blockchanin it
  //          The DB will be updated
  // Save the contract with the new svg
  //****************************************************************************
  last_blockchained_id: string | undefined



  //Not only blockChain but every commit
  async sendToServer(rv: any) {
    return new Promise<any>(async (resolve, reject) => {
      let blob: Blob = rv.blob
      console.log("sendToServer ")

      let inst: any = {
        type: "contract",
        name: this.asset.name,
        _id: this.asset._id,
        new_contract_id: this.asset.new_contract_id, //for online contracts
        key: this.asset.key, //this is to sve it
        sec_opts: this.sec_opts, //if it is after every time, we will make a copy
        signer_name: this.signer_name, //to send the email to the signer
        signer_email: this.signer_email,
        signer_key: this.signer_key,
        signers: this.signers,
        copy_to: this.copy_to,
        signer_roll: this.signer_roll,
        // next_signer_key: this.next_signer_key,
        contract_executed: this.contract_executed,
        svg: rv.svg
      }

      let sinst = JSON.stringify(inst)
      const form = new FormData();
      let encoded = encodeURIComponent(sinst)
      form.append("file", blob, encoded);
      try {
        // let rv: any = await this.httpClient.post('/caller_signed_pdf', form).toPromise();
        let rv: any = await this.httpClient.post('/rep_signed_pdf', form).toPromise(); //contracts.js repSignedPDF
        console.log("<--bc " + JSON.stringify(rv))
        resolve(rv)
        let msg = { last_contract_id: rv._id }
        this.wss_service.sendMessageToOtherMembers(msg)
        this.canvasStore.dispatch(new LastSignedContract(rv))
        this.last_blockchained_id = this.asset._id
        this.closeContract()

      } catch (e: any) {
        reject(e)
        this.closeContract()
      }
    })
  }
  closeContract() {
    this.pagesState.dispatch(new ShowAsset({}))
    this.wss_service.sendMessageToOtherMembers({ contract: "done" })

    let msg: any = {
      show_asset: "none"
    }
    this.wss_service.sendMessageToOtherMembers(msg)
  }
  /*
    async  saveSignedPDFAndBlob(rv: any) {
      let blob = rv.blob
      const form = new FormData();
      form.append("file", blob, this.asset._id);
      try {
        let rv: any = await this.httpClient.post('/contract_signed_pdf', form).toPromise();
        //Now update the svg and send it to the next one
        this.svg = rv.svg
        this.emailingAfterGettingSVG()
      } catch (e) {
        console.error(e)
      }
    }
    */
  async deleteContract(contract_id: string) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.delete('/contract/' + contract_id).toPromise();
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }
  async loadFont(font_name: string) {
    return new Promise<ArrayBuffer>(async (resolve, reject) => {
      try {
        let url = "assets/fonts/" + font_name + "-Regular.ttf"
        const ubuntuBytes = await fetch(url).then(res => res.arrayBuffer())
        resolve(ubuntuBytes)
      } catch (e) {
        console.error(e)
        reject(e)
      }

    })
  }
  sendCanvasMsg(tool: string, action: string, id: string, data?: any) {
    if (this.asset) {
      let jmsg: any = {
        asset_id: this.asset.asset_id,
        canvas: tool,
        action: action,
        id: id,
        data: data
      }
      if (this.viewBoxWidth) {
        jmsg.canvas_width = this.viewBoxWidth
        jmsg.canvas_height = this.viewBoxHeight
      }
      this.wss_service.sendMessageToOtherMembers(jmsg)
    }
  }
  forwardUndo(msg: any) {
    let jmsg = Object.assign({}, msg)
    jmsg.asset_id = this.asset.asset_id
    this.wss_service.sendMessageToOtherMembers(jmsg)
  }

  getContractImage(name: string) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.get('/contract_asset/' + name).toPromise(); //contracts.js repSignedPDF

        resolve(rv)
      } catch (e: any) {
        reject(e)
      }
    })
  }


}

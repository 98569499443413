<div class="out" #outCmp *ngIf="is_chrome">
  <app-left-menu *ngIf="!call_id && bShowLeftMenu && router.url != '/login' && router.url != '/signup'" (closed)="closedIt($event)"> </app-left-menu>
  <div class="right" [ngClass]="{no_lm:router.url == '/login' || router.url == '/signup', r_ph:!bShowLeftMenu}">

    <app-header *ngIf="router.url != '/login' && router.url != '/signup'"> </app-header>
    <!-- <app-header *ngIf="bShowAppHeader"> </app-header> -->
    <!-- <assets-header *ngIf="bShowAssetHeader" class="header"> </assets-header> -->
    <kpay-bar *ngIf="running_kpay=='plan'"></kpay-bar>
    <kpay-invoice-bar *ngIf="running_kpay=='pay'"></kpay-invoice-bar>

    <main [ngClass]="{no_header:router.url == '/login'||router.url == '/signup', kpay:running_kpay}">
      <router-outlet>
      </router-outlet>
    </main>

    <get-signature *ngIf="show_tool == 'get_sig'" class="abs_center z3" (close)="bGetSignature=false"></get-signature>
    <get-initials *ngIf="show_tool == 'get_ini'" class="abs_center z3" (close)="bGetInitials=false"></get-initials>

    <get-user-pic *ngIf="show_tool=='take_pic'" class="abs_center z3"></get-user-pic>
    <get-user-id *ngIf="show_tool=='driver_lic'" class="abs_center z3" (gotPicture)="pic_target=undefined"></get-user-id>
    <bio-results *ngIf="show_tool=='image_rec'" class="abs_center z3"></bio-results>
    <block-chain class="top_right z3" *ngIf="show_tool=='blockchain'"></block-chain>

    <sel-signer class="top_right rsel z3" *ngIf="show_tool=='sel-signer'||show_tool=='sel-signer1'"> </sel-signer>

    <sel-input class="top_right sel_input z3" *ngIf="show_tool=='sel-input'"> </sel-input>
    <text-color class="top_right text_color z3" *ngIf="show_tool=='text-color'"> </text-color>

    <!-- <feed-back class="abs_center z3" *ngIf="bShowFeedBack" (close)="bShowFeedBack = false"></feed-back> -->
    <sel-download class="top_right z3" *ngIf="show_tool=='sel-download'"></sel-download>
    <canvas-opts class="canvas_opts" *ngIf="show_tool=='canvas-opts'"></canvas-opts>
    <change-tz *ngIf="bShowTZ" (close)="bShowTZ=false" class="abs_center"></change-tz>
  </div>
  <colors-tool class="top_left z3" [style.left.px]="show_tool_left - 100" [style.top.px]="show_tool_top" *ngIf="show_tool=='colors'"></colors-tool>
  <sel-shape-opts class="top_left z3" [style.left.px]="show_tool_left - 100" [style.top.px]="show_tool_top" *ngIf="show_tool=='sel_shape'"></sel-shape-opts>
  <line-tools class="top_left z3" [style.left.px]="show_tool_left - 200" [style.top.px]="show_tool_top" *ngIf="show_tool=='pencil'"> </line-tools>
  <sign-tools class="top_left z3" [style.left.px]="show_tool_left - 100" [style.top.px]="show_tool_top" *ngIf="show_tool=='sign'"></sign-tools>
  <text-tool class="top_left z3" [style.left.px]="show_tool_left - 198" [style.top.px]="show_tool_top" *ngIf="show_tool=='text'"></text-tool>
  <text-tool class="top_left z3" [style.left.px]="show_tool_left - 198" [style.top.px]="show_tool_top" *ngIf="show_tool=='field-text'" [field_text]="true"></text-tool>
  <pdf-opts class="top_left z3" [style.left.px]="show_tool_left - 190" [style.top.px]="show_tool_top" *ngIf="show_tool=='pdf-opts'"></pdf-opts>
  <chapala-mls class="mls z3" *ngIf="show_tool=='mls'"></chapala-mls>
  <profile-opts class="opts z3" *ngIf="show_tool=='prof-opts'"></profile-opts>
  <link-opts *ngIf="show_tool=='link-opts'" class="link-opts z3" [style.left.px]="show_tool_left - 30"></link-opts>
  <create-event *ngIf="show_tool=='event'" class="create_event z3" [style.left.px]="show_tool_left - 100" [ngClass]="event_date ? 'abs_center':'new_event'" [event]="event" (eventChange)="eventChange()"></create-event>
  <queue-wnd *ngIf="show_tool=='queue-wnd'" class="queue_wnd z3" [style.left.px]="show_tool_left - 140"></queue-wnd>
  <appts-wnd *ngIf="show_tool=='appts-wnd'" class="queue_wnd z3" [style.left.px]="show_tool_left - 140"></appts-wnd>
  <save-as class="top_left z3 save_as" *ngIf="show_tool=='save-as'" [style.left.px]="show_tool_left"> </save-as>


  <div *ngIf="debug=='lines'" class="h_lines" [style.top.px]="12"> </div>
  <div *ngIf="debug=='lines'" class="h_lines" [style.top.px]="52"> </div>
  <div *ngIf="debug=='lines'" class="h_lines" [style.top.px]="64"> </div>

  <div *ngIf="debug=='lines'" class="h_lines" [style.bottom.px]="12"> </div>
  <div *ngIf="debug=='lines'" class="h_lines" [style.bottom.px]="52"> </div>
  <div *ngIf="debug=='lines'" class="h_lines" [style.bottom.px]="64"> </div>


  <div *ngIf="!isOpen && debug=='lines'" class="v_lines " [style.left.px]="12"> </div>
  <div *ngIf="!isOpen && debug=='lines'" class="v_lines v_lines_l" [style.left.px]="21"> </div>
  <div *ngIf="!isOpen && debug=='lines'" class="v_lines v_lines_l" [style.left.px]="43"> </div>
  <div *ngIf="!isOpen && debug=='lines'" class="v_lines " [style.left.px]="52"> </div>
  <div *ngIf="!isOpen && debug=='lines'" class="v_lines " [style.left.px]="67"> </div>
  <div *ngIf="!isOpen && debug=='lines'" class="v_lines " [style.left.px]="79"> </div>


  <div *ngIf="isOpen && debug=='lines'" class="v_lines" [style.left.px]="28"> </div>
  <div *ngIf="isOpen && debug=='lines'" class="v_lines v_lines_l" [style.left.px]="50"> </div>
  <div *ngIf="isOpen && debug=='lines'" class="v_lines" [style.left.px]="68"> </div>
  <div *ngIf="isOpen && debug=='lines'" class="v_lines" [style.left.px]="80"> </div>
  <div *ngIf="isOpen && debug=='lines'" class="v_lines" [style.left.px]="263"> </div>
  <div *ngIf="isOpen && debug=='lines'" class="v_lines" [style.left.px]="275"> </div>

  <div *ngIf="debug=='lines'" class="v_lines" [style.right.px]="12"> </div>
  <welcome *ngIf="welcome" class="welcome" (close)="welcome=undefined"></welcome>
  <!-- <ask-promo-code class="welcome code" *ngIf="ask_code_from"></ask-promo-code> -->
  <k-notification *ngIf="notify" [notify]="notify" class="notif" [ngClass]="{p_notif:isPhone}"></k-notification>
  <app-expired *ngIf="bSessioExpired" class="abs_center exp"></app-expired>


  <free-end class="screen" *ngIf="bShowFreeEnd" (close)="bShowFreeEnd=false"></free-end>
  <credit-card *ngIf="show_cc=='billing'" class="screen"></credit-card>
  <new-cc *ngIf="show_cc=='new'" class="screen"></new-cc>
  <downgrade *ngIf="show_cc=='cancel'" class="screen"></downgrade>
  <plan-warning *ngIf="show_cc=='w_down'" class="screen"></plan-warning>
  <plan-warning *ngIf="show_cc=='w_del'" what="del" class="screen"></plan-warning>

  <confirm-hang-up class="screen" *ngIf="bAskHangUp"></confirm-hang-up>
  <bill-info class="screen" *ngIf="show_cc && show_cc=='info'"></bill-info>

  <kpay-menu class="kpay-menu" *ngIf="bShowKMenu"></kpay-menu>
  <app-sel-media *ngIf="show_tool=='sel_media'" class="screen"></app-sel-media>
  <rec-ready class="rec_ready" *ngIf="show_tool=='rec_ready'"></rec-ready>
  <!-- <rec-ready class="rec_ready"></rec-ready> -->
  <kpay-screen class="abs_center" *ngIf="bShowKScreen"></kpay-screen>

  <kpay-info *ngIf="show_tool=='pkay_info'" class="screen"></kpay-info>
  <del-lead *ngIf="show_tool=='del_lead'" class="screen"></del-lead>
  <view-chat *ngIf="show_tool=='view_chat'" class="screen"></view-chat>
  <add-user *ngIf="show_tool=='add_one_user'" [user]="opt_params" class="screen"></add-user>
  <new-asset *ngIf="show_tool=='new_asset'" class="screen" [bAdmin]="opt_params.bAdmin"></new-asset>
  <conf-cont-del *ngIf="show_tool=='del_cont'" class="screen" [params]="opt_params"></conf-cont-del>
  <del-user *ngIf="show_tool=='del_user'" class="screen" [del_user]="opt_params"></del-user>
  <calendly-settings *ngIf="show_cc=='calendly_set'" class="screen"></calendly-settings>
  <cal-settings *ngIf="show_cc=='calendar_set'" class="screen"></cal-settings>
  <del-assets *ngIf="show_cc=='del-assets'" class="screen"></del-assets>

</div>
<no-chrome *ngIf="!is_chrome" class="welcome"> </no-chrome>

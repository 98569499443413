import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToolService } from 'src/app/tools/tool.service';
import { asset_types, Hash } from 'src/app/utils';
import { AddAsset, FilterAssets, MoveAssets, RemoveAssets, SearchBy, SelFilterOption, SetFoldersForTab, SetHeaderTitle, SetTab, ShowFolder, UnselectAllAssets } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { filteredAssets, filterOptions, gotAssets, headerPath, hostUrl, myRoll, showAsset, showFolder } from '../../pages.selectors';
import { FileItem, FileUploader } from 'ng2-file-upload';

import { ElementRef, ViewChild } from '@angular/core';
import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragMove,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { WSSState } from 'src/app/wss/wss.model';
import { customerId, sessionId } from 'src/app/wss/wss.selectors';
import { UploaderService } from '../../uploader.service';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { selectToolOptions } from 'src/app/canvas/canvas.selectors';


@Component({
  selector: 'assets',
  templateUrl: './assets.component.html',
  styleUrls: ['../../profile/profile/profile.component.scss', './assets.component.scss']
})
export class AssetsComponent implements OnInit {
  bShowDelUserWarning: boolean = false
  tab: string = "company"
  bAdmin: boolean = true
  tab_folder: string = "company"
  search: string = ""
  options: any[] = []
  filter_options: any[] | undefined
  folders: string[] = []
  assets: any = {}
  // all_assets: any = {}
  asset_keys: string[] = []
  show_index: number = 0;
  num_pages: number = 3
  show_asset: any | undefined
  selected_assets: Hash = new Hash()
  selected_assets_ids: string[] = []
  num_sel_assets: number = 0
  num_of_movable_assets: number = 0

  call_id: string | undefined

  //only one of them at the time
  bShowFolders: boolean = false //to move to
  bShowFilter: boolean = false //filter assets
  bAddingAsset: boolean = false; //when the adding asset is open

  // filtered_folders: any

  sel_folder_index: number = -1
  move_color: string = "#e0e0e0"
  folder: any
  subscriptions: any[] = []
  roll: string = "rep"
  bShowCURD: boolean = true
  header_path: any[] = []
  bAskAorF: boolean = false
  stack: any[] = []



  constructor(
    private pagesState: Store<PagesState>,
    private tool_service: ToolService,
    public router: Router,
    public datepipe: DatePipe,
    public upload_service: UploaderService,
    // private tools_service: ToolService,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("assets component")
    this.tool_service.getAssets()

    this.subscriptions.push(this.canvasStore.select(selectToolOptions).subscribe((opt: any) => {

      if (opt.name == "close_new_asset") {
        this.bAddingAsset = false
      }

    }))

    this.subscriptions.push(this.pagesState.select(myRoll).subscribe((my_roll: string) => {
      setTimeout(() => {
        this.roll = my_roll
        this.select(this.tab)
      });
    }))


    this.subscriptions.push(this.pagesState.select(gotAssets).subscribe((assets: any) => {
      // this.all_assets = assets
      this.assets = assets

      this.pagesState.dispatch(new FilterAssets(this.tab, this.search))
    }))

    this.options = Object.assign([], asset_types);
    this.options.push({ desc: 'Folders', type: 'folder' })
    // for (let i = 0; i < this.options.length; i++) {
    let option = Object.assign({}, this.options[0])
    option.selected = true
    this.options[0] = option
    // }

    this.subscriptions.push(this.pagesState.select(showFolder).subscribe((folder: any) => {
      if (folder) {
        if (folder.asset_id || folder._id) {
          if (this.folder && this.folder.asset_id != folder.asset_id) {
            let index = this.stack.indexOf(folder.asset_id)
            if (index >= 0) {
              console.log("trim it")
              while (this.stack.length > index) {
                this.stack.pop()
              }
            } else {
              this.stack.push(this.folder.asset_id)
            }
          }
          this.folder = folder
        } else {
          this.folder = undefined
        }
      }
    }))
    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      if (asset) {
        if (asset.asset_id || asset._id) {
          if (asset.type != "folder") {
            this.show_asset = asset;
          }
          return;
        }
      }
      if (this.show_asset) {
        delete this.show_asset
      }
      this.num_sel_assets = 0
      this.selected_assets_ids = []
      this.num_of_movable_assets = 0;
    }))
    this.subscriptions.push(this.pagesState.select(filterOptions).subscribe((filter_options: any) => {
      this.filter_options = filter_options
      this.pagesState.dispatch(new FilterAssets(this.tab, this.search))
    }))
    this.subscriptions.push(this.pagesState.select(filteredAssets).subscribe((filtered_assets: {}) => {
      this.assets = filtered_assets
      this.asset_keys = Object.keys(this.assets)
      this.num_sel_assets = 0
      this.selected_assets_ids = []
      this.num_of_movable_assets = 0
    }))

  }
  back() {
    this.folder = undefined
    if (this.stack.length > 0) {
      let id = this.stack.pop()
      this.pagesState.dispatch(new ShowFolder(id))
    } else {
      this.pagesState.dispatch(new SetTab(""))
      this.select(this.tab);
    }
  }

  ngOnInit(): void {
    let tab = localStorage.getItem('assets_tab')
    if (tab) {
      this.select(tab);
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
    this.tool_service.removePendingKeys()
    // this.pagesState.dispatch(new SetHeaderTitle([]))
  }
  select(tab: string) {
    this.tab = tab
    this.bShowFolders = false
    localStorage.setItem('assets_tab', tab)
    //Now filter the assets
    if (this.tab != 'company') {
      this.bShowCURD = true
    } else if (this.roll != 'rep') {
      this.bShowCURD = true
    } else {
      this.bShowCURD = false
    }
    this.pagesState.dispatch(new SetTab(this.tab))
    // this.filterAssets()
    // this.pagesState.dispatch(new FilterAssets(this.tab, this.search))

  }

  changedSearch() { //filter by name
    console.log("filter by name")

    this.pagesState.dispatch(new SearchBy(this.search))

  }
  selType(options: any[]) {
    this.pagesState.dispatch(new SelFilterOption(options))
  }

  selectAsset(i: number, val: boolean) {
    if (val) {
      this.selected_assets.insert(i)
    } else {
      this.selected_assets.remove(i)
    }
    this.selected_assets_ids = []
    this.num_of_movable_assets = 0
    this.num_sel_assets = this.selected_assets.array.length
    for (let i = 0; i < this.num_sel_assets; i++) {
      let index = this.selected_assets.array[i]
      let id = this.asset_keys[index]
      if (id) {
        let asset = this.assets[id]
        if (asset) {
          this.selected_assets_ids.push(id)
          if (asset.type != 'folder') {
            this.num_of_movable_assets++
          }
        } else {
          console.error("sel asset not found ")
        }
      } else {
        console.error("id from hash found ")
      }
    }



  }
  async delAssets(cut: boolean) {
    this.bShowDelUserWarning = false
    let array = this.selected_assets.array
    let assets_ids: string[] = []
    for (let i = 0; i < array.length; i++) {
      let target = array[i]
      let key = this.asset_keys[target]
      if (this.assets[key]) {

        assets_ids.push(key)
      }
    }
    try {
      this.pagesState.dispatch(new RemoveAssets(assets_ids))
      // await this.tool_service.deleteAssets(this.folder, assets, cut) //We will need a new stream for the new room
      this.selected_assets = new Hash()
      this.num_of_movable_assets = 0
    } catch (e: any) {
      //displayed in the tool_service
    }

  }
  async copyAsset(to_clipboard: boolean) {
    let array = this.selected_assets.array
    let assets: any[] = []
    for (let i = 0; i < array.length; i++) {
      let target = array[i]
      let key = this.asset_keys[target]
      let asset = Object.assign({}, this.assets[key])
      asset.name = "copy_" + asset.name
      asset.asset_id = new Date().getTime()
      this.pagesState.dispatch(new AddAsset(asset))
      // assets.push(asset)
    }

    this.selected_assets = new Hash()
    this.pagesState.dispatch(new UnselectAllAssets())
    this.num_of_movable_assets = 0
  }
  pasteAsset() {
    this.tool_service.pasteAsset(this.folder)
  }
  async addFolder() {
    return new Promise<any>(async (resolve, reject) => {
      this.bAskAorF = false;
      let latest_date = this.datepipe.transform(new Date(), 'MM-dd-yy hh:mm');

      let asset: any = {
        asset_id: new Date().getTime(),
        bAdmin: this.tab == 'company',
        name: "Folder " + latest_date,
        type: "folder",
        assets: {}
      }
      this.pagesState.dispatch(new AddAsset(asset))
      resolve(asset);
    })
  }



  addAsset() {
    this.bAskAorF = false;
    this.bAddingAsset = true
    this.canvasStore.dispatch(new SelectToolOpts("new_asset", { bAdmin: this.bAdmin }))

    this.bShowFolders = false
    this.bShowFilter = false
  }
  openFilterChange($event: any) {
    this.bShowFilter = $event
    if ($event) {
      this.bAddingAsset = false
      this.bShowFolders = false
      this.bAskAorF = false;
    }
  }
  openAskAorF() {
    this.bAskAorF = !this.bAskAorF
    this.bAddingAsset = false
    this.bShowFolders = false
    this.bShowFilter = false

  }
  showMoveTo() {
    // this.pagesState.dispatch(new MoveAssets("f1_f1_f1", "f1_f1", ["f1_f1_f1_a1"]))
    if (this.num_of_movable_assets == 0) {
      return
    }
    this.bShowFolders = !this.bShowFolders
    if (this.bShowFolders) {
      this.bAskAorF = false
      this.bAddingAsset = false
      this.bShowFilter = false
    }
    if (this.bShowFolders) {
      this.tab_folder = this.tab
    }
  }
  selFolder(i: number) {
    this.sel_folder_index = i
    this.move_color = "#000"
    if (this.sel_folder_index > 99) { //update the sel folders
      this.pagesState.dispatch(new SetFoldersForTab(this.sel_folder_index == 100))
    }
  }

  close() {
    this.selected_assets = new Hash()
    this.num_of_movable_assets = 0
    this.bShowFolders = false
  }
  cancel() {
    this.bShowFolders = false
  }
  //*****************************************************************************
  // Drag and Drop
  //*****************************************************************************

  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };
  target_index: number = -1
  dragEntered(event: CdkDragEnter<number>, key: string) {
    this.target_index = event.container.data
    // console.log("Drag entered " + event.container.data)

  }

  dragMoved(event: CdkDragMove<number>, key: string) {

  }

  async dragDropped(event: CdkDragDrop<number>, key: string) {
    let s_key = this.asset_keys[event.item.data]
    let source_asset = this.assets[s_key]

    let t_key = this.asset_keys[this.target_index]
    let target_asset = this.assets[t_key]

    console.log(source_asset.name + " -->" + target_asset.name)
    if (target_asset.type == 'folder') {
      this.pagesState.dispatch(new MoveAssets(t_key, [s_key]))
    }
    if (!this.dropListReceiverElement) {
      return;
    }

    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }
  //*****************************************************************************
  // Cut and paste
  //*****************************************************************************
  // @HostListener('window:keydown', ['$event'])
  onKeyPress($event: any) {
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 67) {
      console.log('CTRL + C');
      this.copyAsset(true)
    } else if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 88) {
      console.log('CTRL +  X, CUT');
      this.delAssets(true)
    } else if ($event.keyCode == 46 || $event.keyCode == 8) {
      console.log('DELETE');
      this.delAssets(false)
    } else if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 86) {
      console.log('CTRL +  V');
      this.pasteAsset()
    } else {
      console.log(" ctrl " + $event.ctrlKey + " meta " + $event.metaKey + " code " + $event.keyCode)
    }

  }

  showCompanyFolders() {
    // this.filterFolders('company')
    this.tab_folder = 'company'
  }
  showPersonalFolders() {
    // this.filterFolders('agent')
    this.tab_folder = 'agent'
  }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KpayMenuComponent } from './kpay-menu/kpay-menu.component';
import { KpayBarComponent } from './kpay-bar/kpay-bar.component';
import { KpayBarPartComponent } from './kpay-bar-part/kpay-bar-part.component';
import { ControlsModule } from '../controls/controls.module';
import { InvoiceWndComponent } from './invoice-wnd/invoice-wnd.component';
import { FormsModule } from '@angular/forms';
import { KpayInvoiceBarComponent } from './kpay-invoice-bar/kpay-invoice-bar.component';
import { SetUpComponent } from './set-up/set-up.component';
import { KPayMainComponent } from './main/main.component';
import { KPayInfoComponent } from './info/info.component';
import { PaymentsComponent } from './payments/payments.component';



@NgModule({
  declarations: [
    KpayMenuComponent,
    KpayBarComponent,
    KpayBarPartComponent,
    KpayInvoiceBarComponent,
    InvoiceWndComponent,
    SetUpComponent,
    KPayMainComponent,
    KPayInfoComponent,
    PaymentsComponent
  ],
  imports: [
    CommonModule,
    ControlsModule,
    FormsModule,
  ], exports: [
    KpayMenuComponent,
    KpayBarComponent,
    KpayInvoiceBarComponent,
    InvoiceWndComponent,
    KPayMainComponent,
    KPayInfoComponent
  ]

})
export class KpayModule { }

import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { PagesState } from 'src/app/pages/pages.model';
import { callerFirstName, callerLastName } from 'src/app/pages/pages.selectors';
import { PayService } from '../pay.service';

@Component({
  selector: 'kpay-wnd',
  templateUrl: './invoice-wnd.component.html',
  styleUrls: ['./invoice-wnd.component.scss']
})
export class InvoiceWndComponent {
  inv_title: string = ""
  inv_desc: string = ""
  inv_price: string = ""
  bReady: boolean = false
  caller_first_name: string = ""
  caller_last_name: string = ""
  constructor(
    private pagesState: Store<PagesState>,
    public pay_service: PayService, //Make sure the service is ready

  ) {
    console.log("kpay-wnd")
    this.inv_title = "Invoice "
    this.inv_desc = new Date().toString()
    this.inv_price = "11"
    this.caller_first_name = "Caller"
    this.bReady = true
    this.pagesState.select(callerFirstName).subscribe((name: string | undefined) => {
      if (name) {
        this.caller_first_name = name
      }
    })
    this.pagesState.select(callerLastName).subscribe((name: string | undefined) => {
      if (name) {
        this.caller_last_name = name
      }
    })
  }
  @Output() close: EventEmitter<any> = new EventEmitter();
  closeIt() {
    this.close.emit()
    this.pay_service.cancelInvoice()
  }
  sendInvoice() {
    this.pay_service.invoiceReady({
      title: this.inv_title,
      desc: this.inv_desc,
      amount: this.inv_price,
    })
    this.close.emit()
  }
  changed() {
    this.bReady = (this.inv_title.length > 0
      && this.inv_desc.length > 0
      && parseInt(this.inv_price) > 0
      && this.inv_title.length > 0)

  }
}

<div class="out">
  <div class="abs_center box">
    <div class="msg">
      Session timed out.
    </div>
    <div class="el" (click)="reload()">
      <div class="abs_center">
        Reload
      </div>
    </div>

  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KpayMenuComponent } from './kpay-menu/kpay-menu.component';
import { KpayBarComponent } from './kpay-bar/kpay-bar.component';
import { KpayBarPartComponent } from './kpay-bar-part/kpay-bar-part.component';
import { ControlsModule } from '../controls/controls.module';
import { InvoiceWndComponent } from './invoice-wnd/invoice-wnd.component';
import { FormsModule } from '@angular/forms';
import { KpayInvoiceBarComponent } from './kpay-invoice-bar/kpay-invoice-bar.component';
import { SetUpComponent } from './set-up/set-up.component';
import { KPayMainComponent } from './main/main.component';
import { KPayInfoComponent } from './info/info.component';
import { PaymentsComponent } from './payments/payments.component';
import { KplanWndComponent } from './kplan-wnd/kplan-wnd.component';

import * as fromKPay from './kpay.reducer';
import { StoreModule } from '@ngrx/store';
import { CaptchaComponent } from './captcha/captcha.component';
import { KpayScreenComponent } from './kpay-screen/kpay-screen.component';

@NgModule({
  declarations: [
    KpayMenuComponent,
    KpayBarComponent,
    KpayBarPartComponent,
    KpayInvoiceBarComponent,
    InvoiceWndComponent,
    SetUpComponent,
    KPayMainComponent,
    KPayInfoComponent,
    PaymentsComponent,
    KplanWndComponent,
    CaptchaComponent,
    KpayScreenComponent,

  ],
  imports: [
    CommonModule,
    ControlsModule,
    FormsModule,
    StoreModule.forFeature('kPay', fromKPay.kPayReducer),
  ], exports: [
    KpayMenuComponent,
    KpayBarComponent,
    KpayInvoiceBarComponent,
    InvoiceWndComponent,
    KPayMainComponent,
    KPayInfoComponent,
    KplanWndComponent,
    CaptchaComponent,
    KpayScreenComponent
  ]

})
export class KpayModule { }

// recaptcha.service.ts
import { ElementRef, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { PagesState } from '../pages/pages.model';
import { SelectToolOpts } from '../canvas/canvas.actions';
declare var grecaptcha: any;
@Injectable({
  providedIn: 'root'
})

export class RecaptchaService {

  private loaded = false;
  key: string | undefined
  constructor(
    private pagesState: Store<PagesState>,
    public canvasStore: Store<CanvasState>,

  ) {
  }

  private loadRecaptcha() {
    return new Promise<void>(async (resolve, reject) => {
      if (this.loaded) {
        resolve()
        return;
      }
      if (window.location.host.indexOf("keyzii") >= 0) {
        //  this.key = '6LeSESwqAAAAAPIJxkboPq9C2ccS1HzqMWXdOJks'
        this.key = '6LdoHDgqAAAAAJGh4DqibCBXCOaKNQs2yRNLXdX7' //v2
      } else if (window.location.host.indexOf("callvendi.com") >= 0) {
        //vr  this.key = '6LfCFCwqAAAAAAERA77uwKqJAkcVtLQ7-Y5W_CwH'
        this.key = '6LdnHDgqAAAAAHK1gaHpFs2yaCtfsdXGaRWsPKmZ' //v2
      } else if (window.location.host.indexOf("localhost") >= 0) {
        this.key = "6Le4tzgqAAAAAALekr0G2hvOwmc5vKlqPxhYwLWL" //localhost v2
        //v3    this.key = '6LcMFywqAAAAAHbHV7SEh0G7bN5c8OKkYarssZJT'
      }

      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?render=explicit'// + key; // Adjust the URL as needed
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      script.onload = (r: any) => {
        console.log("loaded recaptcha")
        this.loaded = true;
        setTimeout(() => {
          resolve()
        }, 100)
      }
    })
  }

  checkCaptcha(action: string, container?: ElementRef | undefined) {
    return new Promise<string>(async (resolve, reject) => {
      if (!this.loaded) {
        await this.loadRecaptcha();
      }
      let me = this;
      async function onSubmit(token: string) {
        resolve(token)
      }

      try {
        grecaptcha.render('recaptcha-container', {
          'sitekey': this.key, // Replace with your actual site key
          'theme': 'light', // Optional: 'light' or 'dark' theme
          'size': 'normal', // Optional: 'normal' or 'compact'
          'callback': onSubmit
        });
      } catch (error) {
        reject(error)
      }

    })
  }
}

<div class="scrn">
  <div class="abs_center out">
    <div class="title ml">
      keyzii free plan
    </div>
    <div class="txt stitle ml">
      Please read the following:
    </div>
    <div class="tx ml">
      Downgrading deletes all content and users except the account holder, permanently. You'll need to re-upload content, and lost user info can't be recovered. This is irreversible. Proceed carefully.
    </div>
    <div class="l_b ml">
      <strong class="nu">1</strong> user
    </div>
    <div class="l_b ml">
      <strong class="money1">$0.00</strong> /month
    </div>

    <div class="txt stitle">
    </div>

    <div class="wi ml">
      What is included?
    </div>
    <ul>
      <li>
        5 Assets (Image, Video, Virtual Tours & PDFS)
      </li>
      <li>Send or E-Sign Live with Biometric ID Verification <br />(1 p/month)</li>
      <li>Adaptive Call Now & Scheduling Button</li>
      <li>Scheduling Tool / Calendly Integration</li>
    </ul>



    <div class="btn" (click)="downgradeIt()">
      <div class="abs_center">
        Downgrade
      </div>
    </div>
    <div class="del_b" (click)="deleteIt()">
      Delete keyzii account
    </div>
    <img class="close" src="assets/images/close.svg" alt="close" (click)="cancel()">
  </div>
</div>

<div class="out">
  <div class="bdy">
    <img class="top_logo li" src="assets/images/keyzii_logo_white1.svg">
    <div>
      Opps, keyzii is currently not compatible with this browser
    </div>
    <div>
      or mobile device. Please use Google Chrome on a
    </div>
    <div>
      desktop or laptop.
    </div>
    <!-- <k-button class="open" color="white" min_width="190" background="#151515" min_width="150" min_height="40" text="Open Chrome" (click)="openChrome()"></k-button> -->
    <div class="bottom">
      <div class="li new_to" (click)=" download()">Download Google Chrome
      </div>
    </div>

  </div>
</div>

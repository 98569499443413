import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
// import { SingupComponent } from '../singup/singup.component';
import { FormsModule } from '@angular/forms';
import { ControlsModule } from 'src/app/controls/controls.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ExpiredComponent } from './expired/expired.component'
import { BrowserModule } from '@angular/platform-browser';
import { MsalModule, MsalInterceptor, MsalGuard, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalConfig } from './ms_config';
@NgModule({
  declarations: [
    LoginComponent,
    // SingupComponent,
    ExpiredComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ControlsModule,
    HttpClientModule,
    BrowserModule,
    MsalModule.forRoot(new PublicClientApplication(msalConfig), {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['openid', 'profile', 'User.Read']
      }
    }, {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['User.Read']],
          ['https://graph.microsoft.com/v1.0/me/calendar/events', ['Calendars.ReadWrite']]
        ])
      })

  ], providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ], exports: [
    LoginComponent,
    // SingupComponent,
    ExpiredComponent
  ],

})
export class LoginModule { }

<div class="out" *ngIf="!show_asset">
  <div class="header">

    <div class="h_p font_sub_title" (click)="select('company')">
      <div class="ctr">
        <div class="ctr_tx">
          Company Assets
          <div *ngIf="tab=='company'" class="selected">
          </div>
        </div>
      </div>
    </div>
    <div class="h_p font_sub_title" (click)="select('agent')">
      <div class="ctr">
        <div class="ctr_tx">
          Personal Assets
          <div *ngIf="tab=='agent'" class="selected">
          </div>
        </div>
      </div>
    </div>
    <div class="h_p font_sub_title" (click)="select('contracts')">
      <div class="ctr">
        <div class="ctr_tx">
          Sent Contracts
          <div *ngIf="tab=='contracts'" class="selected">
          </div>
        </div>
      </div>
    </div>


    <!-- <div class="h_p h_p_w no_h_left font_sub_title" [ngClass]="{selected:tab=='company'}" (click)="select('company')">Company Assets</div>
    <div class="h_p h_p_w font_sub_title" [ngClass]="{selected:tab=='agent'}" (click)="select('agent')">Personal Assets</div>
    <div class="h_p h_p_w font_sub_title" [ngClass]="{selected:tab=='contracts'}" (click)="select('contracts')">Sent Contracts</div> -->
  </div>

  <div class="line"></div>
  <div class="body bdy_s" *ngIf="tab!='contracts'">

    <div *ngIf="!show_asset" #dropListContainer class="example-container" cdkDropListGroup>
      <div *ngIf="asset_keys.length==0 && search.length>0" class="abs_center">
        No Assets Fond
      </div>
      <div *ngFor="let key of asset_keys, let i = index" cdkDropList [cdkDropListData]="i" class="list">
        <div cdkDrag [cdkDragData]="i" (cdkDragEntered)="dragEntered($event,key)" (cdkDragMoved)="dragMoved($event,key)" (cdkDragDropped)="dragDropped($event,key)" class="sel_asset">
          <div>
            <sel-asset class="list" [asset]="assets[key]" (selectedCahnged)="selectAsset(i, $event)"></sel-asset>
          </div>
        </div>
      </div>
    </div>
  </div>

  <contracts class="body contracts" *ngIf="tab=='contracts'"></contracts>

  <div *ngIf="tab!='contracts'">

    <img *ngIf="!folder" class="back" src="assets/images/back_asset_gray.svg" alt="">
    <img *ngIf="folder" class="back" src="assets/images/back_asset_black.svg" alt="" (click)="back()">
    <input class="search" type="name" placeholder="Search Assets" [(ngModel)]="search" (ngModelChange)="changedSearch()">

    <k-filter [exterior]="true" [openFilter]="bShowFilter" (openFilterChange)="openFilterChange($event)" background="#fff" [min_width]="120" [min_opts_width]="163" [options]="options" class="asset_type" title="Filter Asset" (selected)="selType($event)"></k-filter>
    <div *ngIf="tab!='company' || roll=='admin'" class="add_new" (click)="openAskAorF()">
      Add new <img class="exp exp_t" [ngClass]="{colapse:bAddingAsset}" src="assets/images/expand.svg">
    </div>
    <div class="filler"></div>
    <k-button *ngIf="bShowCURD && num_sel_assets==0" class="copy" icon="copy_disabled.svg" background="#F2F2F2"></k-button>
    <k-button *ngIf="bShowCURD && num_sel_assets!=0" class="copy" icon="copy_gray.svg" background="#F2F2F2" (click)="copyAsset(false)"></k-button>

    <k-button *ngIf="bShowCURD && num_sel_assets==0" class="copy del" icon="trahs_diabled.svg" background="#F2F2F2"></k-button>
    <k-button *ngIf="bShowCURD && num_sel_assets!=0" class="copy del" icon="del_gray.svg" background="#F2F2F2" (click)="bShowDelUserWarning=true"></k-button>

    <div *ngIf="bShowCURD" class="move_to_folder" [ngClass]="{ready:num_of_movable_assets>0}" (click)="showMoveTo()">
      Move
    </div>


    <!-- <new-asset *ngIf="bAddingAsset" class="abs_center z3" (close)="bAddingAsset=false" [bAdmin]="bAdmin"></new-asset> -->
  </div>
  <assets-folders *ngIf="bShowFolders" [selected_assets_ids]="selected_assets_ids" class="abs_center" (close)="close()" (cancel)="cancel()"></assets-folders>


  <div *ngIf="bShowDelUserWarning" class="abs_center sure_box">
    <div class="margin">
      Are you sure you want to delete this assets?
    </div>
    <div class="margin gray">
      *Deleted assets will not be recoverable.
    </div>
    <div class="abs_center btnsyn">
      <k-button class="btn1" text="Yes" background="#fff" color="#F5423B" (click)="delAssets(false)"></k-button>
      <k-button text="No" background="#000" color="#fff" (click)="bShowDelUserWarning=false"></k-button>
    </div>
  </div>
  <asset-or-folder *ngIf="bAskAorF" (addAsset)="addAsset()" (addFolder)="addFolder()" class="a_or_f"></asset-or-folder>
</div>

<edit-asset *ngIf="show_asset" [asset]="show_asset" class="out"></edit-asset>

<div class="out" (clickx)="closeDetails()" #outComponent>
  <input class="search" type="name" placeholder="Search Contracts" [(ngModel)]="search" (ngModelChange)="changedSearch()">
  <div class="header">
    <div class="f_l">
      Date Sent
    </div>
    <div class=" f_l">
      Title
    </div>
    <div class="f_l">
      Status
    </div>
    <div class="f_l">
      Signers
    </div>

    <div class="f_l">
      Actions
    </div>
  </div>
  <div class="content" #content (scroll)="details_index=-1">


    <div class="line" [ngClass]="{last_l:i==contracts.length-1}" *ngFor="let contract of contracts; let i = index">
      <!-- <div class="check" [ngClass]="{selected:isSelected(contract)}" (click)="select(i, contract)"></div> -->
      <div class="f_l">
        {{contract.created_at|date}} {{contract.created_at|date:'shortTime'}}
      </div>
      <div class=" f_l">
        <div class="ellipsis">
          {{contract.name }}
        </div>

      </div>
      <div *ngIf="contract.perc<100" class="f_l">
        <span class="blue">{{contract.perc}}% complete</span>
      </div>
      <div *ngIf="contract.perc==100" class="f_l">
        <!-- <img class="done" src="assets/images/done.svg" alt="">  -->
        <span class="blue">complete</span>
      </div>
      <div class="f_l">

        <div class="select" (click)="openSignersFor(i, contract, $event)">
          Signers <img class="expand" src="assets/images/exp10.svg" [ngClass]="{flip:details_index==i}">
        </div>
      </div>
      <div class="filler"></div>
      <div class="f_l">

        <div class="imgs">
          <img src="assets/images/pdf.svg" class="im point" (click)="downloadContract( contract)">
          <img src="assets/images/delete.svg" class="im ir point" (click)="delete(i, contract)">
        </div>



        <!-- <k-button class="m10" background="#f5f5f5" icon="pdf.svg" (click)="downloadContract( contract)"></k-button>
        <k-button *ngIf="!contract.perc" background="#f5f5f5" icon="delete.svg" (click)="delete(i, contract)"></k-button> -->
      </div>
    </div>
    <div class="btm">

    </div>
  </div>


  <div *ngIf="details_index>=0" class="opt_contianier" [style.left.px]="options_x" [style.top.px]="options_y" (click)="dontCloseDetails($event)">
    <div class="singers_title">
      {{sel_contract.name }} Signers
    </div>

    <div class="opt" *ngFor="let opt of options;let i = index" [style.color]="opt.color">

      <div class=""> {{opt.name}}
      </div>
      <div class="details_line"> {{opt.email}}
        <div class="details" (click)="expandDetails(i)">
          Details <img class="expand right" src="assets/images/exp10.svg" [ngClass]="{flip:expand_index==i}">
        </div>
      </div>
      <div *ngIf="expand_index==i" class="details_box">
        <div class="dl">Title: {{opt.title}}
        </div>
        <div *ngIf="sel_contract.perc<100" class="dl">
          Status: <span class="blue ml5">{{sel_contract.perc}}% complete</span>
        </div>
        <div *ngIf="sel_contract.perc==100" class="dl">
          Status: <span class="blue ml5">complete</span>
          <!-- <img class="done" src="assets/images/done.svg" alt="">  -->
        </div>
        <div class="dl">
          <img src="assets/images/link.svg" class="link" (click)="copyLink($event, expand_index, i)">
          Contract Link
        </div>

      </div>

      <div class="dline">

      </div>

    </div>

    <div class="chain_contianier" *ngIf="chain_deltails_opt">
      <div class="sub_font_title">
        Block chain verification
      </div>
      <p> To verify the integrity of the contract</p>
      <p><a target="_blank" [href]="chain_deltails_opt.chain.url">Cardano Blockchain Explorer</a></p>
      <p>You will see the PDF hash in the metadata</p>
      <p><strong> {{chain_deltails_opt.chain.hash}}</strong></p>
      <p>To independently verify the PDF Click
        <k-button text="Download" class="btn" background="#40BE5C" (click)="download()"></k-button>
        to download your contract </p>
      <p> And use <a href="https://www.openssl.org/"> openssl </a> to get the hash</p>
      <p> openssl dgst -sha256 blockchain.pdf</p>
      <p>the hash and the same hash is now inmutable in the cardando block chain ledger</p>
      <p> </p>
    </div>

  </div>
  <div class="no_contracts" *ngIf="num_contracts==0">
    <div *ngIf="search.length>0" class="abs_center ">
      No result
    </div>
    <div *ngIf="search.length==0" class="abs_center">
      No sent contracts
    </div>
  </div>
  <k-paginator class="abs_center pages" [pages]="num_pages" [page_size]="num_rows" [total]="total" (indexChange)="selPage($event)"></k-paginator>

  <!-- <div *ngIf="bShowDelUserWarning" class="abs_center sure_box">
    <div class="margin">
      Are you sure you want to delete pending contract?
    </div>
    <div class="margin gray">
      *Deleted pending contracts will not be recoverable.
    </div>
    <div class="abs_center btnsyn">
      <k-button class="btn1" text="Yes" background="#fff" color="#F5423B" (click)="deletContract()"></k-button>
      <k-button text="No" background="#000" color="#fff" (click)="bShowDelUserWarning=false"></k-button>
    </div>
  </div> -->
</div>

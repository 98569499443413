import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanvasComponent } from './canvas/canvas.component';
import { PdfCanvasComponent } from './pdf-canvas/pdf-canvas.component';
// import { ImgCanvasComponent } from './img-canvas/img-canvas.component';
import { ControlsModule } from '../controls/controls.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { LineToolsComponent } from './tools/line-tools/line-tools.component';
import { TextToolsComponent } from './tools/text-tools/text-tools.component';
import { ToolBoxComponent } from './tools/tool-box/tool-box.component';
import { SignToolsComponent } from './tools/sign-tools/sign-tools.component';
import * as reducer from './canvas.reducer';
import { StoreModule } from '@ngrx/store';
import { PdfCoverComponent } from './pdf-cover/pdf-cover.component';
// import { KCanvasComponent } from './k-canvas/k-canvas.component';
import { RepPdfCanvasComponent } from './rep-pdf-canvas/rep-pdf-canvas.component';
import { AssetsHeaderComponent } from './assets-header/assets-header.component';
import { SelSignerComponent } from './tools/sel-signer/sel-signer.component';
import { PdfOptsComponent } from './tools/pdf-opts/pdf-opts.component';
import { BioResultsComponent } from './tools/bio-results/bio-results.component';
import { GetUserIdComponent } from './tools/get-user-id/get-user-id.component';
import { GetUserPicComponent } from './tools/get-user-pic/get-user-pic.component';
import { ProgressComponent } from './tools/progress/progress.component';
import { FileUploadModule } from 'ng2-file-upload';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BlockChainComponent } from './tools/block-chain/block-chain.component';
import { GetSignatureComponent } from './tools/get-signature/get-signature.component';
import { FormsModule } from '@angular/forms';
import { SelShapeComponent } from './tools/sel-shape/sel-shape.component';
import { SignPadComponent } from './tools/sign-pad/sign-pad.component';
import { EditCommentComponent } from './tools/edit-comment/edit-comment.component';
import { ShowCommentComponent } from './tools/show-comment/show-comment.component';
import { ColorsComponent } from './tools/colors/colors.component';
import { WhiteBoardComponent } from './white-board/white-board.component';
import { SelDownloadComponent } from './tools/sel-download/sel-download.component';
import { CanvasOptsComponent } from './tools/canvas-opts/canvas-opts.component';
import { WhiteBoardHeaderComponent } from './white-board-header/white-board-header.component';
import { GetPicOrUploadComponent } from './tools/get-pic-or-upload/get-pic-or-upload.component';
import { EmailContractComponent } from './tools/email-contract/email-contract.component';
import { LineWidthComponent } from './tools/line-width/line-width.component';
import { GetInitialsComponent } from './tools/get-initials/get-initials.component';
import { SignPad1Component } from './tools/sign-pad1/sign-pad1.component';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { SelInputComponent } from './tools/sel-input/sel-input.component';
import { TextColorComponent } from './tools/text-color/text-color.component';
import { VideoCanvasComponent } from './video-canvas/video-canvas.component';
import { KVideoModule } from '../k-video/k-video.module';
import { IframeCanvasComponent } from './iframe-canvas/iframe-canvas.component';
import { KzLibDirective } from './kz-lib.directive';
import { KzInputDirective } from './kz-input.directive';
import { VideoPenComponent } from './video-pen/video-pen.component';

@NgModule({
  declarations: [
    CanvasComponent,
    PdfCanvasComponent,
    // ImgCanvasComponent,
    ToolBoxComponent,
    LineToolsComponent,
    TextToolsComponent,
    SignToolsComponent,
    PdfCoverComponent,
    // KCanvasComponent,
    RepPdfCanvasComponent,
    AssetsHeaderComponent,
    SelSignerComponent,
    PdfOptsComponent,
    BioResultsComponent,
    GetUserIdComponent,
    GetUserPicComponent,
    ProgressComponent,
    BlockChainComponent,
    GetSignatureComponent,
    GetInitialsComponent,
    SelShapeComponent,
    SignPadComponent,
    EditCommentComponent,
    ShowCommentComponent,
    ColorsComponent,
    ColorsComponent,
    WhiteBoardComponent,
    SelDownloadComponent,
    CanvasOptsComponent,
    WhiteBoardHeaderComponent,
    GetPicOrUploadComponent,
    EmailContractComponent,
    LineWidthComponent,
    SignPad1Component,
    SelInputComponent,
    TextColorComponent,
    VideoCanvasComponent,
    IframeCanvasComponent,
    KzLibDirective,
    KzInputDirective,
    VideoPenComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ControlsModule,
    ColorPickerModule,
    StoreModule.forFeature('canvas', reducer.canvasReducer),
    DragDropModule,
    FileUploadModule,
    AngularSignaturePadModule,
    KVideoModule,
  ], exports: [
    CanvasComponent,
    PdfCanvasComponent,
    LineToolsComponent,
    TextToolsComponent,
    SignToolsComponent,
    PdfCoverComponent,
    // KCanvasComponent,
    RepPdfCanvasComponent,
    SelSignerComponent,
    PdfOptsComponent,
    GetUserIdComponent,
    GetUserPicComponent,
    BlockChainComponent,
    BioResultsComponent,
    AssetsHeaderComponent,
    GetSignatureComponent,
    GetInitialsComponent,
    SelShapeComponent,
    ColorsComponent,
    WhiteBoardComponent,
    SelDownloadComponent,
    CanvasOptsComponent,
    WhiteBoardHeaderComponent,
    GetPicOrUploadComponent,
    SignPad1Component,
    SelInputComponent,
    TextColorComponent,
    VideoCanvasComponent,
    IframeCanvasComponent,
    KzLibDirective,
    KzInputDirective,
    VideoPenComponent
  ]
})
export class CanvasModule { }

<div class="scrn" #scrn>
  <div class="abs_center out">
    <div class="text title">
      Add new card {{bCCNumOk}}
    </div>
    <div class="text stitle">
      Card details
    </div>
    <div class="cc_li margin">
      <input kzLib class="in first" type="text" placeholder="Card holder name" name="" value="" [(ngModel)]="first_name" (ngModelChange)="ccChange()">
      <input kzLib class="in last" type="text" placeholder="Last Name" name="" value="" [(ngModel)]="last_name" (ngModelChange)="ccChange()">
    </div>
    <div class="code_div margin">
      <input kzLib class="in cc" type="text" placeholder="CC Number" name="" value="" [(ngModel)]="cc_number" [ngClass]="{red_frame:!bCCNumOk}" (ngModelChange)="ccChange()">
      <img class="cc_lg" [src]="cc_logo" alt="">
    </div>
    <div class="cc_li margin">
      <div class="sb">
        <input kzLib class="in month" type="month" min="2024-07" max="2099-11" placeholder="MM/YYYY" name="" value="" [(ngModel)]="cc_exp" (ngModelChange)="ccChange()">
      </div>
      <div class="sb">
        <input kzLib class="in ccv" type="text" placeholder="CCV" name="" value="" [(ngModel)]="cc_cvv" (ngModelChange)="ccChange()">
      </div>
    </div>
    <div class="code_div margin">
      <div class="stitle">Company billing address * (for invoice)</div>
      <input class="in addr" type="text" placeholder="Address" name="" value="" [(ngModel)]="cc_address" (ngModelChange)="changed()">
      <div class="cc_li">
        <input kzLib class="in zip" type="text" placeholder="Zip Code" name="" value="" [(ngModel)]="cc_zip" (ngModelChange)="changed()">
        <input kzLib class="in state" type="text" placeholder="State" name="" value="" [(ngModel)]="cc_state" (ngModelChange)="changed()">
        <img src="assets/images/expand.svg" class="expand" [ngClass]="{close_ex:bShowStates}" kzLib (click)="bShowStates=!bShowStates" />
      </div>
    </div>
    <div class="btmtx new_btmtx">
      PaymentStars is an Elavon Payments Partner & Registered MSP/ISO of Elavon, Inc. Georgia.  PaymentStars is a registered Independent Sales Organization of Wells Fargo Bank, N.A., Concord, CA. Your payment details are sent directly to our
      secure payment gateway. keyzii never stores your card number or CVC number. Payments are charged on the same day you subscribe or update your membership. Upgrades incur prorated charges, and downgrades take effect immediately.
    </div>
    <img class="payment_stars" src="assets/images/payment_stars.svg" alt="">

    <div class="send add_cc new_send" [ngClass]="{btn_ready:bReady}" kzLib (click)="addCard()">
      <div class="abs_center btn" [ngClass]="{btn_tx_ready:bReady}">
        Add new card
      </div>
    </div>
    <img src="assets/images/close.svg" class="close" kzLib (click)="closeIt()">
    <captcha *ngIf="bShowCaptcha" doing="add_cc" class="screen"></captcha>

  </div>
  <!-- <div class="democ">
    <a (click)="goToLink('https://developer.elavon.com/products/converge/v1/test-cards')">Demo Cards Visa </a> 4000000000000002 Amex 370000000000002 MC 5121212121212124
  </div> -->
</div>

<div class="cont">
  <div class="title">
    {{title}}
  </div>


  <div class="take_pic">
    <div class="viewport" [ngClass]="{hide:hide_video}">
      <video [muted]="true" #videoElement class="mirrow" autoPlay autoplay playsinline>
      </video>
    </div>
    <img *ngIf="hide_video" class="img" [src]="img_src">

    <k-button class="camera" icon="camera_white.svg" background="#40BE5C" (click)="takeThePic()"></k-button>

    <div class="inst">
      {{prompt}}
    </div>
  </div>
  <img class="cancel" src="assets/images/close.svg" (click)="cancel()">

  <!-- <k-button text="Cancel" class="cancel" background="#fff" (click)="cancel()"></k-button> -->
  <k-button text="Upload" min_width="90" class="next" color="white" [background]="done_color" (click)="done()"></k-button>


</div>

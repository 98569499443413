<div class="out" [ngClass]="{folder:_asset.type=='folder',sel_frame:selected}" (dblclick)="doubleClick($event)">
  <img class="fit" *ngIf="img_src" [src]="img_src">
  <iframe *ngIf="_asset.type=='matterport' && sanitized_url" [src]="sanitized_url" class="fit">

  </iframe>
  <iframe *ngIf="(_asset.type=='kuula' || _asset.type=='iframe' || _asset.type=='on_board_light' || _asset.type=='on_board_pro'|| _asset.type=='on_board_wnd')&& sanitized_url" class="frame" width="100%" height="640" frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer"
    allowfullscreen scrolling="no" [src]="sanitized_url"></iframe>


  <pdf-cover [asset]="_asset" class="pdf" *ngIf="_asset.type=='pdf'||_asset.type=='contract'"></pdf-cover>
  <video controls #videoUploaded playsinline defaultMuted *ngIf="_asset.type=='uploaded' || _asset.type=='video'" class="fit">
    <source [src]="sanitized_url" type="video/webm" />
    <source [src]="sanitized_url" type="video/mp4">
  </video>
  <!-- <div class="name" *ngIf="_asset.type!='folder'"> {{name}} {{_asset.type}} </div> -->

  <img *ngIf="_asset.type=='folder'" class="abs_center fimg" src="assets/images/folder_white.svg">

  <div class="abs_center fs24" *ngIf="_asset.type=='folder'">
    {{_asset.num_assets}}
  </div>
  <div class="glass">

    <input class="name" type="text" [(ngModel)]="name" (focusout)="saveName()" (keydown)="onKeyPress($event)" (dblclick)="onKeyPress($event)">
    <switch *ngIf="_asset.type !='folder'" class="switch" (checkedChange)="showAsset($event)" [checked]="checked"></switch>
    <div *ngIf="router.url == '/assets'" class="check" [ngClass]="{selected:selected}" (click)="select()"></div>
  </div>

</div>

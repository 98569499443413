<div class="out">
  <assets-header class="top"></assets-header>
  <div class="bottom">
    <div class="left" [ngClass]="{ in_bar:bShowProgress}">


      <div cdkDrag class="round" *ngIf="caller_id">
        <k-video role="caller" screen="in-call-asset" class="full" [small]="true">
        </k-video>
      </div>
      <div cdkDrag class="round" *ngIf="rep_id">
        <k-video role="rep" screen="in-call-asset" class="full" [bMirrow]="my_id==rep_id" [small]="true">
        </k-video>
      </div>
      <!-- <div cdkDrag class="round" *ngIf="manager_id">
          <k-video role="manager" screen="in-call-asset" class="full" [bMirrow]="my_id==manager_id" [small]="true">
          </k-video>
        </div> -->
    </div>


    <div class="right">


      <asset *ngIf="asset || bShowAPI" [asset]="asset" class="content asset"></asset>

      <iframe-canvas class="content" *ngIf="share_stream && !asset"></iframe-canvas>

    </div>
  </div>

</div>

<div *ngIf="!isPhone" class="out white">


  <div class="tools">

    <k-button *ngIf="undo_stack_size==0" icon="undog.svg" [background]="back_colors.undo" (click)="undo()"></k-button>
    <k-button *ngIf="undo_stack_size!=0" icon="undob.svg" [background]="back_colors.undo" (click)="undo()"></k-button>

    <k-button icon="cursor2.svg" [background]="back_colors.none" (click)="none()"></k-button>


    <k-button icon="eraser.svg" [background]="back_colors.erase" (click)="erase()"></k-button>
    <k-button class="btn" icon="pallet.svg" background="transparent" (click)="selectTool($event,'colors')"></k-button>
    <img class="exp" [ngClass]="{colapse:opened_tool == 'colors'}" src="assets/images/expand.svg" (click)="selectTool($event, 'colors')">

    <k-button class="btn" [icon]="shape_icon" [background]="back_colors.shapes" (click)="shapes()"></k-button>
    <img class="exp" [ngClass]="{colapse:opened_tool == 'sel_shape'}" src="assets/images/expand.svg" (click)="selectTool($event,'sel_shape')">

    <k-button class="btn" icon="pencil.svg" [background]="back_colors.pencil" (click)="pencil()"></k-button>
    <img class="exp" [ngClass]="{colapse:opened_tool == 'pencil'}" src="assets/images/expand.svg" (click)="selectTool($event,'pencil')">

    <k-button class="btn" icon="text.svg" [background]="back_colors.drop_text" (click)="text()"></k-button>
    <img class="exp" [ngClass]="{colapse:opened_tool == 'text'}" src="assets/images/expand.svg" (click)="selectTool($event,'text')">
    <!-- <k-button icon="cloud.svg" [background]="back_colors.cloud" (click)="cloud()"></k-button> -->

  </div>


</div>

import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[kzLib]'
})
export class KzLibDirective implements OnInit {

  @Input() kzLib: string = "blue";  // Accept input for background color

  constructor(private el: ElementRef) {
    console.log("kzLib directive")
  }

  ngOnInit(): void {
    // If no color is passed, use the default color

    this.el.nativeElement.dataset['kzbtn'] = this.kzLib
    // const color = this.kzLib
    // this.renderer.setStyle(this.el.nativeElement, 'background-color', color);
  }
}

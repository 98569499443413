import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowCC } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';


@Component({
  selector: 'kpay-menu',
  templateUrl: './kpay-menu.component.html',
  styleUrls: ['./kpay-menu.component.scss']
})
export class KpayMenuComponent {
  sel_option: number = 0
  options: any[] = [
    {
      text: "keyzii Plan"
    }, {
      text: "New Invoice"
    }]
  constructor(
    private pagesState: Store<PagesState>,
  ) { }
  selOption(i: number) {
    if (i == 0) {
      this.pagesState.dispatch(new ShowCC("kpay-bar"))
    } else {
      this.pagesState.dispatch(new ShowCC("kpay-invoice")) //The menu will launch the window

    }
  }
}

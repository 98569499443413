import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { trigger, state, style, animate, transition, } from '@angular/animations';

import { Store } from '@ngrx/store';
import { PagesState } from 'src/app/pages/pages.model';

import { ToolService } from '../tool.service';
import { apiParams, apiUrlParams, filteredAssets, filterOptions, gotAssetsAndContracts, headerPath, iAmManager, showAsset, showFolder, showToolAddAasset } from 'src/app/pages/pages.selectors';
import { WSSService } from 'src/app/wss/wss.service';
import { asset_types, Hash, getAssetFromURL } from 'src/app/utils';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { WSSState } from 'src/app/wss/wss.model';
import { callId, lastMsg, managerId, repId } from 'src/app/wss/wss.selectors';
import { ChatState } from 'src/app/chat/chat.model';
import { chatId } from 'src/app/chat/chat.selectors';

import { ShowAsset, FilterAssets, SelFilterOption, ShowFolder, SetTab, ShowAPI, SearchBy, AddAsset, ShowToolAddAsset } from 'src/app/pages/pages.actions';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { streams } from 'src/app/k-video/k-video.selectors';
import { KVideoState } from 'src/app/k-video/k-video.model';

@Component({
  selector: 'tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
  animations: [
    trigger('OpenClose', [
      state('open', style({
        width: "463px",//"492px", //35+2+150+2+150+2
      })),
      // state('mid', style({
      //   width: "353px",//35+2+150+2
      // })),
      state('closed', style({
        width: "20px",
      })),
      transition('closed => open', [
        animate('.25s')
      ]),
      transition('open => closed', [
        animate('.25s')
      ]),
    ]),
  ],
})
export class ToolsComponent implements OnInit {
  state: string = "closed" //0 = closed, 1 mid, 2 open
  assets: any = {}
  all_assets: any = {}
  asset_keys: string[] = []
  asset_name: string = ""
  mp_ouath: boolean = true
  options: any[] = []

  bAddingAsset: boolean = false;
  bShowAssetDisabled: boolean = true;
  call_id: string | undefined;
  selected_assets: Hash = new Hash()
  chat_id: string | undefined
  msg_from_id: string | undefined
  bManager: boolean = false
  manager_id: string | undefined
  rep_id: string | undefined
  asset_types: any[] = []

  folder: any
  subscriptions: any[] = []
  bAskAorF: boolean = false

  api_iframe_url: any
  show_in: string = "browser"
  @ViewChild('iframe') iframe: ElementRef | undefined;

  bShowAPI: boolean = false

  preview_asset: any
  stack: any[] = []
  api_url_params: string | undefined
  company_icon: string = "assets/images/api_icon.svg"

  constructor(
    private pagesState: Store<PagesState>,
    private tool_service: ToolService,
    public canvasStore: Store<CanvasState>,
    public wssState: Store<WSSState>,
    public chatState: Store<ChatState>,
    public wss_service: WSSService,
    public datepipe: DatePipe,
    private sanitizer: DomSanitizer,
    private kVideoState: Store<KVideoState>,
  ) {
    console.log("tool component")
    this.subscriptions.push(this.kVideoState.select(streams).subscribe((streams: any) => {
      // console.log("k-video  stream for " + JSON.stringify(Object.keys(streams)))

      if (streams && streams["remote_share"]) {
        this.state = "closed"
      }

    }))
    this.subscriptions.push(this.pagesState.select(apiUrlParams).subscribe((url_params: string | undefined) => {
      if (url_params) {
        this.api_url_params = url_params
        this.api_iframe_url = this.sanitizer.bypassSecurityTrustResourceUrl(url_params);
      }
    }))

    this.subscriptions.push(this.pagesState.select(apiParams).subscribe((params: any) => {
      if (params && params.api_iframe_url) {
        setTimeout(() => {
          this.show_in = params.show_in
        });
      }
    }))
    this.subscriptions.push(this.pagesState.select(iAmManager).subscribe((manager: boolean) => {
      this.bManager = manager
      if (manager) {
        this.msg_from_id = this.manager_id
      } else {
        this.msg_from_id = this.rep_id
      }
    }))
    this.subscriptions.push(this.wssState.select(repId).subscribe(async (id: string | undefined) => {
      this.rep_id = id
      if (!this.bManager) {
        this.msg_from_id = this.rep_id
      }
    }))
    this.subscriptions.push(this.wssState.select(managerId).subscribe(async (id: string | undefined) => {
      this.manager_id = id
      if (this.bManager) {
        this.msg_from_id = this.manager_id
      }
    }))
    this.subscriptions.push(this.chatState.select(chatId).subscribe(async (chat_id: string | undefined) => {
      this.chat_id = chat_id
    }))
    this.subscriptions.push(this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
      this.checkShowAddToChat()
    }))

    this.options = Object.assign([], asset_types);
    let option = Object.assign({}, this.options[0])
    option.selected = true
    this.options[0] = option
    this.options.push({ desc: 'Folders', type: 'folder' })

    this.asset_types = asset_types


    this.subscriptions.push(this.pagesState.select(showFolder).subscribe((folder: any) => {
      if (folder) {
        if (folder.asset_id || folder._id) {
          if (this.folder && this.folder.asset_id != folder.asset_id) {
            let index = this.stack.indexOf(folder.asset_id)
            if (index >= 0) {
              console.log("trim it")
              while (this.stack.length >= index) {
                this.stack.pop()
              }
            }
          }
          if (this.folder) {
            this.stack.push(this.folder.asset_id)
          }
          this.folder = folder

          // console.log(JSON.stringify(this.stack))
          this.assets = this.folder.assets
          this.selected_assets = new Hash()
          this.asset_keys = Object.keys(this.assets)
          this.selected(this.options)
        } else {
          this.folder = undefined
        }
      }
    }))

    this.tool_service.getAssets()
    this.subscriptions.push(this.pagesState.select(gotAssetsAndContracts).subscribe((assets: any[]) => {
      this.all_assets = assets
      this.assets = assets
      this.asset_keys = Object.keys(this.assets)
      this.selected(this.options)
    }))
    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      if (asset) {
        if (asset.asset_id) {
          this.state = "closed"
        }
      }
    }))
    this.subscriptions.push(this.pagesState.select(filterOptions).subscribe((filter_options: any) => {
      // this.filter_options = filter_options
      // this.filterAssets()
      this.pagesState.dispatch(new FilterAssets("all", this.asset_name))

    }))
    this.subscriptions.push(this.pagesState.select(filteredAssets).subscribe((filtered_assets: any) => {
      this.assets = filtered_assets
      this.asset_keys = Object.keys(this.assets)
    }))
    this.subscriptions.push(this.pagesState.select(showToolAddAasset).subscribe((show: boolean) => {
      this.bAddingAsset = show
    }))

  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  backFromFolder() {
    this.folder = undefined
    if (this.stack.length > 0) {
      let id = this.stack.pop()
      this.pagesState.dispatch(new ShowFolder(id))
    } else {
      this.folder = undefined
      this.assets = this.all_assets
      this.pagesState.dispatch(new ShowFolder())
    }
  }

  ngOnInit(): void {
  }
  async   ngAfterViewInit() {
    window.addEventListener("message", (event) => {
      // extract the data from the message event
      const {
        data
      } = event;

      // display it in our textarea as formatted JSON

      this.showExternalAsset(data)
    });

  }
  toggleOpen() {
    this.pagesState.dispatch(new SetTab("all"))
    if (this.state == "closed") {
      this.state = "open"
      // setTimeout(() => {
      //   this.asset_name = "https://www.informador.mx/"
      //   this.changedSearch()
      // }, 200)
    } else {
      this.state = "closed"
    }
  }



  selected(options: any[]) {
    this.pagesState.dispatch(new SelFilterOption(options))
  }

  showMLS() {
    this.canvasStore.dispatch(new SelectToolOpts("mls"))
  }
  checkShowAddToChat() {
    let num_sel_assets = this.selected_assets.array.length
    if (!this.call_id || num_sel_assets == 0) {
      this.bShowAssetDisabled = true;
    } else {
      this.bShowAssetDisabled = false;
    }
  }
  selectAsset(i: number) {
    if (!this.selected_assets.insert(i)) {
      this.selected_assets.remove(i)
    }
    this.checkShowAddToChat()
  }
  addToChat() {
    if (this.bShowAssetDisabled) {
      return
    }
    let arr = this.selected_assets.array
    let keys = Object.keys(this.assets)
    for (let i = 0; i < arr.length; i++) {
      let index = arr[i]
      let asset_id = keys[index]
      let msg = {
        chat_id: this.chat_id,
        chat_msg: {
          asset_id: asset_id,
          id: this.msg_from_id
        }
      }
      this.wss_service.sendMessageToMembersAndMe(msg) //it will be picked up by last msg in chat.service
    }
  }
  showKuula() {
    let asset = {
      type: "kuula",
      asset_id: "kuula",
      url: "https://kuula.co/share/NjV1w?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1"
      // url: "https://kuula.co/post/7DTNb/collection/7PNXK"
    }
    this.pagesState.dispatch(new ShowAsset(asset))
    this.wss_service.sendMessageToOtherMembers({ show_asset: asset })

  }
  showExternalAsset(data: any) {
    // console.log(data)
    try {
      if (data && typeof data === 'string') {
        let asset = getAssetFromURL(data)
        if (asset) {
          this.pagesState.dispatch(new ShowAsset(asset))
          this.wss_service.sendMessageToOtherMembers({ show_asset: asset })
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  async addFolder() {
    this.bAskAorF = false;
    let latest_date = this.datepipe.transform(new Date(), 'MM-dd-yy hh:mm');

    let asset: any = {
      bAdmin: false,
      name: "Folder " + latest_date,
      type: "folder"
    }
    if (this.folder) {
      let parents = Object.assign([], this.folder.parents)
      parents.push(this.folder.asset_id)
      asset.parents = parents
    }
    try {
      await this.tool_service.changeAsset(asset) //We will need a new stream for the new room
    } catch (e: any) {
      //displayed in the tool_service
    }
  }
  showHideAPI() {
    if (this.call_id && this.show_in == 'browser') {
      window.open(this.api_url_params, '_blank', 'location=yes,height=800,width=1200,scrollbars=yes,status=yes');
      return
    }
    if (this.show_in == 'tools') {
      this.bShowAPI = !this.bShowAPI

      // setTimeout(() => {
      //   if (this.iframe && this.iframe.nativeElement && this.iframe.nativeElement.contentWindow) {
      //     let msg = {
      //       orig_href: this.orig_href
      //     }
      //     let smgs = JSON.stringify(msg)
      //     this.iframe.nativeElement.contentWindow.postMessage(smgs);
      //   }
      // }, 2000)
    } else {
      //close this window
      this.toggleOpen()
      this.pagesState.dispatch(new ShowAPI(true))
      this.asset_name = ""
    }
  }
  closePreview() {
    this.preview_asset = undefined
    this.asset_name = ""
    this.changedSearch()
    this.toggleOpen()
  }
  addAsset() {
    this.bAskAorF = false;
    this.pagesState.dispatch(new ShowToolAddAsset(true))

  }
  changedSearch() { //filter by name
    console.log("filter by name")
    this.preview_asset = getAssetFromURL(this.asset_name)
    this.pagesState.dispatch(new SearchBy(this.asset_name))
  }
}

<div class="scrn">
  <div class="abs_center out">
    <div class="margin">
      Are you sure you want to delete contact?
    </div>

    <div class="abs_center btns_t">
      <k-button class="btn1" min_height="40" text="Yes" background="#fff" color="#F5423B" (click)="delContact()"></k-button>
      <k-button text="No" min_height="40" background="#000" color="#fff" (click)="cancel()"></k-button>
    </div>

    <!-- <k-check class="abs_center cookies" [(checked)]="bCookiesSaved" text="Save cookies" (checkedChange)="cookiesChange($event)"></k-check> -->
  </div>
</div>

import { Injectable } from '@angular/core';


import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { getQueryParameter, menus } from 'src/app/utils';
import { Store } from '@ngrx/store';
import { PagesState } from '../pages/pages.model';
import { WSSState } from './wss.model';
import { LogedIn } from './wss.actions';
import { ActiveCalls, GotAccount, GotLoginData, GotMyPicture, GotUsers, NotifyUser, ShowError, ShowWelcome, UserRoll } from '../pages/pages.actions';
import { customerId, myId } from './wss.selectors';
import { CalendarState } from '../pages/scheduling/calendar.model';
import { ClendarUser } from '../pages/scheduling/calendar.actions';

import * as CryptoTS from 'crypto-ts';
import { NewInitials, NewSignature } from '../canvas/canvas.actions';
import { codeFromURL, myCompany, workTime } from '../pages/pages.selectors';

@Injectable({
  providedIn: 'root'
})
export class KeyziiService {
  // user: SocialUser | undefined;
  cookie_pwd: string | undefined

  customer_id: string | undefined
  my_id: string | undefined
  menus: any[] = []
  company: string = "keyzii"
  work_time: any
  code_from_url: string | undefined
  login_as: string | undefined

  constructor(
    // private socialAuthService: SocialAuthService,
    private httpClient: HttpClient,
    public router: Router,
    private pagesState: Store<PagesState>,
    private wssState: Store<WSSState>,
    private calendarStore: Store<CalendarState>,
  ) {
    this.menus = menus
    console.log("KeyziiService ")
    this.login_as = getQueryParameter("email")

    if (this.login_as) {
      let token = getQueryParameter("token")
      if (token) {
        this.loginAsEmail(this.login_as, token)
      }
    }


    this.pagesState.select(codeFromURL).subscribe((code_from_url: string | undefined) => {

      this.code_from_url = code_from_url

    })
    this.pagesState.select(myCompany).subscribe((company: string) => {
      if (company) {
        this.company = company
      }
    })
    this.pagesState.select(workTime).subscribe((work_time: any) => {
      if (work_time) {
        this.work_time = work_time
      }
    })
    this.wssState.select(myId).subscribe((my_id: string | undefined) => {
      this.my_id = my_id
    })
    this.wssState.select(customerId).subscribe((customer_id: string | undefined) => {
      if (!this.customer_id && customer_id) {
        this.customer_id = customer_id
        this.getAccount()
      }
    })

  }

  getCookies() {
    if (this.cookie_pwd) {
      let signature = this.getCookie("signature")
      if (signature) {
        let data = JSON.parse(signature)
        this.calendarStore.dispatch(new NewSignature(data.group_id, data.svg, data.rect, data.text_initials))
      }
      let initials = this.getCookie("initials")
      if (initials) {
        let data = JSON.parse(initials)
        this.calendarStore.dispatch(new NewInitials(data.group_id, data.svg, data.rect))
      }
    }
  }

  public logIntWithSession(token: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.get('/token/' + token).toPromise();
        if (rv.welcome) {
          this.pagesState.dispatch(new ShowWelcome(rv.welcome))
        }
        // if (rv.ask_code_from) {
        //   this.pagesState.dispatch(new AskCodeFrom(rv.ask_code_from))
        // }
        this.calendarStore.dispatch(new ClendarUser(rv))
        this.wssState.dispatch(new LogedIn(rv))
        this.pagesState.dispatch(new GotLoginData(rv))
        this.pagesState.dispatch(new UserRoll(rv.roll))
        this.cookie_pwd = rv.cookie_pwd
        this.getCookies()
        // if (!rv.has_google_calendar && window.location.host.indexOf("localhost") < 0) {
        //   this.pagesState.dispatch(new ShowError("It looks like your calendar is not connected. Please re-connect your calendar in settings."))
        // }
        resolve(rv)

      } catch (e: any) {
        reject(e);
      }
    })
  }

  public loginWithEmail(email: string, pwd: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let body = {
          email: email.toLowerCase(),
          pwd: pwd,
        }
        let rv: any = await this.httpClient.post('/login', body).toPromise();
        let rv1 = await this.contLogInWithEmail(rv)

        if (this.login_as && rv.email_token) {
          let url = window.location.href + "?email=" + this.login_as + "&token=" + rv.email_token
          window.location.assign(url);
        }
        resolve(rv1)
      } catch (e: any) {
        reject(e);
      }
    })
  }
  async  loginAsEmail(email: string, token: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let body = {
          email: email.toLowerCase(),
          email_token: token,
        }
        let rv: any = await this.httpClient.post('/login_as', body).toPromise();
        let rv1 = await this.contLogInWithEmail(rv)


        resolve(rv1)
      } catch (e: any) {
        reject(e);
      }
    })
  }
  contLogInWithEmail(rv: any) {
    return new Promise(async (resolve, reject) => {
      try {
        if (rv.welcome) {
          this.pagesState.dispatch(new ShowWelcome(rv.welcome))
        }
        // if (rv.ask_code_from) {
        //   this.pagesState.dispatch(new AskCodeFrom(rv.ask_code_from))
        // }
        this.calendarStore.dispatch(new ClendarUser(rv))
        this.wssState.dispatch(new LogedIn(rv))
        this.pagesState.dispatch(new GotLoginData(rv))
        this.pagesState.dispatch(new UserRoll(rv.roll))
        this.cookie_pwd = rv.cookie_pwd
        this.getCookies()
        // if (!rv.has_google_calendar && window.location.host.indexOf("localhost") < 0) {
        //   this.pagesState.dispatch(new ShowError("It looks like your calendar is not connected. Please re-connect your calendar in settings."))
        // }
        resolve(rv)

      } catch (e: any) {
        reject(e);
      }
    })
  }
  signUp(email: string, name?: string, last_name?: string, company?: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let body = {
          email: email.toLowerCase(),
          name: name,
          last_name: last_name,
          company: company,
          code_from_url: this.code_from_url
        }
        let rv: any = await this.httpClient.post('/signup', body).toPromise();

        this.calendarStore.dispatch(new ClendarUser(rv))
        resolve(rv)
      } catch (e: any) {
        console.error(JSON.stringify(e))
        reject(e);
      }
    })
  }
  resetPWd(token: string, email: string, pwd: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let body = {
          token: token,
          pwd: pwd,
          email: email.toLowerCase()
        }
        let rv: any = await this.httpClient.post('/reset', body).toPromise();
        resolve(rv)
        this.pagesState.dispatch(new LogedIn(rv))
        this.pagesState.dispatch(new GotLoginData(rv))
        this.pagesState.dispatch(new UserRoll(rv.roll))
        if (rv.welcome) {
          this.pagesState.dispatch(new ShowWelcome(rv.welcome))
        }
        // if (rv.ask_code_from) {
        //   this.pagesState.dispatch(new AskCodeFrom(rv.ask_code_from))
        // }
      } catch (e: any) {
        if (e.error.desc == "New user") {
          let new_user = {
            email: e.error.email
          }
          resolve(new_user)
          this.pagesState.dispatch(new LogedIn(new_user))
        }
        // console.error(e)
        reject(e);
      }
    })
  }
  logOut() {
    // if (this.user) {
    //   this.socialAuthService.signOut(true)
    // }
    location.reload();
  }
  getActiveCalls(page: number, page_size: number, agency: number, search: string) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        let url: string = '/active_calls?&page=' + page + "&page_size=" + page_size

        let rv: any = await this.httpClient.get(url).toPromise();
        /*  if (rv.calls.length == 0) {
            rv.count = 14
            rv.calls = [{
              caller_email: "Sergio@asci.us",
              caller_name: "Sergio",
              rep_email: "ts1@asas.xx",
              rep_name: "ts ts",
              started_at: 1723306281637,
            }, {
              caller_email: "Sergio@asci.us",
              caller_name: "Sergio",
              rep_email: "ts2@asas.xx",
              rep_name: "ts ts",
              started_at: 1723326281637,
            }, {
              caller_email: "Sergio@asci.us",
              caller_name: "Sergio",
              rep_email: "ts3@asas.xx",
              rep_name: "ts ts",
              started_at: 1723306281637,
            }, {
              caller_email: "Sergio@asci.us",
              caller_name: "Sergio",
              rep_email: "ts4@asas.xx",
              rep_name: "ts ts",
              started_at: 1723326281637,
            }, {
              caller_email: "Sergio@asci.us",
              caller_name: "Sergio",
              rep_email: "ts5@asas.xx",
              rep_name: "ts ts",
              started_at: 1723306281637,
            }, {
              caller_email: "Sergio@asci.us",
              caller_name: "Sergio",
              rep_email: "ts6@asas.xx",
              rep_name: "ts ts",
              started_at: 1723326281637,
            }, {
              caller_email: "Sergio@asci.us",
              caller_name: "Sergio",
              rep_email: "ts7@asas.xx",
              rep_name: "ts ts",
              started_at: 1723306281637,
            }, {
              caller_email: "Sergio@asci.us",
              caller_name: "Sergio",
              rep_email: "ts8@asas.xx",
              rep_name: "ts ts",
              started_at: 1723326281637,
            }]
          }*/
        this.pagesState.dispatch(new ActiveCalls(rv))

        resolve(rv)
      } catch (e: any) {
        reject(e);
      }
    })
  }

  async getAccount() {
    try {
      let url = '/account/' + this.customer_id
      let account: any = await this.httpClient.get(url).toPromise();
      this.pagesState.dispatch(new GotAccount(account))
    } catch (e: any) {
      console.error("Error getAccount  " + JSON.stringify(e))
    }
  }

  async addUser(user: any) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        let user1 = Object.assign({}, user)
        user1.company = this.company
        user1.work_time = this.work_time
        let msg = { user: user1, customer_id: this.customer_id, }
        let new_user = await this.httpClient.post<any[]>('/users', msg).toPromise();
        resolve(new_user)
      } catch (e: any) {
        reject(e)
      }
    })
  }

  async editUser(user: any) {
    try {
      let msg = { user: user, customer_id: this.customer_id }
      let users_reps = await this.httpClient.put<any[]>('/users', msg).toPromise();
      this.pagesState.dispatch(new GotUsers(users_reps))
    } catch (e: any) {
      console.error("Error editUser  " + JSON.stringify(e))
    }
  }

  async deleteUser(user: any) {
    try {
      console.log("del user " + JSON.stringify(user))
      let users_reps = await this.httpClient.put<any[]>('/del_users/', { user: user }).toPromise();
      this.getUsersAndReps()
    } catch (e: any) {
      console.error("Error del user " + JSON.stringify(e))
    }
  }
  async setUserData(data: any) {
    try {
      let rv: any = await this.httpClient.put<any[]>('/user_data', data).toPromise();
      this.pagesState.dispatch(new GotUsers(rv.users))
    } catch (e: any) {
      console.error("Error editUser show " + JSON.stringify(e))
    }
  }
  async saveUserPicture(picture: any) {
    try {
      let rv: any = await this.httpClient.post('/save_rep_pic', { picture: picture }).toPromise();
    } catch (e: any) {
      console.error("Error delete user picture" + JSON.stringify(e))
    }
  }
  async setAPIIParams(params: any) {
    try {
      let rv: any = await this.httpClient.post("/api_params", params).toPromise();
    } catch (e) {
      console.error("Error setAPIIFrameURL  " + e)
    }
  }
  async delUserPicture() {
    try {
      let rv: any = await this.httpClient.delete('/del_rep_pic').toPromise();
    } catch (e: any) {
      console.error("Error delete user picture" + JSON.stringify(e))
    }
  }
  async setCompanyData(data: any) {
    try {
      let users_reps = await this.httpClient.put<any[]>('/company_data', data).toPromise();
      this.pagesState.dispatch(new NotifyUser("Saved"))
    } catch (e: any) {
      console.error("Error editUser show " + JSON.stringify(e))
    }
  }
  async getUsersAndReps() {
    return new Promise<any>(async (resolve, reject) => {

      try {
        let users_reps = await this.httpClient.get<any[]>('/users').toPromise();
        this.pagesState.dispatch(new GotUsers(users_reps))
      } catch (e: any) {
        console.error("Error editUser show " + JSON.stringify(e))
      }
    })
  }
  setCookie(name: string, sval: any) {
    if (this.cookie_pwd) {
      const ciphertext = CryptoTS.AES.encrypt(sval, this.cookie_pwd);
      let enc_val = ciphertext.toString();
      localStorage.setItem(name, enc_val)
    }
  }
  getCookie(name: string) {
    let plaintext = ""
    let enc_val = localStorage.getItem(name)
    if (enc_val && this.cookie_pwd) {
      try {
        const bytes = CryptoTS.AES.decrypt(enc_val, this.cookie_pwd);
        plaintext = bytes.toString(CryptoTS.enc.Utf8);
      } catch (e) {

      }
    }
    return plaintext;
  }


}

<div class="scrn">
  <div class="abs_center sure_box">
    <div class="margin">
      Are you sure you want to delete this user?
    </div>
    <div class="abs_center btnsyn">
      <k-button class="btn1" text="Yes" background="#fff" color="#000" (click)="deleteUser()"></k-button>
      <k-button text="No" background="#000" color="#fff" (click)="closeIt()"></k-button>
    </div>
  </div>
</div>

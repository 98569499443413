<div class="out">
  <div class="content">
    <div class="row">

      <div class="section">
        <div class="tx font_title">
          Company Call Link
        </div>
        <div class="btn" #company (click)="customerLink()"></div>

        <!-- <k-button class=" btn abs_center" icon="copy.svg" text="Company Link" (click)="customerLink()">
        </k-button> -->
        <div class="info font_text">
          Embed this link on your website to direct callers to agents company-wide.
        </div>
      </div>
      <div class="tx section">
        <div class="font_title">
          Personal Call Link
        </div>
        <div class="btn" #personal (click)="personalLink()"></div>

        <!-- <k-button class=" btn abs_center" icon="copy.svg" text="Personal Link" (click)="personalLink()">
        </k-button> -->
        <div class="info font_text">
          Embed this link on your website to direct callers to your personal video call page. </div>
      </div>
    </div>
    <div class="row">

      <div class="section">
        <div class="tx font_title">
          Company QR Code
        </div>
        <img class="qr" [src]="cia_qr_code">
        <div class="info font_text">
          Company Call link as a QR code.
          Right click to save or copy the image. </div>
      </div>
      <div class="section">
        <div class="tx font_title">
          Personal QRCode
        </div>
        <img class="qr" [src]="rep_qr_code">
        <div class="info font_text">
          Personal Call link as a QR code.
          Right click to save or copy the image.</div>
      </div>
    </div>
    <div class="row last_row">

      <div class="section">
        <div class="tx  font_title">
          Company Dynamic Buttons
        </div>
        <div class="btn" #embed (click)="embedCia()"></div>
        <div class="info font_text">
          Add this dynamic button to your website to let your callers know when agents are ONLINE and OFFLINE. </div>
      </div>

      <div class="section">
        <div class="tx  font_title">
          Personal Dynamic Buttons
        </div>
        <div class="btn per_btn" #embed_per (click)="embedPersonal()"></div>

        <div class="info font_text">
          Add this dynamic button to your website to let your callers know when you are ONLINE and OFFLINE. </div>
      </div>

    </div>
  </div>
</div>

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { PayService } from 'src/app/kpay/pay.service';

import { GotConverge, NotifyError, NotifyUser, ShowCC } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { accCreatedAt, gotConverge, gotInvoices, gotBillInfo, gotReps, gotUsers, showCC, gotPlans, gotCode, convergeAcc } from '../../pages.selectors';


import { KeyziiService } from 'src/app/wss/keyzii.service';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';



@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent {
  search: string = ""
  invoices: any = [];
  orig_invoices: any = [];
  converge: any = {}
  cards: any[] = []
  email: string = ""

  cc_zip: string = ""
  cc_state: string = ""
  bShowStates: boolean = false;
  cc_address: string = ""
  cia_name: string = ""
  first_name: string = ""
  last_name: string = ""
  cc_number: string = "" //"4159288888888882"
  cc_exp: string = ""
  cc_cvv: string = ""
  sel_card: any = {}

  years: any[] = []
  cc_logo: string | undefined
  card_prompt: string = "Change Card"
  plan_ends: Date | undefined
  minDate: string = "";
  selected_plan: any = {}
  code: any
  code_desc: string = ""
  code_epx: string = ""
  non_code_users: number = 0

  bReady: boolean = false
  bChanged: boolean = false
  @ViewChild('token') token_field: ElementRef | undefined;

  pay_token: string | undefined

  users: any = {}
  reps: any = {}
  num_users: string = "1"
  min_users: number = 1
  num_assets: number = 100
  bYearly: boolean = false
  amount: number = 0
  amount_user: number = 0
  period: string = "Monthly";

  subscriptions: any[] = []

  acc_created_at: Date | undefined
  trail_expires: Date | undefined
  exp_propmt: string = "Free trial ends on"
  in_code: string = ""
  show_search: boolean = true
  bFreeTrial: boolean = false
  bInvalidUsers: boolean = false

  @ViewChild('scrn') scrn: ElementRef | undefined;

  @Input() set showSearch(v: boolean) {
    this.show_search = v
  }
  @Output() showSearchCahnged: EventEmitter<boolean> = new EventEmitter();

  cc_page: string | undefined = "done"// "process" //"billing" //
  cc_error: string | undefined
  public us_states: any[] = [{ "name": "Alabama", "abr": "AL" }, { "name": "Alaska", "abr": "AK" }, { "name": "Arizona", "abr": "AZ" }, { "name": "Arkansas", "abr": "AR" }, { "name": "California", "abr": "CA" }, { "name": "Colorado", "abr": "CO" }, { "name": "Connecticut", "abr": "CT" }, { "name": "Delaware", "abr": "DE" }, { "name": "Florida", "abr": "FL" }, { "name": "Georgia", "abr": "GA" }, { "name": "Hawaii", "abr": "HI" }, { "name": "Idaho", "abr": "ID" }, { "name": "Illinois", "abr": "IL" }, { "name": "Indiana", "abr": "IN" }, { "name": "Iowa", "abr": "IA" }, { "name": "Kansas", "abr": "KS" }, { "name": "Kentucky", "abr": "KY" }, { "name": "Louisiana", "abr": "LA" }, { "name": "Maine", "abr": "ME" }, { "name": "Maryland", "abr": "MD" }, { "name": "Massachusetts", "abr": "MA" }, { "name": "Michigan", "abr": "MI" }, { "name": "Minnesota", "abr": "MN" }, { "name": "Mississippi", "abr": "MS" }, { "name": "Missouri", "abr": "MO" }, { "name": "Montana", "abr": "MT" }, { "name": "Nebraska", "abr": "NE" }, { "name": "Nevada", "abr": "NV" }, { "name": "New Hampshire", "abr": "NH" }, { "name": "New Jersey", "abr": "NJ" }, { "name": "New Mexico", "abr": "NM" }, { "name": "New York", "abr": "NY" }, { "name": "North Carolina", "abr": "NC" }, { "name": "North Dakota", "abr": "ND" }, { "name": "Ohio", "abr": "OH" }, { "name": "Oklahoma", "abr": "OK" }, { "name": "Oregon", "abr": "OR" }, { "name": "Pennsylvania", "abr": "PA" }, { "name": "Rhode Island", "abr": "RI" }, { "name": "South Carolina", "abr": "SC" }, { "name": "South Dakota", "abr": "SD" }, { "name": "Tennessee", "abr": "TN" }, { "name": "Texas", "abr": "TX" }, { "name": "Utah", "abr": "UT" }, { "name": "Vermont", "abr": "VT" }, { "name": "Virginia", "abr": "VA" }, { "name": "Washington", "abr": "WA" }, { "name": "West Virginia", "abr": "WV" }, { "name": "Wisconsin", "abr": "WI" }, { "name": "Wyoming", "abr": "WY" }]

  billed_today: number = 0
  done_prompt: string = "Payment Successful"

  converge_acc: string | undefined
  constructor(
    public pay_service: PayService,
    public pagesState: Store<PagesState>,
    public canvasStore: Store<CanvasState>,
    public keyzii_service: KeyziiService,
  ) {
    console.log("billing")

    // let t = new Date().getTime()
    // for (let i = 0; i < 7; i++) {
    //   this.invoices.push({
    //     date: new Date(t),
    //     amount: 150
    //   })
    //   t -= 1000 * 60 * 60 * 24 * 30
    // }
    this.subscriptions.push(this.pagesState.select(convergeAcc).subscribe((converge_acc: string | undefined) => {
      this.converge_acc = converge_acc
    }))

    this.subscriptions.push(this.pagesState.select(gotCode).subscribe((code: any) => {
      if (code) {
        this.gotCode(code)

      }
    }))

    this.subscriptions.push(this.pagesState.select(gotPlans).subscribe((plans: any[]) => {
      if (plans.length > 0) {
        this.selected_plan = plans[0]
        this.num_users = this.selected_plan.num_users
        if (this.code && this.num_users < this.code.users) {
          this.num_users = this.code.users
        }
        this.setCost()
      }
    }))
    this.subscriptions.push(this.pagesState.select(showCC).subscribe((show_cc: string | undefined) => {
      this.cc_page = show_cc //"billing" // "cancel" // "done"// "process" //
      this.cc_error = undefined
    }))
    this.subscriptions.push(this.pagesState.select(gotUsers).subscribe((users: any[]) => {
      this.users = users
      this.setMinUsers()
    }))
    this.subscriptions.push(this.pagesState.select(gotReps).subscribe((reps: any[]) => {
      this.reps = reps
      this.setMinUsers()
    }))
    this.subscriptions.push(this.pagesState.select(accCreatedAt).subscribe((acc_created_at: Date | undefined) => {
      if (acc_created_at) {
        this.acc_created_at = acc_created_at
      }
    }))
    this.subscriptions.push(this.pagesState.select(gotInvoices).subscribe((invoices: any) => {
      if (invoices) {
        this.gotInvoices(invoices)
      }
    }))
    this.subscriptions.push(this.pagesState.select(gotBillInfo).subscribe((bill_info: any) => {
      if (bill_info) {
        this.email = bill_info.email
        this.cia_name = bill_info.cia_name
        this.cc_address = bill_info.cc_address
        this.cc_zip = bill_info.cc_zip
        this.cc_state = bill_info.cc_state
      }
    }))

    this.subscriptions.push(this.pagesState.select(gotConverge).subscribe((converge: any) => {
      if (converge) {
        this.converge = converge;
        if (converge.cards) {
          this.cards = converge.cards
          this.sel_card = this.cards[this.converge.select]
          if (this.sel_card) {
            let cc_number = this.sel_card.ssl_card_number
            this.cc_logo = this.getCCLogo(cc_number)
          }
        }
      }
    }))
    // if (window.location.host.indexOf("localhost") >= 0) {
    //   this.setDevData()
    // }
  }



  ccChange() { //subclassed

  }
  setDevData() {

    setTimeout(() => {
      if (window.location.host.indexOf("localhost") >= 0
        || window.location.host.indexOf("callvendi.com") >= 0) {
        this.cia_name = "acme"
        this.cc_address = "addr"
        this.cc_zip = "12122"
        this.cc_state = "Ca"
        this.email = "sergio@asci.us"
        this.first_name = "Sergio"
        this.last_name = "Fdez"
        this.cc_number = "6011000000000004"
        this.cc_exp = "2027-12"
        this.cc_cvv = "123"
        this.changed()
      }
      // else {
      // this.cia_name = "Venditio"
      // this.cc_address = "700 West E St"
      // this.cc_zip = "92101"
      // this.cc_state = "Ca"
      // this.email = "sergio@asci.us"
      // this.first_name = "Sergio"
      // this.last_name = "Fernandez"
      // this.cc_number = "371384876955123"
      // this.cc_exp = "2027-12"
      // this.cc_cvv = "3891"
      // }

    })
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {
    this.setCost()
    // this.getInvoices()
  }
  // getInvoices() {
  //   // this.pay_service.getInvoices()
  // }
  gotInvoices(invoices: any[]) {
    if (invoices) {
      this.invoices = invoices
      if (this.search == "") {
        this.orig_invoices = invoices
      }
      if (invoices.length > 0) {


        let invoice = invoices[0]
        this.plan_ends = new Date(invoice.next_date)

        if (invoice.amount == 0) {
          // this.num_users = invoice.num_users
          this.done_prompt = "keyzii plan updated successfully!"
          this.billed_today = 0
        }
        if (this.converge && this.invoices) {
          if (this.converge.ssl_card_short_description == "AMEX") {
            this.cc_logo = "assets/images/cc_amex.svg"

          }
        }
      }
    }
  }
  getCCLogo(cc_number: string) {
    let cc_logo = "assets/images/cc_amex.svg"
    if (cc_number.startsWith('4')) {
      cc_logo = "assets/images/cc_visa.svg"
    } else if (cc_number.startsWith('5')) {
      cc_logo = "assets/images/cc_mc.svg"
    } else if (cc_number.startsWith('6')) {
      cc_logo = "assets/images/cc_disc.svg"
    } else if (cc_number.startsWith('300') || cc_number.startsWith('305') || cc_number.startsWith('36') || cc_number.startsWith('38') || cc_number.startsWith('39')) { //300–305, 36, 38, or 39
      cc_logo = "assets/images/cc_diners.svg"
    }
    return cc_logo
  }
  setMinDatel() {

    var dtToday = new Date();

    var month: number = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    let smonth = month.toString();
    if (month < 10)
      smonth = '0' + smonth;
    let sday = day.toString();
    if (day < 10)
      sday = '0' + sday

    this.minDate = year + '-' + smonth + '-' + sday;


  }


  changeUsers($event: any) {
    this.bChanged = true
    let num_users = this.num_users.replace(/(?![0-9])./gmi, '')
    // if (num_users.length == 0) {
    //   num_users = this.min_users.toString()
    // }
    // if (num_users != this.num_users) {
    //   this.num_users = num_users
    // }

    let code_users = 0
    if (this.code) {
      code_users = this.code.users
    }

    if (parseInt(this.num_users) + code_users < this.min_users) {
      this.bInvalidUsers = true
      // this.num_users = (this.min_users - code_users).toString()
      this.pagesState.dispatch(new NotifyError("Remove users in the users tab "))
    } else {
      this.bInvalidUsers = false
      this.setCost()
    }
  }
  setMinUsers() {
    let num_users = 0
    if (this.users) {
      let uk = Object.keys(this.users)
      num_users += uk.length
    }
    if (this.reps) {
      let rk = Object.keys(this.reps)
      num_users += rk.length
    }
    this.min_users = num_users
    if (parseInt(this.num_users) < num_users) {

      this.num_users = num_users.toString()
    }
    let sel_plan_users: number = 0
    if (this.selected_plan) {
      sel_plan_users = this.selected_plan.num_users
    }
    if (this.code && this.code.users) {
      if (this.code.users > sel_plan_users) {
        sel_plan_users = this.code.users
        this.num_assets = sel_plan_users * 100
      }
    }
    if (this.selected_plan.free) {
      this.num_assets = 5
    }
    else {
      if (parseInt(this.num_users) > sel_plan_users) {
        this.num_assets = num_users * 100
      }
    }
    this.setCost()
  }
  gotCode(code: any) {
    this.code = code
    if (!this.code.users) {
      console.error("!this.code.users")
    }
    if (this.num_users < this.code.users) {
      this.num_users = this.code.users
    }
    if (code.name == "rockethub") {
      if (code.users == 1) {
        this.code_desc = "RH Personal"
      } else if (code.users == 10) {
        this.code_desc = "RH Entrepreneur"
      } else {
        this.code_desc = "RH Agency"
      }
      this.code_epx = "Lifetime"
    }

    this.setCost()
  }
  setCost() {
    if (this.selected_plan && this.selected_plan.created_at && this.num_users.length > 0) {

      //this.min_users The number of existing reps and users
      //plan_user, the number of paid users
      //non_code_users, the users not included in the code
      //num_users the input field

      let num_users = parseInt(this.num_users)

      let paid_users = num_users
      if (this.code) {
        if (this.code && this.code.users > num_users) {
          num_users = this.code.users
        }
        if (this.min_users > num_users) {
          num_users = this.min_users
        }

        if (this.code.users) {
          paid_users -= this.code.users
          if (paid_users < 0) {
            paid_users = 0
          }

        } else {
          console.error("No this.code.users")
        }
      }

      this.bYearly = this.selected_plan.bYearly
      let bYearly = this.bYearly
      let billed_today = 0
      let bReady = false
      let bFreeTrial = false

      let costs = 80 //1st
      if (paid_users == 0) {
        costs = 0
      } else if (paid_users >= 5) {
        costs = 50 * 5 + 30 * (paid_users - 5)
      } else if (paid_users == 4) {
        costs = 220
      } else {
        costs = 80 + 50 * (paid_users - 1)
      }

      let year_cost = costs * 12 * .85
      if (bYearly) {
        this.period = "Yearly"
      } else {
        this.period = "Monthly"
      }
      let invoice = Object.assign({}, this.invoices[0])
      let prev_invoice = this.invoices[1]
      if (invoice) {

        let end = new Date(invoice.next_date)
        let now = new Date()

        if (!prev_invoice) {
          billed_today = 0
          bFreeTrial = true
        } else {//padi plan

          if (prev_invoice.concept != "Free trial") {
            if (end > now) {//prorate it
              let new_cost = costs
              if (invoice.bYearly) {
                new_cost = year_cost
              }

              if (new_cost > this.selected_plan.amount) {
                //Find the first full term invoice
                for (let i = 0; i < this.orig_invoices.length; i++) {
                  invoice = this.orig_invoices[i]
                  if (!invoice.prorated) {
                    break;
                  }
                }

                let start_ms = new Date(invoice.created_at).getTime()
                let ends_ms = new Date(invoice.next_date).getTime()
                let invoice_ms = ends_ms - start_ms
                let invoice_days = invoice_ms / (24 * 60 * 60 * 1000)

                //cost differences
                let prev_amount = this.selected_plan.amount
                let new_amount = new_cost
                let dif_cost = new_amount - prev_amount
                let day_cost = dif_cost / invoice_days

                //Find the number of remaining days
                let now_ms = now.getTime()
                let dif_ms = ends_ms - now_ms
                let remaining_days = Math.floor(dif_ms / (24 * 60 * 60 * 1000))

                billed_today = Math.floor(day_cost * remaining_days)

                bReady = billed_today > 0
              } else {
                billed_today = 0
              }
            } else { //Full cost of the invoice
              billed_today = costs
              bReady = billed_today > 0

            }
          } else {
            invoice.amount = costs;
            invoice.num_users = paid_users
            bReady = this.cards.length > 0

            let orig_invoices = Object.assign([], this.orig_invoices)
            orig_invoices[0] = invoice
            this.orig_invoices = orig_invoices

            // if(paid_users!=num_users){
            //
            // }
          }
        }
        if (invoice.overdue) {
          for (let i = 0; i < this.orig_invoices.length; i++) {
            let over_inv = this.orig_invoices[i]
            if (over_inv.overdue) {
              billed_today += over_inv.amount
            } else {
              break;
            }
          }
          if (billed_today > 0) {
            if (this.cards.length == 0) {

              bReady = this.changedCCData()
            } else {
              bReady = true
            }
          }
        }
        if (this.num_users && this.billed_today == 0 && invoice.amount == 0 && parseInt(this.selected_plan.num_users) != parseInt(this.num_users)) {
          if (this.cards.length == 0) {
            bReady = this.changedCCData()
          } else {
            bReady = true
          }
        }
        if (!bReady && this.selected_plan.num_users != this.num_users) {
          bReady = this.cards.length > 0
        }
        //If!CC & cc_number then ready should be on
        if (!bReady && bFreeTrial) {
          bReady = this.changedCCData()
        }

      }
      setTimeout(() => {
        this.bFreeTrial = bFreeTrial
        // this.num_users = num_users.toString()
        this.non_code_users = paid_users
        this.num_assets = num_users * 100
        this.bYearly = bYearly
        if (this.bYearly) {
          this.amount = year_cost
        } else {
          this.amount = costs
        }
        if (paid_users > 0) {
          this.amount_user = Math.floor(this.amount / paid_users)
        } else {
          this.amount_user = 0
        }
        this.billed_today = billed_today
        if (bReady) { //make sure somthing changed before setting the ready btn
          bReady = this.bChanged
        }
        this.bReady = bReady
      })
    }
  }
  setAnualy(val: boolean) {
    if (this.bYearly != val) {
      if (this.selected_plan) {
        let selected_plan = Object.assign({}, this.selected_plan)
        selected_plan.bYearly = val
        this.selected_plan = selected_plan
      }
      this.setCost()
    }
  }
  openCC() {
    this.pagesState.dispatch(new ShowCC("billing"))
  }

  showUpdate() {
    this.show_search = false;
    this.showSearchCahnged.emit(this.show_search)
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }
  closeIt() {
    this.pagesState.dispatch(new ShowCC(undefined))
  }
  changed() {
    this.bChanged = true
    this.cc_logo = this.getCCLogo(this.cc_number)
    this.bReady = this.changedCCData()
  }

  changedCCData() {

    if (
      this.cc_address.length < 1
      || this.cc_zip.length < 1
      || this.cc_state.length < 1

      || this.first_name.length < 1
      || this.last_name.length < 1
      || this.cc_number.length < 1
      || this.cc_cvv.length < 3
      || this.cc_exp.length < 6) {

      return false
    }
    return this.validateCC()
  }
  validateCC() {

    // Remove all whitespaces from card number.
    let cc_number = this.cc_number.replace(/\s/g, '');
    if (cc_number.length > 14) {

      // 1. Remove last digit;
      const lastDigit = Number(this.cc_number[this.cc_number.length - 1]);

      // 2. Reverse card number
      const reverseCardNumber = this.cc_number.slice(0, this.cc_number.length - 1).split('').reverse().map(x => Number(x));
      let sum = 0;

      // 3. + 4. Multiply by 2 every digit on odd position. Subtract 9 if digit > 9
      for (let i = 0; i <= reverseCardNumber.length - 1; i += 2) {
        reverseCardNumber[i] = reverseCardNumber[i] * 2;
        if (reverseCardNumber[i] > 9) {
          reverseCardNumber[i] = reverseCardNumber[i] - 9;
        }
      }

      // 5. Make the sum of obtained values from step 4.
      sum = reverseCardNumber.reduce((acc, currValue) => (acc + currValue), 0);

      // 6. Calculate modulo 10 of the sum from step 5. and the last digit. If it's 0, you have a valid card number :)
      return ((sum + lastDigit) % 10 === 0);
    }
    return true
  }
  downgrade() {
    this.pagesState.dispatch(new ShowCC("cancel"))
  }
  filterName(val: string) {
    this.search = val;
    this.pay_service.getInvoices(this.search)
  }
  formatExpDate(exp: string) {
    return exp.slice(0, 2) + "/" + exp.slice(-2)
  }
  addCard() {
    this.pagesState.dispatch(new ShowCC("new"))

    // if (this.converge && this.converge.cards && this.converge.cards.length > 0) {
    //   this.pagesState.dispatch(new ShowCC("new"))
    // } else {
    //   this.openCC()
    // }
  }
  selCard(i: number) {
    let converge = {
      cards: this.converge.cards,
      select: i
    }
    this.pagesState.dispatch(new GotConverge(converge))


    this.pay_service.selectCC(i)
  }
  async   download(invoice: any) {
    try {
      let pdfData = await this.pay_service.createInvoicePDF(invoice)
      if (pdfData) {
        this.pay_service.downloadContract(pdfData, "keyzii.pdf")
        this.pagesState.dispatch(new NotifyUser("invoice downloaded"))
      }
    } catch (e) {
      this.pagesState.dispatch(new NotifyError("error building pdf " + e))
    }
  }
}

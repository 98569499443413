<div class="out">
  <!-- <k-button class="schedule" background="#8F5DFF" color="white" text="Book a Call" cmd="schedule"></k-button> -->

  <div class="btns">
    <k-button min_width="40" min_height="40" class="btn" background="#ECECEC" icon="settings.svg" (click)="settings()"></k-button>
    <k-button min_width="40" min_height="40" *ngIf="bAudioOn" background="#ECECEC" class="btn" icon="mic.svg" (click)="setAudio(false)"></k-button>
    <k-button min_width="40" min_height="40" *ngIf="!bAudioOn" background="#ECECEC" class="btn" icon="mic_off.svg" (click)="setAudio(true)"></k-button>

    <k-button min_width="40" min_height="40" *ngIf="bShowHangUp" class=" btn" background="#ECECEC" icon="profile_white.svg" (click)="askEmail()"></k-button>
    <!-- <k-button min_width="40" min_height="40" *ngIf="bShowHangUp && bASskingForEmail" class=" btn" background="#ECECEC" icon="profile.svg" (click)="askEmail()"></k-button> -->


    <k-button min_width="40" min_height="40" *ngIf="bVideoOn" class="btn" icon="camera_on.svg" background="#ECECEC" (click)="setVideo(false)"></k-button>
    <k-button min_width="40" min_height="40" *ngIf="!bVideoOn" class="btn" icon="camera_off.svg" background="#ECECEC" (click)="setVideo(true)"></k-button>

    <k-button min_width="40" min_height="40" *ngIf="!bShareOn" background="#ECECEC" class=" btn" icon="share.svg" (click)="share()"></k-button>
    <k-button min_width="40" min_height="40" *ngIf="bShareOn" background="#ECECEC" class=" btn" icon="share_off.svg" (click)="stopShare()"></k-button>

    <phone-hang-up *ngIf="bShowHangUp" class=" btn" (click)="hangUp()"></phone-hang-up>
    <status *ngIf="bShowHangUp" class="have_caller_info" [ok]="contactInfo"></status>
  </div>
  <k-button min_width="40" min_height="40" *ngIf="bShowChat" class="chat" background="#151515" icon="arrow_down_white.svg" (click)="closeChat()"></k-button>
  <k-button min_width="40" min_height="40" *ngIf="!bShowChat" class="chat" background="#151515" icon="chat.svg" internal=true (click)="openChat()"></k-button>

  <k-button min_width="40" min_height="40" class="white_board" [background]="white_board_btn_back" icon="pencil.svg" (click)="whiteBoard()"></k-button>


  <div class="kpay" *ngIf="converge_acc&&bShowHangUp && !bShowDots && !ruuning_kpay " (click)="kpay()">
    <img src="assets/images/keyzii.svg" class="kpay_l" alt="">
    Pay
  </div>
  <div class="kpay kpay_c" *ngIf="converge_acc&&bShowHangUp && !bShowDots && ruuning_kpay" (click)="close_kpay()">
    Cancel
    <img src="assets/images/kz_sm_wh.svg" class="kpay_cl" alt="">
    <div class="kpay_ctx">
      pay
    </div>
  </div>
  <div *ngIf="bShowChatNotification" class="chat-noti">

  </div>
  <div *ngIf="bShowDots" class="stop_sharing" (click)="close()">
    Stop sharing
    <div class="strop_circle">
      <img class="abs_center" src="assets/images/stop.svg">
    </div>
  </div>
</div>

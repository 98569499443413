<div class="out">
  <div class="margin">
    Do you want to change to: {{time_zone.text}}
  </div>

  <div class="abs_center yn_l">
    <k-button class="btn1" text="Yes" background="#fff" color="#000" (click)="change()"></k-button>
    <k-button text="No" background="#000" color="#fff" (click)="cancel()"></k-button>
  </div>


</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { OpenCloseComponent } from './open-close/open-close.component';
import { OptionComponent } from './option/option.component';
import { ControlsModule } from '../controls/controls.module';
import { PagesModule } from '../pages/pages.module';



@NgModule({
  declarations: [
    LeftMenuComponent,
    OpenCloseComponent,
    OptionComponent,
  ],
  imports: [
    CommonModule,
    ControlsModule,
    PagesModule
  ],
  exports: [
    LeftMenuComponent
  ]
})
export class LeftMenuModule { }

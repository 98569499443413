<div class="out">
  <img class="top_logo" src="assets/images/keyzii_logo_white1.svg">

  <div *ngIf="page_no==1" class="body" [ngClass]="{bphone:isPhone}">
    <div class="left" [ngClass]="{phone:isPhone}">
      <div class="left_body">
        <div class="center font_title ft">Create Account</div>

        <div class="center" (click)="backToLogin()"> Already have an account?<span class="signup"> Log in </span> </div>
        <div class="ms_wraper" (click)="msLogin()">
          <img src="assets/images/ms.png" class="google_logo ms_logo" alt="">
          <div class="google_tx">Sign in with Microsoft</div>
        </div>
        <div class="google_wraper" (click)="logInWithGoogle()">
          <img src="assets/images/google_lg.svg" class="google_logo" alt="">
          <div class="google_tx"> Sign up with Google</div>
        </div>

        <div class="center"> or </div>

        <div class="dos_f">
          <input class="in in2_1" type="text" placeholder="Name" name="" value="" [(ngModel)]="name">
          <input class="in in2_1 in2_2" type="text" placeholder="Last Name" name="" value="" [(ngModel)]="last_name">
        </div>
        <input class="in" type="text" placeholder="Company" name="" value="" [(ngModel)]="company">
        <input class="in" [ngClass]="{invalid:bInvalidEmail}" type="email" placeholder="Email" name="" value="" [(ngModel)]="email" (ngModelChange)="changedEmail($event)">

        <div class="error">{{error_text }}</div>
        <div class="info">{{info_text}}</div>
        <div class="line_wrapper">
          You will receive an email to set your password
        </div>
        <k-button class="google" color="white" min_width="296" min_height="50" background="black" text="Sign up" (click)="signup()"></k-button>
      </div>
    </div>

  </div>
  <div *ngIf="page_no==2" class="body" [ngClass]="{bphone:isPhone}">
    <div class="abs_center tx_line">
      <img class="logo" src="assets/images/logo-animated-small.gif" width="20">
      creating account...
    </div>
    <div class="abs_center error error_2"> {{error_text }}</div>
    <k-button *ngIf="error_text" class="abs_center back_err" color="white" min_height="40" min_width="100" background="black" text="Back" (click)="page_no=1"></k-button>

  </div>
  <div *ngIf="page_no==3" class="body p3" [ngClass]="{bphone:isPhone}">


    <div class="ma_tp3 font_title ft"> Account Created Successfully!

    </div>
    <div class="ma_tp3 p3_st">
      <div class="p3_st1">
        Please check your email to activate your account and reset your password. Once you've reset your password, you can log in to your new account.
      </div>
    </div>
    <div class="abs_center resend">
      <div class="abs_center rst" (click)="signup()">
        Resend Email
      </div>
    </div>
    <div class="abs_center sc" (click)="support()">
      Contact support
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { NotifyUser } from '../pages.actions';

@Component({
  selector: 'app-singup',
  templateUrl: './singup.component.html',
  styleUrls: ['./singup.component.scss']
})

export class SingupComponent extends LoginComponent {
  page_no: number = 1
  backToLogin() {
    this.router.navigate(["login"]);
  }
  signup() {

    setTimeout(async () => {
      this.page_no = 2
      this.error_text = ""
      try {
        // await this.recaptcha_service.checkCaptcha("signup")
        await this.keyzii_service.signUp(this.email, this.name, this.last_name, this.company)
        this.page_no = 3
      } catch (e: any) {
        this.error_text = "There was a problem sending you the email "
      }
    })

    // this.info_text = "You will receive an email to set your password"
    // this.bShowSentEmail = true
    // this.pagesState.dispatch(new NotifyUser(this.info_text))


  }

  support() {
    window.open("https://www.keyzii.com/contact")
  }

}

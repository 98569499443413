<div class="out" [@openClose]="isOpen ? 'open' : 'closed'">
  <div class="menu">
    <div *ngIf="isOpen && router.url == '/hub'" class="alpha">
      Beta
    </div>

    <k-button *ngIf="!isOpen" color="#151515" background="#FFCE38" icon="support.svg" class="support_btn_r" (click)="support()">
    </k-button>
    <k-button *ngIf="isOpen" color="#151515" background="#FFCE38" min_width="147" min_height="50" icon="support.svg" text="Support" class="support_btn_l" fontSize="12px" fontWeight="100" (click)="support()">
    </k-button>


    <menu-open-close class="menu-open" [ngClass]="{'menu-close': !isOpen}" (closed)="closedIt($event)"></menu-open-close>

    <img *ngIf="isOpen" class="top_logo" [src]="img_src" alt="homepage" (click)="toggleDebug()" (load)="onLogoeLoad($event)" [style.width.px]="img_w" [style.height.px]="img_h">
    <img *ngIf="!isOpen" class="top_logo" [src]="small_img_src" alt="homepage" (click)="toggleDebug()">


    <img *ngIf="isOpen" class="btm_logo btm_logo_l" src="assets/images/powered.svg">
    <img *ngIf="!isOpen" class="btm_logo" src="assets/images/keyzii.svg">


    <div class="items">
      <app-option *ngFor="let menu of menus, let i = index" [image]="menu.image" [dark]="menu.dark" [text]="menu.text" [disabled]="menu.disabled" [index]="i" [(selected)]="sel_menu" [opened]="isOpen"></app-option>
    </div>



    <div class="mt">
    </div>

  </div>
  <live-flash *ngIf="bShowLiveCalls" [ngClass]="{live_calls_o:isOpen}" class="live_calls"></live-flash>
</div>

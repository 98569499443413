import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AccOverdue, GotBillInfo, GotCode, GotConverge, GotInvoices, GotPlans, NotifyError, NotifyUser, ShowCC } from "../pages/pages.actions";
import { PagesState } from "../pages/pages.model";

import { WSSService } from "../wss/wss.service";

import {
  PDFDocument,
  rgb,
  PDFFont,
  PDFPage,
  StandardFonts,
  asPDFName,
  PDFHexString,
} from 'pdf-lib'
import { callerEmail, callerFirstName, callerLastName, gotBillInfo, myLastName, repEmail, repName, showCC } from "../pages/pages.selectors";
import { WSSState } from "../wss/wss.model";
import { callId, customerId, lastMsg } from "../wss/wss.selectors";
import { kPayState } from "./kpay.model";
import { cancelLocalKPay, createAccInSvr, gotInvoice, planFromRep, runKPay, sendPaymentToSvr, targetUrl } from "./kpay.selectors";
import { CancelLocalKPay, CancelRemoteKPay, DoneKPay, SetAcc, SetBillInfo, SetCC, SetPlanFromCaller, SetResponse, SetTargetURL } from "./kpay.actions";

//declare var PayWithConverge: any;
declare var ConvergeEmbeddedPayment: any;
@Injectable({
  providedIn: 'root'
})
export class PayService {
  token: string | undefined
  bill_info: any
  customer_id: string | undefined
  caller_id: string = ""
  caller_first_name: string = ""
  caller_last_name: string = ""
  caller_email: string = ""

  rep_name: string = ""
  rep_last_name: string = ""
  rep_email: string = ""

  running: string | undefined
  target_url: string | undefined

  //step acc, plan, bill, process
  constructor(
    private httpClient: HttpClient,
    private pagesState: Store<PagesState>,
    private wss_service: WSSService,
    private wssState: Store<WSSState>,

    public kpayState: Store<kPayState>,
  ) {
    console.log("pay.service")

    //****************************************************************************
    // MVC code
    //****************************************************************************

    this.kpayState.select(runKPay).subscribe((running: string | undefined): void => {
      this.running = running
      let msg
      if (running) {
        msg = {
          k_pay: "start",
          running: running
        }
      } else {
        msg = {
          k_pay: "stop"
        }
      }
      this.wss_service.sendMessageToOtherMembers(msg)
    })

    this.kpayState.select(planFromRep).subscribe((plan: any) => {
      if (plan) {
        this.wss_service.sendMessageToOtherMembers({
          k_pay: "rep_plan",
          plan: plan
        })
      }
    })
    this.kpayState.select(gotInvoice).subscribe((invoice: any) => {
      if (invoice) {
        this.wss_service.sendMessageToOtherMembers({
          k_pay: "invoice",
          invoice: invoice
        })
      }
    })
    this.kpayState.select(createAccInSvr).subscribe((data: any) => {
      if (data) {
        this.createAccInSvr(data)
      }
    })
    this.kpayState.select(sendPaymentToSvr).subscribe((data: any) => {
      if (data) {
        this.sendPaymentToSvr(data)
      }
    })

    this.pagesState.select(lastMsg).subscribe((msg: any) => {
      if (msg) {
        if (msg.k_pay) {
          this.gotMsgFromCaller(msg)
        }
      }
    })
    this.kpayState.select(cancelLocalKPay).subscribe((bCancel: boolean) => {
      if (bCancel) {
        let msg = {
          k_pay: "cancel",
        }
        this.wss_service.sendMessageToOtherMembers(msg)
      }
    })
    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      if (!call_id) {
        this.kpayState.dispatch(new CancelLocalKPay())
      }
    })
    this.wssState.select(targetUrl).subscribe((target_url: string | undefined) => {
      this.target_url = target_url
    })



    this.pagesState.select(showCC).subscribe((show_cc: string | undefined) => {
      if (show_cc) {

        if (show_cc == 'kpay-menu') {
          this.contact_info = undefined
          this.plan = undefined
          this.invoice = undefined
        }
      }
    })

    this.pagesState.select(repName).subscribe((name: string | undefined) => {
      if (name) {
        this.rep_name = name
      }
    })
    this.pagesState.select(myLastName).subscribe((name: string | undefined) => {
      if (name) {
        this.rep_last_name = name
      }
    })
    this.pagesState.select(repEmail).subscribe((email: string | undefined) => {
      if (email) {
        this.rep_email = email
      }
    })
    this.pagesState.select(callerFirstName).subscribe((name: string | undefined) => {
      if (name) {
        this.caller_first_name = name
      }
    })
    this.pagesState.select(callerLastName).subscribe((name: string | undefined) => {
      if (name) {
        this.caller_last_name = name
      }
    })
    this.pagesState.select(callerEmail).subscribe((email: string | undefined) => {
      if (email) {
        this.caller_email = email
      }
    })
    this.wssState.select(customerId).subscribe((customer_id: string | undefined) => {
      this.customer_id = customer_id
    })
    let src = "https://api.convergepay.com/hosted-payments/Checkout.js"
    if (window.location.host.indexOf("localhost") >= 0
      || window.location.host.indexOf("callvendi.com") >= 0) {
      src = "https://api.demo.convergepay.com/hosted-payments/Checkout.js"
    }

    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.onload = () => {
      console.log(src + " loaded ")

    };

    script.onerror = (error: any) => {
      console.error("Error loading " + src)
    };
    document.getElementsByTagName('head')[0].appendChild(script);
    //messages are received in key-bar.component
    this.pagesState.select(gotBillInfo).subscribe((bill_info: any) => {
      this.bill_info = bill_info
    })

  }

  //****************************************************************************
  // MVC code
  //****************************************************************************


  async gotMsgFromCaller(msg: any) {
    console.log("<--  gotMsgFromCaller" + JSON.stringify(msg))
    if (msg && msg.k_pay == "acc") {
      this.kpayState.dispatch(new SetAcc(msg.acc))
    } else if (msg && msg.k_pay == "caller_plan") {
      this.kpayState.dispatch(new SetPlanFromCaller(msg.plan))
    }
    else if (msg && msg.k_pay == "get_save_cc_auth_token") {
      this.kpayState.dispatch(new SetBillInfo(msg.bill_info))
      this.sendSaveCCAuthToken(msg.cap_token, msg.amount)
    }
    else if (msg && msg.k_pay == "get_pay_token") {
      this.kpayState.dispatch(new SetBillInfo(msg.bill_info))
      this.sendPayAuthToken(msg.cap_token, msg.amount)

      console.log("-->  gotPaymentToken" + msg.cap_token)
    } else if (msg && msg.k_pay == "response") {
      this.kpayState.dispatch(new SetResponse(msg.response)) //that trigers the createAccInSvr selecto to send it to the server
    } else if (msg && msg.k_pay == "cancel") {
      this.kpayState.dispatch(new CancelRemoteKPay())
    } else if (msg && msg.k_pay == "plan_done") {
      if (this.target_url) {
        this.openTargetInCaller(this.target_url)
      }
    }
  }
  async sendSaveCCAuthToken(cap_token: string, amount: number) {
    try {
      if (!this.token) {
        this.token = "pending"
        this.token = await this.getSaveCCAuthToken(cap_token, amount, "client_pay")
      }
      if (this.token && this.token != "pending") {
        this.wss_service.sendMessageToOtherMembers({
          k_pay: "cc_auth_token",
          token: this.token
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  getSaveCCAuthToken(cap_token: string, amount: number, user_action: string) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        console.log("login with token ")

        var user_agent = navigator.userAgent;
        let rv: any = await this.httpClient.post('/token_auth', {
          amount: amount,
          cap_token: cap_token,
          user_agent: user_agent,
          user_action: user_action

        }).toPromise();
        resolve(rv.token)

      } catch (e: any) {
        reject(e.error);
      }
    })
  }
  async sendPayAuthToken(cap_token: string, amount: number) {
    try {
      if (!this.token) {
        this.token = "pending"
        this.token = await this.getPayAuthToken(cap_token, amount)
      }
      if (this.token && this.token != "pending") {
        this.wss_service.sendMessageToOtherMembers({
          k_pay: "pay_token",
          pay_token: this.token
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  getPayAuthToken(cap_token: string, amount: number) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        var user_agent = navigator.userAgent;
        let rv: any = await this.httpClient.post('/pay_token_auth', {
          amount: amount,
          cap_token: cap_token,
          user_agent: user_agent,
          user_action: "pay",
        }).toPromise();
        resolve(rv.token)

      } catch (e: any) {
        reject(e.error);
      }
    })
  }

  createAccInSvr(data: any) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.post('/cc_subscription', { //convege.js saveCCSubscription
          token: data.response,
          bill_info: data.bill_info,
          plan: data.plan,
          acc: data.acc
        }).toPromise();
        this.kpayState.dispatch(new SetTargetURL(rv.url))
        console.log("<-- cc_subscription " + rv)
        let msg = {
          k_pay: "email",
        }
        this.wss_service.sendMessageToOtherMembers(msg)
        this.kpayState.dispatch(new SetTargetURL(rv.url))
        //open the URL


      } catch (e: any) {
        console.error(e)
      }
    })
  }

  async openTargetInCaller(target_url: string) {
    let url = await this.wss_service.setkzCode(target_url)
    let asset = {
      asset_id: new Date().getTime(),
      bAdmin: false,
      // customer_id:"6692df35c53600249985507e"
      // name:"on board pro"
      // player_id:"6692df35c53600249985507f"
      type: "on_board_pro",
      url: url
    }

    this.wss_service.sendMessageToOtherMembers({ show_asset: asset })

  }

  sendPaymentToSvr(data: any) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.post('/payment', { //convege.js saveCCSubscription
          conv_pay: data.response,
          bill_info: data.bill_info,
          invoice: data.invoice,
          acc: data.acc
        }).toPromise();
        console.log("<-- payment " + rv)
        let msg = {
          k_pay: "payed",
        }
        this.wss_service.sendMessageToOtherMembers(msg)
        this.kpayState.dispatch(new DoneKPay())
      } catch (e: any) {
        console.error(e)
      }
    })
  }
  cancelInvoice() {
    this.kpayState.dispatch(new CancelLocalKPay())
  }




  tryToOpenPlan() {
    if (this.plan && this.contact_info) {    //Wait until the other side finished the contact
      this.wss_service.sendMessageToOtherMembers({
        k_pay: "plan",
        plan: this.plan
      })
    }
  }


  async  forwardPayment(payment: any) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.post('/payment', payment).toPromise();
        resolve(rv.token)
      } catch (e: any) {
        reject(e.error);
      }
    })
  }

  selectCC(i: number) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.httpClient.post('/sel_cc', { select: i }).toPromise();
        resolve()
      } catch (e: any) {
        reject(e.error);
      }
    })
  }
  savePayment(msg: any) {
    return new Promise<void>(async (resolve, reject) => {
      try {

        let body = {
          conv_pay: msg.payment,
          caller_id: msg.caller_id,
          call_id: msg.call_id,
          bill_info: msg.bill_info,
          caller_first_name: this.caller_first_name,
          caller_last_name: this.caller_last_name,
          caller_email: this.caller_email,
          invoice: msg.invoice,
          rep_name: this.rep_name,
          rep_last_name: this.rep_last_name,
          rep_email: this.rep_email
        }
        await this.httpClient.post('/payment', body).toPromise();
        resolve()
      } catch (e: any) {
        reject(e.error);
      }
    })
  }
  setInfo(info: any) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.post('/bill_info', info).toPromise();
        if (rv.converge) {
          this.pagesState.dispatch(new GotConverge(rv.converge))
        }
        if (rv.plans) {
          this.pagesState.dispatch(new GotPlans(rv.plans))
        }
        if (rv.invoices) {
          this.pagesState.dispatch(new GotInvoices(rv.invoices))
        }
        if (info.bill_info) {
          this.pagesState.dispatch(new GotBillInfo(info.bill_info))
        }
        resolve()
      } catch (e: any) {
        reject(e.error);
      }
    })
  }
  saveCCToken(token: any) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.post('/cc_token', token).toPromise();
        if (rv.converge) {
          this.pagesState.dispatch(new GotConverge(rv.converge))
        }
        if (rv.plans) {
          this.pagesState.dispatch(new GotPlans(rv.plans))
        }
        if (rv.invoices) {
          this.pagesState.dispatch(new GotInvoices(rv.invoices))
        }
        if (token.info && token.info.bill_info) {
          this.pagesState.dispatch(new GotBillInfo(token.info.bill_info))
        }
        resolve()

      } catch (e: any) {
        reject(e.error);
      }
    })
  }
  /*getTransactions() {
    return new Promise<any[]>(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.get('/cc_transactions').toPromise();
        resolve(rv.token)

      } catch (e: any) {
        reject(e.error);
      }
    })
  }*/
  async getInvoices(search: string) {
    try {
      let rv: any = await this.httpClient.get('/invoices?search=' + search).toPromise();
      this.pagesState.dispatch(new GotInvoices(rv))
    } catch (e: any) {
      console.error(e.error);
    }

  }
  public async sendPromoCode(promo_code: string, write: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = '/code/' + promo_code
        if (write) {
          url += "?write=true"
        }
        let rv: any = await this.httpClient.get(url).toPromise();
        let invoices = rv.invoices

        if (write) {
          this.pagesState.dispatch(new GotInvoices(rv.invoices))
          this.pagesState.dispatch(new GotCode(rv.code))
        }
        resolve(rv)

      } catch (e: any) {
        let err = e.error.error
        if (!err) {
          err = e.error
        }
        this.pagesState.dispatch(new NotifyError(err))
        reject(e.error);
      }
    })
  }
  public async removeAccount() {
    return new Promise(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.post('/del_acc', { customer_id: this.customer_id }).toPromise();
        resolve(rv)
        this.pagesState.dispatch(new NotifyUser("Account deleted"))
      } catch (e: any) {
        this.pagesState.dispatch(new NotifyError(e.error))
        reject(e.error);
      }
    })
  }
  public async setFree() {
    return new Promise(async (resolve, reject) => {
      try {
        let rv: any = await this.httpClient.get('/set_free').toPromise();
        resolve(rv)
        this.pagesState.dispatch(new NotifyUser("Account in Free plan"))
      } catch (e: any) {
        this.pagesState.dispatch(new NotifyError(e.error))

        reject(e.error);
      }
    })
  }

  // openPay(type: string) {
  //   this.contact_info = undefined
  //   this.invoice = undefined
  //   this.token = undefined
  //   this.wss_service.sendMessageToOtherMembers({
  //     k_pay: type
  //   })
  // }
  /*
  info exist when called from credit-card.
  info-plan calls billingCtrl.updatePlan(info) info.bill_info,
  updatePlan may also create and invoice and pay it
  */
  async setCC(cap_token: string, card: any, info?: any) {
    return new Promise(async (resolve, reject) => {


      let cc_page = "process"
      let cc_error: string | undefined = ""

      let me = this
      var callback = {
        onError: function(error: any) {
          // console.log("error" + error);
          if (error.errorMessage) {
            cc_error = "Error " + error.errorMessage;
          } else {
            cc_error = "Error " + error
          }
          cc_page = "done"
          reject({ page: cc_page, error: cc_error })

        },
        onCancelled: function(response: any) {
          // console.log("cancelled");
          if (response.errorMessage) {
            cc_error = "Cancelled " + response.errorMessage;
          } else {
            cc_error = "Cancelled " + response
          }

          cc_page = "done"
          reject({ page: cc_page, error: cc_error })

        },
        onDeclined: function(response: any) {
          // console.log("declined " + JSON.stringify(response, null, '\t'));
          me.pagesState.dispatch(new NotifyError("CC Error " + response.ssl_result_message))
          if (response.errorMess) {
            cc_error = "Declined " + response.ssl_result_message;
          } else {
            cc_error = "Declined " + response
          }
          cc_page = "done"
          reject({ page: cc_page, error: cc_error })
        },
        onApproval: async function(response: any) {
          console.log("approval " + JSON.stringify(response, null, '\t'));
          try {

            let body = {
              token: response,
              info: info,
            }

            await me.saveCCToken(body)
            // me.pagesState.dispatch(new NotifyUser("CC tokenized"))
            cc_error = undefined
            cc_page = "done"
            me.pagesState.dispatch(new AccOverdue(false))

            resolve({ page: cc_page })
            // me.closeIt()
          } catch (e: any) {
            // me.pagesState.dispatch(new NotifyError("Save Error " + e))
            cc_error = "Save Error "
            if (e.error) {
              cc_error += e.error
            } else {
              cc_error += e
            }
            cc_page = "done"
            reject({ page: cc_page, error: cc_error })
          }
        }
      };
      try {
        let parts = card.cc_exp.split('-')
        let yy = parseInt(parts[0]) - 2000
        let mm = parts[1]

        let pay_token = await this.getSaveCCAuthToken(cap_token, card.amount, "set_cc")
        if (pay_token) {
          if (pay_token == "local") {
            setTimeout(async () => {
              let body = {
                token: {
                  ssl_account_balance: "0.00",
                  ssl_add_token_response: "Card Added",
                  ssl_avs_address: "addr",
                  ssl_avs_zip: "12122",
                  ssl_card_number: "40**********0002",
                  ssl_card_short_description: "VISA",
                  ssl_card_type: "CREDITCARD",
                  ssl_exp_date: "0824",
                  ssl_first_name: "a",
                  ssl_get_token: "N",
                  ssl_last_name: "s",
                  ssl_result: "0",
                  ssl_token: "4459079401220002",
                  ssl_token_format: "N",
                  ssl_token_provider: "V",
                  ssl_token_response: "SUCCESS",
                  ssl_transaction_type: "GETTOKEN",
                  ssl_txn_time: "07/20/2024 03:20:19 PM"
                },
                info: info,
              }
              await me.saveCCToken(body)
              // me.pagesState.dispatch(new NotifyUser("CC tokenized"))
              cc_error = undefined
              cc_page = "done"
              resolve({ page: cc_page })
            }, 1000)
            return
          }
          var paymentData = {
            ssl_txn_auth_token: pay_token,
            ssl_card_number: card.cc_number,
            ssl_exp_date: mm + "/" + yy.toString(),
            ssl_get_token: "y",
            ssl_add_token: "y",
            ssl_first_name: card.first_name,
            ssl_last_name: card.last_name,
            ssl_cvv2cvc2: card.cc_cvv,
            ssl_avs_address: card.cc_address,
            ssl_avs_zip: card.cc_zip
            // ssl_merchant_txn_id: merchanttxnid
            //Add company information and save it in client's
          };
          if (!paymentData.ssl_avs_address) {
            this.pagesState.dispatch(new NotifyError("add address"))
            return
          }
          if (!paymentData.ssl_avs_zip) {
            this.pagesState.dispatch(new NotifyError("add zip code"))
            return
          }
          // ConvergeEmbeddedPayment.getEFSToken(paymentData, callback);

          ConvergeEmbeddedPayment.pay(paymentData, callback);
          // PayWithConverge.open(paymentData, callback);
        }

      } catch (e) {
        reject(e)
      }
    })
  }

  async delCC(card: any) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let converge: any = await this.httpClient.put('/del_cc', card).toPromise();
        if (converge) {
          this.pagesState.dispatch(new GotConverge(converge))
        }

        resolve()

      } catch (e: any) {
        reject(e.error);
      }
    })
  }

  //****************************************************************************
  //  Invoice
  //****************************************************************************

  contact_info: any
  invoice: any
  plan: any
  contactReady(contact: any) { //<-- from caller
    this.contact_info = contact
    if (this.invoice) {
      this.tryToOpenInvoice()
    } else if (this.plan) {
      this.tryToOpenPlan()
    }
  }


  invoiceReady(invoice: any) {
    this.invoice = invoice
    this.plan = undefined
    this.tryToOpenInvoice()
  }
  tryToOpenInvoice() {
    if (this.invoice && this.contact_info) {    //Wait until the other side finished the contact
      this.wss_service.sendMessageToOtherMembers({
        k_pay: "invoice",
        invoice: this.invoice
      })
    }
  }


  saveAPICreds(creds: any) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.httpClient.post('/converge', creds).toPromise();
        resolve()
      } catch (e: any) {
        reject(e.error);
      }
    })
  }

  saveMerchantId(merchant_id: string) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.httpClient.post('/merchant_id', { merchant_id: merchant_id }).toPromise();
        resolve()
      } catch (e: any) {
        reject(e.error);
      }
    })
  }

  async  createInvoicePDF(inv: any) {
    return new Promise<Uint8Array>(async (resolve, reject) => {
      try {
        let invoice = Object.assign({}, inv)
        //check the billing info
        if (this.bill_info) {
          invoice.email = this.bill_info.email
          invoice.cia_name = this.bill_info.cia_name
          invoice.cc_address = this.bill_info.cc_address
          invoice.cc_zip = this.bill_info.cc_zip
          invoice.cc_state = this.bill_info.cc_state
        }

        invoice.period = "From " + new Date(inv.created_at).toLocaleDateString()
          + " to " + new Date(inv.next_date).toLocaleDateString()
        invoice.total = "$" + invoice.amount + ".00"
        const labels: any[] = [{
          name: "number",
          col: -68,
          line: 7,
          fs: 12
        }, {
          name: "created_at",
          col: -68,
          line: 8,
          fs: 12,
          date: true
        },
        {
          name: "cia_name",
          col: 6.2,
          line: 11,
          fs: 10
        },
        {
          name: "cc_address",
          col: 6.2,
          line: 15,
          fs: 10
        },
        {
          name: "cc_state",
          col: 6.2,
          line: 16,
          fs: 10
        },
        {
          name: "cc_zip",
          col: 6.2,
          line: 17,
          fs: 10
        },
        {
          name: "concept",
          col: 6.2,
          line: 24,
          fs: 10
        },
        {
          name: "period",
          col: 6.2,
          line: 25,
          fs: 10
        },
        {
          name: "total",
          col: -68,
          line: 35.6,
          fs: 12
        }]
        let pdfData = await fetch("assets/billing/invoice.pdf").then(res => res.arrayBuffer())
        if (pdfData) {
          try {
            // PDF Creation
            const pdfDoc = await PDFDocument.load(pdfData)

            const pdfFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

            const page = pdfDoc.getPage(0)

            let page_height = page.getHeight()
            let page_width = page.getWidth()

            let colum_space = 8
            let row_space = 18//842/16 page_height / 46

            for (let i = 0; i < labels.length; i++) {
              let label = labels[i]
              let top = page_height - label.line * row_space
              let text = invoice[label.name]
              // if (!text) {
              //   text = label.name + " " + label.col
              // }
              if (text) {
                if (label.date) {
                  let date = new Date(text)
                  text = date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short', // Use 'short' for abbreviated month name
                    day: 'numeric'
                  });
                }
                text = text.toString()
                let x = label.col * colum_space
                if (label.col < 0) {
                  const textWidth = pdfFont.widthOfTextAtSize(text, label.fs);
                  x *= -1
                  x -= textWidth
                }
                page.drawText(text, {
                  x: x,
                  y: top,
                  size: label.fs,
                  font: pdfFont,
                  //  color: rgb(0, 0.53, 0.71),
                })
              }
            }
            /*
            let fontSize = 12
                        let l = 1
                        for (let c = 1; c < 80; c++) {
                          let lbl = "l= " + l + " c= " + c
                          let top = page_height - l * row_space
                          page.drawText(lbl.toString(), {
                            x: c * colum_space,
                            y: top,
                            size: fontSize,
                            font: pdfFont,
                            //  color: rgb(0, 0.53, 0.71),
                          })
                          if (++l > 46) {
                            l = 1
                          }
                        }
            */

            const pdfBytes = await pdfDoc.save()
            resolve(pdfBytes)
            // PDF Modification
            // const pdfDoc = await PDFDocument.load(...)
            // const pages = pdfDoc.getPages()
            // pages[0].drawText('You can modify PDFs too!')
            // const pdfBytes = await pdfDoc.save()
          } catch (e) {
            console.error(pdfData.toString())
          }
        } else {
          reject("could not load the pdf")
        }
      } catch (e) {
        reject("could not load the pdf " + e)
      }
    })
  }
  async downloadContract(pdfBytes: Uint8Array, name: string) {

    console.log("Down load  it")
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    //  var enc = new TextEncoder();

    const blob = new Blob([pdfBytes]);
    const url1 = window.URL.createObjectURL(blob);
    a.href = url1;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url1);
    console.log("downloaded pdf ");
  }
  sendRequest(data: any) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.httpClient.post('/pay_req', data).toPromise();
        resolve()
      } catch (e: any) {
        reject(e.error);
      }
    })
  }
  async getPayments(page: number, page_size: number, agency: number, search: string) {
    return new Promise<any>(async (resolve, reject) => {

      try {
        let url: string = '/payments?&page=' + page + "&page_size=" + page_size
        if (agency) {
          url += "&agency=1"
        }
        if (search) {
          const encoded = encodeURI(search);
          url += "&search=" + encoded
        }

        let rv: any = await this.httpClient.get(url).toPromise();
        //  console.log("got history <-- " + JSON.stringify(rv))
        resolve(rv)
      } catch (e) {
        console.error("Error add_asset show " + e)
        reject(e)
      }

    })
  }
}

import { Component, } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { Store } from '@ngrx/store';
import { PagesState } from '../pages/pages.model';
import { customizeAccount, headerPath, isPhone, notifySnd, showAsset } from '../pages/pages.selectors';
import { AddAppointment } from '../pages/scheduling/calendar.actions';
import { CalendarState } from '../pages/scheduling/calendar.model';
import { WSSService } from '../wss/wss.service';
import { CanvasState } from '../canvas/canvas.model';
import { CanvasService } from '../canvas/canvas.service';
import { NotifySnd, SetHeaderTitle, ShowAsset, ShowFolder } from '../pages/pages.actions';
import { ToolService } from '../tools/tool.service';
import { WSSState } from '../wss/wss.model';
import { callId } from '../wss/wss.selectors';
import { selectToolOptions } from '../canvas/canvas.selectors';
import { SelectToolOpts } from '../canvas/canvas.actions';
declare var kzLib: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  isPhone: boolean = false;
  bRecording: boolean = false;
  img_src = "assets/images/keyzii_logo_primary.svg"
  btn_tx: string = "New"
  bShowingAsset: boolean = false;

  bShowingContract: boolean = false;
  bShowOptions: boolean = false;
  path: any[] = []
  asset: any = {}
  asset_name: string = ""
  bNotify: boolean = false;
  call_id: string | undefined

  opened_tool: string = ""
  bShowCloseAndRename: boolean = false
  small_img_src: string = "assets/images/keyzii.svg"
  company_logo: string = "assets/images/keyzii.svg"
  img_w: number = 0
  img_h: number = 0
  constructor(
    public router: Router,
    public pagesState: Store<PagesState>,
    public calendarStore: Store<CalendarState>,
    public wss_service: WSSService,
    public canvasStore: Store<CanvasState>,
    public canvas_service: CanvasService,
    private tools_service: ToolService,
    private wssState: Store<WSSState>,
  ) {
    console.log("header")
    this.pagesState.select(customizeAccount).subscribe((customize: any) => {
      if (customize) {
        if (customize.icon) {
          this.small_img_src = customize.icon
        }
        if (customize.logo) {
          // this.logo = customize.logo
          this.company_logo = customize.logo
        }
      }
    })
    this.canvasStore.select(selectToolOptions).subscribe((opt: any) => {
      this.bShowOptions = (opt.name == 'link-opts')
    })
    this.canvasStore.select(selectToolOptions).subscribe((tool_options: any) => {
      if (tool_options) {
        this.opened_tool = tool_options.name
        console.log("got selectToolOptions " + this.opened_tool)
      }
    })
    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
    })
    this.pagesState.select(headerPath).subscribe((path: any[]) => {
      setTimeout(() => this.path = path)
    })


    this.pagesState.select(showAsset).subscribe((asset: any) => {
      setTimeout(() => {
        if (asset) {
          if (asset.type != "folder") {
            if (asset.asset_id) {
              this.asset = asset
              this.asset_name = asset.name
              this.bShowingAsset = true
              this.setShowingPD()
            }
            this.bShowCloseAndRename = (this.asset.type == "matterport"
              || this.asset.type == "video"
              || this.asset.type == "youtube")
            return
          }
        }
        this.bShowCloseAndRename = false;
        this.bShowingAsset = false
        this.bShowingContract = false;
      })
    })

    this.pagesState.select(isPhone).subscribe((isPhone: boolean) => {
      setTimeout(() => this.isPhone = isPhone);
    })
    this.pagesState.select(notifySnd).subscribe((bNotify: boolean) => {
      setTimeout(() => this.bNotify = bNotify);
    })

    // this.calendarStore.select(newAppointment).subscribe((slot: any) => {
    //   if (slot) {
    //     this.bShowOptions == false;//btn_tx = "Cancel";
    //   } else {
    //     this.bShowOptions = true//btn_tx = "New"
    //   }
    // })
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.setShowingPD()

        this.calendarStore.dispatch(new AddAppointment(undefined))
      }
    })
  }
  ngAfterViewInit() {
    kzLib.addPage("login", this)
  }
  setShowingPD() {
    if (this.router.url != '/assets') {
      this.bShowCloseAndRename = false;
      this.bShowingContract = false;
    } else {
      if (this.asset) {
        if (this.asset.type == "contract") {
          this.bShowingContract = true;
        } else {
          this.bShowingContract = false;
        }
        this.bShowCloseAndRename = (this.asset.type == "matterport"
          || this.asset.type == "video"
          || this.asset.type == "youtube")
      }
    }
  }
  selLink(i: number) {

  }
  hangUp() {
    this.wss_service.hangUp()
  }


  savePdf() {
    this.canvas_service.savePDFAsset()
  }
  // emailContract() {
  //   this.canvas_service.sendPDFEmails()
  // }
  goTo(last: number) {
    let title: any[] = []
    for (let i = 0; i <= last; i++) {
      title.push(this.path[i])
    }

    this.pagesState.dispatch(new SetHeaderTitle(title))

  }
  async saveName() {
    try {
      if (this.asset.name != this.asset_name) {
        let asset = Object.assign({}, this.asset)
        asset.name = this.asset_name
        await this.tools_service.changeAsset(asset)
        this.asset = asset;
      }
    }
    catch (e: any) {
      console.error(e)
    }
  }
  close() {
    this.bShowingAsset = false
    this.bShowingContract = false
    this.pagesState.dispatch(new ShowAsset({}))
    this.wss_service.sendMessageToOtherMembers({ show_asset: {} })
  }
  toogleNotify() {
    this.pagesState.dispatch(new NotifySnd(!this.bNotify))
  }

  toogleSaveAs() {
    if (this.opened_tool == "save-as") {
      this.canvasStore.dispatch(new SelectToolOpts(""))
    } else {
      this.canvasStore.dispatch(new SelectToolOpts("save-as", { x: 90, y: 70 }))
    }
  }
  selPath(i: number) {
    let folder = this.path[i]
    this.pagesState.dispatch(new ShowFolder(folder.folder_id))
  }
  showLinks($event: any) {
    var viewportOffset = $event.srcElement.getBoundingClientRect();

    if (this.bShowOptions) {
      this.canvasStore.dispatch(new SelectToolOpts(""))
    } else {
      this.canvasStore.dispatch(new SelectToolOpts("link-opts", { x: viewportOffset.left }))
    }
  }
  onLogoeLoad($event: any) {
    console.log("loaded logo")
    let i_h = 42
    let i_w = $event.currentTarget.naturalWidth * i_h / $event.currentTarget.naturalHeight
    if (i_w > 150) {
      i_w = 150
      i_h = $event.currentTarget.naturalHeight * i_w / $event.currentTarget.naturalWidth
    } setTimeout(() => {
      this.img_w = i_w
      this.img_h = i_h
    })
  }
}

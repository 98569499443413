<div class="scrn" #scrn>
  <div class="abs_center out">
    <div class="margin">
      Are you sure you want to delete this assets?
    </div>
    <div class="margin gray">
      *Deleted assets will not be recoverable.
    </div>
    <div class="abs_center btnsyn">
      <k-button class="btn1" text="Yes" background="#fff" color="#F5423B" (click)="delAssets()"></k-button>
      <k-button text="No" background="#000" color="#fff" (click)="close()"></k-button>
    </div>
  </div>
</div>

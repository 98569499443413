<div class="out">
  <div class="calls" *ngFor="let call of calls, let i = index ">
    <div class="line">
      <div class="el tx">{{waiting[i]}}</div>
      <div class="el tx">{{call.caller_name}}</div>
      <div class="el tx">{{call.caller_email}}</div>
      <div class="el agent"> {{call.agent}} </div>
      <div class="filler">
      </div>
      <!-- <phone *ngIf="call.state == 'calling'" class="el phone"></phone> -->
      <phone-answer class="el " (click)="answer(i)"></phone-answer>
    </div>
  </div>
  <div *ngIf="calls.length==0" class="abs_center">
    No Calls in the queue
  </div>
</div>

import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatState } from 'src/app/chat/chat.model';
import { KVideoState } from 'src/app/k-video/k-video.model';
import { checkVideo, streams } from 'src/app/k-video/k-video.selectors';
import { PagesState } from 'src/app/pages/pages.model';
import { ToolService } from 'src/app/tools/tool.service';
import { WSSState } from 'src/app/wss/wss.model';
import { WSSService } from 'src/app/wss/wss.service';


import { CanvasService } from '../canvas.service';
import { MetaPageService } from '../meta-page.service';
import { fitImgInTarget } from 'src/app/utils';
import { CanvasComponent } from '../canvas/canvas.component';
import { ShowDrawTools } from '../canvas.actions';

@Component({
  selector: 'video-canvas',
  templateUrl: './video-canvas.component.html',
  styleUrls: ['../canvas/canvas.component.scss',
    '../white-board/white-board.component.scss',
    './video-canvas.component.scss']
})
export class VideoCanvasComponent extends CanvasComponent {
  video_roll: string = ""
  bMirrow: boolean = false

  @ViewChild('outComponent') outComponent: ElementRef | undefined
  @ViewChild('divContainer') container: ElementRef | undefined
  @ViewChild('videoElement') video: ElementRef | undefined;
  container_height: number = 500;
  container_width: number = 500;
  container_top: number = 0;
  container_left: number = 0;
  canvas_height: number = 500;
  canvas_width: number = 500;
  canvas_top: number = 0;
  canvas_left: number = 0;
  bSmall: boolean = false
  streams: any
  share_stream: MediaStream | undefined
  bCanvasReady: boolean = false
  @Input() set roll(r: string) {
    if (r) {
      this.video_roll = r
      this.bMirrow = (this.video_roll == 'me' || this.video_roll == "rep")

    } else {
      this.video_roll = ""
    }
    this.setVideoStream()
  }
  @Input() rv_l: number = 0

  @Input() set rv_r(r: number) {
    this.rv_r1 = r
    this.rv_rw = "calc(100% - " + r + "px)"
  }
  rv_r1: number = 0;
  rv_rw: string = "0"
  last_on_board: any

  wall_l: number = 0
  wall_t: number = 0
  wall_w: number = 0
  wall_h: number = 0

  twall_l: number = 0
  twall_t: number = 0
  twall_w: number = 0
  twall_h: number = 0
  constructor(
    public canvasStore: Store<CanvasState>,
    public wssState: Store<WSSState>,
    public wss_service: WSSService,
    public canvas_service: CanvasService,
    public pagesState: Store<PagesState>,
    public chatState: Store<ChatState>,
    public tools_service: ToolService,
    public meta_server: MetaPageService,
    private kVideoState: Store<KVideoState>,

  ) {
    super(canvasStore, wssState, wss_service, canvas_service, pagesState, chatState, tools_service, meta_server);
    console.log("video-canvas")
  }
  ngAfterViewInit() {

    let me = this
    if (this.video) {

      this.video.nativeElement.addEventListener('loadeddata', function($event: any) {
        console.log("Loaded videoHeight " + $event.srcElement.videoWidth + " " + $event.srcElement.videoHeight);
        if (!me.last_on_board) {
          me.setCanvas()
        }
      })
    }
    if (this.video_roll == "kuula") {
      this.setCanvas()
    }

    this.subscriptions.push(this.kVideoState.select(checkVideo).subscribe((check: any | undefined) => {
      if (check) {
        if (check.role == "local_share" || check.role == "remote_share" && this.share_stream) {
          delete this.share_stream
        }
      }
    }))
    this.subscriptions.push(this.kVideoState.select(streams).subscribe((streams: any) => {
      // console.log("k-video  stream for " + JSON.stringify(Object.keys(streams)))
      let share_stream = streams["local_share"]
      if (!share_stream) {
        share_stream = streams["remote_share"]
      }

      if (share_stream && !this.share_stream) {
        let vts = share_stream.getVideoTracks()
        if (vts.length > 0) {
          this.share_stream = share_stream
          let interval = setInterval(() => {
            if (this.video) {
              let newStream = new MediaStream()
              newStream.addTrack(vts[0])
              this.video.nativeElement.srcObject = newStream
              this.video.nativeElement.volume = 0
              clearInterval(interval)
            }
          }, 100)
        }
      } else if (this.share_stream) {
        if (this.share_stream && !share_stream) {
          this.share_stream = share_stream //This will remove the share element
        }
      } else {
        this.streams = streams
        this.setVideoStream()
      }

    }))

    // this.subscriptions.push(this.wssState.select(lastOnBoard).subscribe((msg: any) => {
    //   if (msg) {
    //     this.last_on_board = msg
    //     this.setOnBoardDims()
    //     this.showGrid()
    //   }
    // }))
  }
  @HostListener('window:resize', ['$event'])
  onResize(_event?: any) {
    if (this.last_on_board) {
      this.setOnBoardDims()
      // this.showGrid()
    } else {
      this.setCanvas()
    }
  }
  setOnBoardDims() {
    if (this.last_on_board) {
      let msg = this.last_on_board
      console.log(JSON.stringify(msg))
      if (this.outComponent && this.video) {
        setTimeout(() => {
          if (this.outComponent) {
            // this.aspect_ratio = msg.on_board.height / msg.on_board.width
            let bScaleWidh = true
            //This is s_out the view port
            let o_w = this.outComponent.nativeElement.clientWidth;
            let o_h = this.outComponent.nativeElement.clientHeight;

            //Try to map the area we care about, the share, to the view port
            let in_h = o_h
            let in_w = o_h * msg.on_board.width / msg.on_board.height

            if (in_w > o_w) { //now do it with the width
              in_w = o_w
              in_h = o_w / msg.on_board.width * msg.on_board.height
              bScaleWidh = false
            }
            //Get the scale, sould allway be the same
            let scale_w = in_w / msg.on_board.width
            let scale_h = in_h / msg.on_board.height

            //This is the window  scale it
            this.container_height = msg.w_height * scale_h
            this.container_width = msg.w_width * scale_w

            //move the container so that the box in in the out_s space
            if (bScaleWidh) {
              //This is the window  scale it
              this.container_height = msg.w_height * scale_h
              this.container_width = msg.w_width * scale_w

              this.container_left = (o_w - this.container_width - msg.on_board.x * scale_w) / 2
              // this.container_top = (o_h - this.container_height + msg.on_board.y * scale_h)
              let bottom = (msg.on_board.bottom) * scale_h
              this.container_top = o_h - bottom


              //now cover the part of the pictures
              this.wall_l = 0
              this.wall_t = 0;
              this.wall_h = o_h
              this.wall_w = (o_w - (this.container_left + this.container_width))

              this.twall_l = 0; //No right wall needed
              this.twall_t = 0;
              this.twall_h = 0;
              this.twall_w = 0;


              //now map the canvas to the visible part
              // this.canvas_width = this.container_width + this.container_left - this.wall_w * 2
              // // this.canvas_width = msg.on_board.width * scale_w
              this.canvas_left = this.wall_w //o_w - this.canvas_width - this.wall_w
              this.canvas_top = 0
              // this.canvas_height = o_h

              this.canvas_width = 1000 * scale_w//(msg.on_board.width * scale_w)
              this.canvas_height = 750 * scale_h
            } else {

              this.container_left = msg.on_board.left * scale_w * -1
              this.wall_l = 0
              this.wall_w = o_w

              this.wall_h = (o_h - msg.on_board.height * scale_h) / 2
              this.wall_t = o_h - this.wall_h
              this.twall_l = 0
              this.twall_w = o_w

              this.twall_t = 0;
              this.twall_h = this.wall_h;
              /*    CONTAINER
                header
                       -----------
                       |         |
                  pics |         |
                       -----------
                          cmds
              */


              this.container_top = o_h - msg.on_board.bottom * scale_h - this.wall_h


              this.canvas_left = 0
              this.canvas_top = this.twall_h

              this.canvas_width = 1000 * scale_w//(msg.on_board.width * scale_w)
              this.canvas_height = 750 * scale_h// o_h - this.wall_h - this.twall_h


            }

            console.log("canvas l " + this.canvas_left + " t " + this.canvas_top + " w " + this.canvas_width + " h " + this.canvas_height)
            if (!this.bCanvasReady && this.container) {
              console.log("canvas created")
              this.bCanvasReady = true;
              this.addCanvasToDiv(true, this.container.nativeElement, undefined, this.canvas_width, this.canvas_height)

            }


            // console.log("l " + this.container_left + " t " + this.container_top + " w " + this.container_width + " h " + this.container_height + " o_w " + o_w + " o_h " + o_h + " f " + free_space)
          }

        })
      }
    }
  }
  setVideoStream() {
    if (this.video) {
      if (this.video_roll && this.streams && this.streams[this.video_roll]) {
        this.video.nativeElement.srcObject = this.streams[this.video_roll]
      } else {
        this.video.nativeElement.srcObject = undefined
      }
    }
  }
  setCanvas() {
    setTimeout(() => {
      let ns = this.setVSize()

      this.container_height = ns.height
      this.container_width = ns.width
      this.container_top = ns.top
      this.container_left = ns.left

      this.canvas_height = ns.height
      this.canvas_width = ns.width
      this.canvas_top = ns.top
      this.canvas_left = ns.left



      if (!this.bCanvasReady && this.container) {
        this.bCanvasReady = true
        this.addCanvasToDiv(true, this.container.nativeElement, this.page_svg, ns.width, ns.height)
      }
    })
  }

  setVSize() { //keps a .75 aspect ratio

    let width = 0
    let top = 0;
    let left = 0
    let height = 0
    let out_w = 0
    let out_h = 0
    if (this.outComponent && this.container) {
      // if (this.video) {
      this.bSmall = this.outComponent.nativeElement.clientHeight < 200
      out_w = this.outComponent.nativeElement.clientWidth
      out_h = this.outComponent.nativeElement.clientHeight
      height = out_h

      let img_w = 640//this.video.nativeElement.videoWidth
      let img_h = 480//this.video.nativeElement.videoHeight
      if (this.video_roll == "kuula") {

        return {
          height: out_h,
          width: out_w,
          top: 0,
          left: 0
        }
      } else if (this.video_roll == "share" && this.video) {
        img_w = this.video.nativeElement.videoWidth
        img_h = this.video.nativeElement.videoHeight
      }
      let rv = fitImgInTarget(img_w, img_h, out_w, out_h)

      return rv;
      // }
    }
    return {
      height: height,
      width: width,
      top: top,
      left: left
    }
  }
  scalePointsToViewBox(poly_array: number[], viewBox: any) { //used after a move\
    if (this.bNoScale) {
      return poly_array;
    }
    let new_points: number[] = []
    console.log("poly set anchors ")
    for (let i = 0; i < poly_array.length; i += 2) {
      let x1: number = poly_array[i]
      if (this.video_roll == "kuula") {
        /*          ***********-*-*************
            viewBox            |  | my_canvas *
          ------------         |  |           *
          |    x     |         |  |           *
          ------------         |  |           *
                    *          |  |           *
                    ************-*-************
        */
        let view_box_scaled_width = viewBox.width * this.my_canvas.clientHeight / viewBox.height
        x1 = x1 * view_box_scaled_width / viewBox.width
        let offset_x = (view_box_scaled_width - this.my_canvas.clientWidth) / 2
        x1 -= offset_x

        /*  -----
            |   |    -----***-----
            |   |    |    * *    |
            |   |    |    * *    |
            |   |    -----***-----
            -----
          viewBox
        */

      } else {
        x1 = this.scaleXToWidth(poly_array[i], viewBox.width)
      }

      new_points.push(x1)
      new_points.push(this.scaleYtoHeight(poly_array[i + 1], viewBox.height))
    }
    return new_points
  }
  setSize(aspect_ratio: number) { //keps a .75 aspect ratio
    let width = 0
    let top = 0;
    let left = 0
    let height = 0
    let out_w = 0
    let out_h = 0
    if (this.divContainer && this.outComponent) {
      out_w = this.outComponent.nativeElement.clientWidth - 8
      out_h = this.outComponent.nativeElement.clientHeight - 8 //border 4px
      height = out_h
      width = out_h / aspect_ratio
      if (width > out_w) {
        width = out_w
        height = width * aspect_ratio
      }
      top = (out_h - height) / 2
      left = (out_w - width) / 2
    }
    return {
      height: height,
      width: width,
      top: top,
      left: left
    }
  }

  setAspectRatio() {
    // if (!this.last_on_board) {
    //   let ns = this.setOnBoardDims()
    // }
  }
}

import { Action } from '@ngrx/store';

export enum CanvasActionTypes {
  SignerName = '[signer]name',
  SignerEmail = '[signer]email',
  SetSignerRoll = '[user] roll',
  // SetSignerKey = '[signer]key',
  // MissingEmailsFor = '[emails]missing',

  SelectToolOpts = '[tool]opt wmd',
  SetTool = '[tool]in canvas',
  SetInput = '[input]type',
  SetPencil = '[pencil]wd',
  SetColors = '[colors]set',
  SetFont = '[font]f w s',
  SelShape = '[shape]sel',

  GetPDFSVG = '[asset]svg save',
  SelSigner = '[signer]set', //Its the key selected by the select signer dialog, to drop signatures


  //Asset Data
  PdfSecOpt = '[pdf] sec',
  PdfSvg = '[pdf] svg',
  PdfSvgBlob = '[pdf]svg+blob',
  SetSigners = '[signers]set',
  LastSigner = '[signer]last',
  SetCopyTo = '[contacts]cc',
  //Saving result
  SVGModified = '[svg]changed',
  SignerSteps = '[signer]steps',
  DidStep = '[signer]did',
  Progress = '[progress]val',
  GoToStep = '[step]goto',

  SignerPic = '[signer]pic',
  SignerLic = '[signer]lic',


  CallerPic = '[caller]pic',
  CallerLic = '[caller]lic',
  CallerResults = '[caller]results',

  BioResults = '[bio]results',
  BioResultsError = '[bio]error',
  LastSignedContract = '[contract]signed',
  ContractExecuted = '[contract]executed',
  ContractSigned = '[contract]signed_by_user',//by the user
  ReadyToBlockChain = '[block_chain]ready',


  GetSignature = '[sing]get',
  GetInitials = '[initials]get',
  NewSignature = '[sign]set',
  NewInitials = '[initials]set',
  UnDo = '[undo]',

  EditComment = '[comment]edit',
  ShowComment = '[comment]show',

  AddToUndo = '[undo]add',
  SelectId = '[draw_id]sel',
  ClearSel = '[draw_id]clear',
  SaveSVG = '[svg]save',
  ClearSVGs = '[svg]clear',

  Download = '[canvas]download',
  AddToChat = '[canvas]add', //tell the derived class (white board, pdf ... ) to send its content to the chat
  SetCanvasOpts = '[canvas]opts',
  IsInitials = '[initials]on off',
  // NumSignAndInitials = '[signatures]number',
  ClearCanvasState = '[canvas]new',
  SignersWithSteps = '[signers&steps]',
  ResizedCanvas = '[resized]canvas',
  RemoveSignature = '[signature]del',
  RemoveInitials = '[intitals]del',
  TextColor = '[text]color',
  ShowDrawTools = '[draw]yn'

}
export class ShowDrawTools implements Action {
  readonly type = CanvasActionTypes.ShowDrawTools;
  constructor(public drawing: boolean) { }
}
export class TextColor implements Action {
  readonly type = CanvasActionTypes.TextColor;
  constructor(public text_color: string) { }
}
export class RemoveSignature implements Action {
  readonly type = CanvasActionTypes.RemoveSignature;
  constructor() { }
}
export class RemoveInitials implements Action {
  readonly type = CanvasActionTypes.RemoveInitials;
  constructor() { }
}
export class ResizedCanvas implements Action {
  readonly type = CanvasActionTypes.ResizedCanvas;
  constructor() { }
}
export class ClearCanvasState implements Action {
  readonly type = CanvasActionTypes.ClearCanvasState;
  constructor() { }
}
// export class NumSignAndInitials implements Action {
//   readonly type = CanvasActionTypes.NumSignAndInitials;
//   constructor(public num: number) { }
// }
export class IsInitials implements Action {
  readonly type = CanvasActionTypes.IsInitials;
  constructor(public on: boolean) { }
}
export class SetCanvasOpts implements Action {
  readonly type = CanvasActionTypes.SetCanvasOpts;
  constructor(public opts: any) { }
}
export class Download implements Action {
  readonly type = CanvasActionTypes.Download;
  constructor() { }
}
export class AddToChat implements Action {
  readonly type = CanvasActionTypes.AddToChat;
  constructor() { }
}

export class SaveSVG implements Action {
  readonly type = CanvasActionTypes.SaveSVG;
  constructor(public asset_id: string, public svg: any) { }
}

export class ClearSVGs implements Action {
  readonly type = CanvasActionTypes.ClearSVGs;
  constructor() { }
}

export class AddToUndo implements Action {
  readonly type = CanvasActionTypes.AddToUndo;
  constructor(public undo: any) { }
}
export class EditComment implements Action {
  readonly type = CanvasActionTypes.EditComment;
  constructor(public show: boolean, public id?: string, public content?: string, public x?: number, public y?: number) { }
}
export class ShowComment implements Action {
  readonly type = CanvasActionTypes.ShowComment;
  constructor(public show: boolean, public content?: string, public x?: number, public y?: number) { }
}
export class UnDo implements Action {
  readonly type = CanvasActionTypes.UnDo;
  constructor() { }
}
export class ReadyToBlockChain implements Action {
  readonly type = CanvasActionTypes.ReadyToBlockChain;
  constructor(public ready: boolean) { }
}
export class ContractSigned implements Action {
  readonly type = CanvasActionTypes.ContractSigned;
  constructor(public signed: boolean) { }
}
export class ContractExecuted implements Action {
  readonly type = CanvasActionTypes.ContractExecuted;
  constructor(public executed: boolean) { }
}
// export class MissingEmailsFor implements Action {
//   readonly type = CanvasActionTypes.MissingEmailsFor;
//   constructor(public missing: string[]) { }
// }
export class LastSignedContract implements Action {
  readonly type = CanvasActionTypes.LastSignedContract;
  constructor(public contract: any) { }
}
// export class SetSignerKey implements Action {
//   readonly type = CanvasActionTypes.SetSignerKey;
//   constructor(public key: string) { }
// }
export class SignerName implements Action {
  readonly type = CanvasActionTypes.SignerName;
  constructor(public name: string) { }
}
export class SignerEmail implements Action {
  readonly type = CanvasActionTypes.SignerEmail;
  constructor(public email: string) { }
}
/*
Called by app.component for the caller, in the sidebar navigatt
*/
export class SetSignerRoll implements Action {
  readonly type = CanvasActionTypes.SetSignerRoll;
  constructor(public roll: string) { }
}

export class BioResults implements Action {
  readonly type = CanvasActionTypes.BioResults;
  constructor(public results: any) { }
}
export class BioResultsError implements Action {
  readonly type = CanvasActionTypes.BioResultsError;
  constructor(public error: any) { }
}
export class Progress implements Action {
  readonly type = CanvasActionTypes.Progress;
  constructor(public progress: any) { }
}
export class SignerPic implements Action {
  readonly type = CanvasActionTypes.SignerPic;
  constructor(public pic: string) { }
}
export class SignerLic implements Action {
  readonly type = CanvasActionTypes.SignerLic;
  constructor(public str: string, public img_type: string) { }
}

export class CallerPic implements Action {
  readonly type = CanvasActionTypes.CallerPic;
  constructor(public pic: string) { }
}
export class CallerLic implements Action {
  readonly type = CanvasActionTypes.CallerLic;
  constructor(public str: string, public img_type: string) { }
}
export class CallerResults implements Action {
  readonly type = CanvasActionTypes.CallerResults;
  constructor(public results: any) { }
}

export class GoToStep implements Action {
  readonly type = CanvasActionTypes.GoToStep;
  constructor(public step: any) { }
}
export class SVGModified implements Action {
  readonly type = CanvasActionTypes.SVGModified;
  constructor() { }
}
export class SignerSteps implements Action {
  readonly type = CanvasActionTypes.SignerSteps;
  constructor(public steps: any) { }
}
export class DidStep implements Action {
  readonly type = CanvasActionTypes.DidStep;
  constructor(public id: string) { }
}

export class SelSigner implements Action {
  readonly type = CanvasActionTypes.SelSigner;
  constructor(public key: string) { }
}

export class SelectToolOpts implements Action {
  readonly type = CanvasActionTypes.SelectToolOpts;
  constructor(public name: string, public params?: any) { }
}
export class SetTool implements Action {
  readonly type = CanvasActionTypes.SetTool;
  constructor(public name: string) { }
}
export class SetInput implements Action {
  readonly type = CanvasActionTypes.SetInput;
  constructor(public name: string) { }
}
export class SetColors implements Action {
  readonly type = CanvasActionTypes.SetColors;
  constructor(public fill_color: string, public stroke_color: string, public text_color: string) { }
}
export class SetPencil implements Action {
  readonly type = CanvasActionTypes.SetPencil;
  constructor(public width: number) { }
}
export class SetFont implements Action {
  readonly type = CanvasActionTypes.SetFont;
  constructor(public font: string, public weight: string, public size: string, public field_text: boolean) { }
}
export class SelShape implements Action {
  readonly type = CanvasActionTypes.SelShape;
  constructor(public index: number) { } //asset, download, chat
}
export class ClearSel implements Action {
  readonly type = CanvasActionTypes.ClearSel;
  constructor() { } //asset, download, chat
}
export class SelectId implements Action {
  readonly type = CanvasActionTypes.SelectId;
  constructor(public id: string, public shiftKey: boolean, public k_type: string) { } //asset, download, chat
}
export class GetPDFSVG implements Action {
  readonly type = CanvasActionTypes.GetPDFSVG;
  constructor(public send_to: string) { } //asset, download, chat
}
export class PdfSecOpt implements Action {
  readonly type = CanvasActionTypes.PdfSecOpt;
  constructor(public options: any) { }
}
export class PdfSvg implements Action {
  readonly type = CanvasActionTypes.PdfSvg;
  constructor(public svg: any) { }
}

export class PdfSvgBlob implements Action {
  readonly type = CanvasActionTypes.PdfSvgBlob;
  constructor(public requested_by: string, public svg: any, public blob: Blob) { }
}
export class SetSigners implements Action {
  readonly type = CanvasActionTypes.SetSigners;
  constructor(public signers: any) { }
}
export class SignersWithSteps implements Action {
  readonly type = CanvasActionTypes.SignersWithSteps;
  constructor(public signers: any) { }
}
export class SetCopyTo implements Action {
  readonly type = CanvasActionTypes.SetCopyTo;
  constructor(public copy_to: any[]) { }
}

export class LastSigner implements Action {
  readonly type = CanvasActionTypes.LastSigner;
  constructor(public last_signer: boolean) { }
}

export class NewSignature implements Action {
  readonly type = CanvasActionTypes.NewSignature;
  constructor(public group_id: string, public svg: string, public rect: any, public text_initials: string | undefined) { }
}
export class NewInitials implements Action {
  readonly type = CanvasActionTypes.NewInitials;
  constructor(public group_id: string, public svg: string, public rect: any) { }
}
export class GetSignature implements Action {
  readonly type = CanvasActionTypes.GetSignature;
  constructor(public group_id: string | undefined) { }
}
export class GetInitials implements Action {
  readonly type = CanvasActionTypes.GetInitials;
  constructor(public group_id: string | undefined) { }
}

export type CanvasActions =
  SignerName
  | SignerEmail
  // | SetSignerKey
  // | MissingEmailsFor
  | SelectToolOpts
  | SetTool
  | SetInput
  | SetPencil
  | SetColors
  | SetFont
  | GetPDFSVG
  | SelSigner
  | PdfSecOpt
  | PdfSvg
  | SetSigners
  | SignersWithSteps
  | SetCopyTo

  | SetSignerRoll
  | SVGModified
  | SignerSteps
  | DidStep
  | GoToStep
  | PdfSvgBlob
  | SignerPic
  | SignerLic
  | CallerPic
  | CallerLic
  | CallerResults
  | Progress
  | BioResults
  | BioResultsError
  | LastSigner
  | LastSignedContract
  | ContractExecuted
  | ContractSigned
  | ReadyToBlockChain

  | GetSignature
  | GetInitials
  | NewSignature
  | NewInitials
  | RemoveSignature
  | RemoveInitials

  | UnDo
  | SelShape

  | EditComment
  | ShowComment
  | AddToUndo
  | SelectId
  | ClearSel
  | SaveSVG
  | ClearSVGs
  | Download
  | AddToChat
  | SetCanvasOpts
  | IsInitials
  // | NumSignAndInitials
  | ClearCanvasState
  | ResizedCanvas
  | TextColor
  | ShowDrawTools
  ;

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { WSSState } from 'src/app/wss/wss.model';
import { kPayState } from '../kpay.model';
import { gotKAcc, planFromCaller, planFromRep, runKPay } from '../kpay.selectors';

@Component({
  selector: 'kpay-screen',
  templateUrl: './kpay-screen.component.html',
  styleUrls: ['./kpay-screen.component.scss']
})
export class KpayScreenComponent {
  state: string = "plan" //"acc" "invoice" "plan" //"bill" //
  acc: any
  caller_plan: any
  rep_plan: any
  updating_msg: string = "Uploading Information"
  running: string | undefined
  subscriptions: any[] = []
  constructor(
    public kpayState: Store<kPayState>,
    public wssState: Store<WSSState>
    // public recaptcha_service: RecaptchaService
  ) {
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngAfterViewInit() {
    this.subscriptions.push(this.kpayState.select(runKPay).subscribe((running: string | undefined) => {
      this.running = running
      this.setStep()
    }))
    this.subscriptions.push(this.kpayState.select(planFromRep).subscribe((plan: any) => {
      this.rep_plan = plan
      this.setStep()
      //After the plan is done, we
    }))
    this.subscriptions.push(this.kpayState.select(planFromCaller).subscribe((plan: any) => {
      this.caller_plan = plan
      this.setStep()
    }))
    this.subscriptions.push(this.kpayState.select(gotKAcc).subscribe((acc: any) => {
      this.acc = acc
      this.setStep()
    }))



    // this.pagesState.select(showCC).subscribe((show: any) => {
    //   if (show && show != "pay_token") {
    //     setTimeout(() => {
    //       this.state = show
    //
    //     })
    //   }
    // })
  }
  setStep() {

    if (this.running == "plan") {
      if (!this.rep_plan) {
        this.state = "plan"
      }
    } else {
      this.state = "pay"
    }

  }
}

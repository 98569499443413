import { Component, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { selectToolOptions } from 'src/app/canvas/canvas.selectors';
import { PagesState } from 'src/app/pages/pages.model';
import { myCompanyTitle, myLastName, myName, myPicture } from 'src/app/pages/pages.selectors';
import { Busy } from 'src/app/wss/wss.actions';
import { WSSState } from 'src/app/wss/wss.model';
import { busy, callId } from 'src/app/wss/wss.selectors';
declare var kzLib: any;

@Component({
  selector: 'agent-profile',
  templateUrl: './agent-profile.component.html',
  styleUrls: ['./agent-profile.component.scss']
})
export class AgentProfileComponent {
  bBusy: boolean = true;
  theme: string = "light"
  rep_name: string = ""
  last_name: string = ""
  rep_title: string = ""
  img_src: string = "assets/images/profile.svg"
  show_tool: string | undefined
  subscriptions: any[] = []

  profile_height: number = 120;
  profile_width: number = 120;
  profile_left: number = 0;
  profile_top: number = 0;
  call_id: string | undefined
  constructor(
    private wssState: Store<WSSState>,
    private pagesState: Store<PagesState>,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("agent profile")
    this.subscriptions.push(this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
    }))
    this.subscriptions.push(this.canvasStore.select(selectToolOptions).subscribe((opt: any) => {
      this.show_tool = opt.name
    }))
    this.subscriptions.push(this.pagesState.select(myPicture).subscribe((picture: any) => {
      if (picture) {
        if (picture.url) {
          this.img_src = picture.url
        } else {
          this.img_src = picture
        }
      } else if (this.img_src) {
        this.img_src = "assets/images/default_prof_pic.svg"
      }
    }))
    this.subscriptions.push(this.pagesState.select(myName).subscribe((name: string) => {
      this.rep_name = name
    }))
    this.subscriptions.push(this.pagesState.select(myLastName).subscribe((last_name: string) => {
      this.last_name = last_name
    }))
    this.subscriptions.push(this.pagesState.select(myCompanyTitle).subscribe((rep_title: string) => {
      this.rep_title = rep_title
    }))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  ngOnInit(): void {
    this.wssState.select(busy).subscribe((busy: boolean) => {
      setTimeout(() => this.bBusy = busy)
    })
  }
  ngAfterViewInit() {
  }
  showOpts($event: any) {

    console.log("Call kzLib")

    if (this.show_tool == "prof-opts") {
      this.canvasStore.dispatch(new SelectToolOpts(""))
    } else if (!this.call_id) {
      this.canvasStore.dispatch(new SelectToolOpts("prof-opts"))
    }
  }

  onImageLoad($event: any) {
    console.log("loaded profile pic")
    let ih = $event.currentTarget.naturalHeight//1285
    let iw = $event.currentTarget.naturalWidth//981
    if (ih > iw) {
      this.profile_width = 40
      this.profile_height = this.profile_width * ih / iw
      this.profile_left = 0
      this.profile_top = (40 - this.profile_height) / 2

    } else {
      this.profile_height = 40
      this.profile_width = this.profile_height * iw / ih
      this.profile_top = 0
      this.profile_left = (40 - this.profile_width) / 2
    }

  }
}

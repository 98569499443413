// DOC https://mozilla.github.io/pdf.js/api/draft/module-pdfjsLib.html
//I FIXED THIS
//Error: node_modules/pdfjs-dist/types/src/display/text_layer.d.ts:119:41 - error TS2552: Cannot find name 'OffscreenCanvasRenderingContext2D'. Did you mean 'CanvasRenderingContext2D'?
// by removing OffscreenCanvasRenderingContext2D from text_layer.d.ts:119

//https://stackblitz.com/edit/angular-pdf-to-canvas-1ftevr?file=src%2Fapp%2Fapp.component.ts
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

//used to build our canvas
import { SVG, extend as SVGextend, Element as SVGElement, G } from '@svgdotjs/svg.js'

//manipulates the pdf
import {
  StandardFonts,
  PDFDocument,
  rgb,
  asPDFName,
  PDFFont,
  PDFHexString,
  PDFPage
} from 'pdf-lib'
import { ChatState } from 'src/app/chat/chat.model';
import { PagesState } from 'src/app/pages/pages.model';
import { iAmManager, myEmail, myName } from 'src/app/pages/pages.selectors';
import { ToolService } from 'src/app/tools/tool.service';
import { WSSState } from 'src/app/wss/wss.model';
import { lastMsg, myId } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';
import { AddToUndo, DidStep, SaveSVG, SelectId, SelectToolOpts, SetInput, SetTool, SVGModified } from '../canvas.actions';
import {
  addToChat,
  addToUndo,
  assetSVG,
  canvasOpts,
  download,
  editComment,
  fillColor,
  pencilWidth,
  selectedId,
  selectedInput,
  selectedTool,
  selectToolOptions,
  showComment,
  textFont,
  textSize,
  textWeight,
  tools,
  undo
} from '../canvas.selectors';
import { CanvasService } from '../canvas.service';
import { kArrow } from '../draw/arrow';
import { kComments } from '../draw/comments';
import { kEllipse } from '../draw/ellipse';
import { kInput } from '../draw/input';

import { kPencil } from '../draw/pencil';
import { kPolygon } from '../draw/polygon';
import { kRectangle } from '../draw/rectangle';
// import { kSign } from '../draw/sign';
import { kText } from '../draw/text';

import { MetaPageService } from '../meta-page.service';
@Component({
  selector: 'kx-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss']
})
export class CanvasComponent {
  @ViewChild('divContainer') divContainer: ElementRef | undefined;
  my_email: string | undefined
  my_id: string | undefined
  _asset: any | undefined

  @Input() set asset(a: any) {
    if (a) {
      this._asset = a
      this.gotAsset()
    }
  }
  sel_tool_name: string = "none"
  selected_input: string = ""
  tools: any
  // pencil_width: number = 5
  //
  // text_font: string = "Cookie"
  // text_weight: string = "Regular"
  // text_size: string = "12px"

  isDrawing: boolean = false;
  drawing_line: any | undefined


  drawing_poly: any | undefined
  poly_array: number[] = []
  stroke_group: any

  startX: number = 0
  startY: number = 0
  polygonX: number = -1
  polygonY: number = -1

  draw_tool: any


  moving_target: any | undefined
  mouse_down_at: number = 0
  moving: string = "all"
  moving_borders: any
  resize_target: any | undefined


  groups_bb: any = {}
  groups_ids: string[] = []

  cuurent_div: HTMLElement | undefined

  draw_arrow!: kArrow
  draw_polygon!: kPolygon
  draw_ellipse!: kEllipse
  draw_pencil!: kPencil
  draw_rectangle!: kRectangle
  draw_text!: kText
  draw_input!: kInput
  // draw_sign!: kSign
  draw_comment!: kComments

  bEditComment: boolean = false
  bShowComment: boolean = false
  edit_comment_x: number = 316
  edit_comment_y: number = 209
  show_comment_x: number = 316
  show_comment_y: number = 209
  _asset_id: string = "wb"
  page_svg: any
  caller_svg: any

  my_canvas: any
  zoom: number = 80;

  //****************************************************************************
  //selection
  selected_obj: any | undefined
  hover_div: any

  //multipe selection

  multi_move_divs: any = {}
  pointer_id: any //to capture the mouse


  //single selection
  info_div: any
  top_left_anchor: any
  top_right_anchor: any
  bottom_left_anchor: any
  bottom_right_anchor: any


  moving_anchor: any
  top_line: any
  right_line: any
  left_line: any
  bottom_line: any


  back_div: any
  top_anchor: any
  right_anchor: any
  left_anchor: any
  bottom_anchor: any

  last_x: number = 0 //used to know where to paste
  last_y: number = 0

  select_div: any
  bIsSigningContract: boolean = false
  mouse_lbl: string = "Caller"
  email: string = ""
  name: string = ""
  role: string = "caller"
  other_mouses: any = {}
  other_box: any = {}
  set asset_id(id: string) {
    this._asset_id = id
    this.subscriptions.push(this.canvasStore.select(assetSVG, this._asset_id).subscribe((in_svg: any) => {
      if (in_svg) {
        console.log("<-- svg " + in_svg)
        this.page_svg = SVG(in_svg)
      }
    }))
  }

  selected_id: string | undefined
  selected_type: string | undefined

  marging_top: number = 0; //used by images

  bNoScale: boolean = false //When we are in the white-board we dont scale
  viewBoxWidth: number = 0
  viewBoxHeight: number = 0

  show_tool: string | undefined
  subscriptions: any[] = []
  aspect_ratio: number = 0.75
  constructor(
    public canvasStore: Store<CanvasState>,
    public wssState: Store<WSSState>,
    public wss_service: WSSService,
    public canvas_service: CanvasService,
    public pagesState: Store<PagesState>,
    public chatState: Store<ChatState>,
    public tools_service: ToolService,
    public meta_server: MetaPageService
  ) {
    console.log("canvas")
    this.initCanvas()
  }
  setMouseLbl() {
    let me = this
    function setMouseLbl() {
      me.mouse_lbl = me.role
      if (me.email.length > 1) {
        me.mouse_lbl = me.email
      }
      if (me.name.length > 1) {
        me.mouse_lbl = me.name
      }
    }
    this.subscriptions.push(this.pagesState.select(iAmManager).subscribe((manager: boolean) => {
      if (manager) {
        this.role = "manager"
      } else {
        this.role = "rep" //we used agent in sing-tools.component
      }
      setMouseLbl()
    }))
    this.subscriptions.push(this.pagesState.select(myEmail).subscribe((email: string) => {
      if (email) {
        this.email = email
        setMouseLbl()
      }
    }))
    this.subscriptions.push(this.pagesState.select(myName).subscribe((name: string) => {
      if (name) {
        this.name = name
        setMouseLbl()
      }
    }))

  }
  initCanvas() {
    this.setMouseLbl()
    this.canvasStore.select(selectToolOptions).subscribe((opt: any) => {
      this.show_tool = opt.name

    })
    this.subscriptions.push(this.canvasStore.select(canvasOpts).subscribe((opts: any) => {
      this.canvas_service.sendCanvasMsg("options", "set", "", opts)
    }))




    //START rep
    this.canvasStore.select(selectedInput).subscribe((selected_input: string) => {
      this.selected_input = selected_input
    })
    //end rep
    //start caller
    // this.pagesState.select(callerName).subscribe((name: string | undefined) => {
    //   if (name) {
    //     this.name = name
    //     setMouseLbl()
    //   }
    // })
    // this.pagesState.select(callerEmail).subscribe((email: string | undefined) => {
    //   if (email) {
    //     this.email = email
    //     setMouseLbl()
    //   }
    // })
    //
    // this.pagesState.select(myEmail).subscribe((email: string | undefined) => {
    //   if (email) {
    //     this.email = email
    //     setMouseLbl()
    //   }
    // })
    //end caller
    this.subscriptions.push(this.canvasStore.select(fillColor).subscribe((color: string) => {
      if (color) {
        if (this.selected_obj) {
          let drawObj = this.getDrawObj(this.selected_obj.k_type)
          drawObj.setColor(this.selected_obj.id, color)
          this.canvas_service.sendCanvasMsg("color", this.selected_obj.k_type, this.selected_obj.id, color)
        }
        let ids = Object.keys(this.multi_move_divs)
        for (let i = 0; i < ids.length; i++) {
          let id = ids[i]
          let back = this.multi_move_divs[id]
          let k_type = back.dataset['k_type']
          let drawObj = this.getDrawObj(k_type)
          drawObj.setColor(id, color)
          this.canvas_service.sendCanvasMsg("color", k_type, id, color)
        }
      }
    }))
    this.subscriptions.push(this.canvasStore.select(pencilWidth).subscribe((width: number) => {
      if (width) {
        if (this.selected_obj) {
          let drawObj = this.getDrawObj(this.selected_obj.k_type)
          drawObj.setPencilSize(this.selected_obj.id, width)
          this.canvas_service.sendCanvasMsg("width", this.selected_obj.k_type, this.selected_obj.id, width)
        }
        let ids = Object.keys(this.multi_move_divs)
        for (let i = 0; i < ids.length; i++) {
          let id = ids[i]
          let back = this.multi_move_divs[id]
          let k_type = back.dataset['k_type']
          let drawObj = this.getDrawObj(k_type)
          drawObj.setPencilSize(id, width)
          this.canvas_service.sendCanvasMsg("width", k_type, id, width)
        }
      }
    }))

    this.subscriptions.push(this.canvasStore.select(textWeight).subscribe((weight: string) => {
      if (weight) {
        if (this.selected_obj) {
          let drawObj = this.getDrawObj(this.selected_obj.k_type)
          drawObj.setFontWeight(this.selected_obj.id, weight)
          this.canvas_service.sendCanvasMsg("weight", this.selected_obj.k_type, this.selected_obj.id, weight)
        }
        let ids = Object.keys(this.multi_move_divs)
        for (let i = 0; i < ids.length; i++) {
          let id = ids[i]
          let back = this.multi_move_divs[id]
          let k_type = back.dataset['k_type']
          let drawObj = this.getDrawObj(k_type)
          drawObj.setFontWeight(id, weight)
          this.canvas_service.sendCanvasMsg("weight", k_type, id, weight)

        }
      }
    }))
    this.subscriptions.push(this.canvasStore.select(textSize).subscribe((size: string) => {
      if (size) {
        if (this.selected_obj) {
          let drawObj = this.getDrawObj(this.selected_obj.k_type)
          drawObj.setFontSize(this.selected_obj.id, size)
          this.canvas_service.sendCanvasMsg("font_size", this.selected_obj.k_type, this.selected_obj.id, size)
        }
        let ids = Object.keys(this.multi_move_divs)
        for (let i = 0; i < ids.length; i++) {
          let id = ids[i]
          let back = this.multi_move_divs[id]
          let k_type = back.dataset['k_type']
          let drawObj = this.getDrawObj(k_type)
          drawObj.setFontSize(id, size)
          this.canvas_service.sendCanvasMsg("font_size", k_type, id, size)
        }
      }
    }))
    this.subscriptions.push(this.canvasStore.select(textFont).subscribe((font: string) => {
      if (font) {
        if (this.selected_obj) {
          let drawObj = this.getDrawObj(this.selected_obj.k_type)
          drawObj.setFont(this.selected_obj.id, font)
          this.canvas_service.sendCanvasMsg("font", this.selected_obj.k_type, this.selected_obj.id, font)
        }
        let ids = Object.keys(this.multi_move_divs)
        for (let i = 0; i < ids.length; i++) {
          let id = ids[i]
          let back = this.multi_move_divs[id]
          let k_type = back.dataset['k_type']
          let drawObj = this.getDrawObj(k_type)
          drawObj.setFont(id, font)
          this.canvas_service.sendCanvasMsg("font", k_type, id, font)
        }
      }
    }))


    this.subscriptions.push(this.canvasStore.select(download).subscribe((seq: number) => {
      if (seq) {
        this.download()
      }
    }))
    this.subscriptions.push(this.canvasStore.select(addToChat).subscribe((seq: number) => {
      if (seq) {
        this.addToChat(this.role)
      }
    }))
    this.subscriptions.push(this.canvasStore.select(showComment).subscribe((action: any) => {
      if (action) {
        this.bShowComment = action.show
        this.show_comment_x = action.x
        this.show_comment_y = action.y + 20
      }
    }))
    this.subscriptions.push(this.canvasStore.select(editComment).subscribe((action: any) => {
      if (action) {
        console.log("comment edit canvas  " + action.id + " " + action.x + " " + action.y)
        this.bEditComment = action.show
        this.edit_comment_x = action.x
        this.edit_comment_y = action.y
      }
    }))
    this.subscriptions.push(this.canvasStore.select(tools).subscribe((tools: any) => {
      this.tools = tools
    }))

    this.subscriptions.push(this.canvasStore.select(selectedTool).subscribe((tool_name: string) => {
      this.sel_tool_name = tool_name
      if (this.sel_tool_name != "none") {
        this.removeInfoAndAnchors()
      }
      if (this.sel_tool_name == "pencil" && this.selected_type == "arrow") {
        this.selected_obj = undefined
        this.selected_type = undefined
        this.selected_id = undefined
      }
    }))
    this.subscriptions.push(this.canvasStore.select(undo).subscribe((seq: number) => {
      if (seq) {
        this.undo()
        this.canvasStore.dispatch(new SetTool("none"))
      }
    }))
    this.subscriptions.push(this.canvasStore.select(addToUndo).subscribe((undo: any) => {
      if (undo) {
        this.addToUndo(undo)
      }
    }))

    this.subscriptions.push(this.wssState.select(myId).subscribe((id: string | undefined) => {
      this.my_id = id
    }))


    this.subscriptions.push(this.wssState.select(lastMsg).subscribe((msg: any | undefined) => {
      this.processLastMsg(msg)

    }))
  }
  processLastMsg(msg: any) {
    if (msg && this.my_canvas) {
      if (msg.canvas) {
        try {
          if (msg.canvas == "mouse") {
            this.processIncomingMouseMsg(msg)
            return
          }
          if (msg.canvas == "box") {
            if (msg.action == "remove") {
              if (this.other_box[msg.id]) {
                this.my_canvas.removeChild(this.other_box[msg.id])
                delete this.other_box[msg.id]
              }
              // } else if (msg.action == "set") {
              //   if (!this.other_box[msg.id]) {
              //     this.other_box[msg.id] = document.createElement("div");
              //     this.other_box[msg.id].style.position = "absolute"
              //     this.other_box[msg.id].style.border = "1px solid #8F5CFF"
              //
              //     this.my_canvas.appendChild(this.other_box[msg.id])
              //   }
              //   let x1 = msg.data.x1 * this.my_canvas.clientWidth / msg.canvas_width
              //   let y1 = msg.data.y1 * this.my_canvas.clientHeight / msg.canvas_height
              //   let x2 = msg.data.x2 * this.my_canvas.clientWidth / msg.canvas_width
              //   let y2 = msg.data.y2 * this.my_canvas.clientHeight / msg.canvas_height
              //
              //   this.other_box[msg.id].style.left = x1 + "px"
              //   this.other_box[msg.id].style.top = y1 + "px"
              //   let w = x2 - x1
              //   let h = y2 - y1
              //
              //   this.other_box[msg.id].style.width = w + "px"
              //   this.other_box[msg.id].style.height = h + "px"

            }
            return
          }
          if (msg.canvas == "multi") {
            this.setMultipleState(msg)
            return
          }
          if (msg.canvas == "color") {
            let drawObj = this.getDrawObj(msg.action)
            drawObj.setColor(msg.id, msg.data)
            // this.setMultipleState(msg)
            return
          }
          if (msg.canvas == "width") {
            let drawObj = this.getDrawObj(msg.action)
            drawObj.setPencilSize(msg.id, msg.data)
            return
          }
          if (msg.canvas == "weight") {
            let drawObj = this.getDrawObj(msg.action)
            drawObj.setFontWeight(msg.id, msg.data)
            return
          }
          if (msg.canvas == "font_size") {
            let drawObj = this.getDrawObj(msg.action)
            drawObj.setFontSize(msg.id, msg.data)
            return
          }
          if (msg.canvas == "font") {
            let drawObj = this.getDrawObj(msg.action)
            drawObj.setFont(msg.id, msg.data)
            return
          }
          if (msg.canvas == "init") {
            this.caller_svg = msg
          }
          if (msg.canvas == "ar") {
            this.aspect_ratio = msg.data.aspect_ratio

            this.setAspectRatio()
          }
          let drawObj = this.getDrawObj(msg.canvas)
          if (drawObj) {
            drawObj.processMsg(msg)
          }
          // if (msg.canvas == "arrow") {
          //   this.draw_arrow.processMsg(msg)
          // } else if (msg.canvas == "polygon") {
          //   this.draw_polygon.processMsg(msg)
          // } else if (msg.canvas == "ellipse") {
          //   this.draw_ellipse.processMsg(msg)
          // } else if (msg.canvas == "pencil") {
          //   this.draw_pencil.processMsg(msg)
          // } else if (msg.canvas == "rectangle") {
          //   this.draw_rectangle.processMsg(msg)
          // } else if (msg.canvas == "text") {
          //   this.draw_text.processMsg(msg)
          // } else if (msg.canvas == "sign" || msg.canvas == "initial") {
          //   this.draw_sign.processMsg(msg)
          // } else if (msg.canvas == "comment") {
          //   this.draw_comment.processMsg(msg)
          // }
        } catch (e) {
          console.error(e)
        }
        // this.removeInfoAndAnchors()
        // this.removeMultiSelBoxes()
        // if (this.selected_obj) {
        //   this.selected_obj.drawObj.select("")
        //
        // }
      } else if (msg.undo) {
        this.undoMsg(msg)
      }

    }
  }
  makeMouseBox(msg: any) {
    this.other_mouses[msg.id] = document.createElement("div");
    this.other_mouses[msg.id].style.position = "absolute"
    this.other_mouses[msg.id].style.textAlign = "center";
    this.other_mouses[msg.id].fontSize = "8px"
    this.other_mouses[msg.id].style.height = "20px"
    let circle = document.createElement("div");
    circle.style.width = "6px";
    circle.style.height = "6px";
    circle.style.borderRadius = "3px";
    circle.style.background = "#8F5CFFa0"
    circle.style.position = "absolute"
    circle.style.top = "6px"
    this.other_mouses[msg.id].appendChild(circle)
    let text = document.createElement("div");
    text.innerText = msg.id
    text.style.marginLeft = "10px"
    text.style.color = "#fff"
    text.style.textShadow = "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black";
    this.other_mouses[msg.id].appendChild(text)
    this.my_canvas.appendChild(this.other_mouses[msg.id])
  }
  processIncomingMouseMsg(msg: any) {

    if (msg.rep_id && this.my_id) {
      if (msg.rep_id == this.my_id) {
        return
      }
    } else if (msg.manager_id && this.my_id) {
      if (msg.manager_id == this.my_id) {
        return
      }
    }
    if (!this.other_mouses[msg.id]) {
      this.makeMouseBox(msg)
    }
    let x = msg.data.x * this.my_canvas.clientWidth / msg.data.client_width
    let y = msg.data.y * this.my_canvas.clientHeight / msg.data.client_height
    // console.log("x " + x + " y " + y + " w " + this.my_canvas.clientWidth + " h " + this.my_canvas.clientHeight + " msg x " + msg.data.x + " y " + msg.data.y + " w " + msg.data.client_width + " h " + msg.data.client_height)
    this.other_mouses[msg.id].style.left = (x - 3) + "px"
    this.other_mouses[msg.id].style.top = (y - 10) + "px"
    return
  }
  // processMultiMove(msg: any) {
  //   let k_t = msg.data.k_t
  //   let delta_x = msg.data.dx
  //   let delta_y = msg.data.dy
  //   for (let i = 0; i < k_t.length; i++) {
  //     let id = k_t[i].id
  //     let k_type = k_t[i].k_type
  //
  //     let draw = this.getDrawObj(k_type)
  //     draw.moveDelta(id, delta_x, delta_y)
  //   }
  // }
  //****************************************************************************

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.removeInfoAndAnchors()
    this.removeMultiSelBoxes()

  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent) {

    if (this.show_tool == 'get_sig'
      || this.show_tool == 'get_ini'
      || this.show_tool == 'text'
    ) {
      return

    }
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 67) {
      console.log('CTRL + C');
      this.copy()

    } else if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 86) {
      console.log('CTRL +  V');
      this.paste()
    } else if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 88) {
      console.log('CTRL +  X, CUT');
      this.cut()
    } else if ($event.keyCode == 46 || $event.keyCode == 8) {
      console.log('DELETE');
      this.delete()
    } else if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 90) {
      console.log('CTRL +  Z, UNDO');
      this.undo()
    } else {
      console.log(" ctrl " + $event.ctrlKey + " meta " + $event.metaKey + " code " + $event.keyCode)
    }

  }
  selectObj(id: string, k_type: string) {
    this.startX = -1
    if (this.selected_id && this.selected_type) {
      let drawObj = this.getDrawObj(this.selected_type)
      drawObj.select("x")
    }


    let drawObj = this.getDrawObj(k_type)
    console.log("#PL call select")

    this.selected_id = id
    this.selected_type = k_type
    this.removeMultiSelBoxes()
    if (k_type == "line" || k_type == "arrow") {

      this.removeAnchors()
      this.makeInfoBox()
      let box = drawObj.getBox(id)
      let box2 = this.scaleBox(box)
      this.setBox(box2.x1, box2.y1, box2.x2, box2.y2)
    } else {
      this.removeInfoAndAnchors()
    }

    drawObj.select(id)

    // console.log("#SEL " + this.selected_id + " " + this.selected_type)
    this.selected_obj = { id: id, k_type: k_type, drawObj: drawObj }
    //    this.canvasStore.dispatch(new SetTool("none")), we need to keep the tool available for the next one
  }
  unselect(id: string) {
    if (this.selected_obj) {
      if (this.selected_obj.id == id) {
        delete this.selected_obj
      }
    }
  }
  cut() {
    this.copy()
    this.delete()
  }
  delete() {
    if (this.selected_obj) {
      let rv = this.selected_obj.drawObj.erase(this.selected_obj.id)
      this.canvasStore.dispatch(new AddToUndo(rv))
      this.removeInfoAndAnchors()
    } else {
      this.setUpMultipeUndo()
      let ids = Object.keys(this.multi_move_divs)
      for (let i = 0; i < ids.length; i++) {
        let id = ids[i]
        let back = this.multi_move_divs[id]
        let k_type = back.dataset['k_type']
        let drawObj = this.getDrawObj(k_type)
        drawObj.erase(id)
        //find the svg in the canvas and remove it
        let bDone = false
        for (let i = 0; i < this.my_canvas.children.length; i++) {
          let child = this.my_canvas.children[i]
          if (child.instance && child.instance.type == "svg") {
            for (let j = 0; j < child.children.length; j++) {
              let obj = child.children[j]
              if (obj.instance && obj.instance.node && obj.instance.node.dataset && obj.instance.node.dataset.id == id) {
                console.log("remove it ")
                child.removeChild(obj)
                bDone = true;
                this.canvas_service.setSteps(this.draw_tool)
                break
              }
            }
            if (bDone) {
              break;
            }
          }

        }


      }
      if (ids.length) {
        this.removeMultiSelBoxes()
        this.removeInfoAndAnchors()
      }
    }
  }
  async copy() {
    let arr: string[] = []
    if (this.selected_obj) {
      let svg = this.selected_obj.drawObj.copy(this.selected_obj.id)
      arr.push(svg)
    } else {
      let ids = Object.keys(this.multi_move_divs)
      for (let i = 0; i < ids.length; i++) {
        let id = ids[i]
        let back = this.multi_move_divs[id]
        let k_type = back.dataset['k_type']
        let drawObj = this.getDrawObj(k_type)
        let svg = drawObj.copy(id)
        arr.push(svg)
      }
    }
    if (arr.length > 0) {
      try {
        let sarr = JSON.stringify(arr)
        await navigator.clipboard.writeText(sarr);
        console.log(" --> clipboard " + sarr)
      } catch (e) {
        console.error(e)
      }
    }

  }
  async paste() {
    try {
      this.removeInfoAndAnchors()
      this.removeMultiSelBoxes()
      let pasted: any[] = []
      let sarr = await navigator.clipboard.readText()
      if (sarr) {
        console.log(" <-- clipboard " + sarr)
        let arr = JSON.parse(sarr)
        //find the top, left
        let paste_data: any[] = []
        for (let i = 0; i < arr.length; i++) {
          let svg = arr[i]
          let obj = SVG(svg)
          if (obj) {
            let dataset: any = obj.node.dataset
            if (dataset) {

              let k_type = dataset.k_type

              let drawObj = this.getDrawObj(k_type)
              let rv = drawObj.pasteSVG(obj, this.last_x, this.last_y)
              if (rv) {
                pasted.push(rv)

                paste_data.push({
                  k_type: k_type,
                  id: rv.id,
                  data: svg
                })
                //now select all the ids
                // if (arr.length > 1) {
                this.processMultiSel(rv.id, rv.box, rv.k_type)

                // } else {
                //   this.processSingleSel(rv.id, rv.box, rv.k_type)
                // }
              }
            }
          }
        }
        this.makeGroupFromBoxes()
        console.log("paste")

        this.canvas_service.sendCanvasMsg("multi", "paste", "", paste_data)

        this.canvasStore.dispatch(new AddToUndo({
          undo: "paste",
          k_type: "arrow",
          pasted: pasted,
        }))
      }
    } catch (e) {
      console.error(e)
    }
  }
  //called by the draw objets
  // onHover(element_id: string, box: any, k_type: string) {
  // }
  onHover1(element_id: string, box: any, k_type: string) {
    if (this.multi_move_divs) {
      if (this.multi_move_divs[element_id]) {
        return
      }
    }

    if (!box) {
      return
    }
    if (!this.hover_div) {
      this.hover_div = document.createElement('div')
      this.hover_div.onpointerdown = onHoverMouseDown
      this.hover_div.onpointerleave = onHoverMouseLeave
      // this.hover_div.onmousemove = onHoverMouseMove

      this.my_canvas.appendChild(this.hover_div)
    }


    this.hover_div.dataset['id'] = element_id
    this.hover_div.dataset['k_type'] = k_type

    let me = this


    let w: number = 0
    let h: number = 0
    let x: number = 0
    let y: number = 0
    function setHoverBox(box: any) {

      w = box.x2 - box.x1
      h = box.y2 - box.y1

      if (w > 0) {
        x = box.x1
      } else {
        w *= -1
        x = box.x2
      }
      if (h > 0) {
        y = box.y1
      } else {
        h *= -1
        y = box.y2
      }
      me.hover_div.style.left = x + "px"
      me.hover_div.style.top = y + "px"
      me.hover_div.style.width = w + "px"//page.style.width
      me.hover_div.style.height = h + "px"//page.style.height

      me.hover_div.style.position = "absolute"
      me.hover_div.style.border = "1px solid #FFBA55"
      // console.log("set hover_div " + me.hover_div.dataset['id'] + " " + JSON.stringify(box))
    }
    setHoverBox(box);
    function removeHover() {
      if (me.hover_div) {
        me.hover_div.onpointerdown = undefined
        me.hover_div.onpointerleave = undefined
        me.my_canvas.removeChild(me.hover_div)
        me.hover_div = undefined

        if (me.pointer_id) {
          me.my_canvas.releasePointerCapture(me.pointer_id)
          me.pointer_id = undefined
        }
      }
    }


    function onHoverMouseMove(this: any, $event: any) {
      $event.stopPropagation()
      let x = $event.clientX
      let y = $event.clientY
      if (!x) {
        x = $event.layerX
        y = $event.layerY
      }
      var elements: any = document.elementsFromPoint(x, y);
      if (elements) {
        for (let i = 0; i < elements.length; i++) {
          let element = elements[i]
          let id = element.dataset['id']
          if (id) {
            if (id != element_id) {
              console.log("hover override id " + id + " element_id" + element_id)
              let k_type = element.dataset['k_type']
              if (k_type) {
                let drawObj = me.getDrawObj(k_type)
                let box1 = drawObj.getBox(id)
                me.hover_div.dataset['id'] = id
                me.hover_div.dataset['k_type'] = k_type
                // box = { x1: x, y1: y, x2: x + w, y2: y + h }
                let box2 = this.scaleBox(box1)
                setHoverBox(box2);
                return
              }
            }
          }
        }
      }
    }
    function onHoverMouseDown(this: any, $event: any) {
      let k_type = this.dataset['k_type']
      let id = this.dataset['id']
      if (me.checkToSign(k_type, id)) {
        return;
      }

      if (me.sel_tool_name != "none" && me.sel_tool_name != "erase") {
        me.pointer_id = $event.pointerId
        me.my_canvas.setPointerCapture(me.pointer_id)
        let offsetX = this.offsetLeft + $event.offsetX
        let offsetY = this.offsetTop + $event.offsetY
        me.canvasMouseDown(me.my_canvas, $event, offsetX, offsetY) //hover

        return;
      }
      $event.stopPropagation()
      removeHover()

      let drawObj = me.getDrawObj(k_type)
      if (me.sel_tool_name == "erase") {
        drawObj.erase(id)
        return
      }

      if (k_type == "line") {
        // console.log("#SEL " + k_type + " " + id)
        me.removeMultiSelBoxes()
        me.selectObj(id, k_type)
        return
      }

      me.selected_id = undefined
      me.selected_type = undefined

      me.canvas_service.sendCanvasMsg("box", "set", me.mouse_lbl, { x1: x, y1: y, x2: (x + w), y2: (y + h) })
      drawObj.preSelect(id, k_type)
      me.canvasStore.dispatch(new SetTool("none"))
      if (!$event.shiftKey) {
        me.removeMultiSelBoxes()
      }

      //look for no_anchors to see how sw prevent moving signatures
      let box1 = drawObj.getBox(id)
      if (box1) {
        let box2 = me.scaleBox(box1)
        me.processMultiSel(id, box2, k_type)
        if (!box1.no_anchors) {
          me.makeGroupFromBoxes()
        }
      }
      // } else {
      //   me.processSingleSel(element_id, box, k_type)
      // }
      if (me.hover_div) {
        me.my_canvas.removeChild(me.hover_div)
        me.hover_div = undefined
      }
    }
    function onHoverMouseLeave(this: any, $event: any) {
      removeHover()
    }

  }
  checkToSign(k_type: string, id: string) { //subclssed
    return false
  }
  processSelect(box: any, start_anchor?: any) {
    this.draw_polygon.select("")
    console.log("#PL call select")

    let me = this
    //The position as it moves
    let x1: number = 0
    let x2: number = 0
    let y1: number = 0
    let y2: number = 0

    //this are the proportinal dimesions used for ellipses
    // let e_x1: number = 0
    // let e_x2: number = 0
    // let e_y1: number = 0
    // let e_y2: number = 0
    //**************************************************************************
    function onAnchorDown(obj: any, $event: any) {
      $event.stopPropagation()
      me.pointer_id = $event.pointerId
      me.my_canvas.setPointerCapture(me.pointer_id)
      me.my_canvas.onpointermove = onAnchorMove
      me.my_canvas.onpointerup = onAnchorUp

      me.moving_anchor = obj //for move this is going to be the canvas
      // console.log("#dr anchor down " + me.move + " x " + me.x1 + " " + $event.offsetX + " " + $event.offsetY)

      me.setUpMultipeUndo()
    }

    function anchorDown(this: any, $event: any) {
      onAnchorDown(this, $event)
    }
    function onAnchorMove(this: any, $event: any) {
      if ($event.buttons == 0 || me.moving_anchor == this) { //No button or un-initialized
        return
      }
      if (me.startX == -1) {
        me.startX = $event.offsetX
        me.startY = $event.offsetX
      }
      let delta_x = $event.offsetX - me.startX
      let delta_y = $event.offsetY - me.startY

      let loc = me.moving_anchor.dataset['loc']
      let x = me.startX + delta_x
      let y = me.startY + delta_y
      console.log("#dr anchor move1 offx " + $event.offsetX + " off_y " + $event.offsetX + " loc " + loc + " delta_x " + delta_x + " delta_y " + delta_y + " x " + x + " y " + y)

      //one direction
      if (loc == "la") {
        x1 = x
        y1 = box.y1
        x2 = box.x2
        y2 = box.y2
      } else if (loc == "ra") {
        x1 = box.x1
        y1 = box.y1
        x2 = x
        y2 = box.y2
      } else if (loc == "ta") {
        x1 = box.x1
        y1 = y
        x2 = box.x2
        y2 = box.y2
        // me.setBox(me.x1, y, x, me.y2)
      } else if (loc == "ba") {
        console.log("#draw sbm br ")
        x1 = box.x1
        y1 = box.y1
        x2 = box.x2
        y2 = y
      } else {  //multi dir

        if (loc == "tl") {
          x1 = x
          y1 = y
          x2 = box.x2
          y2 = box.y2
        } else if (loc == "bl") {
          x1 = x
          y1 = box.y1
          x2 = box.x2
          y2 = y
        } else if (loc == "tr") {
          x1 = box.x1
          y1 = y
          x2 = x
          y2 = box.y2
        } else if (loc == "br") {
          x1 = box.x1
          y1 = box.y1
          x2 = x
          y2 = y
        }

      }

      me.setBox(x1, y1, x2, y2) //in processSelect
      //now tell the object to move
      // if (drawObj) {
      //   drawObj.moveObj(id, x1, y1, x2, y2, box.data)
      // } else {
      let gbox = box

      let dx: number = 0
      let dy: number = 0
      if (gbox.x1 != x1) {
        dx = x1 - gbox.x1
      }
      if (gbox.y1 != y1) {
        dy = y1 - gbox.y1
      }

      // console.log("move group dx " + dx + " dy " + dy + " scale_x " + scale_x + " scale_y " + scale_y)

      let keys = Object.keys(me.multi_move_divs)
      let w = (x2 - x1)
      let h = (y2 - y1)
      let scale_x = w / (gbox.x2 - gbox.x1)
      let scale_y = h / (gbox.y2 - gbox.y1)


      for (let i = 0; i < keys.length; i++) {

        let key = keys[i]
        let back = me.multi_move_divs[key]
        let id = back.dataset['id']
        let k_type = back.dataset['k_type']
        let box = JSON.parse(back.dataset['box'])



        let o_x = (box.x1 - gbox.x1)
        let o_y = (box.y1 - gbox.y1)


        let bx1 = o_x * scale_x + gbox.x1 + dx
        let by1 = o_y * scale_y + gbox.y1 + dy

        let o_w = (box.x2 - box.x1) * scale_x
        let o_h = (box.y2 - box.y1) * scale_y
        let bx2 = bx1 + o_w
        let by2 = by1 + o_h


        let w = (bx2 - bx1)
        if (w > 0) {
          back.style.left = bx1 + "px"
        } else {
          w *= -1
          back.style.left = bx2 + "px"
        }
        back.style.width = w + "px"
        let h = (by2 - by1)
        if (h > 0) {
          back.style.top = by1 + "px"
        } else {
          h *= -1
          back.style.top = by2 + "px"
        }
        back.style.height = h + "px"
        // console.log("bsw " + back.style.width)

        let drawObj = me.getDrawObj(k_type)
        drawObj.moveObj(id, bx1, by1, bx2, by2, box.data) //onAnchorMove
      }

    }
    function onAnchorUp(this: any, $event: any) {
      console.log("#dr #MV DL anchor up ")

      me.my_canvas.onpointermove = undefined
      me.my_canvas.onpointerup = undefined
      me.moving_anchor = null;
      if (me.pointer_id) {
        this.releasePointerCapture(me.pointer_id)
        me.pointer_id = undefined
      }

      console.log("#MV DL  doneMoving anchor up ")
      let keys = Object.keys(me.multi_move_divs)
      if (keys.length == 1) {
        let back = me.multi_move_divs[keys[0]]
        let k_type = back.dataset['k_type']
        if (start_anchor) {
          console.log("#dr #MV new Obj ")
          //if the new object is to small, make it big
          let id = back.dataset['id']
          let drawObj = me.getDrawObj(k_type)
          drawObj.setMinSize(id)
          me.selectObj(id, k_type)
          if (k_type == "rectangle" || k_type == "ellipse") {
            let box = drawObj.getBox(id)
            me.processMultiSel(id, box, k_type)
            me.makeGroupFromBoxes()
          }
          return
        }
      }
      let state = me.getMultipleState()
      me.canvas_service.sendCanvasMsg("multi", "move", "", state)

      me.makeGroupFromBoxes()
    }

    function newAnchor(loc: string) {

      let anchor = document.createElement("div");
      anchor.style.width = "10px"//page.style.width
      anchor.style.height = "10px"//page.style.height
      anchor.style.position = "absolute"
      anchor.style.zIndex = "2"
      if (start_anchor) {
        anchor.style.background = "transparent"
      } else {
        anchor.style.background = "#DAE1F7"
      }
      anchor.dataset['loc'] = loc
      // anchor.dataset['id'] = id
      anchor.onpointerdown = anchorDown


      if (loc == "tl" || loc == "br") {
        anchor.style.cursor = 'nwse-resize'
      } else if (loc == "tr" || loc == "bl") {
        anchor.style.cursor = 'nesw-resize'
      } else if (loc == "ta" || loc == "ba") {
        anchor.style.cursor = 'ns-resize'
      } else if (loc == "la" || loc == "ra") {
        anchor.style.cursor = 'ew-resize'
      }
      me.my_canvas.appendChild(anchor)
      return anchor
    }
    function newLine(bIsVertical: boolean, loc: string) {
      let line = document.createElement("div");
      if (bIsVertical) {
        line.style.width = "1px"
      } else {
        line.style.height = "1px"
      }
      line.style.position = "absolute"
      line.style.background = "#DAE1F7"
      // line.dataset['id'] = id
      line.dataset['loc'] = loc

      me.my_canvas.appendChild(line)
      return line
    }

    function onBackDown(this: any, $event: any) {
      $event.stopPropagation()

      me.mouse_down_at = new Date().getTime()
      console.log("#UNSEL back down call sma " + me.mouse_down_at)


      me.pointer_id = $event.pointerId
      me.my_canvas.setPointerCapture(me.pointer_id)
      x1 = box.x1
      y1 = box.y1
      x2 = box.x2
      y2 = box.y2
      me.moving_anchor = this
      me.my_canvas.onpointermove = onAllMove
      me.my_canvas.onpointerup = onAllUp

    }

    function onAllMove(this: any, $event: any) {
      if ($event.buttons == 0 || me.moving_anchor == this) { //No button or un-initialized
        return
      }
      if (me.startX == -1) {
        me.startX = $event.pageX
        me.startY = $event.pageY
      }
      let delta_x = $event.pageX - me.startX
      let delta_y = $event.pageY - me.startY
      x1 += delta_x
      y1 += delta_y
      x2 += delta_x
      y2 += delta_y
      moveGroupDelta(delta_x, delta_y)
      me.startX = $event.pageX
      me.startY = $event.pageY
      me.setBox(x1, y1, x2, y2) //in onAllMove
    }
    function onAllUp(this: any, $event: any) {
      console.log("#dr #MV DL anchor up ")
      let now = new Date().getTime()
      let dif = now - me.mouse_down_at
      // if (dif < 500) {
      //
      //   if (k_type == "polygon"
      //     || k_type == "polyfill") {
      //     me.draw_polygon.select(id)
      //     me.removeInfoAndAnchors()
      //   }
      // }
      me.my_canvas.onpointermove = undefined
      me.my_canvas.onpointerup = undefined

      if (me.pointer_id) {
        this.releasePointerCapture(me.pointer_id)
        me.pointer_id = undefined
      }
      let d = (box.x1 - x1) * (box.x1 - x1) +
        (box.x2 - x2) * (box.x2 - x2) +
        (box.y1 - y1) * (box.y1 - y1) +
        (box.y2 - y2) * (box.y2 - y2)

      if (d > 4) {
        // me.addToUndo({
        //   undo: "move",
        //   data: box.svg,
        //   k_type: k_type,
        //   id: id,
        // })
      }
      console.log("#MV DL  done moving all up " + d)
      //we need to update the box for the next move
      // box.x1 = x1
      // box.y1 = y1
      // box.x2 = x2
      // box.y2 = y2
      me.makeGroupFromBoxes()
    }

    function moveGroupDelta(delta_x: number, delta_y: number) {
      let keys = Object.keys(me.multi_move_divs)
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        let back = me.multi_move_divs[key]
        let id = back.dataset['id']
        let k_type = back.dataset['k_type']
        let x1 = parseFloat(back.style.left)
        let y1 = parseFloat(back.style.top)
        let nx = x1 + delta_x
        let ny = y1 + delta_y

        back.style.left = nx + "px"
        back.style.top = ny + "px"

        let drawObj = me.getDrawObj(k_type)
        let dx = me.deScaleX(delta_x)
        let dy = me.deScaleY(delta_y)
        drawObj.moveDelta(id, dx, dy)
      }
    }

    if (!this.back_div) {
      if (this.my_canvas.children.length > 2) {
        this.back_div = document.createElement("div");
        this.back_div.style.position = "absolute"
        // this.back_div.style.background = "#FF000040"// "transparent"
        this.back_div.style.cursor = 'grab'
        this.back_div.onpointerdown = onBackDown
        let firstChild = this.my_canvas.children[1]
        this.my_canvas.insertBefore(this.back_div, firstChild)
      }
    }

    //where there is only one comment don't make the anchors
    let bMakeAnchors: boolean = true
    let keys = Object.keys(this.multi_move_divs)
    if (keys.length == 1) {
      let id = keys[0]
      let back = this.multi_move_divs[id]
      let k_type = back.dataset['k_type']
      if (k_type == "comment") {
        bMakeAnchors = false;
      }
    }

    if (!this.top_left_anchor && bMakeAnchors) {
      this.makeInfoBox()

      this.top_left_anchor = newAnchor("tl")
      this.top_right_anchor = newAnchor("tr")
      this.bottom_left_anchor = newAnchor("bl")
      this.bottom_right_anchor = newAnchor("br")

      this.top_anchor = newAnchor("ta")
      this.left_anchor = newAnchor("la")
      this.bottom_anchor = newAnchor("ba")
      this.right_anchor = newAnchor("ra")

    }
    if (!bMakeAnchors) {
      this.removeAnchors()
    }

    //make the lines
    if (!this.top_line) {
      this.top_line = newLine(false, "t")
      this.bottom_line = newLine(false, "b")
      this.left_line = newLine(true, "l")
      this.right_line = newLine(true, "r")
    }
    this.setBox(box.x1, box.y1, box.x2, box.y2) // moveGroupDelta

    if (start_anchor) {
      if (start_anchor.anchor == "br") {
        onAnchorDown(this.bottom_right_anchor, start_anchor.event)
      }
    }
  }
  makeInfoBox() {
    if (!this.info_div) {
      this.info_div = document.createElement("div");
      this.info_div.style.position = "absolute"
      this.info_div.style.color = "#152666"
      this.info_div.style.textAlign = "center";
      this.info_div.style.fontSize = "8px"
      this.my_canvas.appendChild(this.info_div)
    }
  }

  processMultiSel(id: string, box: any, k_type: any) {
    //Multiple selects
    this.removeInfoAndAnchors()
    if (this.hover_div) {
      this.my_canvas.removeChild(this.hover_div)
      this.hover_div = undefined
    }

    if (!this.multi_move_divs[id]) { //if it does not exist

      console.log("#MSel select ")
      if (!box) {
        let draw = this.getDrawObj(k_type)
        if (draw) {
          let b = draw.getBox(id)
          box = this.scaleBox(b)
        }
      }

      if (box) {
        let back = document.createElement("div");
        let w = box.x2 - box.x1
        let h = box.y2 - box.y1

        back.style.width = w + "px"
        back.style.height = h + "px"
        back.style.left = box.x1 + "px"
        back.style.top = box.y1 + "px"

        back.style.position = "absolute"
        back.style.border = "1px solid #DAE1F7"
        back.dataset['id'] = id
        back.dataset['k_type'] = k_type
        back.dataset['box'] = JSON.stringify(box)

        console.log("#MM down div " + id + " " + box.x1 + " " + box.y1 + " " + w + " " + h)
        this.my_canvas.appendChild(back)
        this.multi_move_divs[id] = back
        back.onpointerdown = multipleMoveDown
        // back.onmousemove = multiplBackMouseMove //onHoverMouseMove
      }
    }
    let me = this

    let last_move_down_id: string = ""
    let last_move_down_k_type: string = ""
    function multipleMoveDown(this: any, $event: any) {
      $event.stopPropagation()
      let state1 = me.getMultipleState()
      console.log("state1 " + JSON.stringify(state1))

      me.mouse_down_at = new Date().getTime()

      console.log("##UNSEL multpe move down" + me.mouse_down_at)
      me.pointer_id = $event.pointerId
      me.my_canvas.setPointerCapture(me.pointer_id)
      me.startX = -1
      me.setUpMultipeUndo()

      last_move_down_id = this.dataset['id']
      last_move_down_k_type = this.dataset['k_type']
      me.my_canvas.onpointermove = multipleMoveMove
      me.my_canvas.onpointerup = multipleMoveUp
      me.bEditComment = false
      me.bShowComment = false
    }
    function multipleMoveMove(this: any, $event: any) {
      $event.stopPropagation()
      if ($event.offsetX < 3 || $event.offsetY < 3) {
        console.log("#Mv Wrong py= " + $event.pageY + " x " + $event.offsetX + " y " + $event.offsetY + " start x " + me.startX + " y " + me.startY)
        return
      }
      if ($event.buttons == 0) { //No button or un-initialized
        return
      }
      //look for no_anchors to see how sw prevent moving signatures
      let keys = Object.keys(me.multi_move_divs)
      if (keys.length == 1) {
        let key = keys[0]
        let target = me.multi_move_divs[key]
        let sbox = target.dataset['box']
        if (sbox) {
          let box = JSON.parse(sbox)
          if (box.no_anchors) {
            return
          }
        }
      }

      if (me.startX == -1) {
        me.startX = $event.pageX
        me.startY = $event.pageY
      }
      let delta_x = $event.pageX - me.startX
      let delta_y = $event.pageY - me.startY
      if (delta_x > 20 || delta_x < -20 || delta_y > 20 || delta_y < -20) {
        console.log("#Mv Wierd py= " + $event.pageY + "  x " + $event.offsetX + " y " + $event.offsetY + " dx " + delta_x + " dy " + delta_y + "  start x " + me.startX + " y " + me.startY)
      }
      console.log("#Mv ok py= " + $event.pageY + " x " + $event.offsetX + " y " + $event.offsetY + " dx " + delta_x + " dy " + delta_y + "  start x " + me.startX + " y " + me.startY)

      // console.log("#MALL startY " + me.startY + +" offsetY " + $event.offsetY + " delta_y " + delta_y)
      let dx = me.deScaleX(delta_x)
      let dy = me.deScaleY(delta_y)

      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        let back = me.multi_move_divs[key]
        let x1 = parseFloat(back.style.left)
        let y1 = parseFloat(back.style.top)
        // let w = parseFloat(back.style.width)
        // let h = parseFloat(back.style.height)

        let nx = x1 + delta_x
        let ny = y1 + delta_y

        back.style.left = nx + "px"
        back.style.top = ny + "px"
        let id = back.dataset['id']
        let k_type = back.dataset['k_type']

        let draw = me.getDrawObj(k_type)
        draw.moveDelta(id, dx, dy)
      }

      me.startX = $event.pageX
      me.startY = $event.pageY

      if (me.top_left_anchor) {
        if (k_type == "arrow") {
          let x1 = me.scaleX(parseFloat(me.top_left_anchor.style.left) + 5 + delta_x)
          let y1 = me.scaleY(parseFloat(me.top_left_anchor.style.top) + 5 + delta_y)
          let x2 = me.scaleX(parseFloat(me.bottom_right_anchor.style.left) + 5 + delta_x)
          let y2 = me.scaleY(parseFloat(me.bottom_right_anchor.style.top) + 5 + delta_y)
          me.setBox(x1, y1, x2, y2) //in processMultiSel
        } else {
          let x1 = parseFloat(me.top_left_anchor.style.left) + 5 + delta_x
          let y1 = parseFloat(me.top_left_anchor.style.top) + 5 + delta_y
          let x2 = parseFloat(me.bottom_right_anchor.style.left) + 5 + delta_x
          let y2 = parseFloat(me.bottom_right_anchor.style.top) + 5 + delta_y
          me.setBox(x1, y1, x2, y2) //in processMultiSel
        }
      }




      // console.log("set box " + x1 + " " + y1 + " " + x2 + " " + y2 + " " + delta_x + " " + delta_y)


    }
    function multipleMoveUp(this: any, $event: any) {
      $event.stopPropagation()
      let now = new Date().getTime()
      console.log("#UNSEL UP " + now)

      if (me.pointer_id) {
        me.my_canvas.releasePointerCapture(me.pointer_id)
        me.pointer_id = undefined
      }


      let dif = now - me.mouse_down_at
      if (dif < 300) {
        if ($event.shiftKey) {
          let id = this.dataset['id']
          me.unselectOne(id)
        } else {
          // let id = this.dataset['id']
          // let k_type = this.dataset['k_type']
          me.selectObj(last_move_down_id, last_move_down_k_type)
          return
        }
      }

      //look for no_anchors to see how sw prevent moving signatures
      let keys = Object.keys(me.multi_move_divs)
      console.log("#MM up div " + JSON.stringify(keys))


      if (keys.length == 1) {
        let key = keys[0]
        let target = me.multi_move_divs[key]
        let sbox = target.dataset['box']
        if (sbox) {
          let box = JSON.parse(sbox)
          if (box.no_anchors) {
            return
          }
        }
      }
      me.makeGroupFromBoxes()
      let state = me.getMultipleState()
      me.canvas_service.sendCanvasMsg("multi", "move", "", state)
      console.log("state2 " + JSON.stringify(state))
      me.my_canvas.onpointermove = undefined
      me.my_canvas.onpointerup = undefined
    }
  }
  //move multiple utilities

  setBox(x1: number, y1: number, x2: number, y2: number) {
    let w = x2 - x1
    let h = y2 - y1
    let d = Math.sqrt(w * w + h * h)
    if (d > 10) {
      this.canvas_service.sendCanvasMsg("box", "set", this.mouse_lbl, { x1: x1, y1: y1, x2: x2, y2: y2 })
    }
    if (this.info_div) {
      let y = y2 + 10
      if (this.selected_type == "arrow" || this.selected_type == "line") {
        let l = Math.round(Math.sqrt(w * w + h * h))
        let a = (Math.round(Math.atan(h / w) * 180 / Math.PI * 100) / 100).toFixed(2)
        this.info_div.innerText = l + ", " + a + "\xB0"
      } else {
        this.info_div.innerText = Math.round(w) + " x " + Math.round(h)
      }
      this.info_div.style.left = x1 + "px"
      this.info_div.style.width = w + "px"
      this.info_div.style.top = y + "px"

    }
    if (this.back_div) {
      let w = x2 - x1
      if (w > 0) {
        this.back_div.style.left = x1 + "px"
        this.back_div.style.width = w + "px"
      } else {
        w = x1 - x2
        this.back_div.style.left = x2 + "px"
        this.back_div.style.width = w + "px"

      }
      let h = y2 - y1
      if (h > 0) {
        this.back_div.style.top = y1 + "px"
        this.back_div.style.height = h + "px"
      } else {
        h = y1 - y2
        this.back_div.style.top = y2 + "px"
        this.back_div.style.height = h + "px"
      }
    }
    if (this.top_left_anchor) {
      this.top_left_anchor.style.left = (x1 - 5) + "px"
      this.top_left_anchor.style.top = (y1 - 5) + "px"


      this.top_right_anchor.style.left = (x2 - 5) + "px"
      this.top_right_anchor.style.top = (y1 - 5) + "px"


      this.bottom_left_anchor.style.left = (x1 - 5) + "px"
      this.bottom_left_anchor.style.top = (y2 - 5) + "px"


      this.bottom_right_anchor.style.left = (x2 - 5) + "px"
      this.bottom_right_anchor.style.top = (y2 - 5) + "px"


      this.top_line.style.width = (x2 - x1) + "px"
      this.top_line.style.left = x1 + "px"
      this.top_line.style.top = y1 + "px"

      this.bottom_line.style.width = (x2 - x1) + "px"
      this.bottom_line.style.left = x1 + "px"
      this.bottom_line.style.top = y2 + "px"

      let h = (y2 - y1)
      let t = y1
      if (h < 0) {
        h *= -1
        t = y2
      }
      this.left_line.style.height = h + "px"
      this.left_line.style.left = x1 + "px"
      this.left_line.style.top = t + "px"

      this.right_line.style.height = h + "px"

      this.right_line.style.left = x2 + "px"
      this.right_line.style.top = t + "px"
    }

    if (this.left_anchor) {
      this.left_anchor.style.top = ((y2 + y1) / 2 - 5) + "px"
      this.left_anchor.style.left = (x1 - 5) + "px"

      this.right_anchor.style.top = ((y2 + y1) / 2 - 5) + "px"
      this.right_anchor.style.left = (x2 - 5) + "px"

      this.top_anchor.style.left = ((x2 + x1) / 2 - 5) + "px"
      this.top_anchor.style.top = (y1 - 5) + "px"

      this.bottom_anchor.style.left = ((x2 + x1) / 2 - 5) + "px"
      this.bottom_anchor.style.top = (y2 - 5) + "px"
    }
  }


  unselectOne(id: string) {
    console.log("unselect one " + id)
    let back = this.multi_move_divs[id]
    back.onpointerdown = undefined
    back.onpointerup = undefined
    this.my_canvas.removeChild(back)
    delete this.multi_move_divs[id]

    //Now we have to update the multiple box


    // this.removeMultiSelBoxes()
    // this.makeMultiGroup(b_x1, b_y1, b_x2, b_y2)

  }
  makeGroupFromBoxes(start_anchor?: any) {
    let keys = Object.keys(this.multi_move_divs)
    if (keys.length == 0) {
      return
    }

    let b_x1: number = 1000000;
    let b_y1: number = 1000000
    let b_x2: number = 0
    let b_y2: number = 0

    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      let back = this.multi_move_divs[key]
      let x1 = parseFloat(back.style.left)
      let y1 = parseFloat(back.style.top)
      let w = parseFloat(back.style.width)
      let h = parseFloat(back.style.height)
      let x2 = x1 + w
      let y2 = y1 + h
      if (b_x1 > x1) {
        b_x1 = x1
      }
      if (b_y1 > y1) {
        b_y1 = y1
      }
      if (x2 > b_x2) {
        b_x2 = x2
      }
      if (y2 > b_y2) {
        b_y2 = y2
      }
      //update the div box
      let id = back.dataset['id']
      let k_type = back.dataset['k_type']
      let drawObj = this.getDrawObj(k_type)
      let b = drawObj.getBox(id)
      let box = this.scaleBox(b)
      let nbox = JSON.stringify(box)
      back.dataset['box'] = nbox
    }

    this.removeInfoAndAnchors()
    let box = { x1: b_x1, y1: b_y1, x2: b_x2, y2: b_y2 }
    this.processSelect(box, start_anchor)

  }
  removeMultiSelBoxes() {

    let keys = Object.keys(this.multi_move_divs)
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      let back = this.multi_move_divs[key]
      back.onpointerdown = undefined
      back.onpointerup = undefined
      this.my_canvas.removeChild(back)
      delete this.multi_move_divs[key]
    }
  }

  removeInfoAndAnchors() {
    this.selected_obj = undefined
    if (this.info_div) {
      this.canvas_service.sendCanvasMsg("box", "remove", this.mouse_lbl)
      this.my_canvas.removeChild(this.info_div)
      this.info_div = undefined
    }
    this.removeAnchors()
    this.showGrid()
  }
  removeAnchors() {
    if (this.top_left_anchor) {
      this.my_canvas.removeChild(this.top_left_anchor)
      this.top_left_anchor = undefined
    }
    if (this.top_right_anchor) {
      this.my_canvas.removeChild(this.top_right_anchor)
      this.top_right_anchor = undefined
    }
    if (this.bottom_left_anchor) {
      this.my_canvas.removeChild(this.bottom_left_anchor)
      this.bottom_left_anchor = undefined
    }
    if (this.bottom_right_anchor) {
      this.my_canvas.removeChild(this.bottom_right_anchor)
      this.bottom_right_anchor = undefined
    }
    if (this.left_line) {
      this.my_canvas.removeChild(this.left_line)
      this.left_line = undefined
    }
    if (this.right_line) {
      this.my_canvas.removeChild(this.right_line)
      this.right_line = undefined
    }
    if (this.top_line) {
      this.my_canvas.removeChild(this.top_line)
      this.top_line = undefined
    }
    if (this.bottom_line) {
      this.my_canvas.removeChild(this.bottom_line)
      this.bottom_line = undefined
    }
    if (this.back_div) {
      this.my_canvas.removeChild(this.back_div)
      this.back_div = undefined
    }

    if (this.top_anchor) {
      this.my_canvas.removeChild(this.top_anchor)
      this.top_anchor = undefined
      this.my_canvas.removeChild(this.left_anchor)
      this.left_anchor = undefined
      this.my_canvas.removeChild(this.bottom_anchor)
      this.bottom_anchor = undefined
      this.my_canvas.removeChild(this.right_anchor)
      this.right_anchor = undefined
    }
    if (this.hover_div) {
      this.my_canvas.removeChild(this.hover_div)
      this.hover_div = undefined
    }
  }
  //****************************************************************************


  ngOnDestroy(): void { //This will only fire when we are not sublcassed
    console.log("Destroying "); // or when the child calls super.ngOnDestroy
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
    if (this.draw_tool) {
      let svg = this.draw_tool.svg(true)
      console.log("--> svg " + svg)
      this.canvasStore.dispatch(new SaveSVG(this._asset_id, svg))
    }
  }

  getDrawObj(k_type: string) {
    let draw: any
    if (k_type == "arrow") {
      draw = this.draw_arrow
    } else if (k_type == "comment") {
      draw = this.draw_comment
    } else if (k_type == "ellipse") {
      draw = this.draw_ellipse
    } else if (k_type == "pencil") {
      draw = this.draw_pencil
    } else if (k_type == "polygon" || k_type == "polyfill" || k_type == "line") {
      draw = this.draw_polygon
    } else if (k_type == "rectangle") {
      draw = this.draw_rectangle
    }
    // else if (k_type == "sign" || k_type == "initial") {
    //   draw = this.draw_sign
    // }
    else if (k_type == "text") {
      draw = this.draw_text
    } else if (k_type == "input") {
      draw = this.draw_input
    }
    return draw
  }


  getMultipleState() {
    let state: any[] = []

    let keys = Object.keys(this.multi_move_divs)
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      let back = this.multi_move_divs[key]

      let k_type = back.dataset['k_type']
      let id = back.dataset['id']
      let drawObj = this.getDrawObj(k_type)
      let svg = drawObj.copy(id)
      state.push({ id: id, k_type: k_type, data: svg })
    }
    return state
  }
  setUpMultipeUndo() {
    let state = this.getMultipleState()

    this.canvasStore.dispatch(new AddToUndo({
      undo: "multipe",
      k_type: "move",
      data: state
    }))
  }


  setMultipleState(state: any) {
    console.log("multipe undo")
    for (let i = 0; i < state.data.length; i++) {
      let entry = Object.assign({}, state.data[i])
      entry.canvas_width = state.canvas_width
      entry.canvas_height = state.canvas_height
      console.log(JSON.stringify(entry))
      let drawObj = this.getDrawObj(entry.k_type)
      if (drawObj) {
        if (entry.k_type == "arrow") {
          drawObj.processMsg(entry) //no handlers
        } else {
          drawObj.undo(entry)
        }
      }
    }
  }
  undo_stack: any[] = []
  addToUndo(jmsg: any) {
    this.undo_stack.push(jmsg)
    console.log("add to undo " + JSON.stringify(jmsg))
  }
  undo() {
    if (this.undo_stack.length > 0) {
      this.removeInfoAndAnchors()
      this.removeMultiSelBoxes()

      let undo = this.undo_stack.pop()
      if (undo.undo == "paste") {
        let pasted = undo.pasted
        for (let i = 0; i < pasted.length; i++) {
          let id = pasted[i].id
          let k_type = pasted[i].k_type
          let drawObj = this.getDrawObj(k_type)
          drawObj.erase(id)
        }

      } else if (undo.undo == "multipe") {
        this.setMultipleState(undo)
        this.canvas_service.sendCanvasMsg("multi", "undo", "", undo.data)
      } else {
        this.undoMsg(undo)
        /** tool: string, action: string, id: string, data?: any */
        this.canvas_service.sendCanvasMsg(undo.k_type, undo.undo, undo.id, undo.data)
      }
      if (this.undo_stack.length == 0) {
        this.canvasStore.dispatch(new SetTool("none"))
      }

    }
  }
  undoMsg(undo: any) {
    let draw = this.getDrawObj(undo.k_type)
    if (draw) {
      draw.undo(undo)
    }

  }
  setSVG(svg: any) {
    let bNeedsToCheckSteeps = false;
    if (svg) {
      this.draw_tool.clear()
      const viewBox = svg.node.viewBox.baseVal;

      let children = svg.children() //Each to g
      if (children) {
        for (let i = 0; i < children.length; i++) {
          let child: any = children[i]
          if (child.type == 'polyline') {
            this.draw_pencil.scketchFromSVG(child, viewBox)
          } else if (child.type == 'polygon') {
            if (child.node.dataset['k_type'] == "rectangle") {
              this.draw_rectangle.rectangleFromSVG(child, viewBox)
            } else {
              this.draw_polygon.polyFromSVG(child, viewBox)
            }
          } else if (child.type == 'ellipse') {
            this.draw_ellipse.ellipseFromSVG(child, viewBox)
          } else if (child.type == "g") { //the first child might be an empty svg container
            let dataset = child.node.dataset
            if (dataset) {
              let new_el: any
              let k_type = dataset['k_type']
              if (k_type) {
                if (k_type == 'comment') {
                  this.draw_comment.commentFromSVG(child, viewBox)
                }
                // else if (k_type == 'sign' || k_type == 'initial') {
                //   bNeedsToCheckSteeps = true
                //   this.draw_sign.signFromSVG(child, viewBox)
                // }
                else if (k_type == 'text') {
                  this.draw_text.textFromSVG(child, viewBox)
                } else if (k_type == 'arrow') {
                  this.draw_arrow.fromSVG(child, viewBox)
                } else if (k_type == "input") {
                  this.draw_input.inputFromSVG(child, viewBox)
                } else if (child.type == "g") {
                  new_el = this.draw_tool.group()
                  this.addGroupToGroup(child, new_el)
                } else {
                  let atrs = child.attr()
                  console.log("page_svg")
                  new_el = this.draw_tool.polygon(atrs['points']).fill(atrs['fill']).stroke({ width: atrs['stroke-width'], color: atrs['fill'] })
                }
                if (new_el) {
                  new_el.node.dataset['k_type'] = dataset['k_type']
                  new_el.node.dataset['k_target'] = dataset['k_target']
                  new_el.node.dataset['id'] = dataset['id']

                  this.groups_bb[new_el.node.dataset['id']] = this.canvas_service.getGroupBorders(new_el)
                  this.groups_ids = Object.keys(this.groups_bb)
                }
              }
            }
          }
        }
      }
      if (bNeedsToCheckSteeps) {
        this.canvas_service.checkSGV()
      }
    }
  }
  addCanvasToDiv(bScale: boolean, div: HTMLElement, page_svg: any | undefined, w?: number, h?: number) {
    const canvas = document.createElement("div");
    canvas.dataset["Keyzii_z"] = this.zoom.toString()
    this.bNoScale = !bScale

    this.my_canvas = canvas
    canvas.style.position = "absolute"
    canvas.style.overflow = "hidden"
    // canvas.style.background = "#ff000030"
    canvas.style.left = "0px"
    canvas.style.width = "100%"
    canvas.style.height = "100%"


    if (this.marging_top) { //set to 64 by images
      canvas.style.top = this.marging_top + "px"
      canvas.style.height = "calc(100% - " + this.marging_top + "px)"
    } else {
      canvas.style.top = "0px"
    }

    if (w) {
      canvas.style.width = w + "px"
      canvas.dataset["Keyzii_w"] = w.toString()
    }
    if (h) {
      canvas.style.height = h + "px"
      canvas.dataset["Keyzii_h"] = h.toString()
    }
    // canvas.style.background = "#ffaa0020"
    div.appendChild(canvas);
    this.draw_tool = SVG().addTo(canvas)
    let childern = canvas.children
    if (childern) {
      let svg_node = childern[0]
      if (svg_node && bScale) {
        this.viewBoxWidth = canvas.clientWidth
        this.viewBoxHeight = canvas.clientHeight
        this.canvas_service.setSVGDim(this.viewBoxWidth, this.viewBoxHeight)

        svg_node.setAttribute("viewBox", "0 0 " + this.viewBoxWidth + " " + this.viewBoxHeight);
      }
    }
    this.draw_tool.node.dataset['canvas'] = "main"
    this.draw_arrow = new kArrow(this.draw_tool, this, this.canvasStore, this.canvas_service, this.pagesState, this.wssState)
    this.draw_ellipse = new kEllipse(this.draw_tool, this, this.canvasStore, this.canvas_service, this.pagesState, this.wssState)
    this.draw_pencil = new kPencil(this.draw_tool, this, this.canvasStore, this.canvas_service, this.pagesState, this.wssState)
    this.draw_rectangle = new kRectangle(this.draw_tool, this, this.canvasStore, this.canvas_service, this.pagesState, this.wssState)
    this.draw_polygon = new kPolygon(this.draw_tool, this, this.canvasStore, this.canvas_service, this.pagesState, this.wssState)
    this.draw_text = new kText(this.draw_tool, this, this.canvasStore, this.canvas_service, this.pagesState, this.wssState)
    this.draw_input = new kInput(this.draw_tool, this, this.canvasStore, this.canvas_service, this.pagesState, this.wssState)
    // this.draw_sign = new kSign(this.draw_tool, this, this.canvasStore, this.canvas_service, this.pagesState, this.wssState)
    this.draw_comment = new kComments(this.draw_tool, this, this.canvasStore, this.canvas_service, this.pagesState, this.wssState)


    let el: HTMLElement = canvas
    let me = this


    if (page_svg) {
      console.log("page_svg")

      let svg: any = SVG(page_svg)
      this.setSVG(svg)
    } else {
      if (this.page_svg) {
        let svg: any = SVG(this.page_svg)
        this.setSVG(svg)
      }
    }



    let svg = this.draw_tool.node
    if (svg) {        //This is to keep the aspect ratio in an image
      svg.style.width = "100%"
      svg.style.height = "100%"
    }

    if (el.parentNode) {
      el.addEventListener('pointerdown', onMouseDown, false);
      el.addEventListener('touchstart', onMouseDown, false);
      el.addEventListener('pointermove', onMouseMove, false);
      el.addEventListener('touchmove', onMouseMove, false);
      el.addEventListener('pointerup', onMouseUp, false);
      el.addEventListener('touchend', onMouseUp, false);
    }
    if (this.caller_svg) {
      this.initFromCaller(this.caller_svg)
    }
    this.showGrid()
    return this.draw_tool

    //**************************************************************************
    // Mouse Down
    //**************************************************************************
    function onMouseDown(this: any, $event: any) {
      me.canvasMouseDown(this, $event, $event.offsetX, $event.offsetY)
    }

    //**************************************************************************
    // Mouse Move
    //**************************************************************************
    function onMouseMove(this: any, $event: any) {
      me.canvasMouseMove(this, $event)
    }
    //**************************************************************************
    // Mouse Up
    //**************************************************************************
    function onMouseUp(this: any, $event: any) {
      let now = new Date().getTime()
      let dif = now - me.mouse_down_at

      if (dif < 500) {
        if (me.signed($event)) {
          return
        }
      }

      let bDidMultiGroup: boolean = false
      if (me.select_div) {
        if (me.pointer_id) {
          this.releasePointerCapture(me.pointer_id)
          me.pointer_id = undefined
        }

        let left = parseFloat(me.select_div.style.left)
        let top = parseFloat(me.select_div.style.top)
        let width = 0
        if (me.select_div.style.width) {
          width = parseFloat(me.select_div.style.width)
        }
        if (width > 10) {
          let right = width + left
          let bottom = parseFloat(me.select_div.style.height) + top
          me.makeMultiGroup(left, top, right, bottom)
          bDidMultiGroup = true;
        }
        me.my_canvas.removeChild(me.select_div)
        delete me.select_div
      }
      if (!bDidMultiGroup) {
        if (dif < 500) {
          if (me.sel_tool_name != 'pencil' && me.sel_tool_name != 'none') {
            me.newObj($event, $event.offsetX, $event.offsetY)
          }
        }
      }
      if (me.sel_tool_name == 'pencil') {
        me.draw_pencil.onMouseUp($event)
      }
    }
  }
  signed($event: any) { //subclassed
    return false
  }

  scaleX(x1: number) {
    if (this.bNoScale) {
      return x1;
    }
    x1 = x1 * this.my_canvas.clientWidth / this.viewBoxWidth
    return x1
  }
  scaleY(y1: number) {
    if (this.bNoScale) {
      return y1;
    }
    y1 = y1 * this.my_canvas.clientHeight / this.viewBoxHeight
    return y1
  }
  deScaleX(x1: number) {
    if (this.bNoScale) {
      return x1;
    }
    x1 = x1 * this.viewBoxWidth / this.my_canvas.clientWidth
    return x1
  }
  deScaleY(y1: number) {
    if (this.bNoScale) {
      return y1;
    }
    y1 = y1 * this.viewBoxHeight / this.my_canvas.clientHeight
    return y1
  }
  scaleBox(box1: any) {
    if (this.bNoScale) {
      return box1;
    }
    if (box1) {
      let box = Object.assign({}, box1)
      if (box1.x) {
        box.x = this.scaleX(box1.x)
        box.x1 = box.x
      }
      if (box1.x1) {
        box.x1 = this.scaleX(box1.x1)
      }
      if (box1.x2) {
        box.x2 = this.scaleX(box1.x2)
      }

      if (box1.y) {
        box.y = this.scaleY(box1.y)
        box.y1 = box.y
      }
      if (box1.y1) {
        box.y1 = this.scaleY(box1.y1)
      }
      if (box1.y2) {
        box.y2 = this.scaleY(box1.y2)
      }
      if (box1.h) {
        box.h = this.scaleY(box1.h)
        box.height = box.h
      }
      if (box1.height) {
        box.height = this.scaleY(box1.height)
      }
      if (box1.w) {
        box.w = this.scaleX(box1.w)
        box.width = box.w
      }
      if (box1.width) {
        box.width = this.scaleX(box1.width)
      }

      if (box1.cx) {
        box.cx = this.scaleX(box1.cx)
      }
      if (box1.cy) {
        box.cy = this.scaleY(box1.cy)
      }
      return box
    }
    return undefined
  }
  scalePoints(poly_array: number[]) { //used after a move\
    if (this.bNoScale) {
      return poly_array;
    }
    let new_points: number[] = []
    console.log("poly set anchors ")
    for (let i = 0; i < poly_array.length; i += 2) {
      new_points.push(this.scaleX(poly_array[i]))
      new_points.push(this.scaleY(poly_array[i + 1]))
    }
    return new_points
  }

  deScalePoints(poly_array: number[]) { //used after a move\
    if (this.bNoScale) {
      return poly_array;
    }
    let new_points: number[] = []
    console.log("poly set anchors ")
    for (let i = 0; i < poly_array.length; i += 2) {
      new_points.push(this.deScaleX(poly_array[i]))
      new_points.push(this.deScaleY(poly_array[i + 1]))
    }
    return new_points
  }

  scaleXToWidth(x1: number, width: number) {
    if (this.bNoScale) {
      return x1;
    }
    // x1 = x1 * this.my_canvas.clientWidth / width
    x1 = x1 * this.viewBoxWidth / width //This work with dropped objects

    return x1
  }
  scaleYtoHeight(y1: number, height: number) {
    if (this.bNoScale) {
      return y1;
    }
    // y1 = y1 * this.my_canvas.clientHeight / height
    y1 = y1 * this.viewBoxHeight / height
    return y1
  }
  scalePointsToViewBox(poly_array: number[], viewBox: any) { //used after a move\
    if (this.bNoScale) {
      return poly_array;
    }
    let new_points: number[] = []
    console.log("poly set anchors ")
    for (let i = 0; i < poly_array.length; i += 2) {
      new_points.push(this.scaleXToWidth(poly_array[i], viewBox.width))
      new_points.push(this.scaleYtoHeight(poly_array[i + 1], viewBox.height))
    }
    return new_points
  }

  canvasMouseDown(obj: any, $event: any, offsetX: number, offsetY: number) {

    if (obj) {
      if (this.sel_tool_name) { //This creates the new object

        let left = offsetX
        let top = offsetY
        let right = left + 1 //ellipse
        let bottom = top + 1
        console.log("on mouse down " + typeof $event.srcElement)

        let newRv: any
        if (this.sel_tool_name == 'arrow' && !this.drawing_line) {
          if (this.draw_arrow) {
            newRv = this.draw_arrow.newObj(left, top, right, bottom)
          }
        }
        else if (this.sel_tool_name == 'polyfill' || this.sel_tool_name == 'polygon' || this.sel_tool_name == 'line') {
          newRv = this.draw_polygon.newObj(left, top, right, bottom, this.sel_tool_name)
        }
        else if (this.sel_tool_name == 'eclipse') {
          newRv = this.draw_ellipse.newObj(left, top, right, bottom)
        }
        else if (this.sel_tool_name == 'rectangle') {
          newRv = this.draw_rectangle.newObj(left, top, right, bottom)
        }
        if (newRv) {
          this.selected_id = undefined
          this.selected_type = undefined
          this.removeMultiSelBoxes()
          let box = this.scaleBox(newRv.box)
          this.processMultiSel(newRv.id, box, newRv.k_type)
          this.makeGroupFromBoxes({ event: $event, anchor: "br" }) //This is the mouse move to size the new object
          this.canvasStore.dispatch(new SetTool("none"))
          return
        }
      }

      this.mouse_down_at = new Date().getTime()

      this.startX = -1 //To move the line and arrow
      this.startY = -1

      let group: G | undefined
      this.canvasStore.dispatch(new SelectToolOpts("c2"))
      if ($event.shiftKey) {
        this.canvasStore.dispatch(new SetTool("none"))
      }
      if (this.sel_tool_name == 'pencil') {
        this.newObj($event, offsetX, offsetY)
      } else {
        if (this.selected_type == "arrow"
          || this.selected_type == "line") {
          let drawObj = this.getDrawObj(this.selected_type)
          let box = this.scaleBox(drawObj.getBox(this.selected_id))
          if (offsetX > box.x1 && offsetX < box.x2) {
            if (offsetY > box.y1 && offsetY < box.y2) {
              return
            }
          }
        }
        this.selected_id = undefined
        this.selected_type = undefined
        // console.log("#SEL " + this.selected_id + " " + this.selected_type)
        this.removeInfoAndAnchors()
        this.removeMultiSelBoxes()
        if (!this.select_div && !this.bIsSigningContract) {
          this.draw_polygon.select("x")
          this.draw_arrow.select("x")
          this.selected_id = undefined
          this.selected_type = undefined
          this.select_div = document.createElement('div')
          this.select_div.style.left = offsetX + "px"
          this.select_div.style.top = offsetY + "px"
          this.select_div.style.position = "absolute"
          this.select_div.style.border = "1px solid red"
          this.my_canvas.appendChild(this.select_div)
          this.pointer_id = $event.pointerId
          this.my_canvas.setPointerCapture(this.pointer_id)
          this.my_canvas.onpointermove = canvasMouseMove
        }
      }
    }
    let me = this
    function canvasMouseMove(this: any, $event: any) {
      me.canvasMouseMove(this, $event)
    }
  }

  canvasMouseMove(obj: any, $event: any) {
    if (!$event) {
      console.log("!$event")
    }
    // if the mouse is down move the object
    // console.log("canvas mouse move " + $event.offsetX + " " + $event.offsetY)
    if (this.selected_type == "arrow"
      || this.selected_type == "line") {
      let drawObj = this.getDrawObj(this.selected_type)
      if ($event.buttons == 1) { //left button down
        if (this.startX < 0) {
          this.startX = $event.offsetX
          this.startY = $event.offsetY
        }
        let delta_x = $event.offsetX - this.startX
        let delta_y = $event.offsetY - this.startY
        // console.log("Move the line " + delta_x + ", " + delta_y)
        let dx = this.deScaleX(delta_x)
        let dy = this.deScaleY(delta_y)
        drawObj.moveDelta(this.selected_id, dx, dy)
        this.startX = $event.offsetX
        this.startY = $event.offsetY
        // console.log("canvas mouse move r1")
        return
      }
    }
    if (this.sel_tool_name == 'pencil') {
      this.draw_pencil.onMouseMove($event)
    }

    let x = $event.clientX
    let y = $event.clientY
    if (!x) {
      x = $event.layerX
      y = $event.layerY
    }
    var elements: any = document.elementsFromPoint(x, y);
    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        let element = elements[i]
        if (element.localName != "div") {
          let id = element.dataset['id']
          let k_type = element.dataset['k_type']
          if (!id) {
            let parent = element.parentElement
            if (parent) {
              id = parent.dataset['id']
              k_type = parent.dataset['k_type']
            }
          }
          if (id) {
            // console.log("select hover override id " + id + " " + k_type + " " + $event.buttons)
            if (k_type) {
              let drawObj = this.getDrawObj(k_type)
              //if the object is selectable and its allready selected dont hver
              if (this.selected_id) {
                if (this.selected_id == id) {
                  // console.log("#SEL " + this.selected_id + " id " + id + " k_type " + k_type)
                  if (
                    k_type == "arrow"
                    || k_type == "line"
                  ) {
                    // console.log("canvas mouse move r2")
                    return
                  }
                }
              }
              if (this._asset && this._asset.type == "contract") {
                let dataset = element.dataset
                if (!dataset.id) {
                  dataset = element.parentNode.dataset
                }
                if (!this.canSignerMoveObject(dataset)) {
                  return
                }
              }
              if (this.selected_obj) {
                if (this.selected_obj.k_type == "polygon" || this.selected_obj.k_type == "polyfill") {
                  return
                }
              }
              let box1 = drawObj.getBox(id)
              if (box1) {
                let box2 = this.scaleBox(box1)


                this.onHover1(id, box2, k_type)
                // console.log("canvas mouse move r3")
              }
              return
            }
          }
        }
      }
    }

    if (!this.hover_div) {//the mouse message is porcesded in canvas lastMsg
      this.canvas_service.sendCanvasMsg("mouse", "move", this.mouse_lbl, {
        x: $event.offsetX,
        y: $event.offsetY,
        client_width: $event.currentTarget.clientWidth,
        client_height: $event.currentTarget.clientHeight,
      })
    }
    if (this.select_div) {
      let x1 = parseFloat(this.select_div.style.left)
      let y1 = parseFloat(this.select_div.style.top)
      let x2 = $event.offsetX
      let y2 = $event.offsetY
      if (x1 > x2) { let t = x1; x1 = x2; x2 = t }
      if (y1 > y2) { let t = y1; y1 = y2; y2 = t }
      let w = x2 - x1
      let h = y2 - y1
      this.select_div.style.left = x1 + "px"
      this.select_div.style.top = y1 + "px"
      this.select_div.style.width = w + "px"//page.style.width
      this.select_div.style.height = h + "px"//page.style.height
    }

    this.last_x = $event.offsetX
    this.last_y = $event.offsetY

    this.cuurent_div = obj
    if (this.cuurent_div) {


      if (this.sel_tool_name) {
        let sel_tool
        if (this.sel_tool_name == "drop_input") {
          sel_tool = this.tools[this.selected_input]
        } else {
          sel_tool = this.tools[this.sel_tool_name]
        }


        if (sel_tool) {
          if (sel_tool.cursor) {
            let click = ""
            if (sel_tool.hasOwnProperty('x')) {
              click = sel_tool.x + " " + sel_tool.y
            }
            this.cuurent_div.style.cursor = 'url(assets/images/' + sel_tool.cursor + ') ' + click + ', pointer'
            return
          }
        }
      }
      this.cuurent_div.style.cursor = "auto"
    }
  }
  canSignerMoveObject(dataset: any) {
    return true
  }
  newObj($event: any, offsetX: number, offsetY: number) { //called onMouseUp when the tools is not a pencil
    console.log("new obj " + typeof $event.srcElement)
    this.removeInfoAndAnchors()
    this.removeMultiSelBoxes()

    let newRv: any
    if (this.sel_tool_name == 'arrow' && !this.drawing_line) {
      if (this.draw_arrow) {
        let rv = this.draw_arrow.onMouseDown($event)
        this.selectObj(rv.id, rv.k_type)
      }
    }
    else if (this.sel_tool_name == 'polyfill' || this.sel_tool_name == 'polygon' || this.sel_tool_name == 'line') {
      let rv = this.draw_polygon.onMouseDown($event, this.sel_tool_name)
      this.selectObj(rv.id, rv.k_type)
    } else if (this.sel_tool_name == 'comment') {
      newRv = this.draw_comment.onMouseDown($event)
    } else if (this.sel_tool_name == 'eclipse') {
      newRv = this.draw_ellipse.onMouseDown($event)
    } else if (this.sel_tool_name == 'rectangle') {
      newRv = this.draw_rectangle.onMouseDown($event)
    } else if (this.sel_tool_name == 'pencil') {
      newRv = this.draw_pencil.onMouseDown($event, offsetX, offsetY)
    } else if (this.sel_tool_name == 'drop_text') {
      newRv = this.draw_text.onMouseDown($event)
    } else if (this.sel_tool_name == 'drop_input') {
      newRv = this.draw_input.onMouseDown($event)
    }
    // else if (this.sel_tool_name == 'drop_signature') {
    //   newRv = this.draw_sign.onMouseDown($event, 'sign')
    // } else if (this.sel_tool_name == 'drop_initials') {
    //   newRv = this.draw_sign.onMouseDown($event, 'initial')
    // }
    if (newRv) {
      let box = this.scaleBox(newRv.box)
      this.removeMultiSelBoxes()
      this.processMultiSel(newRv.id, box, newRv.k_type)
      this.makeGroupFromBoxes()
      //this.processSingleSel(newRv.id, newRv.box, newRv.k_type)
    }
    if (this.sel_tool_name != 'pencil') {
      this.canvasStore.dispatch(new SetTool("none"))
    }
    // this.canvasStore.dispatch(new SetInput("")) if we are trying to unselect it, do in in the sel-input, otherwise it will fail siging
  }

  makeMultiGroup(left: number, top: number, right: number, bottom: number) {
    //Find the objects inside the selected box and select them
    let children = this.draw_tool.children()
    let b_x1: number = 1000000;
    let b_y1: number = 1000000
    let b_x2: number = 0
    let b_y2: number = 0
    for (let i = 0; i < children.length; i++) {
      let group = children[i]
      let bbox = group.bbox()
      if (bbox.x2 > left) {
        if (bbox.x < right) {
          if (bbox.y2 > top) {
            if (bbox.y < bottom) {
              let id = group.node.dataset['id']
              let k_type = group.node.dataset['k_type']
              console.log("Select " + id)
              let drawObj = this.getDrawObj(k_type)
              let nbox0 = drawObj.getBox(id)
              let nbox = this.scaleBox(nbox0)

              if (b_x1 > nbox.x1) {
                b_x1 = nbox.x1
              }
              if (b_y1 > nbox.y1) {
                b_y1 = nbox.y1
              }
              if (b_x2 < nbox.x2) {
                b_x2 = nbox.x2
              }
              if (b_y2 < nbox.y2) {
                b_y2 = nbox.y2
              }
              this.processMultiSel(id, nbox, k_type)
            }
          }
        }
      }
    }
    let box = { x1: b_x1, y1: b_y1, x2: b_x2, y2: b_y2 }
    this.processSelect(box)

  }
  findGroupById(id: string) {
    let children = this.draw_tool.children()
    for (let i = 0; i < children.length; i++) {
      let group = children[i]
      let group_id = group.node.dataset['id']
      if (group_id == id) {

        console.log("found " + id)
        return group;
      }
    }
  }
  removeGroupById(id: string) {
    let children = this.draw_tool.children()
    for (let i = 0; i < children.length; i++) {
      let group = children[i]
      let group_id = group.node.dataset['id']
      if (group_id == id) {
        delete this.groups_bb[group_id]
        this.groups_ids = Object.keys(this.groups_bb)
        let jmsg = {
          asset_id: this._asset.asset_id,
          canvas_erase: group.node.dataset['id']
        }
        this.wss_service.sendMessageToOtherMembers(jmsg)

        //in order to unerase, we need the dataset of the group and the svg
        let dataset = group.node.dataset
        let svg = group.svg()

        this.canvasStore.dispatch(new AddToUndo({
          undo: "erase",
          dataset: dataset,
          svg: svg
        }))

        group.remove()
        this.canvasStore.dispatch(new SVGModified())
        break;
      }
    }
  }
  addGroupToGroup(source: any, target: G) {
    let nodes = source.children()
    if (nodes) {
      //If we dont do the object assing the nodes disapear from the source when they are added to the target
      let the_nodes = Object.assign([], nodes);
      for (let i = 0; i < the_nodes.length; i++) {
        let node: any = the_nodes[i]
        if (node.type == "g") {
          this.addGroupToGroup(node, target)

        } else {
          target.add(node)
        }
      }
    }
  }

  dump(label: string) {
    console.log("##D " + label)
    let svg = this.draw_tool.svg(true)
    console.log("##D " + JSON.stringify(svg))

  }
  gotAsset() {

  }

  //****************************************************************************
  // download
  //****************************************************************************


  async download() {

    let svg = this.draw_tool.svg()
    //
    // const pdfDoc = await PDFDocument.create()
    // const page = pdfDoc.addPage()
    // page.drawText('You can create PDFs!')
    // const pdfBytes = await pdfDoc.save()


    console.log("Down load  it")
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    //  var enc = new TextEncoder();
    let html = '<div style="height:960px; width:1280px; background:#fff">' + svg + '</div>'
    const blob = new Blob([html]);
    const url1 = window.URL.createObjectURL(blob);
    a.href = url1;
    let name = "KEyzii_" + new Date().getTime() + ".html"
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url1);
    console.log("downloaded pdf ");
  }

  addToChat(role: string) { //sub classed

  }

  initFromCaller(msg: any) {
    if (msg.data) {
      if (msg.data.ssvg) {
        let svg: any = SVG(msg.data.ssvg)
        let children = svg.children() //Each to g
        if (children) {
          for (let i = 0; i < children.length; i++) {
            let child: any = children[i]
            if (child.node) {
              let dataset = child.node.dataset
              if (dataset) {
                if (dataset["k_type"] == 'sign' || dataset["k_type"] == 'initial') {
                  if (dataset.done == "true") {

                    let vb = {
                      width: msg.canvas_width,
                      height: msg.canvas_height
                    }
                    // this.draw_sign.removeGroup(dataset["id"])
                    // this.draw_sign.signFromSVG(child, vb)

                    console.log("this needs to be updated")
                  }
                }
              }
            }
          }
        }
      }
    }

    // let drawObj = this.getDrawObj(msg.canvas)
    // if (drawObj) {
    //   drawObj.processMsg(msg)
    // }
  }
  setAspectRatio() { //sub class it

  }


  setRemoteAspectRatio() {
    this.aspect_ratio = this.my_canvas.clientHeight / this.my_canvas.clientWidth,
      this.canvas_service.sendCanvasMsg("ar", "set", this.mouse_lbl, {
        aspect_ratio: this.aspect_ratio,
      })
  }
  bShowGrid: boolean = true
  h_lines: any[] = []
  v_lines: any[] = []
  showGrid() {
    if (!this.bShowGrid) {
      return
    }
    if (this.my_canvas) {
      let h = this.my_canvas.clientHeight
      let w = this.my_canvas.clientWidth
      console.log("showGird w " + w + " h " + h)
      let dy = h / 10
      let num_v_lines = Math.floor(w / dy)
      if (this.h_lines.length == 0) {
        for (let i = 0; i < 9; i++) {
          let line = document.createElement("div");
          line.style.position = "absolute"
          line.style.pointerEvents = "none"
          line.style.width = "100%";
          line.style.height = "1px";
          this.my_canvas.appendChild(line)
          this.h_lines.push(line)
        }
      }
      let y = dy
      for (let i = 0; i < this.h_lines.length; i++) {
        let line = this.h_lines[i];
        let m = (i + 1) % 5
        if (m == 0) {
          line.style.background = "#8F5CFF70"
        } else {
          line.style.background = "#8F5CFF30"
        }
        line.style.top = y + "px"
        line.style.left = "0px"
        y += dy
      }
      for (let i = 0; i < num_v_lines; i++) {
        let line = document.createElement("div");
        line.style.position = "absolute"
        line.style.pointerEvents = "none"
        line.style.width = "1px";
        line.style.height = "100%";
        this.my_canvas.appendChild(line)
        this.v_lines.push(line)
      }
      let x = dy
      for (let i = 0; i < this.v_lines.length; i++) {
        let line = this.v_lines[i];
        let m = (i + 1) % 5
        if (m == 0) {
          line.style.background = "#8F5CFF70"
        } else {
          line.style.background = "#8F5CFF30"
        }
        line.style.top = "0px"
        line.style.left = x + "px"
        x += dy
      }
    }
  }
}

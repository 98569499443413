<div class="out">
  <div class="header_container">
    <div class="header">
      <div *ngFor="let tab of tabs;let i = index " class="h_p font_sub_title" (click)=select(tab.id)>
        <div class="ctr">
          <div class="">
            {{tab.title}}
            <div *ngIf="tab.selected" class="selected">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="scroll scroll_left" *ngIf="show_scroll &&  hdr_left<0" (click)="scrollRight($event)">
      <img src="assets/images/scroll_l.svg" class="abs_center" alt="">
    </div>
    <div class="scroll scroll_right" *ngIf="show_scroll " (click)="scrollLeft($event)" [style.left.px]="mv_scr_left">
      <img src="assets/images/scroll_r.svg" class="abs_center" alt="">
    </div> -->
  </div>

  <div class="s_hdr">
    <div class="s_hdr_t">
      {{title}}
    </div>
    <div class="merchant_div" *ngIf="tab=='payments' && bEditMechantId">
      <input class="merchant" type="text" placeholder="Mechant id" [ngClass]="{bad:merchant_id.length!=7}" [(ngModel)]="merchant_id">
      <div class="saveIt" [ngClass]="{ready:merchant_id.length==7}" (click)="saveMerchantId()">
        <div class="abs_center">
          Save
        </div>
      </div>
    </div>
    <div class="merchant" *ngIf="tab=='payments' && !bEditMechantId">
      Merchant id: {{merchant_id}} <img class="" src="assets/images/edit_user.svg" alt="" (click)="bEditMechantId=true">

    </div>
    <k-search *ngIf="tab=='users'" class="search" type="name" placeholder="Search User" [(myModel)]="search" (myModelChange)="filterName($event)"></k-search>
    <k-filter *ngIf="tab=='users'" [exterior]="true" [min_width]="110" class="filter" title="Filter Role" [str_options]="user_types" (selected)="filterType($event)"></k-filter>
    <div *ngIf="roll=='admin' && tab=='users'" class="add_user" (click)="addUser()">
      <img class="abs_center" src="assets/images/new_user.svg" alt="">
    </div>
    <k-button class="save" *ngIf="bShowSave" min_width="100" min_height="40" [background]="save_back" [color]="save_color" text="Save" (click)="save()"></k-button>
    <k-button class="save bill_d" *ngIf="tab=='billing'" min_width="100" min_height="40" background="#000" color="#fff" text="Billing details" (click)="billingDet()"></k-button>
  </div>
  <!-- <div class="s_hdr" *ngIf="tab=='billing'">
    <k-search *ngIf="bShowBillingSearch" class="search" type="name" placeholder="Search invoice by date" [(myModel)]="search" (myModelChange)="filterName($event)"></k-search>
    <div *ngIf="!bShowBillingSearch" class="" (click)="bShowBillingSearch=true">
      <img class="bk" src="assets/images/back-arrow.svg" alt="">Billing Page
    </div>
  </div> -->
  <div class="body">
    <rep-contact *ngIf="tab=='contact'" (enableSave)="enableSave($event)" [save_it]="save_it"></rep-contact>
    <app-calendar *ngIf="tab=='calendar'" (enableSave)="enableSave($event)" [save_it]="save_it"></app-calendar>
    <app-customization *ngIf="tab=='custom'" (enableSave)="enableSave($event)" [save_it]="save_it"></app-customization>
    <app-users [add_user]="add_user" [filter_name]="filter_name" [filter_type]="filter_type" *ngIf="tab=='users'"></app-users>
    <app-links *ngIf="tab=='links'"> </app-links>
    <app-billing *ngIf="tab=='billing'"> </app-billing>
    <app-api *ngIf="tab=='api'" (enableSave)="enableSave($event)" [save_it]="save_it"></app-api>
    <app-payments [merchant_id]="merchant_id" *ngIf="tab=='payments'"></app-payments>
  </div>

</div>

<div class="out">
  <!-- <assets-header [bShowSigners]="false" class="hdr"> </assets-header> -->

  <iframe id="sdk-iframe" class="frame" [src]="url" allow="xr-spatial-tracking" allowFullScreen></iframe>

  <!-- <mat-icon *ngIf="bShowMenuIcon" iconColor="white" tooltip="Show Menu" class="menui" (click)="showMenu($event)"> menu</mat-icon> -->
  <!-- <k-menu *ngIf="bShowMenu" [pose]="pose" [menu]="asset.menu" class="menu" (close)="closeMenu()" (moveToId)="moveToId($event)"></k-menu> -->


</div>

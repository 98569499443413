<div class="out">
  <div class="content">
    <div class="row branding">
      Company Branding
    </div>
    <div class="row">
      <div class="section">
        <div class="title">Logo</div>
        <div class="svg_png w200">(SVG, PNG, JPEG) <span class="logo_size">200px x 90px</span></div>
        <div class="logo">
          <img *ngIf="!company_logo" class="up_logo" src="assets/images/upload2.svg" alt="upload logo" (click)="uploading='logo';file.click()">
          <img *ngIf="company_logo" class="logo" [src]="company_logo">
          <img *ngIf="company_logo" class="del" alt="del" src="assets/images/delete_g.svg" (click)="deleteLogo()">
        </div>
        <input id="file_input" #file class="hidden" (change)="selectedUploadImage($event)" type="file" name="image" ng2FileSelect [uploader]="uploader" accept="image/svg+xml,image/x-png,image/gif,image/jpeg" />
      </div>
      <div class="section right">
        <div class="title"> Icon </div>
        <div class="svg_png">(SVG, PNG, JPEG)</div>
        <div class=" logo_size"> </div>
        <div class="logo favicon">
          <img *ngIf="!company_icon" class="up_icon" src="assets/images/upload2.svg" alt="upload company icon" (click)="uploading='icon';file.click()">
          <img *ngIf="company_icon" class="logo favicon" [src]="company_icon">
          <img *ngIf="company_icon" class="del" alt="del" src="assets/images/delete_g.svg" (click)="deleteIcon()">
        </div>
      </div>
      <div class="section right">
        <div class="title"> Favicon </div>
        <div class="svg_png">(SVG, PNG, JPEG)</div>
        <div class=" logo_size"> </div>
        <div class="logo favicon">
          <img *ngIf="!company_favicon" class="up_icon" src="assets/images/upload2.svg" alt="upload fab icon" (click)="uploading='fav';file.click()">
          <img *ngIf="company_favicon" class="logo favicon" [src]="company_favicon">
          <img *ngIf="company_favicon" class="del" alt="del" src="assets/images/delete_g.svg" (click)="deleteFav()">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="section">
        <div class="row branding">
          Browser tab name
        </div>
      </div>
    </div>
    <div class="row">
      <div class="sel_bx">
        <div class="sel_color">
          <input class="br_bt w150" placeholder="Tab name" type="name" [ngModel]="tab_name" (ngModelChange)="changedBorwserTabName($event)">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="section">
        <div class="row branding">
          Dynamic Buttons
        </div>
        <div class="title">Button style</div>
        <div class="sel_bx">
          <div class="sel_color">
            <sel-color class="sc" name="buton_back" [(value)]="btn_back" (valueChange)="setBackColor($event)"> </sel-color>
            <div class="lbl font_sub_title">Button Color</div>
          </div>
        </div>
        <div class="sel_bx sel_bx2">
          <div class="sel_color">
            <sel-color class="sc" name="buton_text" [(value)]="btn_color" (valueChange)="setTextColor($event)"> </sel-color>
            <div class="lbl font_sub_title">Text Color</div>
          </div>
        </div>
        <div class="sel_bx">
          <div class="sel_color">
            <input class="br_bt" [ngModel]="border_radius1" (ngModelChange)="setBorder($event)">
            <div class="lbl btl font_sub_title">Button Radius</div>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="section">
        <div class="title cbo">Call button options</div>
      </div>
    </div>
    <!-- <div class="row ctr">
    <div class="check" [ngClass]="{selected:av=='video'}" (click)="av='video'"></div> <span class="av">Video only</span>
    <div class="check" [ngClass]="{selected:av=='audio'}" (click)="av='audio'"></div><span class=" av"> Audio only</span>
    <div class="check" [ngClass]="{selected:av=='both'}" (click)="av='both'"></div><span class=" av"> Both</span>
  </div> -->

    <div class="row">
      <div class="sel_bx">
        <div class="sel_color">
          <input class="br_bt w150" type="name" placeholder="Available Text" [ngModel]="avaliable" (ngModelChange)="changedAvailable($event)">

          <!-- <k-input width="150px" type="name" class="bname" placeholder="Available Text" [(myModel)]="avaliable" (myModelChange)="changedAvailable($event)"></k-input> -->
          <div class="btn s_btn" #available>
          </div>
          <div class="s_lbl" [style.left.px]="available_pos"> Example</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="sel_bx">
        <div class="sel_color">
          <input class="br_bt w150" placeholder="Offline Text" type="name" [ngModel]="offline" (ngModelChange)="changedOffLine($event)">

          <!-- <k-input width="150px" class="bname" type="name" placeholder="Available Text" [(myModel)]="offline" (myModelChange)="changedOffLine($event)"></k-input> -->
          <div class="btn s_btn" #off_line> </div>
          <div class="s_lbl" [style.left.px]="off_line_pos"> Example</div>
        </div>
      </div>
    </div>

  </div>
</div>

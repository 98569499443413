<div class="out">


  <div class="title">
    Account Details
  </div>
  <div class="line ">
    <input class="in" type="text" placeholder="Invoice title" name="" value="" [(ngModel)]="num_users">
    <span class="users"> Minumun number of users</span>
  </div>
  <div class="line ul">
    Amount/User
    <div class="filler"></div>
    <input class="in" type="text" placeholder="Invoice title" name="" value="" [(ngModel)]="user_price">
  </div>
  <div class="line sw_li">
    iFrame Pro<div class="filler"></div>
    <switch class="switch" [(checked)]="bIFrame" (checkedChange)="setIframe($event)"></switch>
  </div>
  <div class="line sw_li">
    Onboarding Pro<div class="filler"></div>
    <switch class="switch" [(checked)]="bOnBoard" (checkedChange)="setOnBoard($event)"></switch>
  </div>
  <div class="send" (click)="sendPlan()">
    <div class="abs_center btn">
      Send
    </div>
  </div>
  <img src="assets/images/close.svg" class="close" (click)="closeIt()">

</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fomatTime } from 'src/app/utils';
import { callWaiting } from 'src/app/pages/pages.selectors';
import { myId } from 'src/app/wss/wss.selectors';
import { audioOn, videoOn } from 'src/app/k-video/k-video.selectors';
import { Store } from '@ngrx/store';
import { PagesState } from 'src/app/pages/pages.model';
import { WSSState } from 'src/app/wss/wss.model';
import { WSSService } from 'src/app/wss/wss.service';
import { KVideoState } from 'src/app/k-video/k-video.model';

@Component({
  selector: 'wnd-line',
  templateUrl: './wnd-line.component.html',
  styleUrls: ['./wnd-line.component.scss']
})
export class WndLineComponent {
  _call: any = {}

  @Input() set call(q: any) {
    this._call = q
    this.setQueuEntry(q)

    if (this._call.start_calling_at) {

      if (!this.interval) {

        this.interval = setInterval(() => {

          if (this._call) {
            let now = new Date().getTime()
            this.waiting = fomatTime(now - this._call.start_calling_at)
          }

        }, 1000)
      }
    }
  }
  waiting: string = ""
  @Input() show_index: number = -1;
  @Input() index: number = -1;
  @Output() show_opts: EventEmitter<number> = new EventEmitter();


  caller_name: string = "n/a";
  caller_email: string = "n/a";
  prev_rep_name: string = ""
  agent: string = "";
  my_id: string = "";
  interval: any;
  queue_entry: any = {}
  bAudioOn: boolean = false;
  bVideoOn: boolean = false;
  bShowHangUp: boolean = false;
  prev_date: Date | undefined
  bShowPersonal: boolean = false;
  bShowOpts: boolean = false;
  new_caller: string = "New Caller"
  subscriptions: any[] = []

  constructor(
    public pagesState: Store<PagesState>,
    public wssState: Store<WSSState>,
    public wss_service: WSSService,
    public kVideoState: Store<KVideoState>,
  ) {
    console.log("wnd-line")
    this.subscriptions.push(this.kVideoState.select(audioOn).subscribe((on: boolean) => {
      this.bAudioOn = on
    }))
    this.subscriptions.push(this.kVideoState.select(videoOn).subscribe((on: boolean) => {
      this.bVideoOn = on
    }))
    this.subscriptions.push(this.wssState.select(myId).subscribe((my_id: any) => {
      this.my_id = my_id
    }))
    this.subscriptions.push(this.pagesState.select(callWaiting).subscribe((queue_entry: any) => this.setQueuEntry(queue_entry)))
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  setQueuEntry(queue_entry: any) {
    if (queue_entry) {
      this.queue_entry = queue_entry
    } else {
      this.queue_entry = {}
    }
    if (queue_entry) {
      if (queue_entry.caller_email) {
        if (queue_entry.caller_email.length > 0) {
          this.caller_email = queue_entry.caller_email
        }
      }

      if (queue_entry.caller_name) {
        if (queue_entry.caller_name.length > 0) {
          this.caller_name = queue_entry.caller_name
        }
      }
      this.agent = "Any agent"
      if (queue_entry.agent) {
        if (queue_entry.agent.length > 0) {
          this.agent = queue_entry.agent
        }
      }
      if (queue_entry.rep_id) {
        if (queue_entry.rep_id == this.my_id) {
          this.agent = "You"
          this.bShowHangUp = true
          this.bShowPersonal = true;
        }
      }

      if (queue_entry.prev_call) {
        this.new_caller = "Contact"
        // this.bShowPersonal = true;
        this.prev_rep_name = queue_entry.prev_call.rep_name
        this.prev_date = new Date(queue_entry.prev_call.start)
        if (this.caller_name == "n/a") {
          if (queue_entry.prev_call.caller_name) {
            this.caller_name = queue_entry.caller_name
          }
        }
        if (this.caller_name == "n/a") {
          if (queue_entry.prev_call.caller_name) {
            this.caller_name = queue_entry.caller_name
          }
        }
      }

    } else {
      this.caller_name = ""
      this.agent = ""
    }
  }

  expand() {
    this.bShowOpts = !this.bShowOpts
    let show = this.index
    if (!this.bShowOpts) {
      show = -1
    }
    this.show_opts.emit(show)
  }

}

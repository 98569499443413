<div class="out">
  <div class="line">
    {{signature_text}}
    <div class="check sing" [ngClass]="{selected:bSign}" (click)="signature()"></div>
  </div>

  <div class="line">
    {{initials_text}}
    <div class="check init" [ngClass]="{selected:bInit}" (click)="initials()"></div>
  </div>

  <!-- I am the signer -->

  <!-- <div class="line" *ngIf="bIAmSigner ">
    <div #signComponent class="sing_view" [style.width.px]="signature_width"></div>
    <div class="btn" (click)="editSignature()">
      {{sign_create_or_edit}}
    </div>
    <div class="check sing lower" [ngClass]="{selected:bSign}" (click)="signature()"></div>

  </div>

  <div class="line" *ngIf="bIAmSigner">
    <div #initComponent class="init_view" [style.width.px]="signature_width"></div>
    <div class="btn" (click)="editInitials()">
      {{init_create_or_edit}}
    </div>
    <div class="check init lower" [ngClass]="{selected:bInit}" (click)="initials()"></div>

  </div> -->
</div>

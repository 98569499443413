<div class="out">
  S
  <img class="search" src="assets/images/search.svg" (click)=searchNow()>
  <input class="tx" placeholder="Search State and City" [(ngModel)]="search" (keydown.enter)="searchNow()">
  <img class="exp" [ngClass]="{colapse:bLocShowOptions}" src="assets/images/expand.svg" (click)="showOptions()">

  <div *ngIf="bLocShowOptions" #optComponent class="options opts" [style.border]="border">
    <sel-state class="opt"></sel-state>
    <sel-city class="opt"></sel-city>
  </div>
</div>

<div class="out">


  <div class="folder f_title">
    <img *ngIf="path.length>1" class="back hand" src="assets/images/previous.svg" alt="" (click)="back()">
    <img *ngIf="path.length<2" class="back" src="assets/images/previous_g.svg" alt="">
    <path-title class="path" [ngClass]="{path_sm:filtered_folders.length}" [path]="path" (selected)=selPath($event)></path-title>

  </div>



  <div class="sfolder" *ngIf="num_sel_assets>0">
    <div *ngIf="filtered_folders.length==0" class="abs_center">
      No folders found
    </div>
    <div class="folder" *ngFor="let folder of filtered_folders; let i = index">
      <k-check class="check" [checked]="i == sel_folder_index" (checkedChange)="selFolder(i)"></k-check>
      <!-- <div class="check" [ngClass]="{selected: i == sel_folder_index}" (click)="selFolder(i)"></div> -->
      <span (click)="selFolder(i)">{{folder.name}} </span>
      <img *ngIf="folder.num_folders" class="next" src="assets/images/next.svg" alt="" (click)="nextFolder(i)">
    </div>
  </div>
  <div class="bfolder" *ngIf="num_sel_assets>0">
    <k-button class="abs_center" min_width="100" min_height="40" [disabled]="bMoveDisabled" background="#000" color="#fff" text="Move" (click)="moveAsset()"></k-button>
  </div>
  <div class="abs_center lbl1" *ngIf="num_sel_assets==0">
    Please select an asset to move
  </div>
  <img class="close" src="assets/images/close.svg" alt="" (click)="cancelMe()">
</div>

<div *ngIf="!isPhone" class="out" [ngClass]="{yellow:asset_type=='kuula' && bShowDrawTools}">
  <close-and-rename-asset [bSharingScr]="sharing_scr"></close-and-rename-asset>

  <div class="rtx present" *ngIf="asset_type=='kuula' && bShowDrawTools">
    <span class="fw700">Present mode:</span> To collaborate, draw inside yellow frame below
  </div>

  <div class="rtx present" *ngIf="sharing">
    When you move the mouse, the caller will see it, press the mouse to start drawing {{asset_type}}
  </div>

  <div class="right" *ngIf="bShowDrawTools">

    <div *ngIf="bShowSigners" class="font_text select" [style.border]="signer_border" (click)="selSigner($event)">
      <div class="ellipsis" [style.color]="signer_color">
        {{signer_lbl}}
      </div>
      <img *ngIf="bShowSigners" src="assets/images/expand.svg" class="expand" [ngClass]="{colapse:opened_tool=='sel-signer'}" (click)="selSigner($event)" />
    </div>

    <div *ngIf="bShowInputFields && bIsContract" class="font_text select sel_input" (click)="selInput($event)">
      <div class="ellipsis input_fields" [ngClass]="{disabled:signer_key.length==0}">
        Input Fields
      </div>
      <img *ngIf="bShowInputFields && bIsContract && signer_key.length>0" src="assets/images/expand.svg" class="expand" [ngClass]="{colapse:opened_tool=='sel-input'}" (click)="selInput($event)" />
      <img *ngIf="bShowInputFields && bIsContract && signer_key.length==0" src="assets/images/expand_g.svg" class="expand" [ngClass]="{colapse:opened_tool=='sel-input'}" (click)="selInput($event)" />
    </div>

    <!-- Heare are the tools, if the asset is a pdf add the signatures -->
    <div class="tools">
      <!-- <k-button *ngIf="bIsContract" icon="comment.svg" [background]="back_colors.comment" (click)="comment()"></k-button> -->

      <k-button *ngIf="bIsContract" class="btn " icon="sec_opts.svg" [background]="back_colors.settings" (click)="selectTool($event, 'pdf-opts')"></k-button>
      <img *ngIf="bIsContract" class="exp" [ngClass]="{colapse:opened_tool == 'pdf-opts'}" src="assets/images/expand.svg" (click)="selectTool($event, 'pdf-opts')">

      <k-button *ngIf="bIsContract" class="btn" icon="font_color.svg" [background]="back_colors.drop_text" (click)="selectTool($event, 'field-text')"></k-button>
      <img *ngIf="bIsContract" class="exp" [ngClass]="{colapse:opened_tool == 'text'}" src="assets/images/expand.svg" (click)="selectTool($event, 'field-text')">

      <div class="div_line">

      </div>
      <k-button class="btn" *ngIf="undo_stack_size==0" icon="undob.svg" background="transparent" (click)="undo()"></k-button>
      <k-button class="btn" *ngIf="undo_stack_size!=0" icon="undog.svg" background="transparent" (click)="undo()"></k-button>

      <k-button class="btn" icon="default.svg" [background]="back_colors.none" (click)="none()"></k-button>


      <k-button class="btn" icon="eraser.svg" [background]="back_colors.erase" (click)="erase()"></k-button>

      <k-button class="btn" icon="pallet.svg" [background]="back_colors.colors" (click)="selectTool($event, 'colors')"></k-button>
      <img class="exp" [ngClass]="{colapse:opened_tool == 'colors'}" src="assets/images/expand.svg" (click)="selectTool($event, 'colors')">

      <k-button class="btn" [icon]="shape_icon" [background]="back_colors.shapes" (click)="shapes()"></k-button>
      <img class="exp" [ngClass]="{colapse:opened_tool == 'sel_shape'}" src="assets/images/expand.svg" (click)="selectTool($event, 'sel_shape')">

      <k-button class="btn" icon="pencil.svg" [background]="back_colors.pencil" (click)="pencil()"></k-button>
      <img class="exp" [ngClass]="{colapse:opened_tool == 'pencil'}" src="assets/images/expand.svg" (click)="selectTool($event, 'pencil')">

      <k-button class="btn" icon="text.svg" [background]="back_colors.drop_text" (click)="text()"></k-button>
      <img class="exp" [ngClass]="{colapse:opened_tool == 'text'}" src="assets/images/expand.svg" (click)="selectTool($event, 'text')">

      <!-- <k-button class="btn" icon="cloud.svg" [background]="back_colors.cloud" (click)="down()"></k-button> -->

    </div>
  </div>
  <div class="right" *ngIf="asset_type=='kuula' && !bShowDrawTools">
    <div class="rtx">
      Enable draw mode below to annotate on shared asset
    </div>
  </div>

</div>
<div class="out" *ngIf="isPhone">
  <div class="filler">
  </div>

</div>

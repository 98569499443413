import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

import * as pdfjsLib from 'pdfjs-dist';
// import { OptionalContentConfig } from 'pdfjs-dist/types/src/display/optional_content_config';

if (pdfjsLib !== undefined) {
  console.log("set worker...");
  pdfjsLib.GlobalWorkerOptions.workerSrc = "https://npmcdn.com/pdfjs-dist@2.16.105/build/pdf.worker.js";
}

pdfjsLib: pdfjsLib

import {
  PDFDocument,
  rgb,
  PDFFont,
  PDFPage,
  StandardFonts,
  asPDFName,
  PDFHexString,
} from 'pdf-lib'

import { CanvasComponent } from '../canvas/canvas.component';
import { G, SVG } from '@svgdotjs/svg.js';
import fontkit from '@pdf-lib/fontkit'; //  "esModuleInterop": true, in tsconfig seems to
import { contractSigned, fieldTextFont, fieldTextSize, fieldTextWeight, goToStep, resizedCanvas, saveSVG, selSignerKey, signerEmail, signerPic, signers, signerSteps, signersWithSteps } from '../canvas.selectors';
import { callId, lastMsg } from 'src/app/wss/wss.selectors';
import { ClearSVGs, ClearCanvasState, PdfSvg, PdfSvgBlob, SelectToolOpts, SVGModified, SetFont } from '../canvas.actions';
import { closeProgress, isPhone, myEmail } from 'src/app/pages/pages.selectors';
import { animate, state, style, transition, trigger } from '@angular/animations';



@Component({
  selector: 'pdf-canvas',
  templateUrl: './pdf-canvas.component.html',
  styleUrls: ['./pdf-canvas.component.scss'],
  animations: [
    trigger('OpenClose', [
      state('open', style({
        width: "320px",//"492px", //35+2+150+2+150+2
      })),
      state('closed', style({
        width: "0px",
      })),
      transition('closed => open', [
        animate('.25s')
      ]),
      transition('open => closed', [
        animate('.25s')
      ]),

    ]),
    trigger('aOpenClose', [
      state('open', style({
        left: "320px",//"492px", //35+2+150+2+150+2
      })),
      state('closed', style({
        left: "0px",
      })),
      transition('closed => open', [
        animate('.25s')
      ]),
      transition('open => closed', [
        animate('.25s')
      ]),
    ])
  ],
})
export class PdfCanvasComponent extends CanvasComponent {
  // signer_key: string | undefined

  @ViewChild('contWnd') contWnd: ElementRef | undefined;

  @ViewChild('outContainer') outContainer: ElementRef | undefined;
  @ViewChild('textField') textField: ElementRef | undefined;


  @Input() bSigning: boolean = false
  error: string = ""

  pdf_src: string | undefined
  pdfDoc: PDFDocument | undefined
  pdflibDoc: any
  pdfData: ArrayBuffer | undefined
  signature_data: string | undefined
  initials_data: string | undefined
  // waiting_for_signature: any | undefined
  helveticaFont: any

  fonts: any = {}

  //****************************************************************************
  //To set the signature and initials boxes
  //****************************************************************************
  signers: any = {} //the signers to the document from the assets
  signers_with_steps: any = {}
  sel_signer: any; //the signature target
  sel_signer_key: string = "" //this is the one selected by the header

  //****************************************************************************
  //To sign or initial
  //****************************************************************************
  user_siging_key: string = "";//the is the key of the the user,
  //it might be  rep, caller manager or we use the email
  //to find the id of the  user in the signers
  bShowText: boolean = false
  text_borders: any;

  text_group: any
  stop_drag: boolean = true;

  step_left: number = -1
  step_top: number = -1

  bShowEmailWnd: boolean = false;
  bShowSigners: boolean = false;

  email_back_color: string = "#DADADA"

  meta: any = {}
  pdf_page_count: number = 0
  pdf_page_height: number = 0
  pdf_page_width: number = 0


  my_pic: string | undefined

  call_id: string | undefined

  isPhone: boolean = false
  bShowProgress: boolean = false

  state: string = "open"
  signer_email: string | undefined

  field_text_font: string = "OpenSans"
  field_text_weight: string = "Regular"
  field_text_size: string = "12px"

  in_scroll_to_debouncer: number = 0;
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.zoomChanged(this.zoom)
  }
  ngAfterViewInit() {
    console.log("pdf-canvas")
    this.signers_with_steps = {}
    this.email_back_color = "#DADADA"
    this.onResize()
    this.loadPDF();

  }


  ngOnInit() {
    this.subscriptions.push(this.wssState.select(lastMsg).subscribe((msg: any | undefined) => {
      if (msg && msg.field_text_font) {
        this.canvasStore.dispatch(new SetFont(msg.field_text_font,
          msg.field_text_weight,
          msg.field_text_size + "px", true))
        this.reDrawInputFields()
      } else if (msg.scroll_to && this.contWnd && this.divContainer) {
        if (msg.from != this.role) {
          let content_h = msg.content_h
          let scroll_to = msg.scroll_to / content_h * this.divContainer.nativeElement.scrollHeight
          this.in_scroll_to_debouncer = new Date().getTime()
          this.contWnd.nativeElement.scrollTo({
            top: scroll_to,
            behavior: 'smooth'
          });
        }
      }
    }))
    this.canvasStore.select(fieldTextWeight).subscribe((text_weight: string) => {
      this.field_text_weight = text_weight
      this.reDrawInputFields()
    })

    this.canvasStore.select(fieldTextSize).subscribe((text_size: string) => {
      this.field_text_size = text_size
      this.reDrawInputFields()
    })

    this.canvasStore.select(fieldTextFont).subscribe((text_font: string) => {
      this.field_text_font = text_font
      this.reDrawInputFields()
    })
    this.subscriptions.push(this.canvasStore.select(resizedCanvas).subscribe((num: number) => {
      if (num) {
        setTimeout(() => {
          this.zoomChanged(this.zoom)
        })
      }
    }))
    this.subscriptions.push(this.canvasStore.select(signerEmail).subscribe((email: string | undefined) => {
      if (email) {
        this.signer_email = email //by canvas service when it gets myEmail
      }
    }))
    this.subscriptions.push(this.pagesState.select(closeProgress).subscribe((num: number) => {
      if (num > 0) {
        this.state = "closed"
      }
    }))
    this.subscriptions.push(this.pagesState.select(isPhone).subscribe((isPhone: boolean) => {
      this.isPhone = isPhone;
      if (this.isPhone) {
        this.zoom = 100
      } else {
        if (this.call_id) {
          if (this._asset.type == "contract") {
            this.bShowProgress = true
          }
        } else {
          this.bShowProgress = false
        }
      }
    }))
    this.subscriptions.push(this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
      if (this.call_id) {
        if (this._asset.type == "contract") {
          this.bIsSigningContract = true;
          this.bShowProgress = (!this.isPhone)
        }
      }
    }))
    this.subscriptions.push(this.canvasStore.select(signerPic).subscribe((pic: string | undefined) => {
      this.my_pic = pic
    }))
    this.subscriptions.push(this.pagesState.select(myEmail).subscribe((email: string | undefined) => {
      if (email) {
        this.my_email = email
      }
    }))

    //This will be done by ContractSigned
    // this.subscriptions.push(this.canvasStore.select(numSigandInitials).subscribe((num: number) => {
    //
    //   if (num > 0) {
    //     this.email_back_color = "#151515"
    //     return
    //   }
    //   this.email_back_color = "#DADADA"
    //
    // }))
    this.subscriptions.push(this.canvasStore.select(contractSigned).subscribe((bSigned: boolean) => {
      if (bSigned) {
        this.email_back_color = "#151515"
        return
      }
      //if we are not in a live call enael the button
      this.email_back_color = "#DADADA"
    }))

    this.subscriptions.push(this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      if (call_id) {
        this.bSigning = true
      } else {
        this.bSigning = false
        this.setEmailBtn()
      }
    }))

    this.subscriptions.push(this.canvasStore.select(goToStep).subscribe(async (step: any) => {
      // console.log("mgb 3 " + new Date().getTime())
      if (step.left) {
        this.step_left = step.left - 24
        this.step_top = step.top + 2
        if (this.contWnd) {
          this.contWnd.nativeElement.scrollTo({
            top: step.top - 100,
            behavior: 'smooth'
          });
        }
      } else {
        this.step_left = 0
      }

      //look for no_anchors to see how sw prevent moving signatures
      //This is to prevent moving signed boxes
      this.removeMultiSelBoxes()
      this.removeInfoAndAnchors()
    }));
    this.subscriptions.push(this.canvasStore.select(saveSVG).subscribe(async (save_svg: any) => {
      if (save_svg) {
        let send_to = save_svg.send_to
        if (send_to) {
          if (send_to == "signed") {//send_to == "blockchain" ||
            if (this.pdfDoc) {
              await this.writeSvgToPDF(true)
              // if (send_to == "blockchain") {
              this.downloadContract(this.pdfDoc, "blockchain.pdf")
              // }
              let svg = this.draw_tool.svg(true)
              const pdfReadyBytes: Uint8Array = await this.pdfDoc.save()
              const readyBlob = new Blob([pdfReadyBytes]);
              this.canvasStore.dispatch(new PdfSvgBlob(send_to, svg, readyBlob))
            }
          } else if (send_to == "download") {
            if (this.pdfDoc) {
              try {
                await this.writeSvgToPDF(false)
                this.downloadContract(this.pdfDoc, "signed.pdf")
              } catch (e) {
                console.error(e)
              }
            }

          } else {
            if (this.draw_tool) {
              let svg = this.draw_tool.svg(true)
              this.canvasStore.dispatch(new PdfSvg({ requested_by: send_to, svg: svg }))
            }
          }
        }
      }
    }))

    //****************************************************************************
    // Set up by assets-header
    //****************************************************************************
    this.subscriptions.push(this.canvasStore.select(signers).subscribe((signers: any) => {
      //Find out if there is a name change and if so update that signer
      let prev_keys = Object.keys(this.signers)
      for (let i = 0; i < prev_keys.length; i++) {
        let key = prev_keys[i]
        let signer = this.signers[key]
        let new_signer = signers[key]
        if (signer && new_signer) {
          if (signer.name != new_signer.name) {
            this.updateName(key, new_signer)
          } else if (!signer.email && new_signer.name) {
            this.updateName(key, new_signer)
          }
        }
      }



      this.signers = signers
      let jmsg = {
        signers: this.signers
      }
      this.wss_service.sendMessageToOtherMembers(jmsg)
      // console.log("signers " + JSON.stringify(this.signers))

      let keys = Object.keys(this.signers)
      if (keys.length > 0) {
        this.sel_signer = this.signers[this.sel_signer_key]
      }

    }))
    // this.subscriptions.push(this.canvasStore.select(signerKey).subscribe(async (key: string | undefined) => {
    //   this.signer_key = key
    // }))
    this.subscriptions.push(this.canvasStore.select(selSignerKey).subscribe((sel_signer_key: string | undefined) => {
      if (sel_signer_key) {
        this.sel_signer_key = sel_signer_key
        this.sel_signer = this.signers[this.sel_signer_key]
      }
    }))

    this.subscriptions.push(this.canvasStore.select(selSignerKey).subscribe((sel_signer_key: string | undefined) => {
      if (sel_signer_key) {
        this.sel_signer_key = sel_signer_key
        this.sel_signer = this.signers[this.sel_signer_key]
      }
    }))

    this.subscriptions.push(this.canvasStore.select(signersWithSteps).subscribe((signers: any[]) => {
      this.signers_with_steps = signers
      this.setEmailBtn()
    }))

  }

  setEmailBtn() {
    if (!this.call_id) {
      let keys = Object.keys(this.signers_with_steps)
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        let signer = this.signers_with_steps[key]
        let steps = signer.steps
        if (steps) {
          let steps_keys = Object.keys(steps)
          for (let j = 0; j < steps_keys.length; j++) {
            let skey = steps_keys[j]
            if (skey != "driver_lic" && skey != "image_rec" && skey != "take_pic") {
              this.email_back_color = "#151515"
              break;
            }
          }
        }
      }
    }

  }
  onScroll($event: any) {
    let now = new Date().getTime()
    let dif = now - this.in_scroll_to_debouncer

    if (dif > 2000 && this.divContainer) {
      // console.log("Scrolling " + $event.srcElement.scrollTop)
      let jmsg: any = {
        scroll_to: $event.srcElement.scrollTop,
        from: this.role,
        content_h: $event.srcElement.scrollHeight
      }
      this.wss_service.sendMessageToOtherMembers(jmsg)
    }
  }

  ngOnDestroy() {
    this.canvasStore.dispatch(new ClearSVGs())
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })

    this.canvasStore.dispatch(new ClearCanvasState())

  }

  zoomChanged(new_zoom: number) {
    console.log("zoom " + new_zoom)
    if (this.contWnd && this.my_canvas) {
      let orig_dc_w = parseFloat(this.my_canvas.dataset["Keyzii_dc_w"])
      let new_dc_w = this.contWnd.nativeElement.clientWidth

      let orig_z = parseFloat(this.my_canvas.dataset["Keyzii_z"])
      let orig_w = parseFloat(this.my_canvas.dataset["Keyzii_w"])
      let orig_h = parseFloat(this.my_canvas.dataset["Keyzii_h"])
      let new_w = new_zoom / orig_z * orig_w
      new_w = new_w * new_dc_w / orig_dc_w
      let new_h = new_zoom / orig_z * orig_h
      new_h = new_h * new_dc_w / orig_dc_w
      let margins = this.pdf_page_count * 2
      let dif = orig_z - new_zoom
      let delta = dif / 30 * margins

      new_h += delta
      //When I go from 80 to 50 I need 6 pixels more
      //When I go from 80 to 100 I need 3 less

      this.my_canvas.style.width = new_w + "px"
      this.my_canvas.style.height = new_h + "px"


      // console.log("new_w " + new_w)
      // console.log(" new_w " + new_w + " new_h " + new_h)

    }
  }

  //****************************************************************************
  // user_siging_key set up by the user's roll or email
  //****************************************************************************
  canSignerMoveObject(dataset: any) {
    let k_type = dataset['k_type']
    if (k_type == "input") {
      let k_input = dataset['k_input']
      let done = dataset['done']
      if (!done) {
        return true
      }
      if (k_input == "Sign" || k_input == "Initial") {
        let k_target = dataset['k_target']
        let signer = this.signers[k_target]
        if (signer) {
          if (signer.email == this.signer_email) {
            return true
          }
        }
      } else {
        return true
      }

    } else {
      return true
    }
    return false
  }
  setSinger() { //called when we know the signers roll
    if (this.role) {
      this.user_siging_key = this.role

    }
    //TODO We need to use the email to find the sel_signer_key
  }
  bLoading: boolean = false;
  async loadPDF() {
    if (this.bLoading) {
      return
    }
    if (this.pdf_src && this.divContainer) {
      // this.canvasStore.dispatch(new ClearCanvasState())
      this.bLoading = true
      let pdfData = await fetch(this.pdf_src).then(res => res.arrayBuffer())
      if (pdfData) {
        try {
          this.pdfDoc = await PDFDocument.load(pdfData)
          if (this.pdfDoc) {
            let page = this.pdfDoc.getPage(0)
            this.pdf_page_height = page.getHeight()
            this.pdf_page_width = page.getWidth()
            this.pdf_page_count = this.pdfDoc.getPageCount()

            if (this.pdfDoc.catalog) {
              let smeta = this.pdfDoc.catalog.get(asPDFName("metadata")) as any
              if (smeta) {
                let sfields = smeta.decodeText()
                console.log("%% Loaded k_pages ")
                this.meta = JSON.parse(sfields)
                if (this.meta) {
                  if (this.meta.first_signature_page) {
                    this.pdf_page_count = this.meta.first_signature_page - 1 //To scale without the meta page
                  }
                }
              }
            }

          }
          this.pdfDoc.registerFontkit(fontkit)
        } catch (e) {
          console.error(e)
          this.error = "Error loading PDF, the document is encrypted and you will not be able to save it"
        }
        this.pdflibDoc = await pdfjsLib.getDocument(pdfData).promise;
        // loadingTask.promise.then(async (pdf: any) => {
        console.log('PDF loaded');
        let w: number = 0
        let h: number = 0

        // Fetch the first page
        if (this.divContainer) {
          for (let i = 0; i < this.divContainer.nativeElement.children.length; i++) {
            let child = this.divContainer.nativeElement.children[i]
            child.remove()
          }

          // this.canvas_service.setNumPages(this.pdflibDoc.numPages)

          for (let index = 0; index < this.pdflibDoc.numPages; index++) {
            let pageNumber = index + 1
            let page = await this.pdflibDoc.getPage(pageNumber)

            console.log('Page ' + pageNumber + ' loaded');
            var scale = 4;
            var viewport = page.getViewport({ scale: scale });
            var canvas = document.createElement("canvas");
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            // this.page_height = canvas.height / scale
            canvas.width = viewport.width;

            var renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            var renderTask = page.render(renderContext);
            try {
              await renderTask.promise
            } catch (e) {
              console.error(e)
            }

            const div = document.createElement("div");
            div.appendChild(canvas);
            canvas.style.position = "relative"
            canvas.style.width = "100%"//(viewport.width / scale) + "px"
            canvas.style.height = "100%"//(viewport.height / scale) + "px"
            div.style.position = "relative"
            div.style.borderBottom = "1px solid gray"
            div.style.width = "fit-content"
            div.style.height = "fit-content"
            div.style.margin = "auto"


            this.divContainer.nativeElement.appendChild(div);
            w = div.clientWidth;
            h += div.clientHeight;
          }
        }
        let page_svg: any | undefined
        if (this._asset) {
          page_svg = this._asset.svg
        }

        if (this.meta) {
          if (this.meta.first_signature_page) {
            h = (this.meta.first_signature_page - 1) / this.pdflibDoc.numPages * h//one page
          }
        }
        this.addCanvasToDiv(true, this.divContainer.nativeElement, page_svg, w, h)

        // this.my_canvas.dataset["Keyzii_z"] = this.zoom.toString()
        if (this.contWnd) {
          this.my_canvas.dataset["Keyzii_dc_w"] = this.contWnd.nativeElement.clientWidth
          this.my_canvas.dataset["Keyzii_dc_h"] = this.contWnd.nativeElement.clientHeight
        }
        this.bLoading = false
        this.canvas_service.loadedPDF()

      }
    }
  }

  async loadFont(fname: string) {
    return new Promise<PDFFont>(async (resolve, reject) => {
      if (this.fonts[fname]) {
        resolve(this.fonts[fname])
      }
      try {
        let fontData: any = await this.canvas_service.loadFont(fname)
        // this.satisfy = fontkit.create(fontData);
        if (!this.pdfDoc && this.pdfData) {
          this.pdfDoc = await PDFDocument.load(this.pdfData)
          this.pdfDoc.registerFontkit(fontkit)

        }
        if (this.pdfDoc) {
          let fonts = Object.assign({}, this.fonts)
          let font = await this.pdfDoc.embedFont(fontData)
          fonts[fname] = font
          this.fonts = fonts
          resolve(font)
        }
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  }

  async gotAsset() {
    if (this._asset.url) {
      if (this._asset.url.length > 2) {
        if (this._asset.url.length < 2048) {
          this.bShowSigners = (this._asset.type == "contract")
          // this.pdf_src = this._asset.url
          try {
            this.pdf_src = await this.tools_service.getKeyURL(this._asset.key)
            this.loadPDF();
          } catch (e) {
            console.error(e + " " + "gotAsset")
          }
          // if (!this.pdf_src) {
          //   this.pdf_src = this._asset.url
          // }

        }
      }
    }
  }

  async downloadContract(pdfDoc: PDFDocument, name: string) {
    const pdfBytes: Uint8Array = await pdfDoc.save()

    console.log("Down load  it")
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    //  var enc = new TextEncoder();

    const blob = new Blob([pdfBytes]);
    const url1 = window.URL.createObjectURL(blob);
    a.href = url1;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url1);
    console.log("downloaded pdf ");
  }

  async writeSvgToPDF(bAddMetadata: boolean) {
    return new Promise<void>(async (resolve, reject) => {
      if (!this.pdfDoc) {
        reject("no pdf")
        return
      }
      //Go thourgh all the groups, find the ones that are signatures or initial
      // find the page and write the svg in the page
      let children = this.draw_tool.children()
      for (let i = 0; i < children.length; i++) {
        let group = children[i]
        let dataset = group.node.dataset
        let k_type = dataset['k_type']
        // let k_target = dataset['k_target']
        // let signer = this.signers[k_target]
        // let target_email = signer.email
        // if (this.my_email && k_target && this.my_email == target_email) {

        if (k_type == "input") {
          let k_input = group.node.dataset['k_input']
          if (k_input == 'Sign' || k_input == 'Initial') {
            let done = group.node.dataset['done']
            if (done == 'true') {
              console.log("found " + k_type)
              let borders = this.canvas_service.getGroupBorders(group)
              let top = this.scaleScrToPDFY(borders.top)
              let page_number = Math.floor(top / this.pdf_page_height)
              try {
                await this.addSignatureToDoc(group, page_number)
              } catch (e) {
                reject(e)
                return;
              }
            }
          } else {
            let borders = this.canvas_service.getGroupBorders(group)
            let top = this.scaleScrToPDFY(borders.top)
            let page_number = Math.floor(top / this.pdf_page_height)
            try {
              await this.addTextToDoc(group, page_number)
            } catch (e) {
              reject(e)
              return;
            }
          }
          let id = group.node.dataset['id']
          if (id) {
            let drawObj = this.getDrawObj(k_type)
            if (drawObj) {
              drawObj.erase(id)
            }
          }
          group.remove()
        }
        // }
      }
      this.removeMultiSelBoxes()
      this.removeInfoAndAnchors()

      if (bAddMetadata && this.my_email) {
        let keys = Object.keys(this.signers)
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i]
          let signer = this.signers[key]
          if (signer.email == this.my_email) {
            try {
              await this.addMyInfoToDOC(signer)
            } catch (e) {
              console.error(e)
            } break

          }
        }
      }
      this.reLoadPDFLib()
      resolve()
    })
  }

  addMyInfoToDOC(signer: any) {
    return new Promise<any>(async (resolve, reject) => {
      if (!this.my_email || !this.pdfDoc || !this.sel_signer || !this._asset) {
        return
      }
      //*************************************
      // load previous state
      //*************************************


      if (await this.meta_server.setupDoc(this.pdfDoc)) {
        await this.meta_server.addHeader(this._asset.name)
        let bAddedBC: boolean = false
        if (this._asset.sec_opts) {
          if (this._asset.sec_opts.blockchain == "once" || this._asset.sec_opts.blockchain == "all") {
            await this.meta_server.addBlocChain("Blockchain verification")
            bAddedBC = true
          }
        }
        if (!bAddedBC) {
          await this.meta_server.addBlocChain("Document metadata")
        }
      }

      this.pdfDoc = await this.meta_server.addContact(signer.email, signer.first_name, signer.last_name)

      resolve(top)
    })
  }
  async reLoadPDFLib() {
    if (this.pdfDoc) {
      const pdfData: Uint8Array = await this.pdfDoc.save()
      this.pdflibDoc = await pdfjsLib.getDocument(pdfData).promise;
      // loadingTask.promise.then(async (pdf: any) => {
      console.log('reLoadPDFLib');

      // Fetch the first page
      if (this.divContainer) {
        let len = this.divContainer.nativeElement.children.length
        for (let index = 0; index < len; index++) {
          let div = this.divContainer.nativeElement.children[0]
          if (div) {
            this.divContainer.nativeElement.removeChild(div)
          }
        }

        let w: number = 0
        let h: number = 0
        for (let index = 0; index < this.pdflibDoc.numPages; index++) {
          // const div = this.divContainer.nativeElement.children[index + 3]

          let pageNumber = index + 1
          let page = await this.pdflibDoc.getPage(pageNumber)

          console.log('Page ' + pageNumber + ' loaded');
          var scale = 4;
          var viewport = page.getViewport({ scale: scale });


          var canvas = document.createElement("canvas");
          var context = canvas.getContext('2d');
          if (context) {
            context.clearRect(0, 0, canvas.width, canvas.height);
            canvas.height = viewport.height;
            // this.page_height = canvas.height / scale
            canvas.width = viewport.width;

            var renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            var renderTask = page.render(renderContext);
            try {
              await renderTask.promise
            } catch (e) {
              console.error(e)
            }


            const div = document.createElement("div");
            // let svg = div.children[0]
            // div.removeChild(svg)
            div.appendChild(canvas);
            canvas.style.position = "relative"
            canvas.style.width = "100%"//(viewport.width / scale) + "px"
            canvas.style.height = "100%"//(viewport.height / scale) + "px"
            div.style.position = "relative"
            div.style.borderBottom = "1px solid gray"
            div.style.width = "fit-content"
            div.style.height = "fit-content"
            div.style.margin = "auto"
            this.divContainer.nativeElement.appendChild(div);
            w = div.clientWidth;
            h += div.clientHeight;

          }
        }
        let page_svg: any | undefined
        if (this._asset && !this.call_id) {//If we are reloading in a call, we dont want the asset svg
          page_svg = this._asset.svg
        }
        if (this.meta) {
          if (this.meta.first_signature_page) {
            h = (this.meta.first_signature_page - 1) / this.pdflibDoc.numPages * h//one page
          }
        }
        this.addCanvasToDiv(true, this.divContainer.nativeElement, page_svg, w, h)
        this.my_canvas.dataset["Keyzii_z"] = this.zoom.toString()
        if (this.contWnd) {
          this.my_canvas.dataset["Keyzii_dc_w"] = this.contWnd.nativeElement.clientWidth
          this.my_canvas.dataset["Keyzii_dc_h"] = this.contWnd.nativeElement.clientHeight
        }
        this.bLoading = false
        this.canvasStore.dispatch(new SVGModified())
      }

    }
  }

  scaleScrToPDFY(y: number) {
    let orig_h = parseFloat(this.my_canvas.dataset["Keyzii_h"])
    let screenPageHeight = orig_h / this.pdf_page_count

    // let screenPageHeight = this.my_canvas.clientHeight / this.pdf_page_count
    let rv = y * this.pdf_page_height / screenPageHeight
    return rv
  }

  scaleScrToPDFX(x: number) {
    let orig_w = parseFloat(this.my_canvas.dataset["Keyzii_w"])
    let rv = x * this.pdf_page_width / orig_w
    // let rv = x * this.pdf_page_width / this.my_canvas.clientWidth
    return rv
  }
  async addTextToDoc(group: any, page_number: number) {
    return new Promise<void>(async (resolve, reject) => {
      let me = this
      let page
      let page_height = 0
      let delta_y = 0
      if (this.pdfDoc) {
        page = this.pdfDoc.getPage(page_number)
        if (page) {
          page_height = page.getHeight()
          delta_y = (page_height * page_number) + 3
          try {
            await addTextToPage(page, group, page_height, delta_y, 0)
            resolve()
          } catch (e) {
            reject(e)
          }
        }
      } else {
        reject("no pdf doc")
        return;
      }
      async function addTextToPage(page: PDFPage, group: any, page_height: number, delta_y: number, stack: number) {
        return new Promise<void>(async (resolve, reject) => {

          let nodes = group.children()
          let dataset = group.node.dataset
          if (dataset.k_type == "input") {
            try {
              await addInputToPage(page, group, page_height, delta_y, stack)
              resolve()
            } catch (e) {
              reject(e)
            }
            return
          }
          if (nodes.length == 0) {
            nodes = [group]
          }
          if (nodes) {
            for (let i = 0; i < nodes.length; i++) {
              let node: any = nodes[i]
              if (node.type == "g" && stack < 4) {
                try {
                  await addTextToPage(page, node, page_height, delta_y, ++stack)
                  resolve()
                } catch (e) {
                  reject()
                }
                return
              }
              if (node.type == "text") {
                let font = node.font()
                let left = font.x
                let x = me.scaleScrToPDFX(left)
                let _y = font.y
                // let style = me.getAttrbuteObj(node, "style")

                let font_family = "OpenSans"
                let size1 = parseFloat(dataset.size)
                if (!size1) {
                  size1 = 18
                }
                if (font) {
                  if (font.family) {
                    font_family = font.family
                  }
                  if (font.size) {
                    size1 = font.size
                  }
                }

                let top = me.scaleScrToPDFY(_y + size1)
                let size = me.scaleScrToPDFY(size1)

                let y = page_height - (top - delta_y)

                let text = node.node.textContent
                try {

                  if (me.pdfDoc) {
                    let font
                    try {
                      font = await me.setFontInDoc(me.pdfDoc, font_family)
                    } catch (e) {
                      console.error("seting font " + font_family)
                    }  // let font = await me.loadFont(node.node.dataset['font'])
                    page.drawText(
                      text,
                      {
                        x: x,
                        y: y,
                        size: size,
                        font: font,
                        color: rgb(0.0, 0.0, 0.0),
                        lineHeight: 24,
                        opacity: 1,
                      },
                    )
                  }
                } catch (e) {
                  reject(e)
                  return;
                }
              } else if (node.type == "foreignObject") {
                let left = me.getValOrLessThanZero(node, "x")
                let x = me.scaleScrToPDFX(left)
                let _y = me.getValOrLessThanZero(node, "y")
                let style = me.getAttrbuteObj(node, "style")
                let font_family = style["font-family"]
                let size1 = parseFloat(style["font-size"])
                // let weight = style["font-weight"] //'Courier-Bold',https://github.com/Hopding/pdf-lib/issues/1272

                let top = me.scaleScrToPDFY(_y + size1)
                let size = me.scaleScrToPDFY(size1)

                let y = page_height - (top - delta_y)

                let text = node.node.textContent
                try {

                  if (me.pdfDoc) {
                    let font = await me.setFontInDoc(me.pdfDoc, font_family)
                    // let font = await me.loadFont(node.node.dataset['font'])
                    page.drawText(
                      text,
                      {
                        x: x,
                        y: y,
                        size: size,
                        font: font,
                        color: rgb(0.0, 0.0, 0.0),
                        lineHeight: 24,
                        opacity: 1,
                      },
                    )
                  }
                } catch (e) {
                  reject(e)
                  return;
                }

              }
            }
            resolve()
          }
        })
      }


      async function addInputToPage(page: PDFPage, group: any, page_height: number, delta_y: number, stack: number) {
        return new Promise<void>(async (resolve, reject) => {

          let nodes = group.children()
          let dataset = group.node.dataset

          if (nodes.length == 0) {
            nodes = [group]
          }
          if (nodes) {
            let text_node
            let rect_node
            for (let i = 0; i < nodes.length; i++) {
              let node: any = nodes[i]
              if (node.type == "g" && stack < 4) {
                try {
                  await addInputToPage(page, node, page_height, delta_y, ++stack)
                  resolve()
                } catch (e) {
                  reject(e)
                }
                return
              }
              if (node.type == "text") {
                text_node = node
              }
              if (node.type == "polyline" || node.type == "polygon") {
                rect_node = node
              }
            }
            if (text_node && rect_node) {
              //find the position
              let font = text_node.font()
              let bbox = rect_node.bbox()



              let x = me.scaleScrToPDFX(bbox.x)
              let top = me.scaleScrToPDFY(bbox.y2)
              let y = page_height - (top - delta_y)

              let font_family = "OpenSans"
              let size1 = parseFloat(dataset.size)
              if (!size1) {
                size1 = 18
              }
              if (font) {
                if (font.family) {
                  font_family = font.family
                }
                if (font.size) {
                  size1 = font.size
                }
              }
              let size = me.scaleScrToPDFY(size1)


              let text = text_node.text()
              try {

                if (me.pdfDoc) {
                  let font
                  try {
                    font = await me.setFontInDoc(me.pdfDoc, font_family)
                  } catch (e) {
                    console.error("seting font " + font_family)
                  }  // let font = await me.loadFont(node.node.dataset['font'])
                  page.drawText(
                    text,
                    {
                      x: x,
                      y: y,
                      size: size,
                      font: font,
                      color: rgb(0.0, 0.0, 0.0),
                      lineHeight: 24,
                      opacity: 1,
                    },
                  )
                }
              } catch (e) {
                reject(e)
                return;
              }

              resolve()
            }
          } else {
            reject() //!text_node or ! rect_node
          }
        })
      }


    })
  }

  setFontInDoc(pdfDoc: PDFDocument, font_family: string) {
    return new Promise<PDFFont>(async (resolve, reject) => {
      let font
      try {

        if (font_family == "Cookie" //imported form assets in styles.css
          || font_family == "Kalam"
          || font_family == "Satisfy"
          || font_family == "OpenSans"
          || font_family == "OpenSansBold"
        ) {
          font = await this.loadFont(font_family)
        }
        if (!font) {
          font = await pdfDoc.embedFont(font_family)//StandardFonts.TimesRoman);
        }
        console.log("loaded " + font_family)
      } catch (e) {
        font = await pdfDoc.embedFont(StandardFonts.TimesRoman);
        console.log("on error loaded " + StandardFonts.HelveticaOblique)
      }
      resolve(font)
    })
  }
  getAttrbute(node: any, name: string) {
    let attributes = node.attributes
    if (!attributes) {
      attributes = node.node.attributes
    }
    if (attributes) {
      if (attributes[name]) {
        return attributes[name].value
      }
    }
  }
  getAttrbuteObj(node: any, name: string) {
    let rv: any = {}
    let attribute = this.getAttrbute(node, name)
    if (attribute) {
      let parts = attribute.split(';')
      for (let i = 0; i < parts.length; i++) {
        let part = parts[i]
        let name_val = part.split(':')
        if (name_val.length == 2) {
          let name = name_val[0].trim()
          let val = name_val[1].trim()
          rv[name] = val
        }
      }
    }
    return rv
  }
  getValOrLessThanZero(node: any, name: string) {
    let rv = -1;
    let attribute = this.getAttrbute(node, name)
    if (attribute) {
      rv = parseInt(attribute)
    }
    return rv
  }

  async addSignatureToDoc(signature: any, page_number: number) { //look at addSvtToPDF(pdfDoc: PDFDocument)  in pdf-wb
    return new Promise<void>(async (resolve, reject) => {
      let me = this

      let page
      let page_height = 0
      let delta_y = 0
      if (this.pdfDoc) {
        page = this.pdfDoc.getPage(page_number)
        if (page) {
          page_height = page.getHeight()
          delta_y = page_height * page_number
          try {
            await addSignatureToPage(page, signature, page_height, delta_y)
            resolve()
          } catch (e) {
            reject(e)
          }
        }
      } else {
        reject("no pdf doc")
        return;
      }

      async function addSignatureToPage(page: PDFPage, signature: any, page_height: number, delta_y: number) {
        return new Promise<void>(async (resolve, reject) => {

          let nodes = signature.children()
          if (nodes.length == 0) {
            nodes = [signature]
          }
          if (nodes) {
            for (let i = 0; i < nodes.length; i++) {
              let node: any = nodes[i]
              if (node.type == "g") {
                addSignatureToPage(page, node, page_height, delta_y)
                return
              }
              if (node.type == "polyline") {

                let attr = node.attr()
                let points = me.draw_polygon.getArrayFromStr(attr.points)

                for (let i = 2; i <= points.length - 4; i += 2) {
                  let x1 = me.scaleScrToPDFX(points[i + 0])
                  let top = me.scaleScrToPDFY(points[i + 1])
                  let y1 = page_height - (top - delta_y)
                  let x2 = me.scaleScrToPDFX(points[i + 2])
                  let bottom = me.scaleScrToPDFY(points[i + 3])
                  let y2 = page_height - (bottom - delta_y)
                  page.drawLine({
                    start: { x: x1, y: y1 },
                    end: { x: x2, y: y2 },
                    thickness: 1,
                    color: rgb(0.2, 0.2, 0.2),
                    opacity: 0.75,
                  })
                }

              } else if (node.type == "line") {

                let left = me.getValOrLessThanZero(node, "x1")
                let x1 = me.scaleScrToPDFX(left)
                let right = me.getValOrLessThanZero(node, "x2")
                let x2 = me.scaleScrToPDFX(right)
                let y = me.getValOrLessThanZero(node, "y1")
                let top = me.scaleScrToPDFY(y)
                let y1 = page_height - (top - delta_y)
                y = me.getValOrLessThanZero(node, "y2")
                let btm = me.scaleScrToPDFY(y)
                let y2 = page_height - (btm - delta_y)
                page.drawLine({
                  start: { x: x1, y: y1 },
                  end: { x: x2, y: y2 },
                  thickness: 1,
                  color: rgb(0.2, 0.2, 0.2),
                  opacity: 0.75,
                })
              } else if (node.type == "text") {
                let left = me.getValOrLessThanZero(node, "x")
                let x = me.scaleScrToPDFX(left)

                let font_family = node.node.dataset["font"]
                let size1 = parseFloat(node.node.dataset["size"])
                let size = me.scaleScrToPDFY(size1)

                let weight = node.node.dataset["weight"] //'Courier-Bold',https://github.com/Hopding/pdf-lib/issues/1272

                let _y = me.getValOrLessThanZero(node, "y")
                let top = me.scaleScrToPDFY(_y)
                let y = page_height - (top - delta_y)
                if (node.node.lastChild) {
                  let text = node.node.lastChild.innerHTML
                  try {

                    if (me.pdfDoc) {
                      let font = await me.setFontInDoc(me.pdfDoc, font_family)

                      // let font = await me.loadFont(node.node.dataset['font'])
                      page.drawText(
                        text,
                        {
                          x: x,
                          y: y,
                          size: size,
                          font: font,
                          color: rgb(0.0, 0.0, 0.0),
                          lineHeight: 24,
                          opacity: 1,
                        },
                      )
                    }
                  } catch (e) {
                    reject(e)
                    return;
                  }
                } else {
                  reject("no last child")
                }
              } else if (node.type == "image" && me.pdfDoc) {
                let attr = node.attr()
                console.log("add image to pdf ")
                let pngImageBytes: ArrayBuffer = await me.base64ToFile(attr.href)
                let pngImage = await me.pdfDoc.embedPng(pngImageBytes)
                let w1 = attr.height / pngImage.height * pngImage.width
                let cx = attr.x + attr.width / 2
                let x1 = cx - w1 / 2
                let x = me.scaleScrToPDFX(x1)

                let _y = attr.y
                let top = _y + attr.height
                let t = me.scaleScrToPDFY(top)
                let y = page_height - (t - delta_y)

                // let w = me.scaleScrToPDFX(attr.width)
                let h = me.scaleScrToPDFY(attr.height)
                let w = me.scaleScrToPDFX(w1)

                page.drawImage(pngImage, {
                  x: x,
                  y: y,
                  width: w,
                  height: h
                })
              }
            }
            resolve()
          }
        })
      }
    })
  }
  async base64ToFile(dataURL: string) {
    return new Promise<ArrayBuffer>(async (resolve, reject) => {
      const arr = dataURL.split(',');
      if (arr && arr.length > 0) {
        let line = arr[0]
        if (line) {
          return (fetch(dataURL)
            .then(function(result) {
              resolve(result.arrayBuffer());
            }));
        }
      }
      reject();
    })
  }
  //****************************************************************************
  // PDF Download
  //****************************************************************************

  async download() {
    try {
      await this.writeSvgToPDF(false)
      if (this.pdfDoc) {
        this.downloadContract(this.pdfDoc, "blockchain.pdf")
      }
    } catch (e) {
      console.error(e)
    }
  }
  debuouce: number = 0
  emailContract() {
    if (this.email_back_color == "#DADADA") {
      return
    }
    let keys = Object.keys(this.signers)
    if (keys.length > 0) {
      let now = new Date().getTime()
      let dif = now - this.debuouce
      if (dif > 500) {
        this.debuouce = now
        this.canvasStore.dispatch(new SelectToolOpts("p1"))
        this.bShowEmailWnd = true
      }
    }
  }
  signed($event: any) {
    //Nov 4, 2023
    //If we are in a call and we are over a signature and the caller_email is the one of the object, sign it
    if (this.bIsSigningContract) {
      if (this.my_email) {
        // if (this.call_id) {
        let x = $event.clientX
        let y = $event.clientY
        if (!x) {
          x = $event.layerX
          y = $event.layerY
        }
        var elements: any = document.elementsFromPoint(x, y); if (elements) {
          for (let i = 0; i < elements.length; i++) {
            let element = elements[i]
            if (element.localName != "div") {
              let id = element.dataset['id']
              let k_type = element.dataset['k_type']
              let k_target = element.dataset['k_target']
              if (!id) {
                let parent = element.parentElement
                if (parent) {
                  id = parent.dataset['id']
                  k_type = parent.dataset['k_type']
                  k_target = parent.dataset['k_target']
                }
              }
              if (id) {
                // console.log("select hover override id " + id + " " + k_type + " " + $event.buttons)
                if (k_type) {
                  if (k_type == "sign" || k_type == "initial") {
                    let keys = Object.keys(this.signers)
                    for (let i = 0; i < keys.length; i++) {
                      let key = keys[i]
                      if (key == k_target) {
                        let signer = this.signers[key]
                        console.log("click for " + signer.title + "-" + signer.email + ", " + this.my_email)
                        if (signer.email && this.my_email) {
                          if (signer.email == this.my_email) {
                            let drawObj = this.getDrawObj(k_type)
                            drawObj.signOrInitial(id, this.my_email)
                            return true;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return false
  }

  toggleOpen() {
    if (this.state == "closed") {
      this.state = "open"
    } else {
      this.state = "closed"
    }
    setTimeout(() => {
      this.zoomChanged(this.zoom)

    }, 300) //Make sure the animation is done it takes 250ms
  }

  updateName(id: string, signer: any) {
    console.log("Update " + signer.name)

    function getTexElement(svg: any): any {
      let children = svg.children()
      for (let i = 0; i < children.length; i++) {
        let el = children[i]
        if (el.type == "text") {
          return el
        } else if (el.type == "svg" || el.type == "g") {
          return getTexElement(el)
        }
      }
    }
    if (this.draw_tool) {
      let children = this.draw_tool.children()
      for (let i = 0; i < children.length; i++) {
        let group = children[i]
        let k_type = group.node.dataset['k_type']
        if (k_type == 'sign' || k_type == 'initial') {
          let k_target = group.node.dataset['k_target']
          if (k_target == id) {
            console.log("Update " + k_target)
            //Find the text element   new_el.text(text)
            let text_el = getTexElement(group)
            if (text_el) {
              let name: string | undefined
              if (signer) {
                if (signer.email && this.call_id) {
                  if (signer.email == this.signer_email) {
                    name = "Your"
                  }
                }
                if (!name) {
                  let parts: string[] | undefined
                  if (signer.name) {
                    if (signer.name.length > 0) {
                      parts = signer.name.split(' ')
                    }
                  }
                  if (!parts) {
                    if (signer.email) {
                      if (signer.email.length > 1) {
                        parts = signer.email.split('@')
                      }
                    }
                  }
                  if (!parts) {
                    if (signer.title) {
                      parts = signer.title.split(' ')
                    }
                  }
                  if (parts) {
                    if (parts.length > 0) {
                      name = parts[0].substring(0, 1) + "."
                    }
                    if (parts.length > 1) {
                      name += parts[1].substring(0, 1) + "."
                    }
                  }
                }
                if (name) {
                  if (k_type == "sign") {
                    name += " signature"
                  } else {
                    name += " initials"
                  }

                  if (name.length > 15) {
                    name = name.substring(0, 15) + "..."
                  }
                  text_el.text(name)
                }
              }
            }
          }
        }
      }
    }
  }
  reDrawInputFields() {
    if (this.draw_tool) {
      setTimeout(() => {
        let children = this.draw_tool.children()
        for (let i = 0; i < children.length; i++) {
          let group = children[i]
          let dataset = group.node.dataset
          let k_type = dataset['k_type']

          if (k_type == "input") {

            let drawObj = this.getDrawObj(k_type)
            drawObj.reDraw(group)
          }
        }
        //Tell the other side
        let jmsg = {
          field_text_font: this.field_text_font,
          field_text_weight: this.field_text_weight,
          field_text_size: this.field_text_size,
        }
        this.wss_service.sendMessageToOtherMembers(jmsg)
      })
    }
  }
}

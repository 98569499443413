import { CanvasState, initialCanvasState, } from './canvas.model'
import { CanvasActions, CanvasActionTypes } from './canvas.actions';

// function setSignerSteps(canvasState: any) {
//   let keys = Object.keys(canvasState.signers)
//   for (let i = 0; i < keys.length; i++) {
//     let key = keys[i]
//     let signer = canvasState.signers[key]
//     if (signer.email && canvasState.signer_email) {
//       if (signer.email == canvasState.signer_email) { //key == this.signer_roll ||
//         canvasState.signer_steps = signer.steps
//         console.log("Set signer steps " + JSON.stringify(canvasState.signer_steps))
//       }
//     }
//   }
// }
//let streams_cache: any = {}
export function canvasReducer(state = initialCanvasState,
  action: CanvasActions): CanvasState {
  switch (action.type) {

    case CanvasActionTypes.ShowDrawTools: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.show_draw_tools = action.drawing
      return canvasState
    }
    case CanvasActionTypes.ClearCanvasState: {
      const canvasOldState: CanvasState = Object.assign({}, state);
      const canvasState: CanvasState = Object.assign({}, initialCanvasState);
      canvasState.signer_email = canvasOldState.signer_email
      canvasState.signer_name = canvasOldState.signer_name
      canvasState.signer_roll = canvasOldState.signer_roll
      canvasState.signature_data = canvasOldState.signature_data
      canvasState.initials_data = canvasOldState.initials_data

      return canvasState
    }


    case CanvasActionTypes.ResizedCanvas: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.resized_canvas++
      return canvasState
    }
    // case CanvasActionTypes.NumSignAndInitials: {
    //   const canvasState: CanvasState = Object.assign({}, state);
    //   canvasState.num_sig_and_initials = action.num
    //   return canvasState
    // }
    case CanvasActionTypes.IsInitials: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.bIsInitial = action.on
      return canvasState
    }

    case CanvasActionTypes.SetCanvasOpts: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.canvas_opts = Object.assign({}, canvasState.canvas_opts)
      let keys = Object.keys(action.opts)
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        canvasState.canvas_opts[key] = action.opts[key]
      }

      return canvasState
    }

    case CanvasActionTypes.Download: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.download++
      return canvasState
    }
    case CanvasActionTypes.AddToChat: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.add_to_chat++
      return canvasState
    }

    case CanvasActionTypes.SaveSVG: {
      const canvasState: CanvasState = Object.assign({}, state);
      let saved_svgs = Object.assign({}, canvasState.saved_svgs)
      saved_svgs[action.asset_id] = action.svg
      canvasState.saved_svgs = saved_svgs
      return canvasState
    }

    case CanvasActionTypes.ClearSVGs: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.saved_svgs = {}
      canvasState.edit_comment = undefined
      return canvasState
    }

    case CanvasActionTypes.EditComment: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.edit_comment = {
        x: action.x,
        y: action.y,
        id: action.id,
        content: action.content,
        show: action.show,
      }
      return canvasState
    }
    case CanvasActionTypes.ShowComment: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.show_comment = {
        x: action.x,
        y: action.y,

        content: action.content,
        show: action.show,
      }
      return canvasState
    }
    case CanvasActionTypes.AddToUndo: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.add_to_undo = action.undo
      canvasState.undo_stack_size++
      return canvasState
    }

    case CanvasActionTypes.UnDo: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.undo_seq++
      canvasState.undo_stack_size--
      return canvasState
    }

    case CanvasActionTypes.SelectId: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.selected_id = { id: action.id, shiftKey: action.shiftKey, k_type: action.k_type }
      return canvasState
    }

    case CanvasActionTypes.ClearSel: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.selected_id = { id: "x" }
      return canvasState
    }


    case CanvasActionTypes.ReadyToBlockChain: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.ready_to_block_chain = action.ready
      return canvasState
    }
    case CanvasActionTypes.ContractSigned: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.contract_signed = action.signed
      console.log("contract_signed " + canvasState.contract_signed)
      return canvasState
    }
    case CanvasActionTypes.ContractExecuted: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.contract_executed = action.executed
      return canvasState
    }
    // case CanvasActionTypes.MissingEmailsFor: {
    //   const canvasState: CanvasState = Object.assign({}, state);
    //   canvasState.missing_emails_for = action.missing
    //   return canvasState
    // }
    case CanvasActionTypes.LastSignedContract: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.last_signed_contract = action.contract
      return canvasState
    }


    case CanvasActionTypes.SignerName: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.signer_name = action.name
      return canvasState
    }
    case CanvasActionTypes.SignerEmail: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.signer_email = action.email
      // setSignerSteps(canvasState)
      return canvasState
    }
    case CanvasActionTypes.BioResultsError: {
      const canvasState: CanvasState = Object.assign({}, state);
      delete canvasState.bio_results
      canvasState.bio_results_error = action.error

      let signer_steps = Object.assign({}, canvasState.signer_steps)
      let step = Object.assign({}, signer_steps["driver_lic"])
      step.done = false
      signer_steps["driver_lic"]
      step = Object.assign({}, signer_steps["take_pic"])
      step.done = false
      signer_steps["take_pic"]
      step = Object.assign({}, signer_steps["image_rec"])
      step.done = false
      signer_steps["image_rec"]
      canvasState.signer_steps = signer_steps

      return canvasState
    }

    case CanvasActionTypes.BioResults: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.bio_results = action.results
      return canvasState
    }
    case CanvasActionTypes.SignerPic: {
      const canvasState: CanvasState = Object.assign({}, state);
      delete canvasState.bio_results_error
      canvasState.signer_pic = action.pic
      return canvasState
    }
    case CanvasActionTypes.SignerLic: {
      const canvasState: CanvasState = Object.assign({}, state);
      delete canvasState.bio_results_error
      canvasState.signer_lic = { src: action.str, type: action.img_type }
      return canvasState
    }
    case CanvasActionTypes.CallerPic: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.caller_pic = action.pic
      return canvasState
    }
    case CanvasActionTypes.CallerLic: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.caller_lic = { src: action.str, type: action.img_type }
      return canvasState
    }
    case CanvasActionTypes.CallerResults: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.caller_results = action.results
      return canvasState
    }

    case CanvasActionTypes.GoToStep: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.go_to_step = action.step
      if (action.step) {
        if (action.step.tool) {
          canvasState.select_tool_opt = { name: action.step.tool }
          if (action.step.tool == "driver_lic") {
            delete canvasState.bio_results
          }
        }
      }
      return canvasState
    }
    case CanvasActionTypes.SVGModified: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.changed_svg++
      return canvasState
    }

    case CanvasActionTypes.SignersWithSteps: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.signers_with_steps = action.signers
      return canvasState
    }

    case CanvasActionTypes.SetSigners: {
      const canvasState: CanvasState = Object.assign({}, state);
      console.log("SetSigners")
      canvasState.signers = action.signers
      return canvasState
    }


    case CanvasActionTypes.SetCopyTo: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.copy_to = action.copy_to
      return canvasState
    }
    case CanvasActionTypes.SignerSteps: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.signer_steps = action.steps
      return canvasState
    }

    case CanvasActionTypes.DidStep: {
      const canvasState: CanvasState = Object.assign({}, state);

      canvasState.did_step_id = action.id
      canvasState.select_tool_opt = {}
      // console.log("did_step_id " + canvasState.did_step_id)
      return canvasState
    }
    case CanvasActionTypes.Progress: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.progress = action.progress
      return canvasState
    }

    case CanvasActionTypes.SetSignerRoll: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.signer_roll = action.roll
      return canvasState
    }



    case CanvasActionTypes.PdfSecOpt: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.sec_opts = action.options
      return canvasState
    }
    case CanvasActionTypes.PdfSvg: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.pdf_svg = action.svg
      return canvasState
    }
    case CanvasActionTypes.PdfSvgBlob: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.pdf_svg_blob = { requested_by: action.requested_by, svg: action.svg, blob: action.blob }
      return canvasState
    }


    case CanvasActionTypes.LastSigner: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.last_signer = action.last_signer
      return canvasState
    }

    case CanvasActionTypes.SelSigner: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.sel_signer_key = action.key
      return canvasState
    }


    case CanvasActionTypes.SelectToolOpts: {
      const canvasState: CanvasState = Object.assign({}, state); ''
      canvasState.select_tool_opt = { name: action.name, params: action.params }
      console.log("SelectToolOpts " + action.name)
      return canvasState
    }

    case CanvasActionTypes.SetTool: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.selected_tool = action.name
      return canvasState
    }
    case CanvasActionTypes.SetInput: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.selected_input = action.name
      canvasState.selected_tool = "drop_input"
      return canvasState
    }
    case CanvasActionTypes.SetFont: {
      const canvasState: CanvasState = Object.assign({}, state);
      if (action.field_text) {
        canvasState.field_text_font = action.font
        canvasState.field_text_weight = action.weight
        canvasState.field_text_size = action.size
      } else {
        canvasState.text_font = action.font
        canvasState.text_weight = action.weight
        canvasState.text_size = action.size
      }
      return canvasState
    }

    case CanvasActionTypes.SelShape: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.sel_shape = action.index
      console.log("sel shape " + JSON.stringify(canvasState.sel_shape))
      return canvasState
    }

    case CanvasActionTypes.SetPencil: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.pencil_width = action.width
      return canvasState
    }
    case CanvasActionTypes.SetColors: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.fill_color = action.fill_color
      canvasState.stroke_color = action.stroke_color
      canvasState.text_color = action.text_color
      return canvasState
    }

    case CanvasActionTypes.TextColor: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.text_color = action.text_color
      return canvasState
    }

    case CanvasActionTypes.GetPDFSVG: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.save_svg_counter++
      canvasState.save_svg = { send_to: action.send_to, counter: canvasState.save_svg_counter }
      return canvasState
    }

    case CanvasActionTypes.GetSignature: {
      console.log("GetSignature ");
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.get_signature = action.group_id
      if (canvasState.get_signature) {
        canvasState.select_tool_opt = { name: "get_sig" }
      }
      else {
        if (canvasState.select_tool_opt.name == "get_sig") {
          canvasState.select_tool_opt = {}
        }
      }
      return canvasState
    }
    case CanvasActionTypes.GetInitials: {
      console.log("GetInitials ");
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.get_initials = action.group_id
      if (canvasState.get_initials) {
        canvasState.select_tool_opt = { name: "get_ini" }
      } else {
        if (canvasState.select_tool_opt.name == "get_ini") {
          canvasState.select_tool_opt = {}
        }
      }
      return canvasState
    }

    case CanvasActionTypes.RemoveSignature: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.signature_data = undefined
      return canvasState
    }
    case CanvasActionTypes.NewSignature: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.signature_data = { svg: action.svg, group_id: action.group_id, rect: action.rect, text_initials: action.text_initials }
      return canvasState
    }

    case CanvasActionTypes.RemoveInitials: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.initials_data = undefined
      return canvasState
    }
    case CanvasActionTypes.NewInitials: {
      const canvasState: CanvasState = Object.assign({}, state);
      canvasState.initials_data = { svg: action.svg, group_id: action.group_id, rect: action.rect }
      return canvasState
    }

    default:
      return state;
  }


}

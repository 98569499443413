import { Component, EventEmitter, Output } from '@angular/core';
import { AssetComponent } from '../asset/asset.component';

@Component({
  selector: 'edit-asset',
  templateUrl: './edit-asset.component.html',
  styleUrls: ['../sel-asset/sel-asset.component.scss', '../asset/asset.component.scss', './edit-asset.component.scss']
})
export class EditAssetComponent extends AssetComponent {
  name: string = "Asset Name"
  bEditName: boolean = false;

}

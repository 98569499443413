<div class="out">
  <input class="search" type="name" placeholder="Search" [(ngModel)]="search" (ngModelChange)="changedSearch()">
  <input #dt class="dt_b " type="date" [min]="minDate" name="" value="date" [ngModel]="date | date:'yyyy-MM-dd'" (ngModelChange)="changedDate($event)" (mouseover)="bShowSelTime=false">
  <div class="d_lbl" (click)="toggleDate()">
    {{fdate}}
  </div>
  <div class="appts">
    <div class="appt m20" *ngFor="let event of events;let i = index">
      <div class="li date">
        <span class="blue bold"> {{getDayLbl(event)}} </span>
        <div class="space">

        </div>
        <span class="dt" (click)="openIndex(i)">
          {{getDate(event)|date :"dd MMM yy"}}
        </span>
        <img class="open" [ngClass]="{close:i==opened_index}" src="assets/images/expand.svg" (click)="openIndex(i)">
      </div>
      <div class="li "> <span class="bold">Name: </span> {{getName(event)}} </div>
      <div class="li "> <span class="bold">Title: </span> {{event.title}}</div>
      <div class="li "> <span class="bold">Time: </span> {{getFromTo(event)}}</div>
      <div class="li fs12"> {{event.notes}} </div>

      <k-button *ngIf="event._id||event.uri" background="#ECECEC" class="cli" icon="copy_gray.svg" (click)="getLink(event)"></k-button>
      <div class="line"> </div>
      <div *ngIf="i==opened_index" class="options" (click)="delEvent(event)">
        <div class="deltx "> Delete </div>
        <img src="assets/images/delete_red.svg" alt="" class="del">
      </div>
    </div>
  </div>
  <div *ngIf="events.length==0" class="abs_center">
    No Appointments
  </div>
</div>

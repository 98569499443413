<div class="out">
  <div class="el">
    <div class="l">{{caller_name}}</div>
    <div class="r">{{waiting}}</div>
  </div>
  <div class="el ">
    <div class="l">{{caller_email}}</div>
    <div class="r opts">Options
      <img class="exp_o" width="10" [ngClass]="{colapse:show_index==index}" src="assets/images/expand.svg" (click)="expand()">
    </div>
  </div>
</div>
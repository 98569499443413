<div class="out">
  <!-- <div class="circle draw_i" (click)="draw()" [ngClass]="{sel:bDraw}"></div> -->
  <div class="line">Enable contract and signature tool
    <switch class="switch" [checked]="bContract" (checkedCahnge)="contract($event)"></switch>
  </div>
  <!-- <div class="line">Enable draw tool for caller
    <switch class="switch" [checked]="bDraw" (checkedCahnge)="draw($event)"></switch>

  </div> -->

  <!-- <div class="circle download_i" (click)="download()" [ngClass]="{sel:bDownload}"></div> -->
  <div class="line">Enable download
    <switch class="switch" [checked]="bDownload" (checkedCahnge)="download($event)"></switch>
  </div>


</div>

<div class="out">
  <div class="day">

    <div class="h_row t_b l_b r_b" *ngFor="let hour of hour_slots; let r = index">
      <div class="hour r_b">
        <span class="abs_center ">{{hour.stime}}</span>
      </div>
      <div class="slots">

        <div class="slot" [style.min-height.px]="slot.height" *ngFor="let slot of hour.slots;let i = index" (click)="addAppointment(slot)" [ngClass]="{ 'disabled':slot.status=='d', mid: i !>0 }">

          <span *ngIf="slot.event">{{slot.event.title}}<span *ngIf="slot.event.name">, </span>{{slot.event.name}} </span>
          <!-- <div class="abs_center">{{i}} </div> -->
        </div>
      </div>

    </div>
  </div>

<div class="out">
  <div class="top_line">
    <input class="search" type="name" placeholder="Search calls" [(ngModel)]="search" (ngModelChange)="changedSearch()">
  </div>
  <div class="gray_line"></div>

  <div class="line t_t" *ngIf="calls.length>0">
    <div class="el">Duration</div>
    <div class="el">Caller Name</div>
    <div class="el">Caller Email</div>
    <div class="el">Agent Name</div>
    <div class="el">Agent Email</div>
  </div>

  <div class="h_content" #content>
    <div class="line" *ngFor="let call of calls, let i = index ">


      <div class="el">{{call.duration}}</div>
      <div class="el"><span class="ellipsis">{{call.caller_name}}{{call.caller_first_name}}{{call.caller_last_name}}</span></div>
      <div class="el">{{call.caller_email}}</div>
      <div class="el"><span class="ellipsis">{{call.rep_name}}</span></div>
      <div class="el">{{call.rep_email}}</div>
      <!-- <k-button *ngIf="rep_id!=call.rep_id" class="el" min_width="120" text="Join Call" (click)="joinCall(call)"></k-button> -->
      <!-- rep_id{{rep_id}}, call.rep_id {{call.rep_id}} -->
      <!-- <div class="filler"></div>
      <k-button *ngIf="rep_id!=call.rep_id" class="el" min_width="120" text="Monitor Call" (click)="monitorCall(call)"></k-button> -->
    </div>
  </div>
  <div *ngIf="calls.length==0" class="abs_center">
    No active calls
  </div>
  <div class="pag_wrapper">

    <k-paginator class="paginator" [total]="count" *ngIf="num_pages>1" [pages]="num_pages" [page_size]="page_size" (indexChange)="selPage($event)"></k-paginator>
  </div>
</div>

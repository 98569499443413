<div *ngIf="!isPhone" class="out">
  <!-- <close-and-rename-asset *ngIf="bShowCloseAndRename"></close-and-rename-asset> -->
  <k-button min_width="40" min_height="40" *ngIf="bShowingContract" icon="save_white.svg" class="record" min_width="100" background="#151515" color="white" text="Save" (click)="savePdf()"></k-button>
  <img *ngIf="bShowingContract" src="assets/images/expand.svg" class="expand" [ngClass]="{close:opened_tool=='save-as'}" (click)="toogleSaveAs()" />

  <!-- <k-button *ngIf="bShowingPDF" class="record" min_width="100" background="#151515" color="white" text="Email Contract" (click)="emailContract()"></k-button> -->
  <!-- <div *ngIf="!bShowingAsset && router.url == '/assets'" class="title">
    <span *ngFor="let entry of path; let i = index" (click)="goTo(i)"><span *ngIf="i>0"> > </span> <span [ngClass]="{last:i == path.length-1}"> {{entry.name}} </span> </span>
  </div> -->
  <path-title *ngIf="!bShowingAsset && router.url=='/assets'||router.url=='/manage'||router.url=='/hub'||router.url=='/kpay'" class=" title" [path]="path" (selected)=selPath($event)>
  </path-title>



  <!-- <div *ngIf="bShowingAsset && !call_id" class="title">
        <input class="asset_name" type="text" [(ngModel)]="asset_name" (focusout)="saveName()">
  </div> -->
  <!-- <k-button *ngIf="!bShowingAsset && !bRecording &&  router.url == '/call'" class="record" min_width="100" background="#40BE5C" color="white" text="Record" (click)="bRecording=!bRecording"></k-button>
  <k-button *ngIf="!bShowingAsset && bRecording  && router.url == '/call'" class="record" min_width="100" background="#F5423B" color="white" text="Stop" (click)="bRecording=!bRecording"></k-button> -->

  <img *ngIf="call_id" class="top_logo" [src]="company_logo" alt="homepage" (load)="onLogoeLoad($event)" [style.width.px]="img_w" [style.height.px]="img_h">



  <div *ngIf="router.url == '/profile'" class="lbl"> Settings </div>
  <div class="filler">
  </div>
  <recording class="record"></recording>
  <div class="vl">

  </div>
  <div class="agent ph nbox" (click)="toogleNotify()">
    <img class="notify" src="assets/images/notify.svg" width="16">
    <div class="notify_i" [ngClass]="{red:!bNotify}">

    </div>
  </div>

  <calls-waiting class="agent ph"></calls-waiting>
  <cal-icon class="agent ph"></cal-icon>
  <schedule-call class="agent links"></schedule-call>
  <div class="agent share" (click)="showLinks($event)">
    <div class="share_tx">
      Share call link
    </div>
  </div>
  <agent-profile class="agent"></agent-profile>
</div>
<div class="out" *ngIf="isPhone ">
  <!-- <recording class="abs_center"></recording> -->
  <img *ngIf="!bRecording" class="abs_center logo" [src]="img_src" alt="homepage">
  <div class="filler">
  </div>
  <phone-hang-up class="phone" (click)="hangUp()"></phone-hang-up>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { selectToolOptions } from 'src/app/canvas/canvas.selectors';
import { OpenCloseChat } from 'src/app/chat/chat.actions';
import { ChatState } from 'src/app/chat/chat.model';
import { openChat } from 'src/app/chat/chat.selectors';
import { SetAudio, SetVideo, SetShare } from 'src/app/k-video/k-video.actions';
import { KVideoState } from 'src/app/k-video/k-video.model';
import { audioOn, hasVideoCamera, shareOn, showingRemoteShare, videoOn } from 'src/app/k-video/k-video.selectors';
import { KVideoService } from 'src/app/k-video/k-video.service';
import { ShowKMenu, StartKPay, StopKPay } from 'src/app/kpay/kpay.actions';
import { kPayState } from 'src/app/kpay/kpay.model';
import { cancelLocalKPay, cancelRemoteKPay, runKPay } from 'src/app/kpay/kpay.selectors';
import { PayService } from 'src/app/kpay/pay.service';


import { WSSState } from 'src/app/wss/wss.model';
import { callId, lastMsg, myId } from 'src/app/wss/wss.selectors';
import { WSSService } from 'src/app/wss/wss.service';
import { AskHangUp, ContactInfo, NotifyError, ShowAsset, ShowCC } from '../../pages.actions';

import { PagesState } from '../../pages.model';
import { callerIsPhone, contactInfo, convergeAcc, onBoarding, showAsset, showCC } from '../../pages.selectors';

@Component({
  selector: 'desk-buttons',
  templateUrl: './desk-buttons.component.html',
  styleUrls: ['./desk-buttons.component.scss']
})

export class DeskButtonsComponent implements OnInit {
  contactInfo: string = "no"
  @Input() bShowChat: boolean = false
  // @Output() bShowChatChange: EventEmitter<boolean> = new EventEmitter();
  bShowChatNotification: boolean = false
  bHasVideoCamera: boolean = false;
  bAudioOn: boolean = false;
  bVideoOn: boolean = false;
  bShareOn: boolean = false;
  bShowingRemoteShare: boolean = false;
  show_tool: string = ""
  bShowDots: boolean = false;
  white_board_btn_back = "#ECECEC"
  three_dots_btn_back = "#ECECEC"
  bShowHangUp: boolean = false;
  show_asset: any
  my_id: string | undefined
  audio: any
  bASskingForEmail: boolean = false
  subscriptions: any[] = []
  caller_is_phone: boolean = false
  converge_acc: string | undefined
  ruuning_kpay: string | undefined


  constructor(
    private pagesState: Store<PagesState>,
    private wss_service: WSSService,
    private wssState: Store<WSSState>,
    private kVideoState: Store<KVideoState>,
    private k_video_service: KVideoService,
    public canvasStore: Store<CanvasState>,
    public chatStore: Store<ChatState>,
    public pay_service: PayService,
    public kpayState: Store<kPayState>,
  ) {
    console.log("desk-buttons")

    this.kpayState.select(runKPay).subscribe((running: string | undefined): void => {
      this.ruuning_kpay = running;
    })

    this.subscriptions.push(this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      if (call_id) {
        this.bShowHangUp = true
      } else {
        this.bShowHangUp = false
      }
    }))
    this.subscriptions.push(this.pagesState.select(onBoarding).subscribe((on_boarding: boolean) => {
      if (on_boarding) {
        this.bShowDots = true
      }

    }))

    this.subscriptions.push(this.pagesState.select(convergeAcc).subscribe((converge_acc: string | undefined) => {
      this.converge_acc = converge_acc
    }))
    this.subscriptions.push(this.pagesState.select(callerIsPhone).subscribe((caller_is_phone: boolean) => {
      this.caller_is_phone = caller_is_phone
    }))
    this.subscriptions.push(this.pagesState.select(contactInfo).subscribe((state: string) => {
      this.contactInfo = state;
      this.bASskingForEmail = false
    }))

    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      if (asset) {
        if (asset.asset_id) {
          this.bShowDots = true
          this.stopShare()
          return
        }
      }
      this.white_board_btn_back = "#ECECEC"
      this.bShowDots = false
    }))

    this.subscriptions.push(this.canvasStore.select(selectToolOptions).subscribe((tool: any) => {
      setTimeout(() => this.show_tool = tool.name)
    }))
    this.subscriptions.push(this.chatStore.select(openChat).subscribe((bOpen: boolean) => {
      this.bShowChat = bOpen
      this.bShowChatNotification = false;
    }))
    this.subscriptions.push(this.kVideoState.select(hasVideoCamera).subscribe((bHasVideoCamera: boolean) => {
      this.bHasVideoCamera = bHasVideoCamera
    }))
    this.subscriptions.push(this.kVideoState.select(shareOn).subscribe((on: boolean) => {
      if (this.bShareOn && !on) {
        this.bShowDots = false
      }
      this.bShareOn = on
      if (this.bShareOn) {
        this.bShowDots = true
      }
    }))
    this.subscriptions.push(this.kVideoState.select(showingRemoteShare).subscribe((on: boolean) => {
      this.bShowingRemoteShare = on
    }))
    this.subscriptions.push(this.kVideoState.select(audioOn).subscribe((on: boolean) => {
      this.bAudioOn = on
    }))
    this.subscriptions.push(this.kVideoState.select(videoOn).subscribe((on: boolean) => {
      this.bVideoOn = on
    }))
    this.subscriptions.push(this.wssState.select(myId).subscribe((id: string | undefined) => {
      this.my_id = id
    }))
    console.log("buttons")

    this.subscriptions.push(this.wssState.select(lastMsg).subscribe((msg: any) => {
      if (!this.bShowChat && msg) {
        if (msg.hasOwnProperty("chat_msg")) {
          if (msg.chat_msg.id != this.my_id)
            if (!this.bShowChatNotification) {
              this.bShowChatNotification = true;
              try {
                if (!this.audio) {
                  this.audio = new Audio();
                  this.audio.src = "assets/notify.mp3";
                  this.audio.load();
                }
                this.audio.play()
              } catch (e) {
                console.error(e)
              }
            }
        }
      }
    }))

    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      if (asset) {
        if (asset.asset_id || asset._id) {
          this.show_asset = asset;
          return;
        }
      }
      delete this.show_asset
    }))

  }

  @Output() actionChanged: EventEmitter<any> = new EventEmitter();
  settings() {
    if (this.show_tool == "sel_media") {
      this.canvasStore.dispatch(new SelectToolOpts(""))
    } else {
      this.canvasStore.dispatch(new SelectToolOpts("sel_media"))
    }
  }
  hangUp() {
    this.pagesState.dispatch(new AskHangUp(true))
    // this.wss_service.hangUp()
  }
  ngOnInit(): void {
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  setAudio($event: boolean) {
    // console.log("Set audio " + $event)
    this.kVideoState.dispatch(new SetAudio($event))
  }
  setVideo($event: boolean) {
    // console.log("Set video " + $event)
    if (this.bHasVideoCamera) {
      this.kVideoState.dispatch(new SetVideo($event))
    } else if ($event) {
      this.pagesState.dispatch(new NotifyError("No video camera detected"))
    }
  }

  setShare($event: boolean) {
    console.log("Set share " + $event)
  }
  selMedia() {
    console.log("Sel media ")
  }


  closeChat() {
    this.chatStore.dispatch(new OpenCloseChat(false))
  }
  openChat() {
    this.chatStore.dispatch(new OpenCloseChat(true))
  }

  scheduleCall() {

  }
  share() {

    // if (!this.bShowingRemoteShare) {
    this.pagesState.dispatch(new ShowAsset({}))
    let msg: any = {
      show_asset: "none"
    }
    this.wss_service.sendMessageToMembersAndMe(msg)
    this.kVideoState.dispatch(new SetShare(true))
    this.k_video_service.shareScreen()
    // }
  }
  stopShare() {
    this.kVideoState.dispatch(new SetShare(false))
    this.k_video_service.stopShareScreen()
  }
  askEmail() {
    this.pagesState.dispatch(new ContactInfo("ask"))
    this.wss_service.sendMessageToOtherMembers({
      ask_for: "email"
    })
    this.bASskingForEmail = !this.bASskingForEmail;
  }
  whiteBoard() {
    if (this.show_asset) {

      this.pagesState.dispatch(new ShowAsset({}));
      let msg: any = {
        show_asset: "none"
      }
      this.wss_service.sendMessageToMembersAndMe(msg)
      if (this.show_asset.type == 'white_board') {
        return
      }
    }

    this.white_board_btn_back = "#DAE1F7"

    // if (this.bShowDots) {
    //   this.pagesState.dispatch(new ShowAsset({}));
    //   let msg: any = {
    //     show_asset: "none"
    //   }
    //   this.wss_service.sendMessageToMembersAndMe(msg)
    //
    // } else {
    let wb_asset = { type: "white_board", asset_id: "white_board1", name: "Whiteboard" }
    // wb_asset = { type: "video_canvas", asset_id: "video_canvas1", name: "Videoboard" }


    this.pagesState.dispatch(new ShowAsset(wb_asset))
    this.wss_service.sendMessageToOtherMembers({ show_asset: wb_asset })
    this.pagesState.dispatch(new OpenCloseChat(false))
    // }
  }
  canvasOpts() {
    if (this.show_tool == "canvas-opts") {
      this.canvasStore.dispatch(new SelectToolOpts(""))
      this.three_dots_btn_back = "#ECECEC"

    } else {
      this.canvasStore.dispatch(new SelectToolOpts("canvas-opts"))
      this.three_dots_btn_back = "#DAE1F7"
    }
  }
  close() {
    if (this.bShareOn) {
      this.stopShare()
    } else {
      this.pagesState.dispatch(new ShowAsset({}));
      //The message is listend to by pages/in-call
      let msg: any = {
        show_asset: "none"
      }
      this.wss_service.sendMessageToMembersAndMe(msg)
    }
  }
  kpay() {
    if (this.converge_acc == "keyzii") {
      if (this.ruuning_kpay) {
        this.kpayState.dispatch(new StopKPay())
      } else {
        this.kpayState.dispatch(new ShowKMenu(true))
      }
    } else {
      if (this.ruuning_kpay && this.ruuning_kpay == "pay") {
        this.kpayState.dispatch(new StopKPay())
      } else {
        this.kpayState.dispatch(new StartKPay()) //The menu will launch the window
      }
    }
  }

  close_kpay() {
    this.pay_service.cancelInvoice()

  }
}
